import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  acceptRejectArticle,
  updatePRReport,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RejectModal = ({
  showReject,
  setShowReject,
  articleId,
  prStatus,
  setUpdateRequestList,
  userId,
}) => {
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState("");
  const [reason, setReason] = useState("");
  const [reasonInput, setReasonInput] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");

  const handleClose = () => setShowReject(false);
  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const onChangeOtherReason = (e) => {
    setReasonInput(e.target.value)
  }

  const onChangeReason = (e) => {
    setAdditionalComment(e.target.value);
  };

  const handleAcceptRejectArticle = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    if (prStatus === "draft") {
      const params = {
        id: articleId,
        prStatus: status,
        prId: userId,
        additionalComment: additionalComment,
        reason: reason === "Other" ? reasonInput : reason
      };
      const response = await updatePRReport(headers, params);
      if (response?.status === 200) {
        setShowReject(false);
        navigate("/peer-reviewer/notAccepted", { state: 'notAccepted' });
        setUpdateRequestList(true);
      } else {
        return toast.error("Please try again!!");
      }
    } else {
      const params = {
        id: articleId,
        peStatus: status,
        peId: userId,
        reason: reason === "Other" ? reasonInput : reason,
        additionalComment: additionalComment
      };
      const response = await acceptRejectArticle(headers, params);
      if (response?.status === 200) {
        setShowReject(false);
        navigate("/publishing-editor/notAccepted", { state: 'notAccepted' });
        setUpdateRequestList(true);
      } else {
        return toast.error("Please try again!!");
      }
    }
  };

  useEffect(() => {
    if (reason === "Other") {
      setDisabled(!reasonInput)
    } else {
      setDisabled(!reason)
    }
  }, [reason, reasonInput])

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      show={showReject}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered modal-md authorModal"
      className="md-x"
    >
      <Modal.Header className="modal-header">
        <h5 className="modal-title">{prStatus === "draft" ? "Reject for Peer Review" : "Reject for editorial assessment"}</h5>
        <button
          type="button"
          className="btn-close"
          ariaLabel="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="rejectModal">
          <div className="row">
            <div className="col-md-12">
              <p>Please specify your reason</p>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Personal"}
                  checked={reason === "Personal"}
                />
                <b></b>
                <span>Personal</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Conflict of Interest"}
                  checked={reason === "Conflict of Interest"}
                />
                <b></b>
                <span>Conflict of Interest</span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Sorry! I don't have the time currently"}
                  checked={reason === "Sorry! I don't have the time currently"}
                />
                <b></b>
                <span>Sorry! I don't have the time currently</span>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Mismatch of my expertise with article content"}
                  checked={reason === "Mismatch of my expertise with article content"}
                />
                <b></b>
                <span>Mismatch of my expertise with article content</span>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Other"}
                  checked={reason === "Other"}
                />
                <b></b>
                <span>Any other reason</span>
              </label>
            </div>
            {reason === "Other" && (
              <div className="col-md-12">
                <textarea
                  className="inputField"
                  placeholder="Write here..."
                  onChange={(e) => onChangeOtherReason(e)}
                  value={reasonInput}
                  maxLength={100}
                ></textarea>
              </div>
            )}
            <div className="col-md-12">
              <div className="additionalTextarea">
              <span>Additional Comments</span>
              <textarea
                className="inputField"
                placeholder="Please add your comment here..."
                onChange={(e) => onChangeReason(e)}
                value={additionalComment}
                maxLength={100}
              ></textarea>
          </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="pb-4">
        <button
          class="button button-primary"
          type="submit"
          onClick={() => {
            handleAcceptRejectArticle("notAccepted");
          }}
          disabled={disabled}
        >
          SUBMIT
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectModal;
