import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import NewsLetter from "../components/global/NewsLetter";
import {
  CONTENT_HEADING,
  FEATURED_CARD_HEADINGS,
  SUB_HEADINGS,
} from "../constants";
import SubHeader from "../components/global/SubHeader";
import Content from "../components/global/Content";
import FeaturedCard from "../components/global/FeaturedCard";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import MobileModalAlert from "../components/Modal/MobileModalAlert";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

function EthicsPolicy() {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  return (
    <>
     {/* <Helmet>
          <title> Aakashganga Article Publication Process</title>
          <meta name="description" content="Learn about Aakashganga  article publication process and how we ensure transparent, efficient, and inclusive publishing for researchers. Explore our peer review models, submission steps, and article processing charges" />

          <meta property="og:title" content="Aakashganga Article Publication Process" data-react-helmet="true" />
          <meta property="og:description" content="Learn about Aakashganga  article publication process and how we ensure transparent, efficient, and inclusive publishing for researchers. Explore our peer review models, submission steps, and article processing charges" data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/how-we-publish" data-react-helmet="true" />


     <link rel="canonical" href="https://www.aakashgangaopen.in/how-we-publish" data-react-helmet="true" />
     <meta name="robots" content="index, follow" />
     </Helmet> */}
    <div className="tagbody">
      <AakashgangaHeader />
      <h1 className="seo-related-tag">
      Ethics Policy
			</h1>
      <main className="main">
        <SubHeader title={SUB_HEADINGS.ETHICS_POLICY} />

        <div className="content-area">
          <div className="container">
            <p className="pera-text tagp mt-3">
            At Aakashganga Open, we are committed to maintaining the highest standards of integrity, transparency, and ethical conduct in all aspects of our publishing activities. Our mission is to foster a trusted environment for researchers, authors, reviewers, and readers. We believe that ethical practices are the cornerstone of a vibrant and credible academic community.{" "}
            </p>

            {/* <p className="pera-text tagp">
            <b>Below, we outline the key principles that guide our operations: {" "}</b>
            </p> */}

           
        <div className="howWorkText">
            <Content title={"Below, we outline the key principles that guide our operations: "}>
            <p className="pera-text tagp">
  <strong>1. Authorship and Contribution</strong>
  <br />
  We recognize the significance of accurate authorship attribution. All authors listed on a manuscript should have made a significant contribution to the research and writing process. We expect all contributors to be clearly identified, and any disputes regarding authorship should be resolved before submission.
  <br /><br />
  
  <strong>2. Originality and Plagiarism</strong>
  <br />
  We uphold a zero-tolerance policy towards plagiarism. Authors must ensure that their work is original and properly cited. Any use of another's work or ideas must be clearly acknowledged, with appropriate references. We use advanced plagiarism detection tools to verify the originality of submissions, and any manuscript found to contain plagiarized content will be rejected.
  <br /><br />
  
  <strong>3. Data Integrity and Transparency</strong>
  <br />
  Accuracy and transparency in data presentation are vital. Authors must present their research data truthfully and without fabrication, falsification, or inappropriate manipulation. Data should be made available for verification upon request, and any issues related to data integrity must be disclosed to the editorial board immediately.
  <br /><br />
  
  <strong>4. Conflict of Interest</strong>
  <br />
  We require authors, reviewers, and editors to disclose any potential conflicts of interest that could influence their objectivity. This includes financial, personal, or professional relationships that may affect the research or its interpretation. Full disclosure ensures transparency and fosters trust within our community.
  <br /><br />
  
  <strong>5. Peer Review Process</strong>
  <br />
  The peer review process is central to our commitment to academic rigor. We ensure that all submissions undergo a thorough, fair, and unbiased review by experts in the relevant field. Reviewers are expected to provide constructive feedback, maintain confidentiality, and disclose any potential conflicts of interest.
  <br /><br />
  
  <strong>6. Ethical Considerations in Research</strong>
  <br />
  All research involving human or animal subjects must adhere to ethical guidelines and obtain appropriate approvals from institutional review boards or ethics committees. Authors must include a statement in their manuscript confirming that ethical standards were met and that informed consent was obtained where necessary.
  <br /><br />
  
  <strong>7. Corrections and Retractions</strong>
  <br />
  If errors or ethical breaches are discovered post-publication, we are committed to taking prompt corrective action. Corrections will be issued to address minor errors, while more significant issues may warrant a retraction. Our priority is to maintain the accuracy and integrity of the academic record.
  <br /><br />
  
  <strong>8. Intellectual Property</strong>
  <br />
  We respect the intellectual property rights of authors and expect the same from our community. Authors retain copyright of their work under the terms of the chosen open access license, allowing for broad dissemination while protecting their rights.
  <br /><br />
  
  <strong>9. Diversity and Inclusion</strong>
  <br />
  We are dedicated to fostering an inclusive environment where diversity in thought, perspective, and background is valued. We strive to ensure that our editorial board, reviewers, and authors reflect a wide range of voices, and we are committed to providing equal opportunities for all.
  <br /><br />
  
  <strong>10. Environmental Responsibility</strong>
  <br />
  As part of our broader commitment to sustainability, we encourage practices that reduce the environmental impact of research and publishing. This includes promoting digital submissions, reducing unnecessary printing, and supporting research that contributes to environmental preservation.
  <br /><br />
  
  By adhering to these principles, we aim to contribute to the advancement of knowledge while upholding the ethical standards that are essential to scholarly communication. We invite all members of our community to join us in this commitment to integrity, respect, and excellence.
</p>

            </Content>
            

            </div>
            <FeaturedCard title={FEATURED_CARD_HEADINGS.ARCHIVING}>
              <div className="archive-content effortsarchiving">
                We at Vikramshila are conscious about the long term availability
                of all OA content published by us. We are committed to making
                this available as close to 100% of the time as is humanly
                possible. Rest assured that your contributions to our open
                access journals have a lasting impact. As we generate a DOI for
                each article through CrossRef, it’s important to note that this
                not only provides a unique and permanent identifier for your
                work but also ensures its automatic inclusion within the
                CrossRef archives. This integration further enhances the
                visibility and accessibility of your research, contributing to
                the global dissemination of knowledge.<br/><br/> If for some reason we are
                unable to host the content where circumstances are beyond our
                control, we promise to let our stakeholders know about this and
                commit to finding solutions that will fill the void.
              </div>
            </FeaturedCard>
            <p className="pera-text last-faq-section tagp">
              For more questions on our processes, policies and other aspects,
              please refer to our{" "}
              <Link to="/faq" target="_blank">
                <strong className="underline ">FAQ section.</strong>
              </Link>
            </p>
          </div>
        </div>
      </main>
      <NewsLetter />
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
      <AakashgangaFooter />
      </div>
    </>
  );
}

export default EthicsPolicy;
