import { useState } from "react";
import NewsLetter from "../components/global/NewsLetter";
import {
  openScienceObjective,
  whatIsOpenScience,
  openScienceComponents,
  openAccessData,
  didYouKnowData,
  publishOpenSurceReasonData,
  howOpenAccessWorks,
  Headings,
} from "../components/unlockingknowforall/data";

import PeraTextData from "../components/global/PeraTextData";
import SubHeader from "../components/global/SubHeader";
import Title from "../components/global/Title";
/*eslint-disable*/
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import OpenAccessModal from "../components/Modal/OpenAccessModal";
import { Helmet } from 'react-helmet';

function UnlockingKnowledgeForAll() {
  const [show, setShow] = useState(false);
  const checkNow = () => {
    window.open("https://creativecommons.org/share-your-work/cclicenses/");
  }
  return (
    <>
     <Helmet>
        <link rel="canonical" href="https://www.aakashgangaopen.in/open-science" data-react-helmet="true" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
          <h1 className="seo-related-tag">
            Open Access
          </h1>
        <main className="main">
          <SubHeader
            title={Headings.OPEN_SCIENCE_UNLOCKING_KNOWLEDGE}
            className={"opensci"}
          />

          <div className="content-area">
            <div className="container">
              <PeraTextData data={openScienceObjective} />

              <Title
                title={Headings.WHAT_IS_OPEN_SCIENCE}
                className={"large-title"}
              />

              <PeraTextData data={whatIsOpenScience} />

              <Title
                title={Headings.COMPONENTS_OF_OPEN_SCIENCE}
                className={"small-title1"}
              />

              <PeraTextData data={openScienceComponents} />

              <Title
                title={Headings.PATHWAY_TO_KNOWLEDGE}
                className={"large-title"}
              />

              <PeraTextData data={openAccessData} />

              <Title title={Headings.DID_YOU_KNOW} className={"large-title"} />

              <p className="pera-text automated tagp">The Budapest Open Access Initiative in 2002 marked a significant step in defining the goals and strategies of the OA movement, of which Vikramshila Research Pvt Ltd (Owners of Aakashganga) is a <a href="https://www.budapestopenaccessinitiative.org/" className="colorBlack" target='blank'><strong className='underline'><u>proud signatory</u></strong></a>.</p>
              <p className="pera-text automated tagp">Aakashganga operates as a <strong className='underline cursor-pointer' onClick={() => setShow(true)}>Gold Open Access</strong>  publishing platform. Our commitment lies in making every article across our array of journals readily accessible online immediately upon publication.</p>
              <p className="pera-text automated tagp">Our Open Access model ensures that information isn’t restricted by financial barriers, making it accessible to researchers, students, and curious minds from all walks of life.</p>
              <p className="pera-text automated tagp">All articles published through Aakashganga are released under the <a href='https://creativecommons.org/licenses/by-nc-sa/4.0/' target='blank' class='colorBlack'><strong className='underline'>CC-BY-NY-SA</strong></a> license, allowing unrestricted utilization, distribution, and reproduction across various mediums, provided proper accreditation is given to the original authors and the source.</p>
              <p className="pera-text automated tagp">These endeavours are upheld through the charge of one of the lowest Article Processing Charges (APCs) in the world, so that everyone wanting to get their paper published has the financial opportunity to do so.</p>
              <p className="pera-text automated tagp">The platform offers multiple and discounts and the opportunity for researchers to apply for grants with the click of a button. It is our endeavour to bring researchers and grant offers on to the same platform thus eliminating the need for cumbersome grant writing and approval processes. We are currently focussed on India but in time we hope to expand grants to other developing nations too.</p>
              <p className="pera-text automated tagp">Our mission is to facilitate unhindered access to knowledge, and promote an equitable, inclusive and affordable platform for seeking, sharing, and creating knowledge that benefits society at large. With free access to research, scholars worldwide can build upon existing findings, leading to faster scientific advancements and innovation.</p>
              <p className="pera-text automated tagp">Our commitment towards Open Science is upheld through a meticulous and automated Peer Review
                Process of two kinds: <strong className='underline'>Pre-Publishing Double Anonymous Peer Review</strong> and <strong className='underline'>Post Publication Open Peer Review.</strong></p>
              <p className="pera-text automated tagp">This is further complemented by technological innovations, including automated Peer Reviewer and Publishing Editor selection, as well as online Peer Review reporting and Publishing Editor decision making.</p>
              <p className="pera-text automated tagp">Further, we support a researcher’s journey from start to end, with 95% automation, upholding research integrity, alongside global dissemination efforts.</p>
              <p className="pera-text automated tagp">By eliminating barriers inhibiting the unhampered distribution of knowledge, we aim to contribute to the transformation of India into a knowledge superpower and a global leader in Higher Education empowering creation of solutions crucial for fostering healthy lives on a thriving planet.</p>
              <p className="pera-text automated tagp">Alongside this objective we want to empower the developing world by providing an affordable, transparent and speedy publishing platform that is free from the expensive Western publishing models.</p>
              <Title
                title={Headings.WHY_SHOULD_YOU_PUBLISH_OPEN_ACCESS}
                className={"large-title"}
              />

              <PeraTextData data={publishOpenSurceReasonData} />

              <Title
                title={Headings.HOW_OPEN_ACCESS_WORKS}
                className={"large-title"}
              />

              <PeraTextData data={howOpenAccessWorks} />

              <h2 className="large-title h2p tagh2 mb-0">
                To Read more about Creative<p className="tagp"> Common Licenses Here</p>
              </h2>
              <div className="register-button mt-4">
                <button className="tagbutton" onClick={checkNow}>Check now</button>
              </div>
              <div className="row quiz">
                <div className="quizLeft">
                  <img src="assets/images/ready1.png" alt="#" className="tagimg" />
                </div>
                <div className="quizRight">
                <div className="quizContent">
                  <span>
                    <p className="takequiz tagp">
                      <span className="blue-text">
                        Take our quiz to understand why
                      </span>
                      <strong className="bold600"> Open Science</strong>
                      <br />
                      <span className="blue-text">
                        matters and why you should consider publishing <br />
                        your work using{" "}
                      </span>
                      <strong className="bold600">Open Access </strong>
                      <span className="blue-text">principles?</span>
                    </p>
                  </span>
                  <div className="register-button register-unlocking-btn">
                    <a
                      href="https://docs.google.com/forms/d/1Cw0_2GfXAL8tAOutPuUn_nbFdhlIKn6phYWF8G7GUqA/edit"
                      target="_blank"
                      className="take_a_quize taga"
                    >
                      Take the Quiz
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <NewsLetter />
        <AakashgangaFooter />
        <OpenAccessModal show={show} setShow={setShow} />
      </div>
    </>
  );
}

export default UnlockingKnowledgeForAll;
