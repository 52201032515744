import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard";
import "./index.scss";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import jwt_decode from "jwt-decode";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useSelector, useDispatch } from "react-redux";
import {
  getArticleDetails,
  getPRDetails,
} from "../../../store/apiCalls/dashboard";
import PRListing from "./PRListing";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import RecommendedArticles from "../dashboard/RecommendedArticles";

const PrDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.LoginReducer);
  const limit = 3;
  const { id } = jwt_decode(localStorage.usertoken);
  const [articleData, setArtcileData] = useState([]);
  const [articleTotalData, setArticleTotalData] = useState([]);
  const [prArticleData, setPRArticleData] = useState([]);
  const [prTotalData, setPRTotalData] = useState([]);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("draft");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const [prActiveTab, setPRActiveTab] = useState("draft");
  const [collapseActive, setIsActive] = useState(false);
  const expandClick = (event) => {
    setIsActive((current) => !current);
  };

  const fetchArticleData = (tab) => {
    const fetchdata = async () => {
      const params = {
        userId: id,
        status: tab,
        page: page,
        limit: limit,
      };
      return getArticleDetails(headers, params);
    };

    fetchdata()
      .then((userData) => {
        if (userData.status === 200) {
          setArtcileData(userData?.data?.data?.article);
          setArticleTotalData(userData?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchPRArticle = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = {
      userId: id,
      page: page,
      limit,
      prStatus: tab,
    };
    const response = await getPRDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPRArticleData(response?.data?.data?.data);
      setPRTotalData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  useEffect(() => {
    if (updateTrigger) {
      fetchArticleData("published");
    } else if (amendmentUpdateTrigger) {
      fetchArticleData("published")
    } else {
      fetchArticleData("draft");
    }
    fetchPRArticle("draft");
    setIsActive(true);
  }, [page, amendmentUpdateTrigger, updateTrigger, updateRequestList]);

  const getArticleType = (type) => {
    fetchArticleData(type);
    setActiveTab(type);
  };

  const getPRArticleType = (type) => {
    fetchPRArticle(type);
    setPRActiveTab(type);
  };

  return (
    <DashboardLayout>
      <div className="pedashboardBox">
        <div className="pedashboardLeft">
          <p className="welcome">
            Welcome,{" "}{user?.fullName.charAt(0).toUpperCase() + user?.fullName.slice(1)}
          </p>
          <div className="articleWrap">
            <div className="articleLeft">
              <h2>My Dashboard</h2>
            </div>
          </div>
        </div>
        <div className="pedashboardMidd">
          <ul className="reviewGray">
            <li>
              <span>Accepted</span>
              <b>{prTotalData?.numberOfAcceptedRecord}</b>
            </li>
            <li>
              <span>Reviewed</span>
              <b>{prTotalData?.numberOfReviewRecord}</b>
            </li>
            <li>
              <span>Not Accepted</span>
              <b>{prTotalData?.numberOfNotAccepted}</b>
            </li>
          </ul>
        </div>
      </div>

      <div className="articleTabsWrap mt-0 pt-0 border-0">
        <h3 className="commanTitle">For Peer Review</h3>
        <div className="customTabs">
          <Tab.Container defaultActiveKey="newrequests">
            <Nav>
              <Nav.Item>
                <Nav.Link
                  eventKey="newrequests"
                  onClick={() => [
                    setPRActiveTab("draft"),
                    getPRArticleType("draft"),
                  ]}
                >
                  New Requests
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="accepted"
                  onClick={() => [
                    setPRActiveTab("accepted"),
                    getPRArticleType("accepted"),
                  ]}
                >
                  Accepted
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="reviewed"
                  onClick={() => [
                    setPRActiveTab("reviewed"),
                    getPRArticleType("reviewed"),
                  ]}
                >
                  Reviewed
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="notaccepted"
                  onClick={() => [
                    setPRActiveTab("notAccepted"),
                    getPRArticleType("notAccepted"),
                  ]}
                >
                  Not Accepted
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="newrequests">
                <PRListing
                  prArticleData={prArticleData}
                  prActiveTab={prActiveTab}
                  totalRecords={prTotalData?.numberRecordBaseOnFilter}
                  fetchPRArticle={fetchPRArticle}
                  setUpdateRequestList={setUpdateRequestList}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="accepted">
                <PRListing
                  prArticleData={prArticleData}
                  prActiveTab={prActiveTab}
                  totalRecords={prTotalData?.numberRecordBaseOnFilter}
                  fetchPRArticle={fetchPRArticle}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="reviewed">
                <PRListing
                  prArticleData={prArticleData}
                  prActiveTab={prActiveTab}
                  totalRecords={prTotalData?.numberRecordBaseOnFilter}
                  fetchPRArticle={fetchPRArticle}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="notaccepted">
                <PRListing
                  prArticleData={prArticleData}
                  prActiveTab={prActiveTab}
                  totalRecords={prTotalData?.numberRecordBaseOnFilter}
                  fetchPRArticle={fetchPRArticle}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <div className="innerTabsView">
          <div className="preReview">
            <span>My Articles</span>
          </div>

          <div className="customTabs">
            <div className="articleTabsWrap mt-3 pt-0 border-0">
              <div className="customTabs">
                <Tab.Container defaultActiveKey="draft">
                  <div className="tabsBoxin">
                    <Nav>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="draft"
                          onClick={() => [
                            setActiveTab("draft"),
                            getArticleType("draft"),
                          ]}
                        >
                          Drafts
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="underReview"
                          onClick={() => [
                            setActiveTab("submit"),
                            getArticleType("submit"),
                          ]}
                        >
                          Under Review
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="approved"
                          onClick={() => [
                            setActiveTab("approved"),
                            getArticleType("approved"),
                          ]}
                        >
                          Approved
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="rejected"
                          onClick={() => [
                            setActiveTab("rejected"),
                            getArticleType("rejected"),
                          ]}
                        >
                          Rejected
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="published"
                          onClick={() => [
                            setActiveTab("published"),
                            getArticleType("published"),
                          ]}
                        >
                          Published{" "}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <button
                      className={collapseActive ? "colapseBtn" : ""}
                      onClick={expandClick}
                    >
                      {collapseActive ? "Collapse" : "Expand"}
                      <i>
                        <img src={downarrow} alt="#" />
                      </i>
                    </button>
                  </div>

                  <div className="subReviewGray mt-4">
                    <ul className="reviewGray">
                      <li>
                        <span>Under Review</span>
                        <b>{articleTotalData?.numberOfSubmitRecords || 0}</b>
                      </li>
                      <li>
                        <span>Approved</span>
                        <b>{articleTotalData?.numberOfApprovedRecords || 0}</b>
                      </li>
                      <li>
                        <span>Published</span>
                        <b>{articleTotalData?.numberOfPublishedRecords || 0}</b>
                      </li>
                    </ul>
                  </div>

                  <Tab.Content className={collapseActive ? "show" : "hide"}>
                    <Tab.Pane eventKey="draft">
                      {activeTab === "draft" && (
                        <ArticleListing
                          articleData={articleData}
                          activeTab={activeTab}
                          totalRecords={
                            articleTotalData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="underReview">
                      {activeTab === "submit" && (
                        <ArticleListing
                          articleData={articleData}
                          activeTab={activeTab}
                          totalRecords={
                            articleTotalData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="approved">
                      {activeTab === "approved" && (
                        <ArticleListing
                          articleData={articleData}
                          activeTab={activeTab}
                          totalRecords={
                            articleTotalData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="rejected">
                      {activeTab === "rejected" && (
                        <ArticleListing
                          articleData={articleData}
                          activeTab={activeTab}
                          totalRecords={
                            articleTotalData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="published">
                      {activeTab === "published" && (
                        <ArticleListing
                          articleData={articleData}
                          activeTab={activeTab}
                          totalRecords={
                            articleTotalData?.numberRecordBaseOnFilter
                          }
                          setAmendmentUpdateTrigger={setAmendmentUpdateTrigger}
                          setUpdateTrigger={setUpdateTrigger}
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <RecommendedArticles /> */}
    </DashboardLayout>
  );
};

export default PrDashboard;
