import NewsLetter from "../components/global/NewsLetter";
import { Link } from "react-router-dom";
import AakashgangaHeader from "../components/global/HeaderComponent";
/*eslint-disable*/
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

function PublishWithUs() {
	const scrollLink = (e) => {
		window.scroll({ top: 0, behavior: "smooth" });
	};
	return (
		<>
		 <Helmet>
			<title> Publish with us | Aakashganga </title>
			<meta name="description" content="Discover the aakashganga seven mega journal and scope of mega journal. Explore our fully automated processes, diverse article types, low APCs, and robust peer review options. Publish your research with us today!" />

			<meta property="og:title" content="Publish with us | Aakashganga " data-react-helmet="true" />
			<meta property="og:description" content="Discover the aakashganga seven mega journal and scope of mega journal. Explore our fully automated processes, diverse article types, low APCs, and robust peer review options. Publish your research with us today!" data-react-helmet="true" />
			<meta property="og:url" content="https://www.aakashgangaopen.in/publish-with-us" data-react-helmet="true" />
		  
		 	<link rel="canonical" href="https://www.aakashgangaopen.in/publish-with-us" data-react-helmet="true" />
			 <meta name="robots" content="index, follow" />
		 </Helmet>
		<div className="tagbody">
		<AakashgangaHeader />
			<h1 className="seo-related-tag">
				Publish With Us
			</h1>
			<main className='main'>
				<div className='banner-section'>
					<div className='page-title'>
						<h1 className='title tagh1'>
							Publ<span>i</span>sh With Us
						</h1>
					</div>
				</div>

				<div className='content-area'>
					<div className='container'>
						<p className='pera-text tagp mt-3'>
							Aakashganga offers Seven Open Access Digital Mega
							Journals that publish articles with the lowest
							turn-around time, and one of the lowest APCs. In due
							course we will add University/Institute/Society
							journals to our portfolio. All journals will be in
							Open Access format only.
						</p>

						<p className='pera-text tagp'>
							<strong>Publishing with Aakashganga means:</strong>
						</p>
						<div className="publishUl">
						<ul className='publish-with-us tagul'>
							<li className='tagli'>A Fully automated publishing platform</li>
							<li className='tagli'>
								Single-view dashboards; transparent and detailed
								on your finger tips
							</li>
							<li className='tagli'>
								Option of Pre Publishing & Post Publishing Peer
								Reviews
							</li>
							<li className='tagli'>Multiple article types</li>
							<li className='tagli'>Indexing for articles</li>
							<li className='tagli'>Resources to learn</li>
							<li className='tagli'>Quick support</li>
						</ul>
						</div>

						<h2 className='large-title tagh2 '>
							Scope of Aakashganga Mega Journals
						</h2>

						<p className='pera-text tagp'>
							Aakashganga welcomes original research articles,
							review papers, case studies, critical analyses and
							other research outputs that report basic scientific,
							scholarly, translational and clinical research
							across Humanities, Social Sciences, Business and
							Management, Medicine, Sciences, Ayurveda and Yoga,
							And Engineering.
						</p>
						<p className='pera-text tagp'>
							We encourage interdisciplinary research and
							multidisciplinary collaboration, recognizing the
							value of cross-pollination of ideas and
							methodologies.
						</p>
						<p className='pera-text section tagp'>
							Every author writing on the Aakashganga platform is
							a qualified researcher, scholar, professional or
							clinician actively working in their speciality.
						</p>
						<p className='pera-text efforts tagp'>
							Overall, Aakashganga aspires to be an inclusive and
							dynamic hub for scholarly communication, offering a
							diverse range of disciplines under one roof. We aim
							to foster interdisciplinary collaborations,
							accelerate research progress, and contribute to the
							collective knowledge of humanity. By definition Mega
							Journals will publish new contributions to the
							discipline instead of focussing on the uniqueness of
							the research.
						</p>

						<p className='pera-text how-publish-faq tagp'>
							For more questions on our processes, policies and
							other aspects, please refer to our <Link  to="/faq" target="_blank"><strong className='underline '>FAQ section.</strong></Link>
						</p>

						<h2 className='large-title tagh2'>
							Aakashganga Mega Journals
						</h2>
						<Link target="_blank" to={`/mega-journals/social-sciences`} onClick={scrollLink}>
						<img className='large-title tagimg mt-2 pb-0 mb-0' alt='Samaj Shastra' src='assets/images/samaj-shastra-new1.png' /></Link>

						<p className='pera-text tagp'>
							Samaj Shastra translates to Social Science in Hindi, is an Open
							Access Mega Journal, making high-quality Social
							Science research accessible to a global audience
							from India.
						</p>

						<p className='pera-text efforts tagp'>
							The Mega Journal, welcomes contributions from all
							subfields within social sciences, such as sociology,
							psychology, anthropology, political science, and
							more. It provides a comprehensive framework for
							understanding and analyzing the complexities of
							human society, its structures, institutions, and
							interactions. Researchers and scholars specializing
							in Samaj Shastra engage in empirical studies,
							theoretical analyses, and critical examinations of
							social phenomena, aiming to uncover patterns,
							explore social dynamics, and contribute to the
							existing body of knowledge. Its inclusive approach
							fosters interdisciplinary collaboration, encouraging
							researchers to explore the intersections of
							different disciplines and promote holistic
							understanding of societal dynamics.
						</p>

						<p className='pera-text efforts tagp'>
							Join us on this exciting journey as we redefine the
							boundaries of social science research and strive for
							a more inclusive and informed world.
						</p>
						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/social-sciences`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<Link target="_blank" to={`/mega-journals/business-management`} onClick={scrollLink}><img
							src='assets/images/chanakya-shastra-new1.png'
							className='logo-shastra tagimg mt-3' alt='Chanakya Shastra'
						/></Link>

						<p className='pera-text section tagp'>
							Chanakya Shastra, The Mega Journal of Business and
							Management, aims to provide a platform for
							researchers, academicians, and practitioners to
							share their cutting-edge insights, transformative
							ideas, and rigorous scholarly research in diverse
							areas of business and management.
						</p>

						<p className='pera-text tagp'>
							Chanakya Shastra derives its name from the ancient
							Indian text attributed to Chanakya, an eminent
							scholar and advisor to the Mauryan Empire.
						</p>

						<p className='pera-text efforts tagp'>
							With a broad and inclusive scope, Chanakya Shastra
							welcomes contributions from various disciplines,
							promoting interdisciplinary dialogue and
							collaboration. We encourage submissions related to
							general management, marketing, finance and
							accounting, operations and supply chain management,
							human resources management, international business,
							organizational theory and behaviour, business ethics
							and corporate social responsibility, information
							systems and technology management, and business law
							and regulations amongst others.
						</p>

						<p className='pera-text efforts tagp'>
							We also welcome studies that explore emerging
							trends, disruptive technologies, and transformative
							business models that shape the future of
							organizations.{" "}
						</p>
						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/business-management`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<Link target="_blank" to={`/mega-journals/medicine`} onClick={scrollLink}><img
							src='assets/images/charakh-shashtra-new1.png'
							className='logo-shastra tagimg mt-3' alt='Charakh Shastra'
						/></Link>

						<p className='pera-text tagp'>
							Charakh Shastra, The Mega Journal of Medicine aims
							to be a comprehensive platform for the publication
							of high-quality research in the field of medicine,
							encompassing various disciplines and specialties.
						</p>

						<p className='pera-text efforts tagp'>
							The name Charakh Shastra is inspired by Charakh
							Samhita, written by the sage Charakh, which provides
							valuable insights into the principles of disease
							management, prevention, and overall well-being.
						</p>

						<p className='pera-text tagp'>
							We are dedicated to advancing the field of medicine
							through the dissemination of high-quality,
							peer-reviewed research. With a commitment to open
							access, we aim to make valuable medical knowledge
							freely available to a global audience, ensuring that
							healthcare professionals, researchers, and the
							general public can benefit from the latest
							advancements in medical science.
						</p>

						<p className='pera-text efforts tagp'>
							By promoting evidence-based medicine and
							highlighting innovative approaches to healthcare
							delivery, our journal aims to improve patient
							outcomes, inform medical practice, and shape the
							future of medicine.
						</p>
						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/medicine`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<Link target="_blank" to={`/mega-journals/engineering`} onClick={scrollLink}><img
							src='assets/images/abhiyantran-shatra.png'
							className='logo-shastra tagimg mt-3' alt='Abhiyantran Shastra'
						/></Link>

						<p className='pera-text tagp'>
							Abhiyantran Shastra champions excellence and is committed to openness as reflected in our Gold Open Access policy. With an automated submission and processing system, we ensure a seamless journey for authors, backed by our pledge to provide one of the most affordable Article Processing Charges globally, making scholarly contributions attainable for all.
						</p>

						<p className='pera-text efforts tagp'>
							Abhiyantran Shastra thrives on promoting interdisciplinary collaboration as we understand that engineering challenges often necessitate diverse solutions. Embracing this ethos, we cultivate an environment where varied perspectives converge, facilitating a vibrant exchange of ideas and holistic problem-solving.
						</p>

						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/engineering`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>
						<Link target="_blank" to={`/mega-journals/information-technology`} onClick={scrollLink}><img
								src='assets/images/soochana-shastra.png'
								className='logo-shastra tagimg mt-3' alt='Sochana Shastra'
							/></Link>
						<p className='pera-text tagp'>
							At Soochna Shastra, we've embarked on a journey to redefine accessibility and excellence in scholarly publishing. As advocates for openness, our journal operates on a Gold Open Access policy, ensuring that ground-breaking research in Information Technology reaches every corner of the globe. With our streamlined, fully automated submission and processing system, authors experience a seamless journey from submission to publication, making it hassle-free.
						</p>

						<p className='pera-text efforts tagp'>
							Our core mission at Soochna Shastra is to be the catalyst propelling the advancement of IT knowledge and innovation. Through our state-of-the-art platform, we foster global visibility, inviting researchers, scholars, and IT experts from diverse backgrounds to share their insights and discoveries.
						</p>

						<p className='pera-text tagp'>
							<Link target="_blank" to={`/mega-journals/information-technology`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<Link target="_blank" to={`/mega-journals/sciences`} onClick={scrollLink}><img src='assets/images/vigyan-shastra.png' className='logo-shastra tagimg mt-3' alt='Vigyan Shastra'
						/></Link>

						<p className='pera-text tagp'>
							Vigyan Shastra pursues an unwavering dedication to openness. Our Gold Open Access policy ensures that scientific research transcends boundaries. With an automated submission and processing system, authors experience a seamless journey, complemented by one of the most affordable Article Processing Charges worldwide, ensuring accessibility for all.
						</p>

						<p className='pera-text efforts tagp'>
							We firmly believe in the transformative potential of shared knowledge, fuelling progress and innovation in Science. Our author dashboards prioritize transparency and convenience, offering all necessary information at the authors' fingertips, just a click away. Acknowledging the urgency of timely publication, we take pride in our ability to expedite the process without compromising academic integrity.
						</p>

						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/sciences`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<Link target="_blank" to={`/mega-journals/ayurveda-and-yoga`} onClick={scrollLink}><img
							src='assets/images/ayuryoga.png'
							className='logo-shastra tagimg mt-3' alt='Ayuryoga Shastra'
						/></Link>

						<p className='pera-text tagp'>
							AyurYoga Shastra is committed to propagating ancient wisdom while embracing modern scientific insights. Our journal operates under a Gold Open Access policy, ensuring that transformative research in Ayurveda and Yoga reaches enthusiasts worldwide.
						</p>

						<p className='pera-text efforts tagp'>
							We deeply value the transformative power of shared wisdom, fuelling progress and innovation in Ayurveda and Yoga. Our author dashboards prioritize transparency and ease, offering comprehensive information at authors' fingertips, ensuring a hassle-free experience.
						</p>

						<p className='pera-text efforts tagp'>
							<Link target="_blank" to={`/mega-journals/ayurveda-and-yoga`} onClick={scrollLink}><b><u>Know More...</u></b></Link>
						</p>

						<div className='row align-items-center mb-4 shastra-logos'>
							<div className='col-lg-8 left-logoss'>
								<div
									className='ag_megajournals_img_boxes aos-init aos-animate'
									data-aos='fade-up'
								>
									<ul className='tagul'>
										<li className='tagli'>
											<div className='ag_megajournals_img'>
											<Link to={`/mega-journals/social-sciences`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/samaj-shastra.png'
													alt='Samaj Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img_right'>
											<Link to={`/mega-journals/business-management`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon'  className='tagimg'
													/>
												</div>
												<img
													src='assets/images/chanakya-shastra.png'
													alt='Chanakya Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img'>
											<Link to={`/mega-journals/medicine`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/charak-shastra.png'
													alt='Charakh Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img_right'>
											<Link to={`/mega-journals/engineering`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/abhiyantran-shastra.png'
													alt='Abhiyantran Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img'>
											<Link to={`/mega-journals/information-technology`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/soochna-shastra.png'
													alt='Soochna Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img'>
											<Link to={`/mega-journals/sciences`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/vigyan-shastra.png'
													alt='Vigyan Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										<li className='tagli'>
											<div className='ag_megajournals_img_right'>
											<Link to={`/mega-journals/ayurveda-and-yoga`} onClick={scrollLink}>
												<div className='ag_livesign'>
													<span>Live</span>
													<img
														src='assets/images/icon-live.svg'
														alt='Live Icon' className='tagimg'
													/>
												</div>
												<img
													src='assets/images/ayuryoga-shastra.png'
													alt='Ayuryoga Shastra' className='tagimg'
												/>
											</Link>
											</div>
										</li>
										
									</ul>
								</div>
							</div>
							<div className='col-lg-4'>
								<h2 className='university-title tagh2'>
									Are you a University, Institute or Society
									that wants to collaborate with us?
								</h2>
								<div className='register-button click-here-shastra'>
									<button className="font26" onClick={() =>window.open("/domestic-societies-partnerships", "_blank")} >Click here</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			{/* NewsLetter Start */}
			<NewsLetter />
			<AakashgangaFooter />
			</div>
			{/* Footer Redline Start */}
		</>
	);
}

export default PublishWithUs;
