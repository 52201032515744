/*eslint-disable*/
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const meghaJournalsObjectiveData = [
  {
    description: `<div class='mt-3'>Aakashganga hosts <strong className="underline">Seven Premium Mega Journals </strong></div>`,
  },
  {
    description:
      "Our Mega Journals offer researchers an expansive and all-encompassing space to publish their work across a wide range of disciplines. Share your research findings with a global audience, foster interdisciplinary collaborations, and engage with diverse perspectives.",
  },
  {
    description:
      "At the heart of our Mega Journals model lies a philosophy of openness and transparency in publishing. Our platform works on a multidisciplinary, open access approach characterised as having four key characteristics: large size; broad disciplinary scope; a Gold-OA business model; and a Pre-Publishing Double Anonymous and Post Publishing Open Peer-review policy.",
  },
  {
    description:
      "Unlike traditional journals with stringent boundaries, our Mega Journals provide an expansive home for submissions spanning a wide spectrum of thematic interests within the following seven disciplines:",
  },
];

const meghaJournalItems = [
  " Social Sciences",
  " Business and Management",
  " Medicine ",
  " Engineering",
  " Information Technology",
  " Pure Sciences",
  " Ayurveda and Yoga",
];

const MeghaJournalObjective = () => {
  const scrollLink = (e) => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {meghaJournalsObjectiveData.map((item) => (
        <p className="pera-text section">{parse(`${item.description}`)}</p>
      ))}

{meghaJournalItems.map((item, index) => (
        <p className="pera-text tagp mb-2">
          <span className="red-text bold600">{index + 1}.</span>
          <strong className="bold600">{item}</strong>
        </p>
      ))}
      <div className="col-12 meghaLiveBox">
        <div className="ag_megajournals_img_boxes aos-init pr-0" data-aos="fade-up">
          <ul className="tagul">
            <li className="tagli">
              <div className="ag_megajournals_img">
                <Link
                  to={`/mega-journals/social-sciences`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/samaj-shastra.png"
                    alt="Samaj Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>

            <li className="tagli">
              <div className="ag_megajournals_img_right">
                <Link
                  to={`/mega-journals/business-management`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/chanakya-shastra.png"
                    alt="Chanakya Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>

            <li className="tagli">
              <div className="ag_megajournals_img">
                <Link
                  to={`/mega-journals/ayurveda-and-yoga`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/charak-shastra.png"
                    alt="Charakh Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>
            <li className="tagli">
              <div className="ag_megajournals_img_right">
                <Link
                  to={`/explore-journals/engineering-megajournal-AYSJE`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/abhiyantran-shastra.png"
                    alt="Abhiyantran Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>

            <li className="tagli">
              <div className="ag_megajournals_img">
                <Link
                  to={`/explore-journals/information-technology-megajournal-SSJIT`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/soochna-shastra.png"
                    alt="Soochna Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>
            <li className="tagli">
              <div className="ag_megajournals_img">
                <Link
                  to={`/mega-journals/ayurveda-and-yoga`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/vigyan-shastra.png"
                    alt="Vigyan Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>
            <li className="tagli">
              <div className="ag_megajournals_img_right">
                <Link
                  to={`/explore-journals/Ayurveda-Yoga-megajournal-AJAY`}
                  onClick={scrollLink}
                >
                  <div className="ag_livesign">
                    <span>Live</span>
                    <img
                      src="assets/images/icon-live.svg"
                      alt="Live Icon"
                      className="tagimg"
                    />
                  </div>
                  <img
                    src="assets/images/ayuryoga-shastra.png"
                    alt="Ayuryoga Shastra"
                    className="tagimg"
                  />
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
     
    </>
  );
};

export default MeghaJournalObjective;
