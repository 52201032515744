import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard"; 
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { PayPalScriptProvider, PayPalButtons, FUNDING  } from "@paypal/react-paypal-js";
import Popover from "react-bootstrap/Popover";
import {
  FetchArticleById,
  iniitatePayments,
  updatePayment,
  downloadOrsendReceipt,
  FetchAllOfferForPayment,
  FetchMudraByUserId,
  FetchOfferById
} from "../../autherFlow/submitArticle/queries";
import {acceptRejectArticle} from "../../../store/apiCalls/dashboard";
import { createNewArticleVersion, getAPCValues } from "../../../store/apiCalls/articles";
import Header from "../../../layout/header";
import successful from "../../../assets/img/icons/successful.svg";
import alertSvg from "../../../assets/img/icons/alert_info.svg";
import doneSvg from "../../../assets/img/icons/done02.svg";
import copyIcon from "../../../assets/img/icons/copy.svg";
import coupanIcon from "../../../assets/img/icons/save.svg";
import active_info from "../../../svg/active-info.svg";
import Checkbox from "../../../components/common/Checkbox";
import { SERVER_URL } from "../../../config";
import { downLoadFile } from "../../../utils";

// import ToolTip from "../../common/ToolTip";

function articleRegistrationPayment() {
  const navigate = useNavigate();
  const router = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.LoginReducer);
  const [finalPaymentStatus, setFinalPaymentStatus] = useState(false); 
  const [amountToPay,setamountToPay] = useState({currencyType:'INR',initialAmt:1000,finalAmt:6000,listpriceFinal:0,listPriceInitial:0,initialStrike:false,finalStrike:false});
  const [activeTabs, setactiveTabs] = useState({ paymentDetail: true, success: false, aggryToc: false, getReceipt: false });
  const [paymentFailedPopup, setpaymentFailedPopup] = useState({ status: false, txnId: '', card_type: '' });
  const [articleDetail, setarticleDetail] = useState();
  const [isPageLoaded, setisPageLoaded] = useState(false);
  const [paymentSuccess, setpaymentSuccess] = useState(false);
  const [availableCoupan, setavailableCoupan] = useState(false);
  const [isDownLoadPdf, setisDownLoadPdf] = useState(false);
  const [isInitialPayment, setisInitialPayment] = useState(true);
  const [articlePublishedModal, setarticlePublishedModal] = useState(false);
  const [mudras, setmudras] = useState(0);
  const [offers, setoffers] = useState([]);
  const [finalPaymentDtl,setfinalPaymentDtl] = useState({id:'',Peid:'',isTermAccept:false,finalAmt:amountToPay.finalAmt,isMudraApplied:false,mudraAmnt:0,isGrantApplied:false,grantAmount:0,sgst:0,cgst:0,isCouponApplied:false,couponCode:'',couponId:'',couponAmt:0});
  const finalPaymentDtlRef = useRef(finalPaymentDtl);
  finalPaymentDtlRef.current = finalPaymentDtl;
  const isDownLoadPdfRef = useRef(isDownLoadPdf)
  const getType = new URLSearchParams(window.location.search).get("type") || "";
  const initialOptionsPaypal = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };
  const couponRef = useRef()
  isDownLoadPdfRef.current = isDownLoadPdf;
  useEffect(() => {
    const articleID = router.pathname.split("/")[2];
    if (getType === "newVersion") {
      getArticleNewVersion();
    }
    else if (articleID)
    {
      dispatch({ type: "SET_LOADER", payload: true });
      setTimeout(function() {
        getArticleDetail(articleID);
        dispatch({ type: "SET_LOADER", payload: false });
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (finalPaymentDtl.finalAmt < 0) {
      setfinalPaymentDtl({ ...finalPaymentDtl, finalAmt: 0 })
    }
  }, [finalPaymentDtl])

  const getArticleNewVersion = async () => {
    const articleID = router.pathname.split("/")[2];
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: articleID
    };
    const response = await createNewArticleVersion(headers, params);
    if (response?.status === 200) {
      getArticleDetail(response?.data?.data?._id);
      handleRedirect('', `/manage-payment/${response?.data?.data?._id}`)
    } else {
      return toast.error("Please try again!!");
    }
  };

  const publishArticleAPICall = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: finalPaymentDtl.id,
      peStatus: "published",
      peId: finalPaymentDtl.Peid,
    };
    // console.log("params",params);
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
    } else {
      return toast.error("Something went wrong. Please contact with admin");
    }
  };

  async function getArticleDetail(id) {
    //
   
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { articleId: id },
      headers: headers,
    };

    getArticleById(queryParams);
  }
  const sucessHandlerUpdatePayment = (info) => {
    const paymentpoup = paymentFailedPopup;
    if (info.status === 200) {
      if(isInitialPayment===true)
      {   
        if (info.data?.data?.initialPayment === true) {
          
          const act = activeTabs;
          act.paymentDetail = false;
          act.success = true
          setactiveTabs({...act})
        }
        else {
          paymentpoup.status = true;
          setpaymentFailedPopup({ ...paymentpoup })
        }
      }
      else{
        if (info.data?.data?.finalPayment === true) {
            const act = activeTabs;
            act.paymentDetail = false;
            act.success = true
            setactiveTabs({...act})
            if(articleDetail?.updateFlow!==true) {
              setFinalPaymentStatus(true);
              publishArticleAPICall()
            }
        } else if (finalPaymentDtl.finalAmt === 0) {

        } else {
          paymentpoup.status = true;
          setpaymentFailedPopup({ ...paymentpoup })
        }
      }
      window.scroll({ top: 0, behavior: "smooth" });

    }

  };

  const { mutate: updatePaymentStatus } = updatePayment(sucessHandlerUpdatePayment);
  const sucessHandlerPayment = (info) => {

    if (info.status === 200) {
      dispatch({ type: "SET_LOADER", payload: true });
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_PAYMENT_SCRIPT;
      script.id = 'paymentScript'
      script.async = true;
      if (!document.getElementById('paymentScript'))
      document.body.appendChild(script);
      setTimeout(function () {
        dispatch({ type: "SET_LOADER", payload: false });
        var easebuzzCheckout = new EasebuzzCheckout(process.env.REACT_APP_PAYMENT_KEY, process.env.REACT_APP_PAYMENT_MODE)
        var options = {
          access_key: info.data.data, // access key received via Initiate Payment
          onResponse: (response) => {
          
           updatePaymentData(response, info.data.data)
          },
          theme: "#123456" // color hex
        }
        easebuzzCheckout.initiatePayment(options);
    }, 1000);
    }
    
  };

  function updatePaymentData(response, access_key) {
    const paymentpoup = paymentFailedPopup;
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    let payStatus = false;
    if (response.status === 'success') 
      payStatus = true;
    let payloads =   {"strike":amountToPay.initialStrike,"currencyType":amountToPay.currencyType,"paymentMode":response.card_type,"txnid":response.txnid,"initialPayment":payStatus,"articleId":articleDetail._id,"data":access_key,"status":response.status,"gst":((18/100)*amountToPay.initialAmt).toFixed(2),"payableAmount":(amountToPay.initialAmt +(18/100)*amountToPay.initialAmt).toFixed(2),"lastPrice":amountToPay.listPriceInitial}
    if(isInitialPayment===false)
    {
      payloads = {"strike":amountToPay.finalStrike,"couponCode":finalPaymentDtl.couponCode,"couponAmt":finalPaymentDtl.couponAmt,"isCouponApplied":finalPaymentDtl.isCouponApplied,"grantAmount":finalPaymentDtl.grantAmount,"isGrantApplied":finalPaymentDtl.isGrantApplied,"mudraAmnt":finalPaymentDtl.mudraAmnt,"isMudraApplied":finalPaymentDtl.isMudraApplied,"currencyType":amountToPay.currencyType,"finalPaymentAmount":handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2)) + ".00","paymentMode":response.card_type,"finalPaymentTxId":response.txnid,"txnid":response.txnid,"finalPayment":payStatus,"articleId":articleDetail._id,"data":access_key,"status":response.status,"gst":((18/100)*finalPaymentDtl.finalAmt).toFixed(2),"total":finalPaymentDtl.finalAmt,"payableAmount":handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2)) + ".00","introductryPrice":amountToPay.finalAmt,"lastPrice":amountToPay.listpriceFinal}
      
      if(finalPaymentDtl.isMudraApplied===true)
      payloads.mudras=finalPaymentDtl.mudraAmnt
      if(finalPaymentDtl.isCouponApplied===true)
      {
        payloads.couponAmount=finalPaymentDtl.couponAmt
        payloads.offerCode=finalPaymentDtl.couponCode
      }
      
    }
    const queryParams = {
      payload: payloads,
      headers,
    };
    updatePaymentStatus(queryParams);
    paymentpoup.txnId = response.txnid;
    paymentpoup.card_type = response.card_type;
    setpaymentFailedPopup({ ...paymentpoup })
  }
  const handleGstPayment = (amt) => {
    let finalAmt = "";
    let totalAmt = amt.split(".");
    if (totalAmt[1] > 50) {
      finalAmt = Number(totalAmt[0]) + 1;
    } else {
      finalAmt = totalAmt[0];
    }
    return finalAmt;
  }

  const { mutate: iniitatePayment } = iniitatePayments(sucessHandlerPayment);
  const sucessHandler = (articleDetails) => {
    if (articleDetails.status === 200) {
      const editDetl = articleDetails.data.data;
      if ((editDetl.status === 'approved' || editDetl.updateFlow===true) && editDetl.finalPayment === false)
      {   
        const payment=finalPaymentDtl;
        if(editDetl?.grantDetails?.amount>0 && editDetl?.grantDetails?.grantStatus==='approved')
        {
           payment.grantAmount=editDetl?.grantDetails?.amount;
           payment.isGrantApplied=true;
        }
        if( editDetl.updateFlow===false)
        {
          payment.id=articleDetails.data.currentStatus[0]._id;
          payment.Peid=articleDetails.data.currentStatus[0].peId[0]; 
        }
      //  console.log("payment", payment);
        setfinalPaymentDtl({...payment})

        setisInitialPayment(false)
       
        getUserMudra()
        offerList()
      }
      // console.log("editDetl",editDetl)
      handleAPCData(editDetl?.reviewType?.typeOfReview,editDetl)
      setarticleDetail(editDetl); 
    }
  }
  const handleAPCData = async (reviewType,editDetl) => { 
    
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` }; 
    const params = {
      typeOfApc: user.nationality,
    };
    const response = await getAPCValues(headers, params);
    if (response?.status === 200) {
      const data=response?.data?.data;
      const amt=amountToPay;const finl=finalPaymentDtl;
      
      amt.initialAmt=data[2].introductoryPrice;
      if(editDetl?.submissionDetail?.articleType?._id == "664aed76ada7b4f743b760a9"){
        amt.initialAmt=data[3].introductoryPrice;
      }
      amt.initialStrike=data[2].strike;
      amt.listPriceInitial=data[2].lastPrice;
      amt.currencyType=(user.nationality==='outSideIndia'?'USD':'INR')
     
      if(reviewType==='pre-publication peer review only')
      {
        amt.finalAmt=data[0].introductoryPrice;
        amt.listpriceFinal=data[0].lastPrice;
        amt.finalStrike=data[0].strike;
      }
      else{
        amt.finalAmt=data[1].introductoryPrice;
        amt.listpriceFinal=data[1].lastPrice;
        amt.finalStrike=data[1].strike;
      }
      
      finl.finalAmt=amt.finalAmt-finl.grantAmount-amt.initialAmt;

      setfinalPaymentDtl({...finl});
      setamountToPay({...amt});
      setisPageLoaded(true)
    } else {
      return toast.error("Please try again!!");
    }
  };
  const { mutate: getArticleById } = FetchArticleById(sucessHandler);

  function paymentInitiation() {

    
    const { id } = jwt_decode(localStorage.usertoken);
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const redairectTo = `${SERVER_URL}/dashboard/edit-article/${articleDetail._id}`; 
    let amnt=(amountToPay.initialAmt +(18/100)*amountToPay.initialAmt).toFixed(2);
    if(isInitialPayment===false)
    amnt= handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2)) + ".00";
    const payloads = { "key": process.env.REACT_APP_PAYMENT_KEY, "amount": amnt, "productinfo": "Create Article Fee", "firstname": user.fullName, "phone": user.phoneNumber, "email": user.email, "surl": `${redairectTo}`, "furl": `${redairectTo}?type=failed`, "salt": process.env.REACT_APP_PAYMENT_SALT, "udf1": articleDetail._id, "udf2": "", "udf3": "", "udf4": "", "udf5": "", "udf6": "", "udf7": "", "udf8": "", "udf9": "", "udf10": "", "userId": id, "articleId": articleDetail._id }
    var formBody = [];
    for (var property in payloads) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(payloads[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const queryParams = {
      payload: formBody,
      headers,
    };
    if (finalPaymentDtl.finalAmt === 0) {
     const payloads = {"productinfo": "Create Article Fee", "amount": amnt, "firstname": user.fullName, "email": user.email, "userId": id, "strike":amountToPay.finalStrike,"couponCode":finalPaymentDtl.couponCode,"couponAmt":finalPaymentDtl.couponAmt,"isCouponApplied":finalPaymentDtl.isCouponApplied,"grantAmount":finalPaymentDtl.grantAmount,"isGrantApplied":finalPaymentDtl.isGrantApplied,"mudraAmnt":finalPaymentDtl.mudraAmnt,"isMudraApplied":finalPaymentDtl.isMudraApplied,"currencyType":amountToPay.currencyType,"finalPaymentAmount":handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2)) + ".00","paymentMode": "Free","finalPayment": true,"articleId":articleDetail._id,"status":'success',"gst":((18/100)*finalPaymentDtl.finalAmt).toFixed(2),"total":finalPaymentDtl.finalAmt,"payableAmount":handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2)) + ".00","introductryPrice":amountToPay.finalAmt,"lastPrice":amountToPay.listpriceFinal}
      if(finalPaymentDtl.isMudraApplied===true)
      payloads.mudras=finalPaymentDtl.mudraAmnt
      if(finalPaymentDtl.isCouponApplied===true)
      {
        payloads.couponAmount=finalPaymentDtl.couponAmt
        payloads.offerCode=finalPaymentDtl.couponCode
      }
      const queryParams = {
        payload: payloads,
        headers,
      };
        updatePaymentStatus(queryParams);
        const act = activeTabs;
        act.paymentDetail = false;
        act.success = true
        setactiveTabs({...act})
        if(articleDetail?.updateFlow!==true) {
          setFinalPaymentStatus(true);
          publishArticleAPICall()
        }
    } else {
      iniitatePayment(queryParams);
    }
  }
  const handleRedirect = (e, path) => {
    if (e !== "") e.preventDefault();
    navigate(path);
  };
  function handleFullCharge(e) {
    const act = activeTabs
    if (e.target.checked)
      act.aggryToc = true
    else
      act.aggryToc = false
    setactiveTabs({ ...act })
  }
  function getReceipt(e) {
    const act = activeTabs
    if (e.target.checked) {

      act.getReceipt = true
      downLoadReciepts('email')
    }
    else {
      act.getReceipt = false
    }

    setactiveTabs({ ...act })
  }

  function continueWithArticle() {
    if (activeTabs.aggryToc === true && activeTabs.getReceipt === true && isInitialPayment===true)
      setpaymentSuccess(true)
    else if (isInitialPayment===false)
    setarticlePublishedModal(true)
    
    else
      handleRedirect('', `/dashboard/edit-article/${articleDetail._id}`)
  }
  const sucessHandlerDownLoadReceipt = (details) => {
    if (details.status === 200) {
      if (isDownLoadPdfRef.current === true) {
        const url = details.data.data
        let filename='initialPaymentReceipt';
        if(isInitialPayment===false)
        filename='finalPaymentReceipt';
        downLoadFile(url, `${filename}${paymentFailedPopup.txnId}.pdf`);
      }


    }
  }
  const { mutate: downloadSendReceipt } = downloadOrsendReceipt(sucessHandlerDownLoadReceipt);

  function downLoadReciepts(type) {

    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const payloads = {"articleId": articleDetail._id }
    if(isInitialPayment===false)
    payloads.finalPayment=true
    else  
    payloads.initialPayment=true
    if (type === 'email') {
      setisDownLoadPdf(false);
      payloads.email = true
    }
    else if (type === 'download') {
      payloads.download = true
      setisDownLoadPdf(true);
    }

    const queryParams = {
      payload: payloads,
      headers,
    };
    downloadSendReceipt(queryParams);

  }

  const sucessHandlerAllOffer = (details) => { 
    if (details.status === 200) {
      
      setoffers(details.data.data)
    }
  }

  function offerList()
  {
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const queryParams = {
        params: { limit: 100,page:1 },
        headers: headers,
      };

      getALLOfferForPayment(queryParams);
  }
  const { mutate: getALLOfferForPayment } = FetchAllOfferForPayment(sucessHandlerAllOffer);
  const sucessHandlerMudraByUserId = (details) => { 
    if (details.status === 200) {
      
      if(details.data.mudras>0)
      setmudras(details.data.mudras)
    
    }
  }
  function getUserMudra()
  {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const { id } = jwt_decode(localStorage.usertoken);
    const queryParams = {
      params: { userId: id },
      headers: headers,
    };

    getMudraByUserId(queryParams);
  }
  const { mutate: getMudraByUserId } = FetchMudraByUserId(sucessHandlerMudraByUserId);
  function convertDateFormate(date)
  {
    let newDate = new Date(date);
    let getDate = newDate.getDate();
    let getYear = newDate.getFullYear();
    let getMonth = newDate.toLocaleString('en-US', { month: 'short' });
    return getDate + " " + getMonth + " " + getYear;
  };
 
  function copyCouponCode(code)
  {
    copy(code)
    return toast.success("Copied");
  }
  function applyMudra()
  {
   
    const payDetl=finalPaymentDtl;
    payDetl.isMudraApplied=true
    payDetl.mudraAmnt=mudras
    payDetl.finalAmt=(finalPaymentDtl.finalAmt-mudras)
    setfinalPaymentDtl({...payDetl})
  }
  function removeMudra()
  {
    const payDetl=finalPaymentDtl;
    payDetl.isMudraApplied=false
    payDetl.mudraAmnt=0
    payDetl.finalAmt=(finalPaymentDtl.finalAmt+parseInt(mudras))
    setfinalPaymentDtl({...payDetl})
  }
  const sucessHandlerMudras = (details) => {  
    if (details.status === 200) {
      const detail=details.data.data;
      if(detail?._id)
      {
        const payment=finalPaymentDtl; 
          payment.isCouponApplied=true
          payment.couponCode=detail.offerCode
          payment.couponId=detail?._id
          payment.couponAmt=detail.value
          payment.finalAmt=payment.finalAmt-detail.value
         if(detail.offerType==='Discount of')
         {
           
           payment.couponAmt=detail.value
           payment.finalAmt=payment.finalAmt-detail.value
         }
         else{
         }
         setfinalPaymentDtl({...payment})
      }
      else{
        toast.error("invalid coupon code!");
      }
    
    }
   
  }
  const { mutate: getOfferById } = FetchOfferById(sucessHandlerMudras);
  function applyCoupon()
  {
    if(couponRef.current.value!=='')
    {
      
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
         const queryParams = {
          params: { offerCode:couponRef.current.value},
          headers: headers,
        };
        getOfferById(queryParams);
      
     
    }
  }
  function removeCouponCode()
  {
    const payment=finalPaymentDtl;
    payment.finalAmt=payment.finalAmt+parseInt(payment.couponAmt)
    payment.isCouponApplied=false
    payment.couponCode=''
    payment.couponId=''
    payment.couponAmt=''
    
    setfinalPaymentDtl({...payment})
  }
  function acceptToc(e)
  { 
    const payment=finalPaymentDtl;
    if (e.target.checked)
    payment.isTermAccept=true
    else
    payment.isTermAccept=false
   setfinalPaymentDtl({...payment})
  }

  function createOrder(data, actions) {
    let amnt=amountToPay.initialAmt.toFixed(2);
    if(isInitialPayment===false)
    amnt=(finalPaymentDtlRef.current.finalAmt.toFixed(2));
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amnt,
          },
        },
      ],
    });
   }
function onApprove(data) {
  
  const paymentpoup = paymentFailedPopup;
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    let payStatus = true;
   
    let payloads =   {"userId":user._id,"strike":amountToPay.initialStrike,"currencyType":amountToPay.currencyType,"paymentMode":data.paymentSource,"txnid":data.paymentID,"initialPayment":true,"articleId":articleDetail._id,"data":data.orderID,"status":'success',"gst":0,"payableAmount":(amountToPay.initialAmt).toFixed(2),"lastPrice":amountToPay.listPriceInitial}
    if(isInitialPayment===false)
    {
      if (articleDetail?.updateFlow === false)
      setFinalPaymentStatus(true);
      payloads = {"userId":user._id,"strike":amountToPay.finalStrike,"couponCode":finalPaymentDtl.couponCode,"couponAmt":finalPaymentDtl.couponAmt,"isCouponApplied":finalPaymentDtl.isCouponApplied,"grantAmount":finalPaymentDtl.grantAmount,"isGrantApplied":finalPaymentDtl.isGrantApplied,"mudraAmnt":finalPaymentDtl.mudraAmnt,"isMudraApplied":finalPaymentDtl.isMudraApplied,"currencyType":amountToPay.currencyType,"finalPaymentAmount":(finalPaymentDtl.finalAmt).toFixed(2),"paymentMode":data.paymentSource,"finalPaymentTxId":data.paymentID,"txnid":data.paymentID,"finalPayment":true,"articleId":articleDetail._id,"data":data.orderID,"status":'success',"gst":0,"total":finalPaymentDtl.finalAmt,"payableAmount":(finalPaymentDtl.finalAmt).toFixed(2),"introductryPrice":amountToPay.finalAmt,"lastPrice":amountToPay.listpriceFinal}
      
      if(finalPaymentDtl.isMudraApplied===true)
      payloads.mudras=finalPaymentDtl.mudraAmnt
      if(finalPaymentDtl.isCouponApplied===true)
      {
        payloads.couponAmount=finalPaymentDtl.couponAmt
        payloads.offerCode=finalPaymentDtl.couponCode
      }
      
    }
    
    const queryParams = {
      payload: payloads,
      headers,
    };
    updatePaymentStatus(queryParams);
    paymentpoup.txnId = data.paymentID;
    paymentpoup.card_type = data.paymentSource;
    setpaymentFailedPopup({ ...paymentpoup })
  }
  function onCancelPayapl(data)
  {
    console.log('onCancelPayapl',data);
  }
  function onErrorPayapl(data)
  {
    console.log('onErrorPayapl',data);
  }
  
  return (
    <>
      <Header />
      <div className="inner-pages">
        {isPageLoaded===true && <div className="centerSection">


          {(activeTabs.paymentDetail === true && isInitialPayment === true)&& <div className="registrationPaymentMain">
            <div className="registrationPaymentCard">
              <div className="registrationPaymentHead">
                <h3>{isInitialPayment === true ? 'Article Registration Payment' : 'Final Article Processing Charge'}</h3>

              </div>
              {isInitialPayment === true && <div className="registrationPaymentCotts">
                <div className="questionBox">
                  <span>Pay the initial registration amount of {amountToPay.currencyType} {amountToPay.initialAmt} to start writing your article immediately<sup className="supStar">*</sup>.
                  </span>
                  <p>
                    <sup className="supStar">*</sup>In case your article is not accepted for publishing, the registration amount of {amountToPay.currencyType} {amountToPay.initialAmt} will be refunded in your Mudra Kosh
                    (Wallet), in your dashboard.</p>
                </div>
              </div>}
              <div className="registrationMidd">

                <div className="registrationMiddLeft">

                  <h4>Bill Summary</h4>
                  <ul>
                    <li>
                       

                      <div className="listCardBox">
                        <div className="listCard">
                          <span>Article Processing Charge</span>
                          <div className="">
                            <b>{amountToPay.currencyType}  {isInitialPayment === true ? (amountToPay.initialAmt).toFixed(2) : (amountToPay.finalAmt).toFixed(2)}</b>
                          </div>
                        </div>
                        
                      </div>
                    </li>
                    {
                      user?.nationality === "withInIndia" ? (
                        <li>
                      <div className="listCardBox">
                        <div className="listCard">
                          <span>GST- 18%</span>
                          <div className="">
                            <b>{amountToPay.currencyType} {((18/100)*amountToPay.initialAmt).toFixed(2)}</b>
                          </div>
                        </div>
                        
                      </div>
                    </li>
                      ) : null                     
                    }
                    
                    <li>
                      <div className="grandCardBox">
                        <div className="grandCardList">
                          <span className="subTitle">Net Payable</span>
                          {
                            user?.nationality === "withInIndia" ? (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {(amountToPay.initialAmt +(18/100)*amountToPay.initialAmt).toFixed(2) }</b>
                            ) : (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {(amountToPay.initialAmt).toFixed(2)}</b>
                            )
                          }
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>}

          {(activeTabs.paymentDetail === true && isInitialPayment === false)&&<div className="registrationPaymentMain">
            <div className="registrationPaymentCard">
              <div className="registrationPaymentHead">
                <h3>{articleDetail?.updateFlow===true?'New Article Update Payment':'Final Article processing charge'}</h3>
                {articleDetail?.updateFlow===true && <div className="questionBox updatePaymentmsg"> <span>Please apply the coupon code you have received in email below</span></div>}
              </div>
              <div className="registrationMidd">
                <div className="registrationMiddLeft">
                  <h4>Bill Summary</h4>
                  <ul>

                  {articleDetail?.updateFlow!==true && <li>
                      <div className="listCardBox">
                        {amountToPay.finalStrike===true && <div className="listCard">
                          <span>Article Processing Charge</span>
                          <div className=""><b><del>{amountToPay.currencyType} {amountToPay.listpriceFinal}</del></b></div>
                        </div>}
                        <div className="listCard">
                          <span>Introductory Price</span>
                          <div className=""><b>{amountToPay.currencyType} {amountToPay.finalAmt}</b></div>
                        </div>
                        
                        {finalPaymentDtl.isCouponApplied===true && <div className="listCard">
                          <span>Coupon Applied</span>
                          <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.couponAmt}</b> </div>
                        </div>}
                        
                        
                        {finalPaymentDtl.isGrantApplied===true &&<div className="listCard">
                        <b>Grant Applied</b>
                        <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.grantAmount}</b> </div>
                        
                      </div>}
                      {finalPaymentDtl.isMudraApplied===true && <div className="listCard">
                      <span>Mudras Applied</span>
                      <div className=""><b>- {amountToPay.currencyType} {mudras}</b> </div>
                       
                      </div>} 
                     </div>
                    </li>}
                    {articleDetail?.updateFlow===true && <li>
                      <div className="listCardBox">
                      <div className="listCard">
                          <span>Introductory Price</span>
                          <div className=""><b>{amountToPay.currencyType} {amountToPay.finalAmt}</b> </div>
                        </div> 
                        {finalPaymentDtl.isCouponApplied===true && <div className="listCard">
                          <span>Coupon Applied</span>
                          <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.couponAmt}</b> </div>
                        </div>}
                        
                        
                        {finalPaymentDtl.isGrantApplied===true &&<div className="listCard">
                        <b>Grant Applied</b>
                        <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.grantAmount}</b> </div>
                        
                      </div>}
                      {finalPaymentDtl.isMudraApplied===true && <div className="listCard">
                      <span>Mudras Applied</span>
                      <div className=""><b>- {amountToPay.currencyType} {mudras}</b> </div>
                       
                      </div>} 
                     </div>
                    </li>}

                    <li>
                      <div className="listCardBox">
                        {articleDetail?.updateFlow!==true && <div className="listCard">
                          <span>Article Registration Charge (Paid)</span>
                          <div className=""><b>- {amountToPay.currencyType} {amountToPay.initialAmt}</b></div>
                        </div>}
                        <div className="listCard">
                          <span>Total</span>
                          <div className=""><b>{amountToPay.currencyType} {finalPaymentDtl.finalAmt}</b></div>
                        </div>
                        {
                          user?.nationality === "withInIndia" ? (
                            <div className="listCard">
                          <span>GST - 18%</span>
                          <div className=""><b>{amountToPay.currencyType} {((18/100)*finalPaymentDtl.finalAmt).toFixed(2)}</b></div>
                        </div>
                          ) : null
                        }
                        
                        
                      </div>
 
                    </li>
                  
                    <li>
                      <div className="grandCardBox">
                        <div className="grandCardList">
                          <span className="subTitle">Net Payable</span>
                          {
                            user?.nationality === "withInIndia" ? (
                              <b className="mudraText">{amountToPay.currencyType} {handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2))}</b>
                            ) : (
                              <b className="mudraText">{amountToPay.currencyType} {(finalPaymentDtl.finalAmt).toFixed(2)}</b>
                            )
                          }
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
                <div className="registrationMiddRight">
                <ul>
                    {(user?.nationality === "withInIndia" && finalPaymentDtl.isMudraApplied===false && mudras>0) &&<li>
                      <span className="subTitleTiel">Available Mudras <div className="customTool">
                        {["bottom"].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <div className="popoverToolWrap">
                                    <p>The available mudras can be reviewed in your Mudra Kosh (Wallet) account and used to make the payment.</p>
                                    <br />
                                    <p>You can use these to make payment for a new article or update an existing article.</p>
                                    <p>Access your Mudra Kosh Account here.</p>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <img
                              className="pe-cursor ps-1"
                              src={active_info}
                              alt="no img"
                            />
                          </OverlayTrigger>
                        ))}
                      </div></span> 
                      <div className="subtotalPrice">
                        <b className="mudra">{mudras}</b> <span className="btn-link-active" onClick={()=>applyMudra()}>Apply</span>
                      </div>
                    </li>}
                    {(finalPaymentDtl.isMudraApplied===true ) &&<li>
                      <span className="successText"><img src={successful} alt="#" />By using Mudras, you saved <b>{amountToPay.currencyType} {mudras}</b></span>
                      <b className="subTitleTiel cursor-pointer" onClick={()=>removeMudra()}>Remove</b>
                    </li>}
                    {(finalPaymentDtl.isCouponApplied===false ) &&<li>
                      <div class="listCardBox">
                        <div className="listCard">
                          <span className="coupanColor">Add Coupon Code</span>
                          <div className="applyInput">
                            <input type="text" placeholder="Enter code" ref={couponRef}/> <a className="btn-link-active" onClick={()=>applyCoupon()}>Apply</a>
                          </div>
                        </div>
                         <div className="listCard">
                         
                          <a className="btn-link-active" onClick={()=>setavailableCoupan(true)}>View coupons</a>
                        </div>
                      </div>
                    </li>}
                    {(finalPaymentDtl.isCouponApplied===true ) &&<li>
                      <span className="successText"><img src={successful} alt="#" />By using {finalPaymentDtl.couponCode}, you saved <b>{amountToPay.currencyType} {finalPaymentDtl.couponAmt}</b></span>
                      <b className="subTitleTiel cursor-pointer" onClick={()=>removeCouponCode()}>Remove</b>
                    </li>}
                  </ul>
                  {
                    articleDetail?.updateFlow===true ? null : (
                      <div className="questionBox">
                        <span>
                          Don’t forgot to apply coupon its 100% discount.
                        </span>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="paymentCheck d-block">
            <label className="customCheck w-100">
               <Checkbox
                  id="isTermAccept"
                  name="isTermAccept"
                  onRadioChange={acceptToc}
                  className="form-check-input"
                  checked={finalPaymentDtl.isTermAccept}
                />
               
                <i></i>
                <b>I and/or my fellow co-authors have read, understood and hereby accept the <a className="btn-link-active inputSet" href="/terms-conditions" target="_blank">Aakashganga Terms & Conditions</a> <span className="mandatoryText">(Mandatory)</span></b>
              </label>
              </div>
          </div>}

          {(activeTabs.success === true &&  isInitialPayment === false) &&  <div className="registrationPaymentMain">
            <div className="registrationPaymentCard">
              <div className="registrationPaymentHead">
              <div className="articleSuccess">
                  <span><img src={successful} alt="#" /> {articleDetail?.updateFlow===true?'Payment SUCCESSFUL':'Payment SUCCESSFUL'} </span>

                </div>
              </div>
              
              <div className="registrationMidd">
                <div className="registrationMiddLeft">
                  <h4>Payment Details</h4>
                  <ul>
                    <li>
                    
                      <div className="listCardBox">
                      {(articleDetail?.updateFlow!==true && amountToPay.finalStrike===true) && <div className="listCard">
                            <span>Article Processing Charge</span>
                            <div className=""><b><del>{amountToPay.currencyType} {(amountToPay.listpriceFinal).toFixed(2)}</del></b></div>
                          </div>}
                          <div className="listCard">
                            <span>Introductory Price</span>
                            <div className=""><b>{amountToPay.currencyType} {amountToPay.finalAmt}</b></div>
                          </div>
                          {finalPaymentDtl.isCouponApplied===true && <div className="listCard">
                            <span>Coupon Applied</span>
                            <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.couponAmt}</b></div>
                          </div>}
                          {finalPaymentDtl.isGrantApplied===true && <div className="listCard">
                            <span>Grant Applied</span>
                            <div className=""><b>- {amountToPay.currencyType} {finalPaymentDtl.grantAmount}</b></div>
                          </div>}
                          {finalPaymentDtl.isMudraApplied===true && <div className="listCard">
                            <span>Mudras Applied</span>
                            <div className=""><b>- {amountToPay.currencyType} {mudras}</b></div>
                          </div>}
                       
                      </div>
                    </li>
                    
                    <li >
                    <div className="listCardBox">
                      {articleDetail?.updateFlow!==true &&  <div className="listCard">
                          <span>Article Registration Charge (Paid)</span>
                          <div className=""><b>- {amountToPay.currencyType} {amountToPay.initialAmt}</b></div>
                        </div>}
                        <div className="listCard">
                          <span>Total</span>
                          <div className=""><b>{amountToPay.currencyType} {finalPaymentDtl.finalAmt}</b></div>
                        </div>
                        {
                          user?.nationality === "withInIndia" ? (
                            <div className="listCard">
                          <span>GST- 18%</span>
                          <div className=""><b>{amountToPay.currencyType} {((18/100)*finalPaymentDtl.finalAmt).toFixed(2)}</b></div>
                        </div>
                          ) : null
                        }
                        
                      </div>
                     
                    </li>
                    <li className="grandTotalList">
                    <div className="grandCardBox">
                        <div className="grandCardList">
                          <span className="subTitle">Net Payable</span>
                          {
                            user?.nationality === "withInIndia" ? (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2))}</b>
                            ) : (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {(finalPaymentDtl.finalAmt).toFixed(2)}</b>
                            )
                          }
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="listCardBox">
                        <div className="listCard">
                          <span>Payment Mode</span>
                          <div className=""><b>{paymentFailedPopup.card_type}</b></div>
                        </div>
                        <div className="listCard">
                          <span>Transaction ID</span>
                          <div className=""><b>{paymentFailedPopup.txnId}</b></div>
                        </div>
                      </div>
                    </li>
                     

                    <li>
                      <div className="listCardBox">

                        <div className="listCard">
                          <span><h4>Receipt</h4></span>
                          <a onClick={() => downLoadReciepts('download')} download className="cursor-pointer">Download</a>
                        </div>

                      </div>
                    </li>

                  </ul>
                </div>
                 
              </div>
            </div>
            <div className="paymentCheck mailBoxPay">
            <label className="customCheck">
                <Checkbox
                   onRadioChange={getReceipt}
                   className="form-check-input"
                   checked={activeTabs.getReceipt}
                  name="isReviwer"
                  
                />
                <i></i>
                <b>Please send a copy of this receipt to my mailbox</b>
              </label>
              {articleDetail?.updateFlow!==true && <div className="button button-primary" onClick={continueWithArticle}>Check message</div>}
              </div>
          </div>}



          {(activeTabs.success === true &&  isInitialPayment === true) && <div className="registrationPaymentMain">
            <div className="registrationPaymentCard">
              <div className="registrationPaymentHead">
                <div className="articleSuccess">
                  <span><img src={successful} alt="#" /> Payment SUCCESSFUL </span>

                </div>
              </div>
              <div className="registrationMidd">
                <div className="registrationMiddLeft w-100">
                  <h4>Payment Details</h4>
                  <ul>
                    <li>
                   
                      <div className="listCardBox">
                        <div className="listCard">
                          <span>Article Processing Charge</span>
                          <b>{amountToPay.currencyType} {(amountToPay.initialAmt).toFixed(2)}</b>
                        </div>
                        
                        </div>
                    </li>
                      {
                        user?.nationality === "withInIndia" ? (
                    <li>
                      
                          <div className="listCardBox">
                        <div className="listCard">
                          <span>GST - 18%</span>
                          <b>{amountToPay.currencyType} {((18/100)*amountToPay.initialAmt).toFixed(2)}</b>
                        </div>
                      </div>
                    </li>
                        ) : null
                      }
                      
                    <li>
                      <div className="grandCardBox">
                        <div className="grandCardList">
                          <span className="subTitle">Net Payable</span>
                          {
                            user?.nationality === "withInIndia" ? (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {(amountToPay.initialAmt +(18/100)*amountToPay.initialAmt).toFixed(2) }</b>
                            ) : (
                              <b className="mudra sm-mudra">{amountToPay.currencyType} {(amountToPay.initialAmt).toFixed(2)}</b>
                            )
                          }
                        </div>

                      </div>
                    </li>
                    <li>
                      <div className="listCardBox">
                        <div className="listCard">
                          <span>Payment Mode</span>
                          <b>{paymentFailedPopup.card_type}</b>
                        </div>
                        
                        <div className="listCard">
                          <span>Transaction ID</span>
                          <b>{paymentFailedPopup.txnId}</b>
                        </div>

                      </div>
                    </li>
                    <li>
                      <div className="listCardBox">

                        <div className="listCard">
                          <span><h4>Receipt</h4></span>
                          <a onClick={() => downLoadReciepts('download')} download className="cursor-pointer">Download</a>
                        </div>

                      </div>
                    </li>
                  </ul>
                </div>


              </div>

            </div>
            
            <div className="paymentCheck">
            <div className="">
              <label className="customCheck">
                <Checkbox
                  name="isReviwer"
                  onRadioChange={getReceipt}
                  className="form-check-input"
                  checked={activeTabs.getReceipt}
                />
                <i></i>
                <b>Please send a copy of this receipt to my mailbox</b>
              </label>

              <label className="customCheck">
                <Checkbox
                  onRadioChange={handleFullCharge}
                  name="isReviwer"
                  checked={activeTabs.aggryToc}
                  className="form-check-input"
                />
                <i></i>
                <b>I and/or my fellow co-authors are fully aware of and agree to pay the balance of Article Processing Charge (APC), should the manuscript be accepted for publishing <span className="mandatoryText">(Mandatory)</span></b>
              </label>
            </div>
            </div>
          </div>}
          {finalPaymentStatus === false && 
          <div className="confirmFooter">
            {(isInitialPayment === true && activeTabs.success === false) && <div className="confirmContent">
              <h3>Article Registration Payment: <b>{amountToPay.currencyType} {amountToPay.initialAmt}</b></h3>

             
                {articleDetail?.submissionDetail?.articleType?._id != "664aed76ada7b4f743b760a9" ? (<>
                  <div className="questionBox">
                <span>The remaining balance of {amountToPay.currencyType} {amountToPay.finalAmt - amountToPay.initialAmt} will be charged after your article is accepted for publishing</span>
                </div>
                </>):null}
             
            </div>}
            {(isInitialPayment === true && activeTabs.success === true) && <div className="confirmContent">
              <h3>Remaining ARTICLE PROCESSING CHARGE: <b>{amountToPay.currencyType} {finalPaymentDtl.finalAmt}</b></h3>

              <div className="questionBox">
                <span>You may also be eligible for additional grants and discounts</span>
                <span>All offers will be applied after the article is accepted for publication</span>
              </div>
            </div>}
            {(articleDetail?.status === 'approved' && articleDetail?.finalPayment === false && activeTabs.success === false) && <div className="confirmContent">
              {
                user?.nationality === "withInIndia" ? (
                  <h3>ARTICLE PROCESSING CHARGE payable: <b>{amountToPay.currencyType} {handleGstPayment((finalPaymentDtl.finalAmt+ ((18/100)*finalPaymentDtl.finalAmt)).toFixed(2))}</b></h3>
                ) : (
                  <h3>ARTICLE PROCESSING CHARGE payable: <b>{amountToPay.currencyType} {(finalPaymentDtl.finalAmt).toFixed(2)}</b></h3>
                )
              }
            </div>}
            {articleDetail?.updateFlow===true && <div className="confirmContent">
              <h3>&nbsp;</h3>
            </div>}
            <div className="confirmBtn">
              {(isInitialPayment === true && activeTabs.success === false) && <span className="btn-link-gray bold mr-4" onClick={(e) => handleRedirect(e, `/dashboard/edit-article/${articleDetail._id}`)}>CANCEL</span> }
              {(user.nationality!=='outSideIndia' && isInitialPayment === true  && activeTabs.success === false ) && <button disabled={activeTabs.success || !articleDetail?._id} className="button button-primary" type="button" onClick={paymentInitiation}>Continue to Payment </button>}
              {(user.nationality==='outSideIndia' && isInitialPayment === true  && activeTabs.success === false ) && 
              
              <PayPalScriptProvider options={initialOptionsPaypal}>
                <PayPalButtons
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={onApprove}
                  fundingSource={FUNDING.PAYPAL}
                  style={{"layout":"vertical",color: "blue",label: "pay"}}
                  onCancel={onCancelPayapl}
                  onError={onErrorPayapl}
                />
              </PayPalScriptProvider>}

              {(user.nationality==='outSideIndia' && (articleDetail?.status === 'approved' || articleDetail?.updateFlow===true) && articleDetail?.finalPayment === false && activeTabs.success === false && finalPaymentDtl.finalAmt > 0) ? <PayPalScriptProvider options={initialOptionsPaypal}>
            <PayPalButtons
                style={{"layout":"vertical",color: "blue",label: "pay"}}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={onApprove}
                fundingSource={FUNDING.PAYPAL}
                disabled={!finalPaymentDtl.isTermAccept}
            />
        </PayPalScriptProvider> : (user.nationality==='outSideIndia' && (articleDetail?.status === 'approved' || articleDetail?.updateFlow===true) && articleDetail?.finalPayment === false && activeTabs.success === false && finalPaymentDtl.finalAmt <= 0) ? (
          <button disabled={!finalPaymentDtl.isTermAccept} className="button button-primary" type="button" onClick={paymentInitiation}>Continue to Payment</button>
        ) : null} 
              {(user.nationality!=='outSideIndia' && (articleDetail?.status === 'approved' || articleDetail?.updateFlow===true) && articleDetail?.finalPayment === false && activeTabs.success === false) && <button disabled={!finalPaymentDtl.isTermAccept} className="button button-primary" type="button" onClick={paymentInitiation}>Continue to Payment</button>}
              {(articleDetail?.initialPayment === false && activeTabs.success === true && isInitialPayment===true) && <button disabled={!activeTabs.aggryToc} className="button button-primary" type="button" onClick={continueWithArticle}>Continue </button>}
              {(articleDetail?.updateFlow===true && activeTabs.success === true ) && <button  className="button button-primary" type="button" onClick={(e) => handleRedirect(e, `/dashboard/edit-article/${articleDetail._id}`)}>Continue </button>}
            </div>

          </div>}


        </div>}
      </div>
      <Modal
        size="md"
        show={paymentFailedPopup.status}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal authorModalRed"
        centered
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title mb-0">payment failed</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setpaymentFailedPopup({ status: false, txnId: '' })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="congratulationsPopup">
            <p className="text-center">Your transaction has failed. Please try again</p>
            {paymentFailedPopup.txnId !== '' && <div className="transactionWrap">
              <b>Transaction ID:</b><span>{paymentFailedPopup.txnId}</span>
            </div>}
            <div className="text-center" >
              <button
                className="button button-primary redBtn"
                onClick={() => setpaymentFailedPopup({ status: false, txnId: '' })}
              >
                try again
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">


          <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=" modal-sm modal-dialog  "
        size="md"
        show={paymentSuccess}       
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Receipt Sent Successfully </h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setsavedDetailsPupup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave text-center">
            <p>
              Please check your registered email address
            </p>

          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="button button-primary sm ml-3"
            onClick={(e) => handleRedirect(e, `/dashboard/edit-article/${articleDetail._id}`)}
          >
            continue submitting my article
          </span>

        </Modal.Footer>
      </Modal>


      <Modal
        dialogclassName=" modal-sm modal-dialog  "
        size="md"
        show={availableCoupan}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title"><img src={coupanIcon} alt="#" className="availCoup" /> Available Coupons</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setavailableCoupan(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="availableCoupanModal">
            <ul>
            {offers.map((offer) => (
              <li>
                <div className="coupanCard">
                  <div className="coupanHead">
                    <div className="coupanCopy">
                      <b>Coupon Code</b>
                      <strong onClick={()=>copyCouponCode(offer.offerCode)}>{offer.offerCode} <img src={copyIcon} alt="#" /></strong>
                    </div>
                    <span className="offOfer">{offer.offerType==='Percentage off'?`${offer.value}% Off`:`${offer?.currency} ${offer.value} Off`}</span>
                  </div>
                  <div className="coupanFoot">
                    <span><b>Expiration:</b> {convertDateFormate(offer.endDate)}</span>
                    <a href="/terms-conditions" target="_blank">View T&C</a>
                  </div>
                </div>
              </li>
            ))}
            </ul>

          </div>
        </Modal.Body>

      
      </Modal>

      <Modal
        dialogclassName=" modal-sm modal-dialog  "
        size="md"
        show={articlePublishedModal} 
      
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Confetti width={"450px"} height={"480px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div className="modal-icon"> 
             
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">congratulations </h5>
         
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave text-left pl-4">
          <h3 className="researchTextGreen">Your Research Article Is On <br/>Its Way to Final Publication 🎉</h3>
            <p className="">
            Now that you've successfully completed the payment, it's time for you to sit back, relax,<br/>and take a well-deserved breather. 
            </p>
            
            <p className=" mt-3">
            The article will now follow our automated workflow and should soon be visible to the world. We will let you know as soon as this happens.
            </p>
            <p className=" mt-3">
            We'll keep you informed every step of the <br/>way, so you'll know exactly when your article <br/>is set to make its debut on our platform. 
            </p>
            <p className=" mt-3">
            In the meantime, if you have any questions<br/>or simply want to share your excitement, <br/>feel free to reach out to us anytime. We <br/>love celebrating achievements together! 
            </p>

          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">
        <Link
                to={{ pathname: "/myArticle/published" }}
                state={"published"}
                
              >
          <span
            className="button button-primary sm ml-3"
            onClick={(e) => setarticlePublishedModal(false)}
          >
            OKAY
          </span>
        </Link>

        <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>        
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default articleRegistrationPayment;
