import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import NewsLetter from "../components/global/NewsLetter";
import {
  CONTENT_HEADING,
  FEATURED_CARD_HEADINGS,
  SUB_HEADINGS,
} from "../constants";
import SubHeader from "../components/global/SubHeader";
import Content from "../components/global/Content";
import FeaturedCard from "../components/global/FeaturedCard";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import MobileModalAlert from "../components/Modal/MobileModalAlert";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet';

function HowWePublish() {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  return (
    <>
     <Helmet>
          <title> Aakashganga Article Publication Process</title>
          <meta name="description" content="Learn about Aakashganga  article publication process and how we ensure transparent, efficient, and inclusive publishing for researchers. Explore our peer review models, submission steps, and article processing charges" />

          <meta property="og:title" content="Aakashganga Article Publication Process" data-react-helmet="true" />
          <meta property="og:description" content="Learn about Aakashganga  article publication process and how we ensure transparent, efficient, and inclusive publishing for researchers. Explore our peer review models, submission steps, and article processing charges" data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/how-we-publish" data-react-helmet="true" />


     <link rel="canonical" href="https://www.aakashgangaopen.in/how-we-publish" data-react-helmet="true" />
     <meta name="robots" content="index, follow" />
     </Helmet>
    <div className="tagbody">
      <AakashgangaHeader />
      <h1 className="seo-related-tag">
				How We Publish
			</h1>
      <main className="main">
        <SubHeader title={SUB_HEADINGS.HOW_WE_PUBLISH} />

        <div className="content-area">
          <div className="container">
            <p className="pera-text tagp mt-3">
              The Aakashganga Platform exists to represent every research
              scholar who feels she isn’t given a fair chance to present her
              case for publishing her research.{" "}
            </p>

            <p className="pera-text tagp">
              Aakashganga’s approach to publishing is straightforward, and the
              entire journey is driven by the community of researchers and
              professors who are, in reality, truly qualified to determine what
              is publishable and hence noteworthy. We do this by placing the
              whole process of publishing decision- making back in the hands of
              the researchers, with automated technology and fast turn-around
              times.{" "}
            </p>

            <p className="pera-text tagp">
              In time we hope to add Open Access Journals that will follow all
              processes outlined for our Mega Journals.
            </p>
        <div className="howWorkText">
            <Content title={CONTENT_HEADING.APC}>
              <p className="pera-text tagp">
                Aakashganga is a <strong>Gold Open Access Digital</strong>{" "}
                Journals platform of choice for institutions, researchers,
                academics, and students. The strong research community
                representation across our journals ensures that the trajectory
                of science is collectively and collaboratively influenced.{" "}
              </p>

              <p className="pera-text tagp">
                A Gold Open Access publisher means that at the point of
                publication, all articles from our portfolio of journals are
                immediately and permanently accessible online free of charge
                under the CC-NY-BY_SA common creative license, which permits
                unrestricted use, distribution, and reproduction in any medium,
                provided the original authors and the source are credited.{" "}
              </p>

              <p className="pera-text tagp">
                Besides embracing the principles of open access, we believe in
                breaking barriers of exclusion, regionalism and discrimination
                faced by scholars and researchers across the globe. This enables
                us to promote inclusivity and foster a more equitable and
                collaborative research ecosystem for the betterment of society.{" "}
              </p>

              <p className="pera-text tagp">
                All article published on the Aakashganga platform are
                permanently accessible and there is no embargo period like in
                the traditional journals.
              </p>

              <p className="pera-text tagp mb-0">
                We remain committed to being inclusive, affordable and{" "}
                <strong>Open for All.</strong>
              </p>
              
            </Content>
            

            <Content title={CONTENT_HEADING.RAPID_PUBLISHING}>
              <p className="pera-text tagp">
                Aakashganga offers a quick turnaround, and speedy publication of
                articles, each of which benefit from an automated process, with
                least manual intervention, zero editorial bias and transparent
                peer review making all source data openly available.{" "}
              </p>

              <p className="pera-text tagp">
                All articles published by the Aakashganga are immediately and
                permanently accessible on our digital platform online free of
                charge.{" "}
              </p>

              <p className="pera-text tagp">
                We publish all content under a Creative Commons{" "}
                <a
                  href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                  target="blank" className="taga colorBlack"
                >
                  <strong className="underline ">CC-BY–NC-SA</strong>
                </a>{" "}
                license, which permits unrestricted use, distribution, and
                reproduction for personal and non-commercial use in any medium,
                provided the original authors and the source are credited.{" "}
              </p>

              <p className="pera-text tagp">
                We charge a very nominal article processing charges for accepted
                papers that want to get Pre-Publishing Peer Reviewed. Read more
                about{" "}
                <Link className="colorBlack"
                  to="/apc"
                  target="_blank"
                >
                  <strong className="underline ">
                    Article Processing Charge
                  </strong>
                </Link>{" "}
                here.
              </p>
            </Content>

            <Content title={CONTENT_HEADING.STATE_OF_ART_TECH}>
              <p className="pera-text tagp">
                Our key aim is to make access to information free as freedom so
                that it is seamless and connected. To help researchers, editors
                and peer reviews do their jobs efficiently, we have developed
                one of the most efficient state-of-art open science technology
                platform in academic publishing.{" "}
              </p>

              <p className="pera-text tagp">
                By harnessing cutting-edge technology, we enable the validation
                and widespread dissemination of their findings. Leveraging
                software tools enables us to offer a streamlined, prompt, and
                efficient service, freeing our editors to concentrate on
                scientific content and enhance manuscripts through the peer
                review process.
              </p>
            </Content>
            <Content title={CONTENT_HEADING.PUBLISHING_MODEL}>
              <p className="pera-text center width75text tagp">
                The most important question that we would like to answer before
                you look at our <br/>Publishing Model is{" "}
                <strong className="red-text d-inline-block">
                  Why Publish with Aakashganga?
                </strong>
              </p>

              <p className="pera-text providing tagp">
                We believe that your words have the power to change the world
                and your research, the ability to make an impact. We also
                believe the future is here, and it is OPEN. And we bridge this
                gap by disseminating research through Open Access Mega Journals
                paired with exceptionally brilliant technology and one of the
                lowest APCs to accelerate discovery.{" "}
              </p>

              <p className="pera-text providing tagp">
                Therefore, we invite you to publish your research on the
                Aakashganga Platform as the predominant model of communication
                for your scholarly outputs.{" "}
              </p>

              <p className="pera-text tagp">
                <strong>You should publish with us because:</strong>
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">1.</span> We are a Homegrown Indian
                Open Access Platform for Research Dissemination registered under{" "}
                <a
                  href="https://www.startupindia.gov.in/"
                  target="blank" className="taga colorBlack"
                ><strong className="underline">Start-Up India,</strong></a> and
                Ministry of Micro, Small & Medium Enterprises{" "}
                <a
                  href="https://msme.gov.in/"
                  target="blank" className="taga colorBlack"
                ><strong className="underline">(MSME)</strong></a>. You can be rest
                assured that you will not be publishing under a predatory
                Journal.
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">2.</span> We exist to represent every
                research scholar who feels she isn’t given a fair chance to
                present her case for publishing her research.{" "}
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">3.</span> Our APCs are one of the
                lowest in the world for Indian researchers, and very affordable
                and competitive for the rest of the world.{" "}
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">4.</span> offer both Pre-Publishing
                Double Anonymous Peer Review and Post Publishing Open Peer
                Review, ensuring absolute honesty and transparency in the
                process.
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">5.</span> Your research journey will
                always be driven by the community of researchers and professors
                who are, in reality, truly qualified to determine what is
                publishable and hence noteworthy.{" "}
              </p>

              <p className="pera-text abstarct tagp">
                <span className="red-text bold600">6.</span> We are committed to
                breaking barriers of exclusion, regionalism and discrimination
                faced by scholars and researchers across the globe.
              </p>

              <p className="pera-text tagp">
                <span className="red-text bold600">7.</span> Technology is at the heart
                of all that we do without losing sight of a researcher’s
                objective to be published.
              </p>

              <div className="register-button publish-register-btn">
                {!token && (
                  <button onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}>
                    Submit your manuscript
                  </button>
                )}
                {token && (
                  <button onClick={() => {isMobile ? setMobileAlert(true):setUploadArticle(true);}}>
                    Submit your manuscript
                  </button>
                )}
              </div>
            </Content>

            <Content title={CONTENT_HEADING.RESEARCH_ARTICLES}>
              <p className="pera-text section tagp">
                Aakashganga publishes a diverse collection of{" "}
                <Link to="/about-megajournals" target="_blank" onClick={() => window.scroll({ top: 0, behavior: "smooth" })}><strong className="underline"> Seven</strong></Link> impactful{" "}
                <Link to="/open-access" target="_blank" onClick={() => window.scroll({ top: 0, behavior: "smooth" })}><strong className="underline">Open Access</strong></Link>{" "} Mega Journals
                spanning across various subjects. Our Mega Journals undergo
                rigorous reporting and peer review processes, ensuring
                high-quality publications that are promptly accessible to all,
                without any restrictions.
              </p>

              <p className="pera-text section tagp">
                We encourage you to carefully assess the scope of each journal
                before submitting your work, as each Mega Journal has a
                specialized publication criteria and a broad range of content.
              </p>
            </Content>

            <Content title={CONTENT_HEADING.ARTICLE_SUBMISSION_STEPS}>
              <div className="row align-items-center mb-4 how-publish-icons">
                <div className="col-lg-2 texmanageBox">
                  <img
                    src="assets/images/Submit Details.png"
                    alt="Submit Details"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="small-title colorGreen mb-1 tagh2">Submit Details</h2>
                  <p className="pera-text tagp">
                    All submissions on the Aakashganga platform are done through
                    an automated single-flow submission system.{" "}
                  </p>
                  <p className="pera-text tagp">
                    Please use your account username and password to{" "}
                    <Link  target="_blank" className="colorBlack"
                      to="/login"
                      onClick={(e) => handleRedirect(e, "/login")}
                    >
                      <strong className="underline">log-in{" "}</strong>
                    </Link>
                    and access{" "}
                    {!token && (
                      <Link className="colorBlack" target="_blank" to="javascript:;" onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}>
                        <strong className="underline">
                          {" "}
                          ‘Submit Your Research’
                        </strong>{" "}
                      </Link>
                    )}
                    {token && (
                      <Link className="colorBlack"
                      onClick={() => {
                        isMobile ? setMobileAlert(true):setUploadArticle(true);
                      }}
                      >
                        <strong className="underline">
                          {" "}
                          ‘Submit Your Research’
                        </strong>{" "}
                      </Link>
                    )}
                    
                    button on your dashboard.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center mb-4 how-publish-icons-second customhowwepublishcontent"
              >
                <div className="col-lg-5">
                  <img
                    src="assets/images/Choose Review Type.png"
                    alt="Choose Review Type"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-5">
                  <h2 className="small-title colorGreen mb-1 tagh2">Choose Review Type</h2>
                  <p className="pera-text tagp">
                    Start by choosing the review type you would want to go with.
                    We offer two types of Peer Reviews:
                  </p>
                  <ul className="margin-botton-none tagul">
                    <li className="tagli">Pre-Publishing Double Anonymous Peer Review</li>
                    <li className="tagli">Post Publishing Open Peer Review.</li>
                  </ul>
                  <p className="pera-text abstarct tagp">
                    Please review the details of Aakashganga’s{" "}
                    <Link
                      to="/peer-review-models"
                      target="_blank"
                      className="underline customhowwepublishcolor taga colorBlack"
                    >
                      Peer Review Models
                    </Link>
                     and{" "}
                <Link target="_blank"
                  to="/apc"
                  className="underline customhowwepublishcolor taga colorBlack"
                >
                  Article Publishing Costs
                </Link>
                    {" "} before you decide to submit your article.
                  </p>
                </div>
              </div>
              <div className="row align-items-center mb-4 how-publish-icons">
                <div className="col-lg-2 texmanageBox">
                  <img
                    src="assets/images/Pay Registration Fee.png"
                    alt="Pay Registration Fee"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="small-title colorGreen mb-1 tagh2">Pay Registration Fee</h2>
                  <p className="pera-text section tagp">
                    After deciding the Peer Review type you would like to
                    choose, please pay the registration fee of INR 1000* (not
                    applicable to payments made in foreign currencies) to get
                    started on your Article Submission Journey.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center mb-4 how-publish-icons-second customhowwepublishcontent"
              >
                <div className="col-lg-5">
                  <img
                    src="assets/images/Online Article Submission.png"
                    alt="Online Article Submission"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-5">
                  <h2 className="small-title colorGreen mb-1 tagh2">Online Article Submission</h2>
                  <p className="pera-text tagp">
                    After the registration payment has been made, you will start
                    filling details about your research. Once the platform
                    records all the details, you will write the research paper
                    online, on the platform.
                  </p>
                </div>
              </div>
              <div className="row align-items-center mb-4 how-publish-icons">
                <div className="col-lg-2 texmanageBox">
                  <img
                    src="assets/images/Clear the Checks.png"
                    alt="Clear the Checks"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="small-title colorGreen mb-1 tagh2">Clear the Checks</h2>
                  <p className="pera-text tagp">
                    Before you can finally submit your research for further
                    processing, after having written the paper, we will take
                    your paper through a:
                  </p>
                  <div className="graList">
                    <ul className="flex-li tagul">
                      <li className="margin-left-none tagli">Grammar Check</li>
                      <li className="tagli">Profanity Check</li>
                      <li className="tagli">Similarity Index Check</li>
                    </ul>
                  </div>
                  <p className="pera-text tagop">
                    After these checks are complete, your manuscript is now
                    ready for editorial assessment.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center mb-4 how-publish-icons-second customhowwepublishcontent"
              >
                <div className="col-lg-5">
                  <img
                    src="assets/images/Publishing Decision.png"
                    alt="Publishing Decision"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-5">
                  <h2 className="small-title colorGreen mb-1 tagh2">Publishing Decision</h2>
                  <p className="pera-text abstarct tagp">
                    The Publishing Decision is given after the accepted
                    manuscript goes through editorial assessment and
                    peer-reviewed by active researchers, who are experts in
                    their fields.
                  </p>
                </div>
              </div>
              <div className="row align-items-center mb-4 how-publish-icons">
                <div className="col-lg-2 texmanageBox">
                  <img
                    src="assets/images/Article Processing Charge.png"
                    alt="Article Processing Charge"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="small-title colorGreen mb-1 tagh2">Article Processing Charge</h2>
                  <p className="pera-text efforts tagp">
                    If the manuscript is accepted for publishing on the
                    platform, you will be required to pay the full Article
                    Processing Fee. If you are eligible for a grant or any
                    discounts through the platform, now will be the time to
                    apply it.
                  </p>
                  <p className="pera-text tagp">
                    You will also be able to apply any Mudras that are available
                    in your Mudrakosh or wallet at this stage. Please understand
                    our{" "}
                    <Link to='/apc' target="_blank" ><strong className="underline colorBlack d-inline-block">
                      {" "}
                      Article Processing Charges{" "}
                    </strong>{" "}</Link>
                    here.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center mb-4 how-publish-icons-second customhowwepublishcontent"
              >
                <div className="col-lg-5">
                  <img
                    src="assets/images/Article is Published!.png"
                    alt="Article is Published"
                    className="icon-oimage tagimg"
                  />
                </div>
                <div className="col-lg-5">
                  <h2 className="small-title colorGreen mb-1 tagh2">Article is Published!</h2>
                  <p className="pera-text tagp">
                    We will publish your article within 45 working days in case
                    of Pre-Publishing Double Anonymous Peer Review and within 30
                    days in case of Open Post Publishing Peer review.
                  </p>
                </div>
              </div>
              <div className="register-button">
                {!token ? (
                    <button onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}>Start now</button>
                  ) : (
                    <button onClick={() => {
                      isMobile ? setMobileAlert(true):setUploadArticle(true);
                    }}>Start now</button>
                  )
                }
              </div>
            </Content>
            </div>
            <FeaturedCard title={FEATURED_CARD_HEADINGS.ARCHIVING}>
              <div className="archive-content effortsarchiving">
                We at Vikramshila are conscious about the long term availability
                of all OA content published by us. We are committed to making
                this available as close to 100% of the time as is humanly
                possible. Rest assured that your contributions to our open
                access journals have a lasting impact. As we generate a DOI for
                each article through CrossRef, it’s important to note that this
                not only provides a unique and permanent identifier for your
                work but also ensures its automatic inclusion within the
                CrossRef archives. This integration further enhances the
                visibility and accessibility of your research, contributing to
                the global dissemination of knowledge.<br/><br/> If for some reason we are
                unable to host the content where circumstances are beyond our
                control, we promise to let our stakeholders know about this and
                commit to finding solutions that will fill the void.
              </div>
            </FeaturedCard>
            <p className="pera-text last-faq-section tagp">
              For more questions on our processes, policies and other aspects,
              please refer to our{" "}
              <Link to="/faq" target="_blank">
                <strong className="underline ">FAQ section.</strong>
              </Link>
            </p>
          </div>
        </div>
      </main>
      <NewsLetter />
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
      <AakashgangaFooter />
      </div>
    </>
  );
}

export default HowWePublish;
