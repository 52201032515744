import NewsLetter from "../components/global/NewsLetter";
import FaqBanner from "../components/faq/FaqBanner";
import Origins from "../components/faq/Origins";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function FaqPage() {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is the Origin of Aakashganga?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `In contemporary day to day usage, "Aakashganga" refers to the Milky Way, our galaxy.
          
          The term "Aakashganga" is found in the Puranas (ancient Indian literature).
          
          Translated into the publishing realm, Aakashganga represents an automated, transformative platform that hosts Open Access Journals.
          
          It also hosts what are India’s first series of Mega Journals. Encompassing 7 mega-journals across disciplines like Social Sciences, Business & Management, Medicine, Engineering, Information Technology, Ayurveda and Yoga, and Sciences, Aakashganga aspires to cover the entire spectrum of subjects researchers need to publish in.
          
          Each of these journals will provide world class peer review and publishing processes.
          
          While being born Indian it will help the developing world find its place in publishing creditable research at an affordable price.
          Aakashganga is on track to be the platform that provides an alternative to Western dominated high priced, exclusive and at times discriminatory publishing options.`
        }
      },
      {
        "@type": "Question",
        "name": "Why did we start Aakashganga?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Standing up against Western-centric, exorbitant, elitist, and occasionally prejudiced publishing behemoths, Aakashganga offers an Indian alternative.
          
          Frustration with Western predominance in research dissemination fueled a determination to blaze our trail, confident that our potential wasn't bound by others' understanding of our homeland.
          
          This homegrown platform aspires to democratise the academic publishing sphere for the developing world, granting an affordable avenue for legitimate research publication.
          
          We break barriers of exclusion, regionalism and discrimination faced by scholars and researchers across the globe. Technology is at the heart of all that we do without losing sight of a researcher’s objective to be published.
          We remain committed to being inclusive, affordable and Open for All.`
        }
      },{
        "@type": "Question",
        "name": "What is Aakashganga’s Purpose & Aim",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga foresees a world where India is hailed as a pivotal player in scientific research and knowledge sharing.
    
    We are dedicated to forging a dynamic environment that nurtures and propels the country's research community, enriching academia, and contributing towards societal betterment.
    
    Through diversity, inclusivity, and transparency, we aim to revolutionise how research is published and esteemed, shaping a prosperous future for ensuing generations.
    
    Our mission is to empower researchers and educators with an accessible, inclusive platform that cultivates scientific excellence and innovation. We aspire to be the publishing platform of choice for not just Indian researchers but for everyone who seeks transparency, inclusion and affordability.`
        }
      },{
        "@type": "Question",
        "name": "What is an open access journal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "An open access journal makes scholarly articles freely available online, allowing researchers and the public to access, read, and use the content without subscription or payment barriers."
        }
      },{
        "@type": "Question",
        "name": "What is a mega journal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A mega journal is a subject specific, open access journal that covers a wide range of disciplines, accepting research articles on diverse topics, making it an ideal platform for interdisciplinary research"
        }
      },{
        "@type": "Question",
        "name": "Why should I publish in an open access journal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Publishing in an open access journal ensures that your research is widely accessible, increasing its visibility, citations, and impact. It promotes collaboration and knowledge sharing across the global research community. With subscription-based journals being weaned out of academic circles Open Access is fast becoming the primary and effective choice."
        }
      },{
        "@type": "Question",
        "name": "Will my manuscript undergo a plagiarism check?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, all submitted manuscripts undergo a Similarity Index Check (Plagiarism Check) to ensure originality and adherence to ethical standards."
        }
      },{
        "@type": "Question",
        "name": "Does the platform provide any other checks to maintain the quality of submitted research?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Besides the similarity Index Check, we also ensure that your research goes through two more checks. These are the Profanity check, and the Language and Grammar check."
        }
      },{
        "@type": "Question",
        "name": "What is Aakashganga and how does it help researchers?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga is an Indian Open Access Automated Journal Publishing Platform bringing together 7 Mega Journals spanning Social Sciences, Business & Management, Medicine, Engineering, Sciences, Information Technology and Ayurveda and Yoga.
    
    Aakashganga is an Open Science advocate, facilitating inclusive, interdisciplinary, and multidisciplinary article publishing with ease.
    
    We offer Pre-Publishing Double Anonymous Peer Review as well as Post Publishing Open Peer Review, all integrated into one user-friendly platform.`
        }
      },{
        "@type": "Question",
        "name": "Does Aakashganga work with Societies, Universities, and Institutes to publish their research?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Yes, we also work closely with Societies, Universities, and Institutes to publish Open Access Research.
    
    As a digital, proudly made in India, Open Access Journal Platform, we are dedicated to supporting Universities, Institutes, and Societies in publishing their journals. Our mission is to provide an inclusive and accessible publishing solution, empowering academic and research communities to disseminate their scholarly work globally.
    
    Our practices completely adhere to the policies, guidelines, and standards set by the Government of India. This promotes transparency, authenticity, and adherence to ethical publishing practices.
    
    Click Here to read more about our association with Societies, Universities and Institutes
    
    With our commitment to affordable publishing and a host of exceptional benefits, we aim to be the Partner of Choice for institutions seeking a reliable and efficient publishing platform.
    
    We offer multiple choices of collaboration: institutional partnerships to independent society journals. Together, we will bring your strategic vision to life, creating a journal that truly inspires and engages your community
    
    We are a fertile ground for collaboration and networking - a place for your research to thrive. For researchers, it serves as a toolkit. The platform and its technology-driven processes are automated, providing one of the most affordable Article Processing Charges (APCs) globally. We also offer multiple grants and discounts to facilitate publishing for researchers from all economic backgrounds. Currently grants are only available for Indian researchers.`
        }
      },{
        "@type": "Question",
        "name": "Is the platform recognized by reputable academic institutions and organisations",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga doesn't stand alone. It is a part of Vikramshila Education.
    The platform is created by people who have led businesses in publishing at senior leadership positions for more than thirty years each, and bring with them vast experience in platform management, digital automations, Journal publishing and editorial processes, sales, and marketing.
    
    Read about Vikramshila Research here:
    1. Company wants to see India as an industry 'frontrunner'
    2. 'Born in India, but for the world'
    3. Chosen as the voice of the international publishing industry
    4. Making research journey more transparent
    
    Quality isn't an afterthought here, it's the foundation. The Aakashganga platform is built on a pledge to excellence and is a fortress of credibility with well-recognised senior academics endorsing it. Check our Advisory Board here. Aakashganga is evolving continuously. We are tirelessly crafting domestic as well as international affiliations, bonds with well-respected academic institutions and organisations.
    
    We give all the affiliation logos here: add all logos in the homeppage under the section Made in Love with Bharat here.
    
    Each collaboration doesn't just add a feather to our cap but strengthens Aakashganga standing and believability.
    
    It's these endeavours that cement Aakashganga as a trusted haven for scholarly discourse in India.`
        }
      },{
        "@type": "Question",
        "name": "What is the editorial assessment process at Aakashganga?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `At Aakashganga, our editorial assessment process is fully automated, ensuring efficiency and fairness. Manuscripts undergo initial screening by our automated system, which checks for adherence to submission guidelines, ethical standards, such as Profanity, and basic quality such as grammar and language.
    
    Submissions that pass this initial screening are then forwarded for editorial assessment. The system checks from the most compatible match for the article and their availability. This process is completely system driven and anonymous with no manual intervention.`
        }
      },{
        "@type": "Question",
        "name": "How can I join Aakashganga as a publishing editor for your mega journals?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `To become a publishing editor for our mega journals, you should register with us on this link. Our in-house team will contact you if you meet our Publishing Editor criteria. The appointment of Publishing Editors is by invitation only.`
        }
      },{
        "@type": "Question",
        "name": "Who is eligible to be a publishing editor on the Aakashganga platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `To qualify as a publishing editor on our platform, individuals should meet the following criteria:
        
    Hold a Ph.D. or equivalent degree in a relevant field.
    Have a strong research background and expertise in the subject area of the journal.
    A track record of peer-reviewed publications in reputable journals.
    Previous experience in editorial roles, such as serving as a reviewer or associate editor, is preferred but not mandatory.
    Commitment to upholding ethical standards in research and publication.`
        }
      },{
        "@type": "Question",
        "name": "Can individuals from developing countries join as publishing editors?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely! Aakashganga welcomes publishing editors from around the world, including those from developing countries. We believe in promoting diversity and inclusivity in our editorial board to ensure a global perspective."
        }
      },{
        "@type": "Question",
        "name": "How can I join the editorial board for society, university, and institute journals hosted on Aakashganga?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `To be a part of the editorial board for society, university, and institute journals, you can express your interest in two ways:
    
    By contacting the respective journal's editorial team. Details for contacting each journal's editorial team can be found on their respective journal pages on the Aakashganga platform.
    By registering with the Aakashganga platform, you increase your chances of being seen for our Society, University and Institute journal as well.`
        }
      },{
        "@type": "Question",
        "name": "Is there any fee associated with becoming a publishing editor or joining an editorial board?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Aakashganga does not charge any fees for individuals to become publishing editors or join editorial boards. Our aim is to foster a collaborative and inclusive research environment. We do however provide a token honorarium for every article assessed."
        }
      },{
        "@type": "Question",
        "name": "How does Aakashganga Peer Review process work and is it rigorous enough to maintain high-quality standards?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga’s Peer Review process is automated and transparent, setting new standards of quality. Our peer-review process involves experts in the field reviewing submissions for quality, originality, and relevance.
    
    In accordance with the Open Science principles, we offer two kinds to Peer Reviews, to suit your research preference:
    
    Pre-Publishing Double Anonymous Peer Review – In this kind of Peer Review, suitable Peer Reviewers, selected by our system, will be invited to review, and present their views before the article is published. Both the authors and reviewers remain anonymous to each other.
    Post Publishing Open Peer Review – In this system, manuscripts are initially published without peer review, and the review process takes place openly, with reviewers' comments and author responses being made public.
    
    No reviewers are invited specifically for this purpose and the review process remains open as long as the article is hosted on Aakashganga.
    
    By default, all articles will undergo the Post Publishing Open Peer Review.
    
    Please note that the choice between Pre-Publishing Double Anonymous Peer Review and Post-Publishing Open Peer Review depends on a researcher's goals, preferences, and the nature of the research.
    
    Both approaches have their own advantages and considerations, and researchers should carefully evaluate which Peer Review process aligns best with their objectives and values.
    
    When the Publishing Editor approves the content, our AI driven engine selects Peer Reviewers anonymously. Each Reviewer is provided with detailed guidelines and the review is submitted digitally online.
    
    The feedback they provide serves as a tool for authors like you, aiding in refining your research into a masterpiece.`
        }
      },{
        "@type": "Question",
        "name": "How does the Pre-publishing Double Anonymous Peer Review work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `In the Pre-publishing Double Anonymous Peer Review process, submitted manuscripts are assigned to expert reviewers who evaluate the work for quality, accuracy, and relevance. The reviewers' identities are kept confidential, and their feedback helps authors improve their manuscripts before publication.
    
    Peer review process on the Aakashganga platform is fully automated. The review is written online and submitted online.`
        }
      },{
        "@type": "Question",
        "name": "What is the advantage of Pre-publishing Double Anonymous Peer Review?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Pre-publishing Double Anonymous Peer Review ensures rigorous assessment of manuscripts, helping to maintain high publication standards and reducing the likelihood of errors or inaccuracies in published research. In some cases, it is a requirement by the research funding agency. We provide a token honorarium to reviewers for every article reviewed"
        }
      },{
        "@type": "Question",
        "name": "How does the Post-publishing Open Peer Review work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In the Post-publishing Open Peer Review process, manuscripts are initially published without peer review. After publication, the research community can openly review and comment on the work. Authors can respond to these comments, fostering transparency and ongoing dialogue. Only registered users are allowed to post their reviews. No honorarium is provided to any reviews posted on the article webpage."
        }
      },{
        "@type": "Question",
        "name": "What are the benefits of Post-publishing Open Peer Review?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Post-publishing Open Peer Review encourages transparency and engagement within the research community. It allows for a broader range of perspectives and facilitates ongoing improvements to the work."
        }
      },{
        "@type": "Question",
        "name": "Who are the peer reviewers, and how are they selected?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Peer reviewers are experts in relevant disciplines, selected based on their expertise and publication history. They ensure a thorough and unbiased review process. Our AI driven anonymous peer review selection engine finds a suitable match of reviewers and requests them to participate in the process. No human allots a reviewer hence it is truly anonymous."
        }
      },{
        "@type": "Question",
        "name": "Can authors choose between the two peer review options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, authors can select their preferred peer review option during the submission process. They can choose between Pre-publishing Double Anonymous Peer Review or Post-publishing Open Peer Review based on their preferences and the nature of their research."
        }
      },{
        "@type": "Question",
        "name": "Is there a difference in the publication timeline between the two peer review options?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `The publication timeline may vary between the two options. Pre-publishing Double Anonymous Peer Review takes around 15-20 days and can be influenced by factors like reviewer availability and revisions. Thus the publishing time is around 15 to 20 days longer than the Post-publishing peer review flow.
    Post-publishing Open Peer Review allows for quicker publication but with ongoing open review and dialogue.`
        }
      },{
        "@type": "Question",
        "name": "What is the time frame for the submission, review, and publication process?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Now, about timelines. We get it, at Aakashganga, the ticking clock matters.
    
    Our submission, review, and publication processes are lean and run through a well-oiled machine of pre-set processes. We've pruned the usual months or years down to weeks.
    
    The result is that your work shines in the limelight sooner, but without ever risking research quality or integrity.
    
    Our timelines are as follows:
    
    1. Pre-Publishing Double Anonymous Peer Review: We aim to publish your research article within 45 Days once submitted. After all the Peer Reviewer and Publishing Editor approvals, the article is published within 5 working days on the platform.
    
    2. Post Publishing Open Review: We aim to publish your research article within 30 days after submission. And within 7 days after Publishing Editor approvals.
    
    Please Note: Appointment of Publishing Editor on the Aakashganga platform is by invitation only. We may extend individual invitations to registered users to join our esteemed Publishing Editor’s Board based on their experience, tenure, and seniority. If you wish to have us review your candidature please drop us a line at contactus@vikramshilaedu.in
    contactus@vikramshilaedu.in`
        }
      },{
        "@type": "Question",
        "name": "Can readers provide feedback in the Pre-publishing Double Anonymous Peer Review process?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Generally, readers do not participate in the Pre-publishing Double Anonymous Peer Review process. Feedback is primarily provided by expert reviewers. However, readers can engage with the work once it is published."
        }
      },{
        "@type": "Question",
        "name": "How can authors or reviewers participate in these peer review processes?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Authors can choose their preferred peer review option during the submission process. Peer Reviewers are selected by our automated search engine that finds the most suitable match for the article and its subject."
        }
      },{
        "@type": "Question",
        "name": "Can author’s recommend Peer reviewers?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Authors can encourage Peer Reviewer colleagues to register on the portal. They may also recommend Peer Reviewers during the submission process.
    
    Please note that the Peer Review selection is automated on the Aakashganga platform, and therefore, while we may add the Peer Reviewers recommended by you to our database, the automated system will not be inviting them to specifically review your research article.`
        }
      },{
        "@type": "Question",
        "name": "Will I be paid to become a Peer Reviewer or Publishing Editor on the Aakashganga platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `To clarify our approach regarding compensation for Peer Reviewers and Publishing Editors on the Aakashganga platform:
    
    At Aakashganga, we believe that Peer Reviewing, and Editorial Assessment play a vital role in advancing scholarly output and knowledge sharing within the academic community. Rather than offering monetary benefits, we express our gratitude and appreciation through a fixed honorarium.
    
    For every Peer Review completed, and Editorial Assessment undertaken, we provide complimentary Mudras (value in INR) credited to your Mudra Kosh (your Online Wallet account) as a token of our appreciation for your dedicated efforts. These Mudras can be used at your discretion, to write a new article or to update an existing one.
    
    We understand the importance of recognizing your hard work and commitment, which is why we offer these Mudras as a gesture of thanks, irrespective of whether the reviewed article is eventually published or not. The amounts reflect the current value of the honorarium and these are subject to change at the management’s discretion.
    
    Additionally, when we send thank-you letters to acknowledge your contributions as a Peer Reviewer, we are more than happy to include your supervisors or mentors to help enhance your professional credibility. In this case, you would need to inform us about the contact details of your supervisor or mentor.
    
    We appreciate the dedication of Publishing Editors and Peer Reviewers to advancing academic knowledge, and we hope that this small token of appreciation in the form of Mudras reflects our gratitude for their valuable time and effort.`
        }
      },{
        "@type": "Question",
        "name": "How do I submit my research to your mega journal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can submit your research through our online submission portal. The submission process on Aakashganga is 97% automated. It is seamless, and guides you through every step clearly. Detailed instructions can be found on our website homepage."
        }
      },{
        "@type": "Question",
        "name": "Are there any submission fees?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `There are no submission fees for our mega journals. We believe in removing financial barriers to publishing quality research.
    
    However, we charge a registration fee of INR 1000 for Indian nationals, and $50 for Foreign Nationals, when you start submitting the paper. This fee is adjusted against the full APC. If your article is rejected the registration amount is returned to your wallet`
        }
      },{
        "@type": "Question",
        "name": "What file formats are accepted for submission?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We have an online submission portal, which does away with the need for any manual intervention or uploading extra document on the platform. You will do all your work, and submission online"
        }
      },{
        "@type": "Question",
        "name": "Can I submit supplementary materials along with my manuscript?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can submit supplementary materials like datasets, images, and videos to support and enhance the understanding of your research"
        }
      },{
        "@type": "Question",
        "name": "What is the publication timeline for accepted manuscripts?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Upon submission, your manuscript will be processed for Editorial Assessment and sequenced on the type of Peer Review you choose. The exact timeline can vary but typically ranges from 30 to 45 days."
        }
      },{
        "@type": "Question",
        "name": "What kind of search capability does the platform have?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our platform supports full-text search capabilities, allowing users to search within the entire text of research papers, further improving the discoverability of specific information within documents."
        }
      },{
        "@type": "Question",
        "name": "vAre there any associated fees for submission, publication, or open access publishing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Yes, there are fees for publishing the article on the platform.
    
    But listen closely – our Article Processing Charges are one of most affordable in the world, very nominal, and designed to be easy on your pocket.
    
    Our goal is to keep the publishing journey financially accessible, not letting money stand in the way of quality research seeing the light of day.
    
    Please access our link on Article Publishing Charges (APC) here.`
        }
      },{
        "@type": "Question",
        "name": "What is the platform's policy regarding copyright and do research scholars or the authors retain their rights?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `At Aakashganga, you will discover the meaning of true ownership and freedom for your research with our Open Access Journals.
    
    We understand the significance of retaining ownership of your hard work and ensuring your research reaches the widest audience without any barriers.
    
    That's why we want you to know that when you publish with us:
    
    Copyright Stays with You: Your research is your intellectual property. We firmly believe in upholding your rights as an author, and your copyright will always remain yours.
    
    No Embargo Periods: We value your time and effort. That's why we promise that there will never be any embargo period on your published work. Your research will be accessible to the global community as soon as it's published, fostering immediate engagement and impact.
    
    Unwavering Commitment to Open Science: Open science is at the heart of our mission. We are committed to promoting transparency, collaboration, and the sharing of knowledge. Your research will always be a reflection of your dedication, and we will ensure it stays that way.
    
    Join us in embracing a future where your research is truly yours. Publish with Us and experience the freedom of Open Access without limitations.
    
    Your contributions deserve to be recognized, shared, and celebrated without any hindrances.`
        }
      },{
        "@type": "Question",
        "name": "How does the platform handle plagiarism and ethical concerns?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Plagiarism and ethical concerns hold substantial gravity. We take them very seriously.
    
    All submissions on the Aakashganga platform undergo a comprehensive Similarity Index Check, using our advanced software to ensure the originality of the content. Any instance of plagiarism is promptly addressed according to our strict policies.
    
    We also put all our manuscripts through a thorough Grammar and Language Test, as well as a mandatory Profanity test to ensure that only original, ethically sound content is published.
    
    We also understand the frustrations of getting duped by predatory journals, and at Aakashganga, we are guardians of academic research integrity, providing a platform you can trust, as transparent as a mountain stream.`
        }
      },{
        "@type": "Question",
        "name": "What measures do you take to avoid predatory journals?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We have a zero-tolerance policy for predatory practices. Our editorial team diligently evaluates each journal and its practices. We are transparent about our Article Processing Charges, Peer Review Processes and provide clear information to authors, readers, and researchers on the platform at each step, ensuring credibility and trustworthiness."
        }
      },{
        "@type": "Question",
        "name": "How does your platform contribute to India's knowledge advancement?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We are dedicated to elevating India's stature as a global knowledge hub. By providing a reputable platform for research dissemination and following the principles of Open Science, we aim to showcase India's research prowess and encourage international collaborations."
        }
      },{
        "@type": "Question",
        "name": "How does your platform align with India's 'Atma Nirbhar' (self-reliance) mission?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our commitment to transparency, quality, and open access resonates with India's 'Atma Nirbhar' vision. We empower Indian researchers and scholars to publish their work on a globally recognized platform, fostering self-reliance in research dissemination."
        }
      },{
        "@type": "Question",
        "name": "What is your vision for India's role in global research?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We envision India as a superpower of knowledge, making substantial contributions to global research and innovation. By providing a platform for researchers to showcase their work on international standards, we aim to amplify India's impact on the world stage."
        }
      },{
        "@type": "Question",
        "name": "How user-friendly is the platform's interface for submission, revision, and communication?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Ease of submission, revision, and communication? We've got it all.
    
    The platform is 97% automated, leaving out the long manual processes offered by many publishers. Our interface, designed keeping you, the user, in mind, promises a seamless experience, letting you concentrate on your work without the noise."
        }
      },{
        "@type": "Question",
        "name": "Does the platform have a dedicated support team for answering queries and providing assistance throughout the publication process?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Assistance throughout the publication process? You bet.
    
    Our dedicated support crew is always ready to answer your queries, provide help as you navigate the publication journey.
    
    We value prompt support, and we're committed to delivering a smooth, positive experience for every author, every researcher, and every Society member, on Aakashganga.
    
    Please write to us at contactus@vikramshilaedu.in
    
    For any particular issue that requires immediate attention, please write to:
    
    Founder and CEO: vivek.mehra@vikramshilaedu.in
    Co-Founder and CMO: Shafina.segon@vikramshilaedu.in`
        }
      },{
        "@type": "Question",
        "name": "Are there any specific formatting guidelines authors need to follow for submission?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `For submissions, Aakashganga has guidelines for each of Authors, Editors & Reviewers and Academic Societies. We have simplified the process since all Articles are submitted in a prescribed format and online. No need to bother with keeping track of conversations or email correspondence; we are online first and open for all.
    
    These help with consistency and the reviewing process. What’s more, they let authors focus on their research content.`
        }
      },{
        "@type": "Question",
        "name": "What is the platform's policy on data sharing, supplementary materials, and open data?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `The platform values openness and transparency.
    
    We encourage data sharing, supplementary materials, and open data. Please check our terms of use here.
    
    Our policies support the reusability and accessibility of research data, promoting a culture of innovation and collaboration.`
        }
      },{
        "@type": "Question",
        "name": "Are there any opportunities for serving as a peer reviewer, editor, or in other editorial roles on the platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `The platform also offers roles for qualified individuals.
    
    These include peer reviewers, and publishing editors, roles. This promotes high standards and the growth of the academic community. Please click here to register. The registration process will automatically put you in to our peer reviewer pool. However the role of publishing editor is by invitation only.`
        }
      },{
        "@type": "Question",
        "name": "Does the platform provide any support or guidance for early-career researchers and authors with limited publishing experience?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `For early-career researchers and authors with limited publishing experience, Aakashganga provides support and guidance.
    
    We have a resource centre, which offers a section on PowerPoint presentations on research publishing, How-to Videos and a blog that offers latest articles to help you take informed decisions and learn more about publishing. We also offer customised music playlists to keep you engaged and happy while you are solving world’s problems with your research.
    
    It helps nurture the next generation of scholars by offering resources and mentorship`
        }
      },{
        "@type": "Question",
        "name": "What is the platform's policy on archiving and preservation of published articles?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga is dedicated to the long-term preservation and accessibility of published articles.
    
    We are conscious about the long term availability of all OA content published by us. We are committed to making this available as close to 100% of the time as is humanly possible. If for some reason we are unable to host the content where circumstances are beyond our control, we promise to let our stakeholders know about this and commit to finding solutions that will fill the void`
        }
      },{
        "@type": "Question",
        "name": "What kind of information does the platform collect when I give my details?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `At Aakashganga, we value the privacy of our users and are committed to safeguarding their personal information.
    
    This Privacy Policy describes the types of information we collect, how we use it, and the choices you have regarding your information. By accessing and using our website, you agree to the terms of this Privacy Policy. If you do not agree with any part of this Privacy Policy, please refrain from using our website.
    
    1. Information We Collect
    We may collect the following types of information from you:
    Personal Information: This includes any information that can be used to identify you personally, such as your name, email address, postal address, phone number, and academic or professional affiliations.
    Non-Personal Information: This includes information that cannot be used to identify you, such as your browsing history, the type of device you use, and other general demographic information.
    
    2. How We Use Your Information
    We use the information we collect to:
    Provide, maintain, and improve our website and services.
    Communicate with you about our products, services, and events.
    Personalise your experience and tailor content for you.
    Analyse the use of our website and services to better understand user behaviour and preferences.
    Detect, prevent, and address technical or security issues
    
    3. Sharing Your Information
    We will never share, sell, or rent your personal information to third parties without your consent, except in the following circumstances:
    When required by law, regulation, or legal process.
    To protect our rights, property, or the safety of our users or the public.
    To provide a service or feature you have requested.
    With our trusted service providers who process data on our behalf and are required to maintain the confidentiality of your information.
    
    4. Your Choices and Rights
    You have the right to:
    Access and update your personal information.
    Request the deletion of your personal information
    Unsubscribe from our communications at any time.
    
    5. Security Measures
    We take the security of your personal information seriously and have implemented industry-standard measures to protect it from unauthorized access, disclosure, or alteration.
    
    6. Children's Privacy
    Our website is not intended for individuals under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that a child under 16 has provided us with personal information, we will take steps to remove such information and terminate the child's account.
    
    7. Changes to This Privacy Policy
    We may update our Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website with a new effective date. Your continued use of our website after the effective date constitutes your acceptance of the updated Privacy Policy.
    
    Contact Us
    If you have any questions or concerns about our Privacy Policy, please contact us at: contactus@vikramshilaedu.in
    Complaint Officer: Fill Shafina’s Details
    Ms. Shafina Segon
    Address:
    Vikramshila Research Private Limited
    I– 1667, 3rd Floor,
    CR Park, New Delhi – 110 019
    contactus@vikramshilaedu.in`
        }
      },{
        "@type": "Question",
        "name": "What is the acceptance rate of submitted articles, and how competitive is the selection process?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `The Aakashganga platform ensures that the entire publishing journey is led by the research community. The acceptance rate for articles varies by journal, and the process is overseen by Peer Reviewers and Publishing Editors, ensuring that only the best quality of research is published. The selection process is competitive to maintain the quality of research. However, our Mega Journals by definition look for contribution an article makes to the discipline and subject as opposed to uniqueness of the research. In addition multidisciplinary and interdisciplinary articles will find our platform the easiest to publish in.
    
    Our acceptance rate reflects a commitment to maintaining the best research output. We strive to ensure the highest quality of research is published on our platform. The selection process is driven by several factors:
    
    Editorial Evaluation: Each submitted article undergoes a thorough editorial evaluation process. involving experts in the relevant field. This process helps maintain the quality and credibility of the research we publish.
    
    Peer Review Process: depending on the choice made by authors the article then goes through a Double Anonymous Pre-publishing peer review OR directly to be published where it is automatically enrolled in the Post Publishing Open Peer Review process.
    
    Editorial Standards: We uphold strict editorial standards and criteria for acceptance, ensuring that published articles meet the highest academic and ethical standards. However, Mega Journals by definition don’t look for uniqueness in the research; they evaluate the contribution to the discipline and subject.
    
    Expertise of Publishing Editors: Our publishing editors are esteemed professionals with expertise across various domains, contributing to the rigor of the review process.
    
    Commitment to Transparency and Fairness: We are dedicated to maintaining a transparent and fair selection process. Submissions are evaluated without bias, and we ensure that the peer review process is conducted ethically and professionally. Every author dashboard displays the status of the article. We pride ourselves in sharing information with authors, editors alike.
    
    Future Improvements: We continuously work to enhance our articles, offering amendments and updation of articles on the platform.
    
    We encourage authors to consider submitting their work to Aakashganga, and we look forward to being part of your research dissemination journey.`
        }
      },{
        "@type": "Question",
        "name": "Are there any language requirements or services available for authors whose first language is not English?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `For authors whose first language is not English, Aakashganga has resources who may offer their services through language assistance.
    
    Similarly, there are freelancers registered with the platform, who offer their expertise in making diagrams, images etc besides other skills; all related to writing, editing, and promoting a research article.
    
    Once you start writing your research paper with us, you may contact us at contactus@vikramshilaedu.in with your requirements`
        }
      },{
        "@type": "Question",
        "name": "Does the platform offer any indexing services to ensure the research is easily discoverable by other scholars and researchers?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Aakashganga aims to make research easily discoverable.
    
    We are committed to enhancing the discoverability of research for scholars and researchers.
    We offer several indexing services and features to achieve this:
    
    Searchable Database: All research content on our platform is included in a comprehensive, user-friendly database that allows users to search for relevant research articles, papers, and other materials easily.
    
    Metadata Optimization: We ensure that research content is enriched with descriptive metadata, including titles, abstracts, keywords, authors, affiliations, publication dates, and more. This metadata is essential for search engines and other discovery tools to index and categorize the content effectively.
    
    DOI Integration: We facilitate the assignment of Digital Object Identifiers (DOIs) through CrossRef to research outputs. DOIs are widely recognized and used in academia to provide permanent and unique identifiers for scholarly works, making it easier for researchers to cite and find the content.
    
    Full-Text Search: Our platform supports full-text search capabilities, allowing users to search within the entire text of research papers, further improving the discoverability of specific information within documents.
    
    Open Access Initiatives: We promote open access initiatives, making all our research content freely available to the public. This not only enhances discoverability but also supports knowledge sharing and collaboration.
    
    Integration with External Indexing Services: We collaborate with external indexing services and academic search engines to ensure that our content is included in their databases. This extends the reach of our research materials to a broader audience.
    
    Customizable Alerts: We offer customizable alert and notification systems that allow researchers to stay updated on specific topics, authors, or keywords, ensuring they don't miss relevant research. 
    
    Analytics and Usage Statistics: We provide analytics and usage statistics to researchers and institutions, helping them assess the impact and visibility of their research on our platform.`
        }
      },{
        "@type": "Question",
        "name": "What type of licensing does the platform offer? Are the articles published under a Creative Commons license that allows for reuse and distribution?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The platform works under the Creative Common License called Attribution-NonCommercial-ShareAlike( CC-BY-NC-SA), which allows others to remix, tweak, and build upon your work non-commercially, as long as they credit you and license their new creations under the identical terms."
        }
      }    
    ]
  };
  return (
    <>
     <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
    <div className="tagbody">
    <AakashgangaHeader />
      <h1 className="seo-related-tag">
        Any Questions For Us? Explore our FAQs section for your answers.
      </h1>
      <main className="main faq-main">
        <FaqBanner /> 
        <div  className="ag_main_wrap ag_partners_wrap bg">
          <div className="container">
            <div className=" align-items-center">
              <div className=" align-items-center">
                <div className="ag_heading_center_wrap our-partner mt-0">
                  <h2 className="tagh2">Overview</h2>
                </div>
              </div>
              <div className="align-items-center mt-3">
                <div className=" owl-theme ag_partners_slider overviewscss faq-overviewscss">
                  <p className="tagp">
                    As authors and researchers, you likely have questions about
                    us, our platform, Aakashganga's offerings, its functioning,
                    our operational procedures, and our policies. You will find
                    comprehensive response to these queries in the FAQs section
                    below, making it a quick and effortless way to access the
                    information you seek.
                  </p>
              
                  <p className="tagp">
                    If you have more questions, we're here to help. Reach out at{" "}
                    <a
                      href="mailto:contactus@vikramshilaedu.in"
                      className="underline blackbold taga"
                    >
                     <b> contactus@vikramshilaedu.in</b>
                    </a>
                    .{" "}Keep in mind that your questions can be valuable to others
                    as well, so feel free to ask and continue exploring.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Origins />
      </main>
      <NewsLetter />
      <AakashgangaFooter />
      </div>
    </>
  );
}

export default FaqPage;
