import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

const ReasonModal = ({
  showNotAccepted,
  setShowNotAccepted,
  reasonHandle,
  reasonComments,
  peActiveTab,
  _id,
  rejectionArticleId,
  prReviewReport,
  userType,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      size="md"
      dialogClassName="modal-dialog-centered modal-md authorModal md-x"
      show={showNotAccepted}
      id="congratsMobile"
      tabIndex={-1}
    >
      <Modal.Header className="modal-header">
        <h2 class="modal-title">
          {peActiveTab === "rejected"
            ? "Reason for rejecting to Publish"
            : "Reason for rejecting to review"}
        </h2>
        <button
          type="button"
          class="btn-close"
          ariaLabel="Close"
          onClick={() => setShowNotAccepted(false)}
        ></button>
      </Modal.Header>

      <Modal.Body>
        <div className="reasonBg">{reasonHandle}</div>
        {reasonComments ? (
          <>
            <span className="pt-3 pb-3 d-block text-left mt-3">
            <b>Publishing Editor's Comments</b> 
            </span>
            <div className="reasonBg colorBlack">{reasonComments}</div>
          </>
        ) : null}
      </Modal.Body>

      <Modal.Footer className="modal-footer">
        {peActiveTab === "rejected" && userType === "Author" ? (
          <button
            className="button button-primary mr-3"
            onClick={() => navigate("/dashboard/submit-article")}
          >
            WRITE ANOTHER ARTICLE 
          </button>
        ) : null}
        {(peActiveTab === "rejected" || peActiveTab === "sentForRevision") &&
        prReviewReport ? (
          <Link
            to={{
              pathname: `/dashboard/article/${_id}/view-report`,
            }}
            state={{
              _id: _id,
              status: peActiveTab,
              rejectionArticleId: rejectionArticleId,
              userType: "Publishing Editor",
            }}
            className="button button-primary "
          >
            View Peer Review Report
          </Link>
        ) : userType !== "Author" && (
          <button
            className="button button-primary"
            onClick={() => setShowNotAccepted(false)}
          >
            Okay
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReasonModal;
