import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {useSelector } from "react-redux";
import { getDateFormat} from "../../utils";
import pfp from "../../assets/img/icons/default_pfp.png";
import { useNavigate } from "react-router-dom";
import notyet from "../../assets/img/icons/notarticle.svg";
import { getArticleDetails } from "../../store/apiCalls/dashboard";
import { toast } from "react-toastify";

const Authors = ({ pagename, details }) => {
    const [authorDetailPupup, setauthorDetailPupup] = useState(false);
    const [indexNUM, setindexNUM] = useState(false);
    const [loginmsg, setloginmsg] = useState(false);
    const [authorDtl, setAuthorDtl] = useState([])
    const userDetails = useSelector((state) => state?.LoginReducer?.user);
    const [articleData, setArtcileData] = useState([]);
    const navigate = useNavigate();
 
    // console.log("detailsdetails==>", details);

    async function userProfileViewMdl(authorData, index) {
        // console.log("=====>", authorDtl);
        setAuthorDtl(authorData);
        if(index==0) {setindexNUM(true);} else {setindexNUM(false);}
        setauthorDetailPupup(true);
        fetchArticleData();
    }
    const fetchArticleData = async () => {
        const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
        const params = {
            userId: details?.userId,
            status: 'published',
            page: 1,
            limit: 5,
        };
        const response = await getArticleDetails(headers, params);
        if (response?.status === 200) {
            setArtcileData(response?.data?.data?.article);
            setloginmsg(false);
        } else {
            setloginmsg(true);
            // return toast.error("Please try again!!");
        }
        console.log(loginmsg);
    };
    const handleRedirect = (e, path) => {
        if (e !== "") e.preventDefault();
        window.scroll({ top: 0, behavior: "smooth" });
        navigate(path);
    };
    const handleClick = (articleId) => {
        handleRedirect("", `/viewPublishedArticle/${articleId}`);
        window.location.reload();
      };

    return (
        <>
            {pagename == 'viewPublishedArticle' ? (
                <div className="publiseTags"><div className="innerMergin">
                    {details?.authorDetails?.author.map((author, index) => (
                        <span className="transText" key={author?.fullName} onClick={() => userProfileViewMdl(author, index)}>   
                        {/* {author?.fullName} */}
                            {`${author?.title} ${author?.fullName}`}

                            {author?.orcidId?(<a href={`https://orcid.org/${author?.orcidId}`} target="_blank"><b className="d-block"></b></a>):null}
                            
                            </span>
                    ))}
                    </div>
                </div>
            ) : (
                <div className="publiseTags">
                    <div className="innerMergin">
                    {details?.map((author, index) => (
                        <span key={author?.fullName}>
                            {`${author?.title} ${author?.fullName}`}
                            {author?.orcidId?(<a href={`https://orcid.org/${author?.orcidId}`} target="_blank"><b className="d-block"></b></a>):null}
                            
                            </span>
                    ))}
                    </div>
                </div>
            )}
            <Modal
                dialogclassName="modal-sm modal-dialog  "
                size="md"
                show={authorDetailPupup}
                id="congratsMobile"
                tabIndex={-1}
                centered
                className="authorModal mdl rightSide"
            >
                <Modal.Header className="modal-header">

                    <h5 className="modal-title">Author details</h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setauthorDetailPupup(false)}
                    ></button>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="authorDetailsPopup">
                        <div className="authorDetails"> 
                            <span className="authorName">
                                <i style={{backgroundImage:`url(${indexNUM?details?.userDetail?.profilePicture:pfp})`}}>
                                </i>{authorDtl?.title} {authorDtl?.fullName}</span>
                            <p>{authorDtl?.affiliatedDepartment}, {authorDtl?.affiliatedInstitude}, {details?.userDetail?.affiliation?.affiliations?.[0]?.state}, {details?.userDetail?.affiliation?.affiliations?.[0]?.pincode}</p>
                            <div className="authorDetailsTag">
                                <span className="t-tag">{details?.submissionDetail?.journalName}</span>
                                <span className="t-tag">{details?.submissionDetail?.subject?.subjectName}</span>
                                <div className="t-tag-journalWrap">
                                    <span className="t-tag t-tag-journal">{authorDtl?.affiliatedDepartment}</span>
                                </div>
                            </div>
                        </div>
                        <div className="commanTitle02 mb-0">
                            Published Articles
                            
                        </div>
                        {loginmsg==true?<p>Please login in your account to view more details.</p>:null}
                        
                        <ul className="publishedList mt-2">
                            {articleData && articleData.length > 0 ? (
                                articleData?.map((article, index) => {
                                    const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                    const contentWithoutSpecialChars = () => {
                                        return article?.article?.[0]?.abstract?.replace(
                                            specialCharPattern, ""
                                        )
                                    };
                                    return(
                                    <>
                                    <li className="pubListLink">
                                        <div className="commanBox">
                                            <span>
                                                <strong>Published On:</strong>{" "}
                                                {getDateFormat(article?.updatedAt)}
                                            </span>
                                            <br></br>
                                            <span onClick={(e) => handleClick(article?.articleId)}>
                                            <a>{article?.article?.[0]?.title}</a>
                                            </span>
                                        </div>
                                    </li>
                                    </>)
                                })) : (
                                <div className="tabContentWrap">
                                    <div className="notArticle">
                                        <figure>
                                            <img src={notyet} alt="No Article" />
                                        </figure>
                                        <h2>No articles yet!</h2>
                                       
                                    </div>
                                </div>
                            )}
                        </ul>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Authors