import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { acceptRejectArticle } from "../../../store/apiCalls/dashboard";
import active_info from "../../../svg/active-info.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const SendForRevision = ({ showRevision, setShowRevision, articleId }) => {
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonInput, setReasonInput] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const handleClose = () => setShowRevision(false);
  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const onChangeOtherReason = (e) => {
    setReasonInput(e.target.value);
  };

  const onChangeReason = (e) => {
    setAdditionalComment(e.target.value);
  };

  const handleAcceptRejectArticle = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleId,
      peStatus: status,
      reason: reason === "Other" ? reasonInput : reason,
      peSentForRevisionReasonAdditionalComment: additionalComment,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowRevision(false);
      return navigate("/dashboard/publishing-editor");
    } else {
      return toast.error("Please try again!!");
    }
  };

  useEffect(() => {
    if (reason === "Other") {
      setDisabled(!reasonInput)
    } else {
      setDisabled(!reason)
    }
  }, [reason, reasonInput])

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      show={showRevision}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered modal-md authorModal"
      className="md-x"
    >
      <Modal.Header className="modal-header">
        <h5 className="modal-title">send for revision</h5>
        <button
          type="button"
          className="btn-close"
          ariaLabel="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="rejectModal">
          <div className="row">
            <div className="col-md-12">
              <p>Please specify your reason:</p>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Structure of this article needs to be reworked"}
                  checked={
                    reason === "Structure of this article needs to be reworked"
                  }
                />
                <b></b>
                <span>Structure of this article needs to<br/>be reworked<div className="customTool">
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <div className="popoverToolWrap">
                              <p>The structure of the article submitted by the author needs significant revision. The current organization lacks coherence and clarity, making it difficult for readers to follow the author’s arguments effectively. A more logical and well-structured arrangement of ideas would greatly enhance the overall readability and comprehension of the article. I recommend that the author revises the structure to improve the flow and cohesiveness of their work</p>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        className="pe-cursor ps-1"
                        src={active_info}
                        alt="no img"
                      />
                    </OverlayTrigger>
                  ))}
                </div></span>
                
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={
                    "The Article is too short – ideal article should be 8000 plus words"
                  }
                  checked={
                    reason ===
                    "The Article is too short – ideal article should be 8000 plus words"
                  }
                />
                <b></b>
                <span>
                  The Article is too short – ideal article should be 8000 plus
                  words
                </span>
              </label>
            </div>
            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={
                    "Conclusions do not support methodology/objective of the article"
                  }
                  checked={
                    reason ===
                    "Conclusions do not support methodology/objective of the article"
                  }
                />
                <b></b>
                <span>
                  Conclusions do not support methodology/objective of the
                  article
                </span>
                <div className="customTool">
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <div className="popoverToolWrap">
                              <p>The conclusions drawn by the author do not align with the stated methodology or the objectives of the study. The findings presented lack a clear link to the research design and fail to address the primary aims outlined in the article. It is imperative that the author revisits their analysis and draws appropriate conclusions that are in line with the study’s objectives and methodology</p>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        className="pe-cursor ps-1"
                        src={active_info}
                        alt="no img"
                      />
                    </OverlayTrigger>
                  ))}
                </div>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={
                    "Language needs to be fixed. Need level 2 or higher language editing"
                  }
                  checked={
                    reason ===
                    "Language needs to be fixed. Need level 2 or higher language editing"
                  }
                />
                <b></b>
                <span>
                  Language needs to be fixed. Need level 2 or higher language
                  editing
                </span>
              </label>
            </div>

            <div className="col-md-12">
              <label className="customRadio">
                <input
                  type="radio"
                  name="reviewRadio"
                  onChange={(e) => handleChangeReason(e)}
                  value={"Other"}
                  checked={reason === "Other"}
                />
                <b></b>
                <span>Any other reason</span>
              </label>
            </div>

            {reason === "Other" && (
              <div className="col-md-12">
                <textarea
                  className="inputField"
                  placeholder="Write here..."
                  onChange={(e) => onChangeOtherReason(e)}
                  value={reasonInput}
                  maxLength={100}
                ></textarea>
              </div>
            )}
            <div className="col-md-12">
              <span>Publishing Editor Comment:</span>
              <textarea
                className="inputField"
                placeholder="Please add your comment here..."
                onChange={(e) => onChangeReason(e)}
                value={additionalComment}
                maxLength={100}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="pb-4">
        <button
          class="button button-primary"
          type="submit"
          onClick={() => {
            handleAcceptRejectArticle("sentForRevisionApproval");
          }}
          disabled={disabled}
        >
          SUBMIT
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendForRevision;
