import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
import flag from "../../../assets/img/icons/author_flag.svg";
import {
  acceptRejectArticle,
  appointPRRole,
  getArticleDetails,
  reviewArticle,
  updatePRReport,
} from "../../../store/apiCalls/dashboard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import notyet from "../../../assets/img/icons/notarticle.svg";
/*eslint-disable*/
import RejectForPR from "../prdashboard/RejectForPR";
import jwt_decode from "jwt-decode";
import {
  checkEmptyArticle,
  get15DaysAheadDate,
  getDateFormat,
} from "../../../utils";
import active_info from "../../../svg/active-info.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import SendForRevision from "../prdashboard/SendForRevision";
import RejectModal from "../prdashboard/RejectModal";
import AcceptModal from "../prdashboard/AcceptModal";
import ContentDetails from "../../../components/viewArticle/contentDetails";
import ConfirmationalModal from "../prdashboard/ConfirmationModal";
import ConflictInterestModal from "../prdashboard/ConflictInterestModal";

const ReviewArticle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showRevisionConfirmation, setShowRevisionConfirmation] =
    useState(false);
  const [showArticleDetailsModal, setShowArticleDetailsModal] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [publishedArticleData, setPublishedArticleData] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [showAccept, setShowAccept] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { id } = jwt_decode(localStorage.usertoken);
  const _id = location?.state?._id;
  const acceptId = location?.state?.acceptId;
  const particularId = location?.state?.particularId;
  const prStatus = location?.state?.prStatus;
  const peActiveTab = location?.state?.status;
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showRevision, setShowRevision] = useState(false);
  const articleStatus = location?.state?.articleStatus;
  const typeOfReview = location?.state?.typeOfReview;
  const sendForRevision = location?.state?.sendForRevision;

  console.log("location?.state",location?.state);
  const getReviewArticle = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setArticleData(response?.data?.data);
      setUserDetail(response?.data?.userDetail);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again!!");
    }
  };

  const fetchArticleData = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: articleData?.userId,
      status: tab,
      page: 1,
      limit: 5,
    };
    const response = await getArticleDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPublishedArticleData(response?.data?.data?.article);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };

  const sendForPeerReview = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
      id: particularId,
    };
    const response = await appointPRRole(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      setDisabled(true);
      setTimeout(() => {
        return navigate("/publishing-editor/review", {
          state: "sendForReview",
        });
      }, 3000);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleApprovePublish = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: particularId,
      peStatus: status,
      peId: id,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowAccept(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getNameFromUrl = (url) => {
    let name = "";
    const pieces = url.split(/[\s/]+/);
    const last = pieces[pieces.length - 1];
    if (last) {
      const dtl = last.split("_");
      if (dtl[1]) name = dtl[1].replace(/%20/g, " ");
      else name = last.replace(/%20/g, " ");
    }
    return name;
  };

  const handleAcceptRejectArticle = async (status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    if (prStatus === "draft") {
      const params = {
        id: acceptId,
        prStatus: status,
        prId: id,
      };
      const response = await updatePRReport(headers, params);
      if (response?.status === 200) {
        setShowAcceptConfirmation(false);
        setShowAccept(true);
      } else {
        return toast.error("Please try again!!");
      }
    } else {
      const params = {
        id: acceptId,
        peStatus: status,
        peId: id,
      };
      const response = await acceptRejectArticle(headers, params);
      if (response?.status === 200) {
        setShowAcceptConfirmation(false);
        setShowAccept(true);
      } else {
        return toast.error("Please try again!!");
      }
    }
  };

  const getUserDetails = (user) => {
    return (
      user?.name +
      ", " +
      user?.department +
      ", " +
      user?.city +
      ", " +
      user?.state
    );
  };

  useEffect(() => {
    getReviewArticle();
  }, [peActiveTab]);

  return (
    <>
      <div className="centerSection">
        <div className="reviewHead">
        <div className="innerMergin">
          <div className="title">Article </div>
          <div className="reviewHeadAction">
            <span onClick={() => navigate(-1)}>CLOSE</span> 

            {prStatus === "accepted" ? (
              <>
                <a
                  className="button-link reportsBtn"
                  href="mailto:contactus@vikramshilaedu.in?subject=Aakashganga - Peer Review Query"
                >
                  SUBMIT QUERY
                </a>
                <Link
                  className="button button-primary"
                  to={`/dashboard/article/submit-review/${particularId}`}
                  state={{
                    articleId: _id,
                  }}
                >
                  WRITE REVIEW
                </Link>
              </>
            ) : (peActiveTab === "draft" &&
                articleStatus === "First Revision") ||
              (peActiveTab === "accepted" &&
                (typeOfReview === "only post-publication openreview" ||
                  typeOfReview === "only post-publication open review")) ||
              (peActiveTab === "accepted" && sendForRevision) ? (
              <>
                <span
                  className="button-gray"
                  onClick={() => setShowConfirmation(true)}
                >
                  Reject to Publish
                </span>
                <button
                  className="button button-primary"
                  onClick={() => {
                    setShowAcceptConfirmation(true);
                  }}
                >
                  Approve to Publish
                </button>
              </>
            ) : peActiveTab === "accepted" &&
              typeOfReview === "pre-publication peer review only" ? (
              <>
                <span
                  className="button-gray"
                  onClick={() => setShowConfirmation(true)}
                >
                  Reject for Peer Review
                </span>
                <button
                  class="button button-primary"
                  disabled={disabled}
                  onClick={() => setShowAcceptConfirmation(true)}
                >
                  Send for Peer Review
                </button>
              </>
            ) : peActiveTab === "draft" ? (
              <>
                <span
                  className="button-gray"
                  onClick={() => setShowConfirmation(true)}
                >
                  Reject for Editorial Assessment
                </span>
                <button
                  class="button button-primary"
                  onClick={() => setShowAcceptConfirmation(true)}
                >
                  Accept for Editorial Assessment
                </button>
              </>
            ) : prStatus === "draft" ? (
              <>
                <span
                  className="button-gray"
                  onClick={() => setShowConfirmation(true)}
                >
                  Reject for Peer Review
                </span>
                <button
                  class="button button-primary"
                  onClick={() => setShowAcceptConfirmation(true)}
                >
                  Accept for Peer Review
                </button>
              </>
            ) : null}
          </div>

</div>
        </div>

        {peActiveTab === "draft" ? (
          <div className="viewReportWrap">
            <ul>
              <li>
                <span className="titleReport">Authors</span>
                <a
                  className="button-link"
                  onClick={() => {
                    setShowArticleDetailsModal(true);
                    fetchArticleData("published");
                  }}
                >
                  {userDetail?.[0]?.user?.[0]?.title +
                    " " +
                    userDetail?.[0]?.user?.[0]?.fullName}
                  </a>
                  {articleData?.authorDetails?.author?.length > 1 &&
                  articleData?.authorDetails?.author?.map(
                    (auth) =>
                      auth?.fullName === userDetail?.[0]?.user?.[0]?.fullName
                  )
                    ? ", "
                    : ""}
                {/* </a> */}
                <a className="button-link">
                  {articleData?.authorDetails?.author
                    ?.filter(
                      (auth) =>
                        auth?.fullName !== userDetail?.[0]?.user?.[0]?.fullName
                    )
                    .map((author, index) => {
                      return (
                        <b>
                          {(index ? ", " : "") +
                            author?.title +
                            " " +
                            author?.fullName}
                        </b>
                      );
                    })}
                </a>
              </li>
              <li>
                <span className="titleReport">Received</span>
                <strong>{getDateFormat(articleData?.createdAt)}</strong>
              </li>
              <li>
                <span className="titleReport">Due By</span>
                <strong>
                  {get15DaysAheadDate(articleData?.createdAt, 15)}
                </strong>
              </li>
            </ul>
          </div>
        ) : peActiveTab === "accepted" ? (
          <div className="viewReportWrap">
            <ul>
              <li>
                <span className="titleReport">Authors</span>
                <a
                  className="button-link"
                  onClick={() => {
                    setShowArticleDetailsModal(true);
                    fetchArticleData("published");
                  }}
                >
                  {userDetail?.[0]?.user?.[0]?.title +
                    " " +
                    userDetail?.[0]?.user?.[0]?.fullName}
                  {articleData?.authorDetails?.author?.length > 1 &&
                  articleData?.authorDetails?.author?.map(
                    (auth) =>
                      auth?.fullName === userDetail?.[0]?.user?.[0]?.fullName
                  )
                    ? ", "
                    : ""}
                </a>
                <a className="button-link">
                  {articleData?.authorDetails?.author
                    ?.filter(
                      (auth) =>
                        auth?.fullName !== userDetail?.[0]?.user?.[0]?.fullName
                    )
                    .map((author, index) => {
                      return (
                        <b>
                          {(index ? ", " : "") +
                            author?.title +
                            " " +
                            author?.fullName}
                        </b>
                      );
                    })}
                </a>
              </li>
              <li>
                <span className="titleReport">Accepted</span>
                <strong>{getDateFormat(articleData?.updatedAt)}</strong>
              </li>
              <li>
                <span className="titleReport">Due By</span>
                <strong>
                  {get15DaysAheadDate(articleData?.createdAt, 15)}
                </strong>
              </li>
            </ul>
          </div>
        ) : null}

        <div className="pageWrap">
          <div className="pageRight w-100">
          <div className="innerMergin">
            <div className="row">
              <div className="col-lg-4">
                <div className="reviewPageBox">
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>Keywords </strong>
                      <div className="KeywordsTagWrap">
                        {articleData?.keywords?.map((keyword) => {
                          return <span>{keyword}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>Abbreviations</strong>
                      <div className="abbreTextBox">
                        {articleData?.abbreviations?.length > 0 ? (
                          articleData?.abbreviations?.map((abbrev) => {
                            return (
                              <div className="abbreTextTitle">
                                <span>{abbrev?.apprv}</span>
                                <span>{abbrev?.fullForm}</span>
                              </div>
                            );
                          })
                        ) : (
                          <div className="fieldWrap">
                            <p>No Abbreviations found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>
                        Images and Figures
                        <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap">
                                      <p>
                                        We require figures to be submitted
                                        individually, in the same order as they
                                        are presented in the manuscript. Kindly
                                        ensure that each table and figure is
                                        mentioned in the text and in numerical
                                        order.
                                      </p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div>
                      </strong>
                      <div className="reviewImgWrap">
                        {articleData?.images?.length > 0 ? (
                          articleData?.images?.map((img) => {
                            return (
                              <div className="reviewImg">
                                <img src={img} alt="img" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="fieldWrap">
                            <p>Nothing uploaded yet</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>
                        Supplementary Material
                        <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap">
                                      <p>
                                        You can upload MS Word, Excel, PPT, Data
                                        and RTF files in this section.
                                      </p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div>
                      </strong>
                      <div className="reviewMaterial">
                        {articleData?.supplymentaryMaterial?.length === 0 ? (
                          <div className="fieldWrap">
                            <p>Nothing uploaded yet</p>
                          </div>
                        ) : (
                          <div className="docShowBox suppleBox">
                            {articleData?.supplymentaryMaterial?.map(
                              (img, index) => (
                                <div
                                  className="docShowList downloadImg"
                                  key={`mat${index}`}
                                >
                                  <a href={img} target="_BLANK">
                                    <span>{getNameFromUrl(img)}</span>
                                    <b></b>{" "}
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                    <div className="commanBox">
                      <div className="reviewContent">
                        <a
                          className="button-link"
                          onClick={() => {
                            setShowAdditionalDetails(true);
                          }}
                        >
                          AUTHOR/S STATEMENT
                          <img src={flag} alt="#" className="ml-2" />
                        </a>
                        <div className="abbreTextBox">
                          {articleData?.statements?.commercial
                            ?.commercialOrfinancialConflictOfInterest ===
                            "2" ? (
                            <div className="mt-4">
                              <b className="commanTitle02 mt-2">
                                CONFLICT OF INTEREST
                              </b>
                              <p className="mt-2">{articleData?.statements?.commercial?.text}</p>
                            </div>
                          ) : (
                            <div className="mt-4">
                              <b className="commanTitle02 mt-2">
                                CONFLICT OF INTEREST
                              </b>
                              <p className="mt-2">
                                The authors declare that the research was conducted in the absence of any commercial or financial relationships that could be construed as a potential conflict of interest.
                              </p>
                            </div>
                          )}
                          {articleData?.statements?.AakashGangaJournal
                            ?.versionOfManuscript === "yes" ? (
                            <div className="mt-3">
                              <b className="commanTitle02 mt-2">
                                MANUSCRIPT VERSION
                              </b>
                              <p className="mt-2">
                                {
                                  articleData?.statements?.AakashGangaJournal
                                    ?.text
                                }
                              </p>
                            </div>
                          ) : (
                            <div className="mt-3">
                              <b className="commanTitle02 mt-2">
                                MANUSCRIPT VERSION
                              </b>
                              <p className="mt-2">
                                No earlier version of this manuscript has been submitted to another Journal.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="reviewPageBox reviewArticleReport">
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>Title</strong>
                      <p>{articleData?.title}</p>
                    </div>
                  </div>
                  <div className="commanBox">
                    <div className="reviewContent">
                      <strong>Running Title</strong>
                      <p>{articleData?.runningTitle}</p>
                    </div>
                  </div>
                  <div className="commanBox">
                    <ContentDetails
                      pagename="review-article"
                      details={{
                        title: "Abstract",
                        desc: articleData?.abstract,
                      }}
                    />
                  </div>

                  {articleData?.articleData?.articleSection?.map(
                    (article) =>
                      checkEmptyArticle(article?.content) !== "" && (
                        <div className="commanBox">
                          <ContentDetails
                            pagename="review-article"
                            details={{
                              title: article?.title,
                              desc: article?.content,
                            }}
                          />
                        </div>
                      )
                  )}

                  {checkEmptyArticle(articleData?.articleData?.references) !==
                    "" && (
                    <div className="commanBox references">
                      <ContentDetails
                        pagename="review-article"
                        details={{
                          title: "References",
                          desc: articleData?.articleData?.references,
                        }}
                      />
                    </div>
                  )}

                  {checkEmptyArticle(
                    articleData?.articleData?.acknowledgement
                  ) !== "" && (
                    <div className="commanBox">
                      <ContentDetails
                        pagename="review-article"
                        details={{
                          title: "Acknowledgements",
                          desc: articleData?.articleData?.acknowledgement,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      {peActiveTab === "accepted" &&
      typeOfReview === "pre-publication peer review only" && !sendForRevision ? (
        <>
          <RejectForPR
            showReject={showReject}
            setShowReject={setShowReject}
            articleId={particularId}
          />
          <ConfirmationalModal
            setShowReject={setShowReject}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            title={"Confirmation"}
            body={`<div class="text-center"><p>Are you sure you want to reject<br/>this article for Peer Review?</div></p>`}
            leftBtnTxt={"CONFIRM"}
            rightBtnTxt={"NO, GO BACK"}
          />
          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAcceptConfirmation}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <h2 class="modal-title">Good to know</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAcceptConfirmation(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss text-center">
                <p className="allGoodText02">
                  Are you sure you want to send this article for Peer Review?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-gray bold mr-3"
                onClick={() => setShowAcceptConfirmation(false)}
              >
                NO, GO BACK
              </span>
              <button
                class="button button-primary"
                onClick={() => {
                  sendForPeerReview();
                }}
              >
                CONFIRM
              </button>
            </Modal.Footer>
          </Modal>
          <AcceptModal
            showAccept={showAccept}
            setShowAccept={setShowAccept}
            title={"SENT FOR PEER REVIEW"}
            body={
              "<p class='text-center'>The system will allocate<br/>a Peer Reviewer soon.</p>"
            }
            btnText="Okay"
          />
        </>
      ) : (peActiveTab === "draft" && articleStatus === "First Revision") ||
        (peActiveTab === "accepted" &&
          (typeOfReview === "only post-publication openreview" ||
            typeOfReview === "only post-publication open review")) || 
            (peActiveTab === "accepted" && sendForRevision) ? (
        <>
          <RejectForPR
            showReject={showReject}
            setShowReject={setShowReject}
            articleId={particularId}
            modalTitle={"REJECT_PUBLISH"}
          />
          <ConfirmationalModal
            setShowReject={setShowReject}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            title={"Confirmation"}
            body={`<div class="text-center allGoodText"><p>Are you sure you want to reject this article to publish?</div></p>`}
            leftBtnTxt={"CONFIRM"}
            rightBtnTxt={"NO, GO BACK"}
          />

          <SendForRevision
            showRevision={showRevision}
            setShowRevision={setShowRevision}
            articleId={particularId}
          />
          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showRevisionConfirmation}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <h2 class="modal-title">CONFIRMATION</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowRevisionConfirmation(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss">
                <p className="allGoodText text-center">
                  Are you sure you want to send this article for revision?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-gray bold mr-3"
                onClick={() => {
                  setShowRevisionConfirmation(false);
                  setShowRevision(true);
                }}
              >
                CONFIRM
              </span>
              <button
                class="button button-primary"
                onClick={() => setShowRevisionConfirmation(false)}
              >
                NO, GO BACK
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAcceptConfirmation}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <h2 class="modal-title">Good to know</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAcceptConfirmation(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss text-center">
                <p className="allGoodText">
                  Are you sure you want to approve this article to publish?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-gray bold mr-3"
                onClick={() => setShowAcceptConfirmation(false)}
              >
                CANCEL
              </span>
              <button
                class="button button-primary"
                onClick={() => {
                  handleApprovePublish("approved");
                  setShowAcceptConfirmation(false);
                  setShowAccept(true);
                }}
              >
                CONFIRM
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            dialogClassName="modal-dialog-centered modal-sm authorModal"
            show={showAccept}
            id="congratsMobile"
            tabIndex={-1}
          >
            <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
            <Modal.Header className="modal-header">
              <div class="modal-icon">
                <img src={doneSvg} alt="#" />
              </div>
              <h2 class="modal-title">Thank You</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => navigate("/dashboard/publishing-editor")}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss pl-2 pr-2">
                <p className="mb-3">
                  We appreciate your contribution
                  <br />
                  to scholarly research.
                </p>
                <p className="mb-3">
                  The author will be notified that their article is approved for
                  publication.
                </p>
                <p>
                  As a thank you gesture, we will add 250 Mudras to your Mudra
                  Kosh (Wallet) once the article is published.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-active mr-3"
                onClick={() => navigate("/dashboard/publishing-editor")}
              >
                MY DASHBOARD
              </span>
              <button
                class="button button-primary"
                onClick={() => {
                  setShowAccept(false);
                  navigate("/dashboard/publishing-editor");
                }}
              >
                OKAY
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : prStatus === "draft" ? (
        <>
          <RejectModal
            showReject={showReject}
            setShowReject={setShowReject}
            articleId={acceptId}
            userId={id}
            prStatus={prStatus}
          />
          <ConfirmationalModal
            setShowReject={setShowReject}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            title={"Confirmation"}
            body={`<div class="text-center"><p class="allGoodText">Are you sure you want to reject this article for Peer Review?</div></p>`}
            leftBtnTxt={"CONFIRM"}
            rightBtnTxt={"NO, GO BACK"}
          />

          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAcceptConfirmation}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <h2 class="modal-title">Good to know</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAcceptConfirmation(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss text-center">
                <p className="allGoodText">
                  Are you sure you want to accept this article for Peer Review?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-gray bold mr-3"
                onClick={() => setShowAcceptConfirmation(false)}
              >
                NO, GO BACK
              </span>
              <button
                class="button button-primary"
                onClick={() => {
                  handleAcceptRejectArticle("accepted");
                }}
              >
                CONFIRM
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAccept}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <div class="modal-icon">
                {" "}
                <img src={doneSvg} alt="#" />
              </div>
              <h2 class="modal-title">ARTICLE ACCEPTED FOR PEER REVIEW</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAccept(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="acceptedArticle">
                <p>Thank You! We truly appreciate you taking</p>
                <p className="mb-4">the time out to review this article.</p>
                <p className="mb-4">
                  As a thank you gesture, we will add 250 Mudras to your Mudra
                  Kosh (Wallet) once the article is published.
                </p>
                <p>
                  Please find this article in the{" "}
                  <Link to="/peer-reviewer/accepted" state={"accepted"}>
                    Accepted
                  </Link>{" "}
                  section of your dashboard under Peer Reviewer.
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-active bold mr-3"
                onClick={() => {
                  setShowAccept(false);
                  navigate("/dashboard/peer-reviewer");
                }}
              >
                MY DASHBOARD
              </span>
              <Link
                className="button button-primary"
                to={`/dashboard/article/submit-review/${acceptId}`}
                state={{
                  articleId: _id,
                }}
              >
                WRITE REVIEW
              </Link>
            </Modal.Footer>
          </Modal>
        </>
      ) : peActiveTab === "draft" ? (
        <>
          <RejectModal
            showReject={showReject}
            setShowReject={setShowReject}
            articleId={acceptId}
            userId={id}
          />
          <ConfirmationalModal
            setShowReject={setShowReject}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            title={"Confirmation"}
            body={`<div class="text-center allGoodText01"><p>Are you sure you want to reject this article for editorial assessment?</div></p>`}
            leftBtnTxt={"REJECT"}
            rightBtnTxt={"MY DASHBOARD"}
            peActiveTab={peActiveTab}
          />

          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAcceptConfirmation}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            <Modal.Header className="modal-header">
              <h2 class="modal-title">Good to know</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAcceptConfirmation(false)}
              ></button>
            </Modal.Header>

            <Modal.Body className="pt-0">
              <div className="aaforAss text-center">
                <p className="allGoodText02">
                  Are you sure you want to accept this article for editorial
                  assessment?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
              <span
                className="btn-link-gray bold mr-3"
                onClick={() => {
                  setShowAcceptConfirmation(false);
                  navigate("/dashboard/publishing-editor");
                }}
              >
                MY DASHBOARD
              </span>
              <button
                class="button button-primary"
                onClick={() => {
                  handleAcceptRejectArticle("accepted");
                }}
              >
                ACCEPT
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            dialogClassName="modal-dialog-centered modal-md authorModal md-x"
            show={showAccept}
            id="congratsMobile"
            tabIndex={-1}
            className="md-x"
          >
            {" "}
            <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
            <Modal.Header className="modal-header">
              <div class="modal-icon">
                {" "}
                <img src={doneSvg} alt="#" />
              </div>
              <h2 class="modal-title">ACCEPTED FOR EDITORIAL ASSESSMENT</h2>
              <button
                type="button"
                class="btn-close"
                ariaLabel="Close"
                onClick={() => setShowAccept(false)}
              ></button>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <div className="aaforAssContent  text-left">
                <p>
                  Thank You! We truly appreciate you taking the time out to
                  review this article.
                </p>
                <p>
                  As a thank you gesture, we will add 250 Mudras to your Mudra
                  Kosh (Wallet) once the article is published.
                </p>
                <p>
                  Please find this article in the{" "}
                  <Link to="/publishing-editor/accepted" state={"accepted"}>
                    Accepted
                  </Link>{" "}
                  section of your dashboard.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <button
                class="button button-primary"
                onClick={() => {
                  setShowAccept(false);
                  navigate("/dashboard/publishing-editor");
                }}
              >
                OKAY
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}

      <Modal
        dialogclassName="modal-sm modal-dialog  "
        size="md"
        show={showArticleDetailsModal}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal mdl rightSide"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Author details</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowArticleDetailsModal(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="authorDetailsPopup">
            <div className="authorDetails">
              <span className="authorName">
                <i
                  style={{
                    backgroundImage: `url(${userDetail?.[0]?.user?.[0]?.profilePicture})`,
                  }}
                ></i>
                {userDetail?.[0]?.user?.[0]?.title}{" "}
                {userDetail?.[0]?.user?.[0]?.fullName}
              </span>
              <p>
                {getUserDetails(
                  userDetail?.[0]?.user?.[0]?.affiliation?.affiliations?.[0]
                )}
              </p>
              <div className="authorDetailsTag">
                {articleData?.submissionDetail?.subject && (
                  <span className="t-tag">
                    {articleData?.submissionDetail?.subject?.subjectName}
                  </span>
                )}
                {articleData?.submissionDetail?.specialization && (
                  <span className="t-tag">
                    {
                      articleData?.submissionDetail?.specialization
                        ?.specialization
                    }
                  </span>
                )}

                <div className="t-tag-journalWrap">
                  {articleData?.submissionDetail?.journalName && (
                    <span className="t-tag t-tag-journal">
                      {articleData.submissionDetail?.journalName}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="commanTitle02">Published Articles</div>
            <ul className="publishedList">
              {publishedArticleData && publishedArticleData.length > 0 ? (
                publishedArticleData?.map((article, index) => {
                  return (
                    <>
                      <li key={index}>
                        <div className="commanBox">
                          <span>
                            <strong>Published On:</strong>{" "}
                            {getDateFormat(article?.updatedAt)}
                          </span>
                          <br></br>
                          <span>
                            <Link
                              to={{
                                pathname: `/dashboard/article/${article?._id}/review-article`,
                              }}
                              state={{
                                _id: article?.articleId,
                              }}
                              onClick={() => setShowArticleDetailsModal(false)}
                            >
                              {article?.article?.[0]?.title}
                            </Link>
                          </span>
                        </div>
                      </li>
                    </>
                  );
                })
              ) : (
                <div className="tabContentWrap">
                  <div className="notArticle">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    <h2>No articles yet!</h2>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <ConflictInterestModal
        showAdditionalDetails={showAdditionalDetails}
        setShowAdditionalDetails={setShowAdditionalDetails}
        articleData={articleData}
      />
    </>
  );
};

export default ReviewArticle;
