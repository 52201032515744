import AakashgangaHeader from "../../components/global/HeaderComponent";
import AakashgangaFooter from "../../components/global/FooterComponent";

const Cookie = () => {
  return (
    <>
      <AakashgangaHeader />
      <div className="cookieSection innerPages">
        <div className="containWrap">
          <div className="cookieRow">
            <div className="cookieLeft">
              <div className="cookieHero">
                <h1>Cookie Settings</h1>
                <p>
                  We use cookies across the website to optimise its performance.
                  You can review your cookie preferences below, and amend them
                  at any time. Preferences will be saved automatically when
                  changed, and can also be saved at the bottom of the page.
                </p>
              </div>
              <div className="cookieContent splContent">
                <div className="row align-items-center justify-content-between">
                  <div className="col-9">
                    <h2>Allow all non-essential cookies</h2>
                  </div>
                  <div className="col text-end">
                    <div className="customSwitch">
                      <label>
                        <input type="checkbox" checked />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cookieContent">
                <div className="row align-items-center justify-content-between">
                  <div className="col-9">
                    <h2>Essential Cookies</h2>
                  </div>
                  <div className="col text-end">
                    <div className="customSwitch">
                      <label>
                        <input type="checkbox" checked />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <p>
                  Certain cookies are considered essential, as they are used
                  solely to maintain our web pages. Essential cookies are used
                  to keep you logged in, anonymously track errors that might
                  occur, and help maintain site stability and functionality.
                  These cookies can not be disabled.
                </p>
              </div>
              <div className="cookieContent">
                <div className="row align-items-center justify-content-between">
                  <div className="col-9">
                    <h2>Functionality Cookies</h2>
                  </div>
                  <div className="col text-end">
                    <div className="customSwitch">
                      <label>
                        <input type="checkbox" checked />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <p>
                  We embed certain third-party widgets on the journal, such as
                  Twitter and Hypothes.is. These widgets may set their own
                  cookies. Cookie information from these sites is usually
                  anonymous, unless you are logged in to those third-party
                  systems whilst on our site.
                </p>
              </div>
              <div className="cookieContent">
                <div className="row align-items-center justify-content-between">
                  <div className="col-9">
                    <h2>Analytics &amp; Tracking</h2>
                  </div>
                  <div className="col text-end">
                    <div className="customSwitch">
                      <label>
                        <input type="checkbox" checked />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <p>
                  Analytics cookies are used to measure how many people visit
                  each page, and what options they click on, in order to
                  maintain and improve website performance.
                </p>
              </div>
              <div className="cookieContent">
                <div className="row align-items-center justify-content-between">
                  <div className="col-9">
                    <h2>Marketing</h2>
                  </div>
                  <div className="col text-end">
                    <div className="customSwitch">
                      <label>
                        <input type="checkbox" checked />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <p>
                  We use cookies for a variety of reasons detailed below.
                  Unfortunately in most cases there are no industry standard
                  options for disabling cookies without completely disabling the
                  functionality and features they add to xyz. It is recommended
                  that you leave on all cookies if you are not sure whether you
                  need them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AakashgangaFooter />
    </>
  );
};

export default Cookie;
