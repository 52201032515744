import React, { useEffect, useState } from "react";
import UserDetail from "../../../components/dashboard/UserDetail";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Modal } from "react-bootstrap";
import DashboardLayout from "../../../layout/dashboard";
import jwt_decode from "jwt-decode";
import {
  getArticleDetails,
  getPRDetails,
} from "../../../store/apiCalls/dashboard";
import PRListing from "../prdashboard/PRListing";
import RecommendedArticles from "./RecommendedArticles";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const limit = 3;
  const handleClose = () => setShow(false);
  const { id } = jwt_decode(localStorage.usertoken);
  const [articleData, setArtcileData] = useState([]);
  const [articleTotalData, setArticleTotalData] = useState([]);
  const [prArticleData, setPRArticleData] = useState([]);
  const [prTotalData, setPRTotalData] = useState([]);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [collapseActive, setIsActive] = useState(false);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("draft");
  const [prActiveTab, setPRActiveTab] = useState("draft");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const expandClick = (event) => {
    setIsActive((current) => !current);
  };

  const fetchArticleData = (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        userId: id,
        status: tab,
        page: page,
        limit: limit,
      };
      return getArticleDetails(headers, params);
    };

    fetchdata()
      .then((userData) => {
        if (userData.status === 200) {
          dispatch({ type: "SET_LOADER", payload: false });
          setArtcileData(userData?.data?.data?.article);
          setArticleTotalData(userData?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchPRArticle = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = {
      userId: id,
      page: page,
      limit,
      prStatus: tab,
    };
    const response = await getPRDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPRArticleData(response?.data?.data?.data);
      setPRTotalData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  useEffect(() => {
    if (updateTrigger) {
      fetchArticleData("published")
    } else if (amendmentUpdateTrigger) {
      fetchArticleData("published")
    } else {
      fetchArticleData("draft");
    }
    fetchPRArticle("draft");
    setIsActive(true);
  }, [page, updateTrigger, amendmentUpdateTrigger, updateRequestList]);

  const getArticleType = (type) => {
    fetchArticleData(type);
    setActiveTab(type);
  };

  const getPRArticleType = (type) => {
    fetchPRArticle(type);
    setPRActiveTab(type);
  };

  return (
    <>
     <Helmet>
     <meta name="robots" content="noindex, nofollow" />
    </Helmet>
      <DashboardLayout>
        <>
          <UserDetail />
          <div className="articleWrap">
            <div className="articleLeft">
              <h2>My Dashboard</h2>
              <a href="">Ready to share your research with the world?</a>
              <p>Let's begin the journey now...</p>
            </div>
            <div className="articleRight">
              <p>Upload Your Article in Four Easy Steps</p>
              <ul>
                <li>
                  <b>1</b>
                  <span>Submit Article</span>
                </li>
                <li>
                  <b>2</b>
                  <span>Get it Reviewed</span>
                </li>
                <li>
                  <b>3</b>
                  <span>Make Payment</span>
                </li>
                <li>
                  <b>4</b>
                  <span>Publish Article</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="articleTabsWrap">
            <h3 className="commanTitle">My Articles</h3>

            <div className="customTabs">
              <Tab.Container defaultActiveKey="draft">
                <Nav>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="draft"
                      onClick={() => [
                        setActiveTab("draft"),
                        getArticleType("draft"),
                      ]}
                    >
                      Drafts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="underReview"
                      onClick={() => [
                        setActiveTab("submit"),
                        getArticleType("submit"),
                      ]}
                    >
                      Under Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="approved"
                      onClick={() => [
                        setActiveTab("approved"),
                        getArticleType("approved"),
                      ]}
                    >
                      Approved
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="rejected"
                      onClick={() => [
                        setActiveTab("rejected"),
                        getArticleType("rejected"),
                      ]}
                    >
                      Rejected
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="published"
                      onClick={() => [
                        setActiveTab("published"),
                        getArticleType("published"),
                      ]}
                    >
                      Published{" "}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  {activeTab === "draft" && (
                    <ArticleListing
                      articleData={articleData}
                      activeTab={activeTab}
                      totalRecords={articleTotalData?.numberRecordBaseOnFilter}
                      fetchArticleData={fetchArticleData}
                    />
                  )}

                  {activeTab === "submit" && (
                    <ArticleListing
                      articleData={articleData}
                      activeTab={activeTab}
                      totalRecords={articleTotalData?.numberRecordBaseOnFilter}
                      fetchArticleData={fetchArticleData}
                    />
                  )}
                  {activeTab === "approved" && (
                    <Tab.Pane eventKey="approved">
                      <ArticleListing
                        articleData={articleData}
                        activeTab={activeTab}
                        totalRecords={
                          articleTotalData?.numberRecordBaseOnFilter
                        }
                        fetchArticleData={fetchArticleData}
                      />
                    </Tab.Pane>
                  )}
                  {activeTab === "rejected" && (
                    <Tab.Pane eventKey="rejected">
                      <ArticleListing
                        articleData={articleData}
                        activeTab={activeTab}
                        totalRecords={
                          articleTotalData?.numberRecordBaseOnFilter
                        }
                        fetchArticleData={fetchArticleData}
                      />
                    </Tab.Pane>
                  )}
                  {activeTab === "published" && (
                    <Tab.Pane eventKey="published">
                      <ArticleListing
                        articleData={articleData}
                        activeTab={activeTab}
                        totalRecords={
                          articleTotalData?.numberRecordBaseOnFilter
                        }
                        fetchArticleData={fetchArticleData}
                        setUpdateTrigger={setUpdateTrigger}
                        setAmendmentUpdateTrigger={setAmendmentUpdateTrigger}
                      />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Tab.Container>
            </div>

            <div className="innerTabsView">
              <div className="preReview">
                <span>For Peer Review</span>
              </div>

              <div className="customTabs">
                <Tab.Container defaultActiveKey="newRequests">
                  <div className="tabsBoxin">
                    <Nav>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="newRequests"
                          onClick={() => [
                            setPRActiveTab("draft"),
                            getPRArticleType("draft"),
                          ]}
                        >
                          New Requests
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="accepted"
                          onClick={() => [
                            setPRActiveTab("accepted"),
                            getPRArticleType("accepted"),
                          ]}
                        >
                          Accepted
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="reviewed"
                          onClick={() => [
                            setPRActiveTab("reviewed"),
                            getPRArticleType("reviewed"),
                          ]}
                        >
                          Reviewed
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="notAccepted"
                          onClick={() => [
                            setPRActiveTab("notAccepted"),
                            getPRArticleType("notAccepted"),
                          ]}
                        >
                          Not Accepted
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <button
                      className={collapseActive ? "colapseBtn" : ""}
                      onClick={expandClick}
                    >
                      {collapseActive ? "Collapse" : "Expand"}
                      <i>
                        <img src={downarrow} alt="#" />
                      </i>
                    </button>
                  </div>

                  <Tab.Content className={collapseActive ? "show" : "hide"}>
                    <Tab.Pane eventKey="newRequests">
                      <PRListing
                        prArticleData={prArticleData}
                        prActiveTab={prActiveTab}
                        totalRecords={prTotalData?.numberRecordBaseOnFilter}
                        fetchPRArticle={fetchPRArticle}
                        setUpdateRequestList={setUpdateRequestList}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="accepted">
                      <PRListing
                        prArticleData={prArticleData}
                        prActiveTab={prActiveTab}
                        totalRecords={prTotalData?.numberRecordBaseOnFilter}
                        fetchPRArticle={fetchPRArticle}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="reviewed">
                      <PRListing
                        prArticleData={prArticleData}
                        prActiveTab={prActiveTab}
                        totalRecords={prTotalData?.numberRecordBaseOnFilter}
                        fetchPRArticle={fetchPRArticle}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="notAccepted">
                      <PRListing
                        prArticleData={prArticleData}
                        prActiveTab={prActiveTab}
                        totalRecords={prTotalData?.numberRecordBaseOnFilter}
                        fetchPRArticle={fetchPRArticle}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </>

        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog-centered modal-lg authorModal"
        >
          <Modal.Header className="modal-header">
            <h5 className="modal-title">Grant Details</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={(e) => handleOTPClose(e)}
            />
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="grantDetails">
              <div className="row">
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Grant Name</b>
                    <span className="textGreen">
                      Vikramshila Grant for Authors
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Available for</b>
                    <span className="textGreen">
                      Vikramshila Grant for Authors
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>URL of Grant available for </b>
                    <span className="textBlue">
                      Urban climate change management and society
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Description</b>
                    <span className="textGray">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis labore et dolore
                      magna aliqua labore et dolore magna...
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Eligibility</b>
                    <ul className="listText">
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </li>
                      <li>
                        Sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Know More</b>
                    <ul className="knowList">
                      <li>
                        <p>
                          <i>Grant Received On</i>
                          <small>12 May 2023</small>
                        </p>
                        <p>
                          <i>Valid Till</i>
                          <small>25 Aug 2023</small>
                        </p>
                      </li>

                      <li>
                        <p>
                          <i>Amount Received</i>
                          <small>INR 7000</small>
                        </p>
                        <p>
                          <i>Amount Remaining</i>
                          <small>INR 6500</small>
                        </p>
                      </li>
                      <li>
                        <p>
                          <i>Transaction ID</i>
                          <small>826475844855</small>
                        </p>
                        <p>
                          <i>Invoice</i>
                          <div className="downloadBOx">
                            <a href="" download></a>
                            <strong>Download</strong>
                          </div>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="pb-4">
            <button
              class="button button-primary"
              type="submit"
              onClick={handleClose}
            >
              CLOSE
            </button>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </>
  );
};
export default Dashboard;
