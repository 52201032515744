import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import share from "../../assets/img/icons/share.svg";
import tags_active from "../../assets/img/icons/tags_active.svg";
import { getDateFormat } from "../../utils";
import { removeFromReadingList } from "../../store/apiCalls/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import copylink from "../../assets/img/icons/copylink.svg";
import linkedin_share from "../../assets/img/icons/linkedin_share.svg";
import twitter_share from "../../assets/img/icons/twitter_share.svg";
import facebook_share from "../../assets/img/icons/facebook_share.svg";
import mendeley from "../../assets/img/icons/mendeley.svg";
import { toast } from "react-toastify";
import { addArticleToReadingList } from "../../store/apiCalls/dashboard";
import tags from "../../assets/img/icons/tags.svg";
import copy from "copy-to-clipboard";
import { SERVER_URL } from "../../config";

const RelatedArticles = ({
  published,
  id,
  title,
  userTitle,
  userName,
  contentWithoutSpecialChars,
  typeOfArticle,
  journalName,
  journalType,
  subjectName,
  specialization,
  fetchReadingList,
  articleId,
  slug,
  readingflag,
  pageName,
}) => {
  const MAX_WORDS = 10;
  const MAX_TITLE_WORDS = 5;
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleted, setShowDeleted] = useState(false);
  const [copied, setCopied] = useState("");
  const [journalNameforurl, setjournalNameforurl] = useState("");
  const [subjectNameforurl, setsubjectNameforurl] = useState("");
  const userDetails = useSelector((state) => state?.LoginReducer?.user);
  const token = localStorage.getItem("usertoken");

  const handleRemoveReadingList = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: id,
      isDeleted: true,
    };
    const response = await removeFromReadingList(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      fetchReadingList();
      setShowDeleted(false);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
  const handleRedirect = (e, path) => {
    if (e !== "") e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const formatUrl = (inputString) => {
    if (!inputString) {
      return ''; // Return an empty string or handle the case appropriately
  }
    let formattedString = inputString.toLowerCase(); 
    formattedString = formattedString.replace(/\s/g, '-');
    return formattedString;
  }
 
  const handlejournalNameforurl = (journalType,typeOfArticle) => {
    var fname = "";
    if (journalType=="Mega Journal" && typeOfArticle=="Conference Papers") {
      fname = formatUrl("Conference Paper");
    }else{
      fname = formatUrl(journalType);
    }

    // setjournalNameforurl(fname);
    return fname;
  }
  const handlesubjectName = (para) => {
    const subjectMapping = {
      "Medicine and Health Sciences": "medicine",
      "Business, Management and Communication": "business-management",
      "Social Science and Humanities": "social-science",
      "Science": "science",
      "Engineering": "engineering",
      "Information Technology": "information-technology",
      "Ayurveda": "ayurveda"
    };
  
    const fname = subjectMapping[para] || para;
    // console.log("subjectName", subjectName);
    // setsubjectNameforurl(fname);
    return formatUrl(fname);
  };
  const SHARE_URL = `${SERVER_URL}/${handlejournalNameforurl(journalType,typeOfArticle)}/${handlesubjectName(subjectName)}/article/${slug}`;
  // console.log("handlesubjectName",`/${handlejournalNameforurl(journalType,typeOfArticle)}/${handlesubjectName(subjectName)}/${slug}`,title);
  const handleClick = () => {
    handleRedirect("", `/${handlejournalNameforurl(journalType,typeOfArticle)}/${handlesubjectName(subjectName)}/article/${slug}`); 
    // handleRedirect("", `/viewPublishedArticle/${articleId}`);
  };
  const copyToClipboard = (code) => {
    copy(code);
    return toast.success("Copied");
  };
  const handleMendeleyClick = () => {
    copy(SHARE_URL);
    window.open(
      "https://www.mendeley.com/reference-manager/library/all-references",
      "_blank"
    );
    addShareCount();
  };
  const addArticleToReadingLists = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: articleId,
      userId: userDetails?._id,
    };
    const response = await addArticleToReadingList(headers, params);
    if (response?.status === 200) {
      fetchReadingList();
    } else {
      return toast.error("Please try again!!");
    }
    
  };
// console.log("subjectName",subjectName);
useEffect(() => {
  // handlejournalNameforurl();
  // handlesubjectName();
}, [journalType]);
  return (
    <>
      <div className="recommendedBox h-auto ml-0 mr-0 bgColorNone w-100">
        <div className="recommendedHead">
          <span>
            <b>Published On: </b> {getDateFormat(published)}
          </span>
          <div className="recommendedShare">
            <i className="shareLogo">
              <img src={share} alt="#" />
              <div className="publisNav">
                <a onClick={handleMendeleyClick}>
                  <b>
                    <img src={mendeley} alt="#" />
                  </b>
                  Mendeley
                </a>
                <TwitterShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtag={"vikramshila"}
                >
                  <a>
                    <b>
                      <img src={twitter_share} alt="#" />
                    </b>
                    Twitter
                  </a>
                </TwitterShareButton>
                <FacebookShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtags={"vikramshila"}
                >
                  <a>
                    <b>
                      <img src={facebook_share} alt="#" />
                    </b>
                    Facebook
                  </a>
                </FacebookShareButton>
                <LinkedinShareButton url={SHARE_URL} title={title}>
                  <a>
                    <b>
                      <img src={linkedin_share} alt="#" />
                    </b>
                    LinkedIn
                  </a>
                </LinkedinShareButton>
                <a onClick={() => copyToClipboard(SHARE_URL)}>
                  <b>
                    <img src={copylink} alt="#" />
                  </b>
                  Copy Link
                </a>
              </div>
            </i>
            {pageName === "search" && token && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() =>
                    readingflag == true
                      ? setShowDeleted(true)
                      : addArticleToReadingLists()
                  }
                />
              </i>
            )}
            {pageName === "search" && !token && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() => navigate("/login")}
                  alt="#"
                />
              </i>
            )}
            {pageName !== "search" && (
              <i>
                <img
                  src={readingflag == true ? tags_active : tags}
                  onClick={() =>
                    readingflag == true
                      ? setShowDeleted(true)
                      : addArticleToReadingLists()
                  }
                />
              </i>
            )}
          </div>
        </div>
        <div className="recommendedTitle">
        {/* {formatUrl(subjectNameforurl)} */}
          {title?.split(" ").length > MAX_WORDS ? (
            <h2
              onClick={() => handleClick(true)}
              dangerouslySetInnerHTML={{
                __html:
                  title?.split(" ")?.slice(0, MAX_TITLE_WORDS).join(" ") +
                  "....",
              }}
            ></h2>
          ) : (
            <h2
              onClick={() => handleClick(true)}
              dangerouslySetInnerHTML={{
                __html: title?.split(" ")?.slice(0, MAX_TITLE_WORDS).join(" "),
              }}
            ></h2>
          )}
          <h3>{userTitle + " " + userName}</h3>
        </div>
        <div className="recommendedContent">
          {contentWithoutSpecialChars()?.split(" ").length > MAX_WORDS ? (
            <p
              dangerouslySetInnerHTML={{
                __html:
                  contentWithoutSpecialChars()
                    ?.split(" ")
                    ?.slice(0, MAX_WORDS)
                    .join(" ") + "....",
              }}
            ></p>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: contentWithoutSpecialChars()
                  ?.split(" ")
                  ?.slice(0, MAX_WORDS)
                  .join(" "),
              }}
            ></p>
          )}
          <div className="t-tag-box">
            {typeOfArticle && <span className="t-tag">{typeOfArticle}</span>}
            {subjectName && <span className="t-tag">{subjectName}</span>}
            {specialization && <span className="t-tag">{specialization}</span>}
            {journalName && (
              <div className="t-tag-journalWrap">
                <span className="t-tag t-tag-journal">{journalName}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="modal-dialog-centered modal-sm authorModal"
        show={showDeleted}
        id="congratsMobile"
        tabIndex={-1}
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> </div>
          <h2 class="modal-title">Confirmation</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowDeleted(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="allGoodText01">
            <p>
              Are you sure you want to remove this article from the reading
              list?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <span
            class="btn-link-gray bold mr-3"
            onClick={() => handleRemoveReadingList()}
          >
            CONFIRM
          </span>
          <button
            className="button button-primary"
            type="button"
            onClick={(e) => {
              pageName === "search"
                ? setShowDeleted(false)
                : (setShowDeleted(false),
              window.history.back())
            }}
          >
            No, GO BACK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RelatedArticles;
