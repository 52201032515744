import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
/*eslint-disable*/
import { Pagination } from "semantic-ui-react";
import notyet from "../../../assets/img/icons/notarticle.svg";
import DashboardLayout from "../../../layout/dashboard";
import jwt_decode from "jwt-decode";
import Confetti from "react-confetti";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  peRequest,
  peAccepted,
  peReview,
  pePeerReview,
  peApproved,
  peRevision,
  peRejected,
} from "../../../constants";
import { get15DaysAheadDate, getDateFormat } from "../../../utils";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { useDispatch } from "react-redux";
import {
  acceptRejectArticle,
  getPEDetails,
  notAcceptedArticleReason,
  reviewArticle,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import RejectModal from "../prdashboard/RejectModal";
import ReasonModal from "../prdashboard/ReasonModal";
import ArticleDetailsModal from "../prdashboard/ArticleDetailsModal";
import ConfirmationalModal from "../prdashboard/ConfirmationModal";

const PublishingEditor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let status = location?.state;
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const { id } = jwt_decode(localStorage.usertoken);
  const [showReject, setShowReject] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonHandle, setReasonHandle] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [rejectionArticleId, setRejectionArticleId] = useState("");
  const [articleId, setArticleId] = useState("");
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [showArticleDetails, setShowArticleDetails] = useState(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const [peData, setPEData] = useState([]);
  const [totalPEData, setTotalPEData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [clicked, setClicked] = useState("");
  const [title, setTitle] = useState(-1);
  const [articleType, setArticleType] = useState(-1);
  const [dueBy, setDueBy] = useState(-1);
  const [received, setReceived] = useState(-1);
  const [requested, setRequested] = useState(-1);
  const [sent, setSent] = useState(-1);
  const [approved, setApproved] = useState(-1);
  const [rejected, setRejected] = useState(-1);
  let totalPages = Math.ceil(totalPEData?.numberRecordBaseOnFilter / limit);

  const fetchPublishingEditor = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        page,
        limit,
        peStatus: tab,
      };
      if (clicked === "TITLE" && title) {
        data.title = title;
      }
      if (clicked === "ARTICLE TYPE" && articleType) {
        data.articleType = articleType;
      }
      if (clicked === "DUE BY" && dueBy) {
        data.dueBy = dueBy;
      }
      if (
        (clicked === "RECEIVED" || clicked === "REVIEW RECEIVED") &&
        received
      ) {
        data.received = received;
      }
      if (clicked === "REQUESTED" && requested) {
        data.requested = requested;
      }
      if (clicked === "SENT" && sent) {
        data.sent = sent;
      }
      if (clicked === "APPROVED" && approved) {
        data.approved = approved;
      }
      if (clicked === "REJECTED" && rejected) {
        data.rejected = rejected;
      }
      return data;
    };
    const response = await getPEDetails(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPEData(response?.data?.data?.data);
      setTotalPEData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handleAcceptRejectArticle = async (_id, status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      fetchPublishingEditor("draft");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleAcceptedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("approved");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleRejectedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("rejected");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const sortingData = (header) => {
    if (header === "RECEIVED" || header === "REVIEW RECEIVED") {
      if (received == -1) {
        setReceived(1);
      } else {
        setReceived(-1);
      }
    } else if (header === "ARTICLE TYPE") {
      if (articleType == -1) {
        setArticleType(1);
      } else {
        setArticleType(-1);
      }
    } else if (header === "TITLE") {
      if (title == -1) {
        setTitle(1);
      } else {
        setTitle(-1);
      }
    } else if (header === "REQUESTED") {
      if (requested === -1) {
        setRequested(1);
      } else {
        setRequested(-1);
      }
    } else if (header === "DUE BY") {
      if (dueBy === -1) {
        setDueBy(1);
      } else {
        setDueBy(-1);
      }
    } else if (header === "SENT") {
      if (sent === -1) {
        setSent(1);
      } else {
        setSent(-1);
      }
    } else if (header === "APPROVED") {
      if (approved === -1) {
        setApproved(1);
      } else {
        setApproved(-1);
      }
    } else if (header === "REJECTED") {
      if (rejected === -1) {
        setRejected(1);
      } else {
        setRejected(-1);
      }
    }
  };

  const handleNotAcceptedReason = async (articleId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      articleId,
      status: "pe",
    };
    const response = await notAcceptedArticleReason(headers, params);
    if (response?.status === 200) {
      setShowNotAccepted(true);
      setReasonHandle(response?.data?.data?.[0]?.reason);
      setReasonComments(response?.data?.data?.[0]?.additionalComment);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setCurrentData(response?.data?.currentStatus);
      setUserDetail(response?.data?.userDetail);
      setShowArticleDetails(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getUserDetails = (user) => {
    return (
      user?.name +
      ", " +
      user?.department +
      ", " +
      user?.city +
      ", " +
      user?.state
    );
  };

  const handlePageChange = (data) => {
    return (
      setPage(data.activePage),
      window.scrollTo({ top: 0, behavior: "smooth" })
    );
  };

  useEffect(() => {
    fetchPublishingEditor(status);
  }, [
    page,
    status,
    title,
    articleType,
    received,
    requested,
    dueBy,
    sent,
    approved,
    rejected,
    updateRequestList,
  ]);
  
  useEffect(() => {
    setPage(1);
  }, [status])

  return (
    <>
      <DashboardLayout>
        <p className="draftTitle">
          {status === "draft"
            ? "Requests"
            : status === "accepted"
            ? "Accepted"
            : status === "sendForReview"
            ? "Sent for Review"
            : status === "peerReview"
            ? "Peer Review"
            : status === "approved"
            ? "Approved"
            : status === "sentForRevision"
            ? "Revision"
            : status === "rejected"
            ? "Rejected"
            : status === "notAccepted"
            ? "Not Accepted"
            : null}
          <span>
            {status === "draft"
              ? "Please click on the article title to view the full article for review."
              : null}
          </span>
        </p>
        <div className="draftTable">
          <table className="w-100">
            {peData && peData.length > 0 && (
              <thead>
                <tr>
                  {status === "draft"
                    ? peRequest.map((requestHeader) => (
                        <th
                          className={
                            requestHeader === "ACTIONS"
                              ? "sortingWrap pe-request-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(requestHeader);
                            sortingData(requestHeader);
                          }}
                        >
                          <span>
                            {requestHeader}
                            {requestHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "accepted"
                    ? peAccepted.map((acceptedHeader) => (
                        <th
                          className={
                            acceptedHeader === "ACTIONS"
                              ? "sortingWrap pe-accepted-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(acceptedHeader);
                            sortingData(acceptedHeader);
                          }}
                        >
                          <span>
                            {acceptedHeader}
                            {acceptedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "sendForReview"
                    ? peReview.map((reviewHeader) => (
                        <th
                          className={
                            reviewHeader === "STATUS"
                              ? "sortingWrap pe-review-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(reviewHeader);
                            sortingData(reviewHeader);
                          }}
                        >
                          <span>
                            {reviewHeader}
                            <b className="sort" />
                          </span>
                        </th>
                      ))
                    : status === "peerReview"
                    ? pePeerReview.map((peerHeader) => (
                        <th
                          className={
                            peerHeader === "ACTIONS"
                              ? "sortingWrap pe-peerReview-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(peerHeader);
                            sortingData(peerHeader);
                          }}
                        >
                          <span>
                            {peerHeader}
                            {peerHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "approved"
                    ? peApproved.map((approvedHeader) => (
                        <th
                          className={
                            approvedHeader === "ACTIONS"
                              ? "sortingWrap pe-approved-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(approvedHeader);
                            sortingData(approvedHeader);
                          }}
                        >
                          <span>
                            {approvedHeader}
                            {approvedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "sentForRevision"
                    ? peRevision.map((revisionHeader) => (
                        <th
                          className={
                            revisionHeader === "ACTIONS"
                              ? "sortingWrap pe-revision-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(revisionHeader);
                            sortingData(revisionHeader);
                          }}
                        >
                          <span>
                            {revisionHeader}
                            {revisionHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : (status === "rejected" || status === "notAccepted")
                    ? peRejected.map((rejectedHeader) => (
                        <th
                          className={
                            rejectedHeader === "ACTIONS"
                              ? "sortingWrap pe-rejected-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(rejectedHeader);
                            sortingData(rejectedHeader);
                          }}
                        >
                          <span>
                            {rejectedHeader}
                            {rejectedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : null}
                </tr>
              </thead>
            )}
            <tbody>
              {peData && peData?.length > 0 ? (
                peData.map((article) => {
                  return (
                    <>
                      <tr className="empty">
                        <td colspan="5"></td>
                      </tr>
                      <tr key={article._id}>
                        <td className="firstDataBoxTd">
                          <div className="firstDataBox">
                            {status === "draft" || status === "accepted" ? (
                              <span className="t-type mr-0">
                                {article?.status}
                              </span>
                            ) : null}
                            <div className="draftArticleWrap">
                              {status === "draft" ? (
                                <h2
                                  onClick={() =>
                                    getReviewArticle(article?.articleId)
                                  }
                                >
                                  {article?.article[0]?.title}
                                </h2>
                              ) : (
                                <h2>
                                  <Link
                                    to={{
                                      pathname: `/dashboard/article/${article?.articleId}/review-article`,
                                    }}
                                    state={{
                                      _id: article?.articleId,
                                      particularId: article?._id,
                                      typeOfReview:
                                        article?.article?.[0]?.reviewType
                                          ?.typeOfReview,
                                      status: status,
                                      sendForRevision: article?.article?.[0]?.sendForRevision
                                    }}
                                  >
                                    {article?.article[0]?.title}
                                  </Link>
                                </h2>
                              )}
                              <div className="authorName">
                                <b>
                                  {article?.user?.[0]?.title +
                                    " " +
                                    article?.user?.[0]?.fullName}
                                  {article?.article?.[0]?.authorDetails?.author
                                    ?.length > 1 &&
                                  article?.article?.[0]?.authorDetails?.author?.map(
                                    (auth) =>
                                      auth?.fullName ===
                                      article?.user?.[0]?.fullName
                                  )
                                    ? ", "
                                    : ""}
                                </b>
                                {article?.article?.[0]?.authorDetails?.author
                                  ?.filter(
                                    (auth) =>
                                      auth.fullName !==
                                      article?.user?.[0]?.fullName
                                  )
                                  .map((author, index) => {
                                    return (
                                      <b>
                                        {(index ? ", " : "") +
                                          author?.title +
                                          " " +
                                          author?.fullName}
                                      </b>
                                    );
                                  })}
                              </div>
                              <div className="authorAff">
                                {getUserDetails(
                                  article?.user?.[0]?.affiliation
                                    ?.affiliations?.[0]
                                )}
                              </div>
                            </div>
                            <div className="footTag">
                              {article?.subjectDetail[0]?.subjectName && (
                                <span className="t-tag">
                                  {article?.subjectDetail[0]?.subjectName}
                                </span>
                              )}
                              {article?.specializations[0]?.specialization && (
                                <span className="t-tag">
                                  {article?.specializations[0]?.specialization}
                                </span>
                              )}
                              {article?.article?.[0]?.submissionDetail
                                ?.journalName && (
                                <div className="t-tag-journalWrap">
                                  <span className="t-tag t-tag-journal">
                                    {
                                      article?.article[0]?.submissionDetail
                                        ?.journalName
                                    }
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {article?.typeofarticles[0]?.articleName
                            ? article?.typeofarticles[0]?.articleName
                            : "--"}
                        </td>
                        <td>{getDateFormat(article?.createdAt)}</td>
                        {(status === "draft" || status === "accepted") ? 
                        <td>{get15DaysAheadDate(article?.updatedAt, 15)}</td> :
                        status === "approved" ||
                        status === "sentForRevision" ||
                        status === "rejected" ||
                        status === "notAccepted" ? (
                          <td> {getDateFormat(article?.updatedAt)}</td>
                        ) : status === "sendForReview" ? (
                          <td>{article?.status}</td>
                        ) : status === "peerReview" ? (
                          <td>
                            {article?.prData?.[0]?.title +
                              " " +
                              article?.prData?.[0]?.fullName}
                          </td>
                        ) : null}

                        <td
                          className={
                            status === "draft"
                              ? "pe-request-td"
                              : status === "accepted"
                              ? "pe-accepted-td"
                              : status === "peerReview"
                              ? "pe-review-td"
                              : status === "approved"
                              ? "pe-approved-td"
                              : status === "sentForRevision"
                              ? "pe-revision-td"
                              : (status === "rejected" ||
                                status === "notAccepted")
                              ? "pe-rejected-td"
                              : null
                          }
                        >
                          <div className="vertical-footAction">
                            {status === "draft" &&
                            article?.status !== "First Revision" ? (
                              <>
                                <div>
                                  <button
                                    className="button-link"
                                    onClick={() => {
                                      getReviewArticle(article?.articleId);
                                    }}
                                  >
                                    Take me to the article details
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="button button-primary"
                                    onClick={() => {
                                      setArticleId(article?._id);
                                      setShowAcceptConfirmation(true);
                                    }}
                                  >
                                    Accept for editorial Assessment
                                  </button>
                                </div>

                                <div>
                                  <button
                                    className="button-link-gray"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowConfirmation(true);
                                      setArticleId(article?._id);
                                    }}
                                  >
                                    Reject for editorial Assessment
                                  </button>
                                </div>
                              </>
                            ) : status === "draft" &&
                              article?.status === "First Revision" ? (
                              <>
                                <div>
                                  <Link
                                    to={{
                                      pathname: `/dashboard/article/${article?._id}/review-article`,
                                    }}
                                    state={{
                                      _id: article?.articleId,
                                      particularId: article?._id,
                                      createdAt: article?.createdAt,
                                      updatedAt: article?.updatedAt,
                                      peData: article?.peData,
                                      status: status,
                                      typeOfReview:
                                        article?.article?.[0]?.reviewType
                                          ?.typeOfReview,
                                      articleStatus: "First Revision",
                                      sendForRevision:
                                        article?.article?.[0]?.sendForRevision,
                                    }}
                                    className="button button-primary"
                                  >
                                    Review Article
                                  </Link>
                                </div>

                                {article?.prReview && (
                                  <div>
                                    <Link
                                      to={{
                                        pathname: `/dashboard/article/${article?._id}/view-report`,
                                      }}
                                      state={{
                                        _id: article?._id,
                                        rejectionArticleId: editor?.articleId,
                                        title: article?.article?.[0]?.title,
                                        createdAt: article?.createdAt,
                                        prData: article?.peData,
                                        status: status,
                                        updatedAt: article?.updatedAt,
                                        userType: "Publishing Editor",
                                        sendForRevision:
                                          article?.article?.[0]
                                            ?.sendForRevision,
                                      }}
                                      className="button button-primary"
                                    >
                                      VIEW REPORT
                                    </Link>
                                  </div>
                                )}
                              </>
                            ) : status === "accepted" ? (
                              <div>
                                <Link
                                  to={{
                                    pathname: `/dashboard/article/${article?._id}/review-article`,
                                  }}
                                  state={{
                                    _id: article?.articleId,
                                    particularId: article?._id,
                                    createdAt: article?.createdAt,
                                    updatedAt: article?.updatedAt,
                                    peData: article?.peData,
                                    typeOfReview:
                                      article?.article?.[0]?.reviewType
                                        ?.typeOfReview,
                                    status: status,
                                    sendForRevision: article?.article?.[0]?.sendForRevision,
                                  }}
                                  className="button button-primary"
                                >
                                  View Article
                                </Link>
                              </div>
                            ) : status === "peerReview" && article?.prReview ? (
                              <div>
                                <Link
                                  to={{
                                    pathname: `/dashboard/article/${article?._id}/view-report`,
                                  }}
                                  state={{
                                    _id: article?._id,
                                    rejectionArticleId: article?.articleId,
                                    title: article?.article?.[0]?.title,
                                    createdAt: article?.createdAt,
                                    prData: article?.prData,
                                    status: status,
                                    updatedAt: article?.updatedAt,
                                    userType: "Publishing Editor",
                                    sendForRevision:
                                      article?.article?.[0]?.sendForRevision,
                                  }}
                                  className="button button-primary"
                                >
                                  VIEW REPORT
                                </Link>
                              </div>
                            ) : status === "sentForRevision" ? (
                              <button
                                className="button button-primary"
                                onClick={() => {
                                  setShowNotAccepted(true);
                                  setArticleId(article?._id);
                                  setRejectionArticleId(article?.articleId);
                                  setReasonHandle(
                                    article?.peSentForRevisionReason
                                  );
                                  setReasonComments(
                                    article?.peSentForRevisionReasonAdditionalComment
                                  );
                                }}
                              >
                                VIEW REASON
                              </button>
                            ) : status === "approved" ? (
                              <>
                                {article?.prReview ? (
                                  <div>
                                    <Link
                                      to={{
                                        pathname: `/dashboard/article/${article?._id}/view-report`,
                                      }}
                                      state={{
                                        _id: article?._id,

                                        title: article?.article?.[0]?.title,
                                        rejectionArticleId: article?.articleId,
                                        status: status,
                                        updatedAt: article?.updatedAt,
                                        createdAt: article?.createdAt,
                                        reasonHandle: article?.peRejectedReason,
                                        userType: "Publishing Editor",
                                      }}
                                      className="button button-primary"
                                    >
                                      VIEW REPORT
                                    </Link>
                                  </div>
                                ) : null}
                              </>
                            ) : status === "rejected" ? (
                              <>
                                <div>
                                  <button
                                    className="button button-primary"
                                    onClick={() => {
                                      setShowNotAccepted(true);
                                      setArticleId(article?._id);
                                      setRejectionArticleId(article?.articleId);
                                      setReasonHandle(
                                        article?.peRejectedReason
                                      );
                                      setReasonComments(
                                        article?.peRejectedReasonAdditionalComment
                                      );
                                    }}
                                  >
                                    VIEW REASON
                                  </button>
                                </div>
                              </>
                            ) : status === "notAccepted" ? (
                              <button
                                className="button button-primary"
                                onClick={() => {
                                  handleNotAcceptedReason(article?.articleId);
                                }}
                              >
                                VIEW REASON
                              </button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <div className="tabContentWrap">
                  <div className="notArticle">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    {status === "draft" ? (
                      <div className="notArticleDraft">
                        <h2>No articles have arrived yet!</h2>
                        <p className="d-inline-block w-100">
                          Articles requesting Editorial Assessment will feature
                          here <span>
                          In the meantime, feel free to enjoy this moment by
                          checking out our{" "}
                          <u>
                            <b>playlists</b>
                          </u>
                          . The next great adventure in scholarly exploration
                          awaits...
                        </span>
                        </p>
                       
                      </div>
                    ) : status === "accepted" ? (
                      <div className="notArticleAccepted">
                        <h2>No accepted manuscripts yet!</h2>
                        <p className="d-inline-block w-100">
                          You help showcase ground-breaking research and ideas<span>
                          We appreciate the significance of your role in this
                          journey. We aim to streamline your workflow and make
                          the assessment process a breeze.
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "sendForReview" ? (
                      <div className="notArticleSendFor">
                        <h2>No research articles sent for peer review yet!</h2>
                        <p className="d-inline-block w-100">
                          No shuffling through piles of paperwork or emails.<span>
                          Everything you need is just a click away! Thank you
                          for being a part of our vibrant publishing community.
                          Appreciate it...
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "peerReview" ? (
                      <div className="notArticlePeer">
                        <h2>Awaiting Peer Reviewed Articles</h2>
                        <p className="d-inline-block w-100">
                          Peer Review reports for your review will land here<span>
                          All assessment reports in one place will allow you to
                          make well-informed decisions about the research
                          articles under your care.
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "approved" ? (
                      <div className="notArticleApproved">
                        <h2>No articles have met your approval yet!</h2>
                        <p className="d-inline-block w-100">
                          All the brilliant research articles shine bright here<span>
                          By accepting these exceptional articles, you are
                          opening doors to a world of knowledge and for new
                          discoveries to flourish...
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "sentForRevision" ? (
                      <div className="notArticleSentRevision">
                        <h2>No articles sent for revision yet!</h2>
                        <p className="d-inline-block w-100">
                          Provide valuable feedback that enhances author’s work<span>
                          Your feedback is a potent catalyst, fostering
                          constructive criticism and intellectual growth. It's a
                          win-win situation for everyone!
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "rejected" ? (
                      <div className="notArticleRejected">
                        <h2>No rejected articles here!</h2>
                        <p className="d-inline-block w-100">
                          Articles that did not get accepted for publishing will
                          show here <span>
                          In scientific terms, this stage is the Schrödinger's
                          cat, where the articles exist in a superposition,
                          neither accepted nor rejected until they land here...
                        </span>
                        </p>
                       
                      </div>
                    ) : status === "notAccepted" ? (
                      <div className="notArticleNot">
                        <h2>We respect Your reasons!</h2>
                        <p className="d-inline-block w-100">
                          Articles you could not accept for assessment will show
                          here<span>
                          We hope you will soon resume the reviewing process,
                          contributing to the collective advancement of
                          knowledge and scientific literature...
                        </span>
                        </p>
                        
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>

        <div className="backFooterBox">
          <div className="backBtnWrap">
            <Link
              to="#"
              onClick={(e) => handleRedirect(e, "/dashboard/publishing-editor")}
            >
              <span>Back</span>
              <img src={backArrow} alt="#" />
            </Link>
          </div>
          {totalPages > 1 && (
            <div className="paginationWrap">
              <Pagination
                boundaryRange={1}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                activePage={page}
                prevItem={{ content: <b class="icon-arrow-left" /> }}
                nextItem={{ content: <b class="icon-arrow-right" /> }}
                onPageChange={(event, data) => handlePageChange(data)}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
        <div
          className="pullUp"
          onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <b className="icon-arrow-right" />
        </div>
      </DashboardLayout>
      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center allGoodText01"><p>Are you sure you want to reject this article for editorial assessment?</div></p>`}
        leftBtnTxt={"REJECT"}
        rightBtnTxt={"MY DASHBOARD"}
        peActiveTab={status}
      />

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText02">
              Are you sure you want to accept this article for editorial
              assessment?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            onClick={() => navigate("/dashboard/publishing-editor")}
          >
            MY DASHBOARD
          </span>
          <button
            class="button button-primary"
            onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
          >
            ACCEPT
          </button>
        </Modal.Footer>
      </Modal>
      <RejectModal
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={articleId}
        userId={id}
        fetchPublishingEditor={fetchPublishingEditor}
        setUpdateRequestList={setUpdateRequestList}
      />
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      > <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">ACCEPTED FOR EDITORIAL ASSESSMENT</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAccept(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAssContent text-left">
            <p>
              Thank You! We truly appreciate you taking the time out to review
              this article.
            </p>
            <p>
              As a thank you gesture, we will add 250 Mudras to your Mudra Kosh
              (Wallet) once the article is published.
            </p>
            <p>
              Please find this article in the{" "}
              <Link to="/publishing-editor/accepted" state={"accepted"}>
                Accepted
              </Link>{" "}
              section of your dashboard.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary"
            onClick={() => setShowAccept(false)}
          >
            OKAY
          </button>
        </Modal.Footer>
      </Modal>
      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
        peActiveTab={status}
        _id={articleId}
        rejectionArticleId={rejectionArticleId}
      />
      {status === "draft" && (
        <ArticleDetailsModal
          showArticleDetails={showArticleDetails}
          setShowArticleDetails={setShowArticleDetails}
          articleData={articleData}
          currentData={currentData}
          userDetail={userDetail}
          status={"Publishing Editor"}
        />
      )}
    </>
  );
};

export default PublishingEditor;
