import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
/*es-lint disable*/
import notyet from "../../../assets/img/icons/notarticle.svg";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  updatePRReport,
  notAcceptedArticleReason,
  reviewArticle,
} from "../../../store/apiCalls/dashboard";
import { get15DaysAheadDate, getDateFormat } from "../../../utils";
import RejectModal from "./RejectModal";
import ReasonModal from "./ReasonModal";
import ArticleDetailsModal from "./ArticleDetailsModal";
import ConfirmationalModal from "./ConfirmationModal";

const PRListing = ({
  prArticleData,
  prActiveTab,
  totalRecords,
  fetchPRArticle,
  setUpdateRequestList,
}) => {
  const navigate = useNavigate();
  const MAX_WORDS = 30;
  const { id } = jwt_decode(localStorage.usertoken);
  const [articleId, setArticleId] = useState("");
  const [redirectionId, setRedirectionId] = useState("");
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonHandle, setReasonHandle] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showArticleDetails, setShowArticleDetails] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [prStatus, setPRStatus] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  const handleAcceptRejectArticle = async (_id, status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      prStatus: status,
      prId: id,
    };
    const response = await updatePRReport(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      fetchPRArticle("draft");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleNotAcceptedReason = async (articleId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      articleId,
      status: "pr",
    };
    const response = await notAcceptedArticleReason(headers, params);
    if (response?.status === 200) {
      setShowNotAccepted(true);
      setReasonHandle(response?.data?.data?.[0]?.reason);
      setReasonComments(response?.data?.data?.[0]?.additionalComment);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setCurrentData(response?.data?.currentStatus);
      setShowArticleDetails(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  return (
    <>
      <div className="contentListBox">
        {prArticleData && prArticleData.length > 0 ? (
          prArticleData?.map((article, index) => {
            const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;

            const contentWithoutSpecialChars = () => {
              return article?.article?.[0]?.abstract?.replace(
                specialCharPattern,
                ""
              );
            };
            return (
              <div className="contentList">
                {prActiveTab === "draft" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Requested On:&nbsp;</strong>
                        {article?.createdAt
                          ? getDateFormat(article?.createdAt)
                          : "--"}
                      </span>
                      <span>
                        <strong>Due By: &nbsp;</strong>
                        {article?.updatedAt
                          ? get15DaysAheadDate(article?.updatedAt, 15)
                          : "--"}
                      </span>
                    </div>
                  </div>
                )}
                {prActiveTab === "accepted" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Accepted:&nbsp;</strong>
                        {article?.createdAt
                          ? getDateFormat(article?.createdAt)
                          : "--"}
                      </span>
                      <span>
                        <strong>Due By:</strong>{" "}
                        {get15DaysAheadDate(article?.updatedAt, 15)}
                      </span>
                    </div>
                  </div>
                )}
                {prActiveTab === "reviewed" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Review Submitted On:</strong>{" "}
                        {getDateFormat(article?.updatedAt)}
                      </span>
                    </div>
                  </div>
                )}
                {prActiveTab === "notAccepted" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Requested On:</strong>{" "}
                        {getDateFormat(article?.createdAt)}
                      </span>
                      <span>
                        <strong>Rejected On:</strong>{" "}
                        {getDateFormat(article?.updatedAt)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="contentListMidd">
                  {prActiveTab === "draft" ? (
                    <h2 onClick={() => getReviewArticle(article?.articleId)}>
                      {article?.article?.[0]?.title}
                    </h2>
                  ) : prActiveTab === "accepted" ||
                    prActiveTab === "reviewed" ? (
                    <h2>
                      <Link
                        to={{
                          pathname: `/dashboard/article/${article?.articleId}/review-article`,
                        }}
                        state={{
                          _id: article?.articleId,
                          prStatus: article?.prStatus,
                        }}
                      >
                        {article?.article?.[0]?.title}
                      </Link>
                    </h2>
                  ) : prActiveTab === "notAccepted" ? (
                    <h2>
                      <Link
                        to={{
                          pathname: `/dashboard/article/${article?.articleId}/review-article`,
                        }}
                        state={{
                          _id: article?.articleId,
                          prStatus: "notAccepted",
                        }}
                      >
                        {article?.article?.[0]?.title}
                      </Link>
                    </h2>
                  ) : null}
                  {prActiveTab === "draft" &&
                  contentWithoutSpecialChars()?.split(" ").length >
                    MAX_WORDS ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          contentWithoutSpecialChars()
                            ?.split(" ")
                            ?.slice(0, MAX_WORDS)
                            .join(" ") + "....",
                      }}
                    ></p>
                  ) : (
                    prActiveTab === "draft" && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: contentWithoutSpecialChars()
                            ?.split(" ")
                            ?.slice(0, MAX_WORDS)
                            .join(" "),
                        }}
                      ></p>
                    )
                  )}
                </div>

                {prActiveTab === "draft" ? (
                  <div className="footTag">
                    {article?.typeofarticles?.[0]?.articleName && (
                      <span className="t-tag">
                        {article?.typeofarticles?.[0]?.articleName}
                      </span>
                    )}
                    {article?.subjectDetail[0]?.subjectName && (
                      <span className="t-tag">
                        {article?.subjectDetail[0]?.subjectName}
                      </span>
                    )}
                    {article?.specializations[0]?.specialization && (
                      <span className="t-tag">
                        {article?.specializations[0]?.specialization}
                      </span>
                    )}
                    {article?.article?.[0]?.submissionDetail?.journalName && (
                      <div className="t-tag-journalWrap">
                        <span className="t-tag t-tag-journal">
                          {article?.article?.[0]?.submissionDetail?.journalName}
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}
                <div
                  className={
                    prActiveTab === "draft"
                      ? "contentListFoot contentListTwo justify-content-end"
                      : "contentListFoot contentListTwo"
                  }
                >
                  <div className="footAction">
                    {prActiveTab === "draft" && (
                      <>
                        <Link
                          to="#"
                          className="button-link"
                          onClick={() => {
                            getReviewArticle(article?.articleId);
                          }}
                        >
                          Article Details
                        </Link>
                        <Link
                          to="#"
                          className="button-link-gray"
                          onClick={() => {
                            setShowConfirmation(true);
                            setArticleId(article?._id);
                            setPRStatus(article?.prStatus);
                          }}
                        >
                          Reject for Peer Review
                        </Link>
                        <Link
                          to="#"
                          className="button button-primary"
                          onClick={() => {
                            setArticleId(article?._id);
                            setRedirectionId(article?.articleId);
                            setShowAcceptConfirmation(true);
                          }}
                        >
                          Accept for Peer Review
                        </Link>
                      </>
                    )}
                  </div>
                </div>

                {prActiveTab === "accepted" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles?.[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles?.[0]?.articleName}
                        </span>
                      )}
                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}
                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}
                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap">
                          <span className="t-tag t-tag-journal">
                            {article?.article[0]?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      <Link
                        to={{
                          pathname: `/dashboard/article/${article?._id}/review-article`,
                        }}
                        state={{
                          _id: article?.articleId,
                          particularId: article?._id,
                          prStatus: article?.prStatus,
                        }}
                        className="button button-primary"
                      >
                        View Article
                      </Link>
                    </div>
                  </div>
                )}

                {prActiveTab === "notAccepted" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles?.[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles?.[0]?.articleName}
                        </span>
                      )}
                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}
                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}
                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap">
                          <span className="t-tag t-tag-journal">
                            {article?.article[0]?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      <Link
                        to="#"
                        className="button button-primary"
                        onClick={() => {
                          handleNotAcceptedReason(article?.articleId);
                        }}
                      >
                        View Reason
                      </Link>
                    </div>
                  </div>
                )}

                {prActiveTab === "reviewed" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles?.[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles?.[0]?.articleName}
                        </span>
                      )}
                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}
                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}
                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap">
                          <span className="t-tag t-tag-journal">
                            {article?.article[0]?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      {article?.prReview && (
                        <Link
                          to={{
                            pathname: `/dashboard/article/${article?._id}/view-report`,
                          }}
                          state={{
                            _id: article?._id,
                            rejectionArticleId: article?.articleId,
                            status: prActiveTab,
                            userType: "Peer Reviewer",
                          }}
                          className="button button-primary"
                        >
                          VIEW REPORT
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="tabContentWrap">
            <div className="notArticle">
              <figure>
                <img src={notyet} alt="No Article" />
              </figure>
              {prActiveTab === "draft" ? (
                <div className="pr_draft">
                  <h2>No New Requests for Peer Review Yet!</h2>
                  <p className="d-inline-block w-100">
                    All the research articles await your expert review here  <span>
                    We know you've got that keen eye for spotting scientific
                    brilliance. It is your own book club, but with a dash of
                    scientific excitement!
                  </span>
                  </p>
                
                </div>
              ) : prActiveTab === "accepted" ? (
                <div className="pr_accepted">
                  <h2>
                    No accepted Peer Review requests yet!
                  </h2>
                  <p className="d-inline-block w-100">
                    This is where the magic happens, and your expertise shines! <span>
                    You hold special power to shape the future of scholarly knowledge! Your constructive critiques play a crucial role in guiding authors toward excellence.
                  </span>
                  </p>
                 
                </div>
              ) : prActiveTab === "reviewed" ? (
                <div className="pr_reviewed">
                  <h2>
                    No peer reviewed articles here!
                  </h2>
                  <p className="d-inline-block w-100">
                    Imagine this tab as a red carpet for your brilliant contributions!<span>
                    We appreciate the time and effort you put into evaluating each article. Your expertise truly makes a world of difference. Thank you very much...
                  </span>
                  </p>
                  
                </div>
              ) : prActiveTab === "notAccepted" ? (
                <div className="pr_notAccepted">
                  <h2>
                    We respect Your reasons!
                  </h2>
                  <p className="d-inline-block w-100">
                    Articles you could not accept for Peer Review will show here <span>
                    We are your biggest fans, celebrating every review you conquer like it's a touchdown in the academic arena! Three cheers to that...
                  </span>
                  </p>
                 
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      {prActiveTab === "draft" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/peer-reviewer/requests" }}
            state={"draft"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL REQUESTS
          </Link>
        </div>
      )}

      {prActiveTab === "accepted" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/peer-reviewer/accepted" }}
            state={"accepted"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL ACCEPTED
          </Link>
        </div>
      )}

      {prActiveTab === "reviewed" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/peer-reviewer/reviewed" }}
            state={"reviewed"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL REVIEWED
          </Link>
        </div>
      )}

      {prActiveTab === "notAccepted" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/peer-reviewer/notAccepted" }}
            state={"notAccepted"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL NOT ACCEPTED
          </Link>
        </div>
      )}

      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center"><p class="allGoodText">Are you sure you want to reject this article for Peer Review?</div></p>`}
        leftBtnTxt={"CONFIRM"}
        rightBtnTxt={"NO, GO BACK"}
      />

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText">
              Are you sure you want to accept this article for Peer Review?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => setShowAcceptConfirmation(false)}
          >
            NO, GO BACK
          </span>
          <button
            class="button button-primary"
            onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
          >
            CONFIRM
          </button>
        </Modal.Footer>
      </Modal>

      <RejectModal
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={articleId}
        fetchPRArticle={fetchPRArticle}
        prStatus={prStatus}
        userId={id}
        setUpdateRequestList={setUpdateRequestList}
      />
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">ARTICLE ACCEPTED FOR PEER REVIEW</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAccept(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="acceptedArticle">
            <p>Thank You! We truly appreciate you taking</p>
            <p className="mb-4">the time out to review this article.</p>
            <p className="mb-4">
              As a thank you gesture, we will add 250 Mudras to your Mudra Kosh
              (Wallet) once the article is published.
            </p>
            <p>
              Please find this article in the{" "}
              <Link to="/peer-reviewer/accepted" state={"accepted"}>
                Accepted
              </Link>{" "}
              section of your dashboard under Peer Reviewer.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            onClick={() => setShowAccept(false)}
          >
            MY DASHBOARD
          </span>
          <Link
            className="button button-primary"
            to={`/dashboard/article/submit-review/${articleId}`}
            state={{
              articleId: redirectionId,
            }}
          >
            WRITE REVIEW
          </Link>
        </Modal.Footer>
      </Modal>
      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
      />
      {prActiveTab === "draft" && (
        <ArticleDetailsModal
          showArticleDetails={showArticleDetails}
          setShowArticleDetails={setShowArticleDetails}
          articleData={articleData}
          currentData={currentData}
          status={"Peer Review"}
        />
      )}
    </>
  );
};

export default PRListing;
