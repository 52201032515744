import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
import { Pagination } from "semantic-ui-react";
import notyet from "../../../assets/img/icons/notarticle.svg";
import DashboardLayout from "../../../layout/dashboard";
import jwt_decode from "jwt-decode";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { get15DaysAheadDate, getDateFormat } from "../../../utils";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { useDispatch } from "react-redux";
import {
  getPRDetails,
  notAcceptedArticleReason,
  reviewArticle,
  updatePRReport,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import {
  prRequest,
  prAccepted,
  prReviewed,
  prNotAccepted,
} from "../../../constants";
import RejectModal from "./RejectModal";
import ReasonModal from "./ReasonModal";
import ArticleDetailsModal from "./ArticleDetailsModal";
import ConfirmationalModal from "./ConfirmationModal";

const PeerReview = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let status = location?.state;
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const { id } = jwt_decode(localStorage.usertoken);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [prStatus, setPRStatus] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showArticleDetails, setShowArticleDetails] = useState(false);
  const [prArticleData, setPRArticleData] = useState([]);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonHandle, setReasonHandle] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [totalPRData, setPRTotalData] = useState([]);
  const [clicked, setClicked] = useState("");
  const [title, setTitle] = useState(-1);
  const [articleType, setArticleType] = useState(-1);
  const [dueBy, setDueBy] = useState(-1);
  const [reviewReceived, setReviewReceived] = useState(-1);
  const [reviewSubmitted, setReviewSubmitted] = useState(-1);
  const [accepted, setAccepted] = useState(-1);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const [articleId, setArticleId] = useState("");
  const [redirectionId, setRedirectionId] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  let totalPages = Math.ceil(totalPRData?.numberRecordBaseOnFilter / limit);

  const fetchPRArticle = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        page,
        limit,
        prStatus: tab,
      };
      if (clicked === "TITLE" && title) {
        data.title = title;
      }
      if (clicked === "ARTICLE TYPE" && articleType) {
        data.articleType = articleType;
      }
      if (clicked === "REVIEW RECEIVED" && reviewReceived) {
        data.reviewReceived = reviewReceived;
      }
      if (clicked === "RECEIVED" || (clicked === "DUE BY" && dueBy)) {
        data.dueBy = dueBy;
      }
      if (clicked === "REVIEW SUBMITTED" && reviewSubmitted) {
        data.reviewSubmitted = reviewSubmitted;
      }
      if ((clicked === "ACCEPTED" || clicked === "NOT ACCEPTED") && accepted) {
        data.accepted = accepted;
      }
      return data;
    };
    const response = await getPRDetails(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPRArticleData(response?.data?.data?.data);
      setPRTotalData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handleAcceptRejectArticle = async (_id, status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      prStatus: status,
      prId: id,
    };
    const response = await updatePRReport(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      fetchPRArticle("draft");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const sortingData = (header) => {
    if (header === "REVIEW RECEIVED") {
      if (reviewReceived == -1) {
        setReviewReceived(1);
      } else {
        setReviewReceived(-1);
      }
    } else if (header === "ARTICLE TYPE") {
      if (articleType == -1) {
        setArticleType(1);
      } else {
        setArticleType(-1);
      }
    } else if (header === "TITLE") {
      if (title == -1) {
        setTitle(1);
      } else {
        setTitle(-1);
      }
    } else if (header === "REVIEW SUBMITTED") {
      if (reviewSubmitted === -1) {
        setReviewSubmitted(1);
      } else {
        setReviewSubmitted(-1);
      }
    } else if (header === "RECEIVED" || header === "DUE BY") {
      if (dueBy === -1) {
        setDueBy(1);
      } else {
        setDueBy(-1);
      }
    } else if (header === "ACCEPTED" || header === "NOT ACCEPTED") {
      if (accepted === -1) {
        setAccepted(1);
      } else {
        setAccepted(-1);
      }
    }
  };

  const handleNotAcceptedReason = async (articleId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      articleId,
      status: "pr",
    };
    const response = await notAcceptedArticleReason(headers, params);
    if (response?.status === 200) {
      setShowNotAccepted(true);
      setReasonHandle(response?.data?.data?.[0]?.reason);
      setReasonComments(response?.data?.data?.[0]?.additionalComment);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setCurrentData(response?.data?.currentStatus);
      setShowArticleDetails(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handlePageChange = (data) => {
    return (
      setPage(data.activePage), window.scrollTo({ top: 0, behavior: "smooth" })
    );
  };

  useEffect(() => {
    fetchPRArticle(status);
  }, [
    page,
    status,
    title,
    articleType,
    dueBy,
    reviewReceived,
    reviewSubmitted,
    accepted,
    updateRequestList,
  ]);

  useEffect(() => {
    setPage(1);
  }, [status])

  return (
    <>
      <DashboardLayout>
        <p className="draftTitle">
          {status === "draft"
            ? "New Requests"
            : status === "accepted"
            ? "Accepted"
            : status === "reviewed"
            ? "Reviewed"
            : status === "notAccepted"
            ? "Not Accepted"
            : null}
          <span>
            {status === "draft"
              ? "Please click on the article title to view the full article for review."
              : null}
          </span>
        </p>
        <div className="draftTable">
          <table className="w-100">
            {prArticleData && prArticleData.length > 0 && (
              <thead>
                <tr>
                  {status === "draft"
                    ? prRequest.map((requestHeader) => (
                        <th
                          className={
                            requestHeader === "ACTIONS"
                              ? "sortingWrap pr-request-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(requestHeader);
                            sortingData(requestHeader);
                          }}
                        >
                          <span>
                            {requestHeader}
                            {requestHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "accepted"
                    ? prAccepted.map((acceptedHeader) => (
                        <th
                          className={
                            acceptedHeader === "ACTIONS"
                              ? "sortingWrap pr-accepted-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(acceptedHeader);
                            sortingData(acceptedHeader);
                          }}
                        >
                          <span>
                            {acceptedHeader}
                            {acceptedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : status === "reviewed"
                    ? prReviewed.map((reviewHeader) => (
                        <th
                          className={
                            reviewHeader === "ACTIONS"
                              ? "sortingWrap pr-review-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(reviewHeader);
                            sortingData(reviewHeader);
                          }}
                        >
                          <span>
                            {reviewHeader}
                            {reviewHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                    : prNotAccepted.map((notAcceptedHeader) => (
                        <th
                          className={
                            notAcceptedHeader === "ACTIONS"
                              ? "sortingWrap pr-notAccepted-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(notAcceptedHeader);
                            sortingData(notAcceptedHeader);
                          }}
                        >
                          <span>
                            {notAcceptedHeader}
                            {notAcceptedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))}
                </tr>
              </thead>
            )}
            <tbody>
              {prArticleData && prArticleData.length > 0 ? (
                prArticleData.map((article) => {
                  return (
                    <>
                      <tr className="empty">
                        <td colspan="5"></td>
                      </tr>
                      <tr key={article._id}>
                      <td className="firstDataBoxTd">
                          <div className="firstDataBox">
                            <div className="draftArticleWrap">
                              {status === "draft" ? (
                                <h2
                                  onClick={() =>
                                    getReviewArticle(article?.articleId)
                                  }
                                >
                                  {article?.article?.[0]?.title}
                                </h2>
                              ) : status === "accepted" ||
                                status === "reviewed" ? (
                                <h2>
                                  <Link
                                    to={{
                                      pathname: `/dashboard/article/${article?.articleId}/review-article`,
                                    }}
                                    state={{
                                      _id: article?.articleId,
                                      prStatus: article?.prStatus,
                                    }}
                                  >
                                    {article?.article?.[0]?.title}
                                  </Link>
                                </h2>
                              ) : status === "notAccepted" ? (
                                <h2>
                                  <Link
                                    to={{
                                      pathname: `/dashboard/article/${article?.articleId}/review-article`,
                                    }}
                                    state={{
                                      _id: article?.articleId,
                                      prStatus: "notAccepted",
                                    }}
                                  >
                                    {article?.article?.[0]?.title}
                                  </Link>
                                </h2>
                              ) : null}
                            </div>
                            <div className="footTag">
                              {article?.subjectDetail[0]?.subjectName && (
                                <span className="t-tag">
                                  {article?.subjectDetail[0]?.subjectName}
                                </span>
                              )}
                              {article?.specializations[0]?.specialization && (
                                <span className="t-tag">
                                  {article?.specializations[0]?.specialization}
                                </span>
                              )}
                              {article?.article?.[0]?.submissionDetail
                                ?.journalName && (
                                <div className="t-tag-journalWrap">
                                  <span className="t-tag t-tag-journal">
                                    {
                                      article?.article[0]?.submissionDetail
                                        ?.journalName
                                    }
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {article?.typeofarticles
                            ? article?.typeofarticles[0]?.articleName
                            : "--"}
                        </td>
                        {status === "draft" ? (
                          <>
                            <td>{getDateFormat(article?.createdAt)}</td>
                            <td>{get15DaysAheadDate(article?.createdAt, 15)}</td>
                          </>
                        ) : status === "accepted" ? (
                          <>
                            <td>{getDateFormat(article?.updatedAt)}</td>
                            <td>{get15DaysAheadDate(article?.createdAt, 15)}</td>
                          </>
                        ) : status === "reviewed" ? (
                          <>
                            <td>{getDateFormat(article?.updatedAt)}</td>
                            <td>
                              {getDateFormat(article?.prReview?.updatedAt)}
                            </td>
                          </>
                        ) : status === "notAccepted" ? (
                          <>
                            <td>{getDateFormat(article?.createdAt)}</td>
                            <td>{getDateFormat(article?.updatedAt)}</td>
                          </>
                        ) : null}

                        <td
                          className={
                            status === "draft"
                              ? "pr-request-td"
                              : status === "accepted"
                              ? "pr-accepted-td"
                              : status === "reviewed"
                              ? "pr-review-td"
                              : status === "notAccepted"
                              ? "pr-notAccepted-td"
                              : null
                          }
                        >
                          <div className="vertical-footAction">
                            {status === "draft" ? (
                              <>
                                <div>
                                  <button
                                    className="button-link"
                                    onClick={() =>
                                      getReviewArticle(article?.articleId)
                                    }
                                  >
                                    Article Details
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="button button-primary"
                                    onClick={() => {
                                      setArticleId(article?._id);
                                      setRedirectionId(article?.articleId);
                                      setShowAcceptConfirmation(true);
                                    }}
                                  >
                                    Accept for Peer Review
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="button-link-gray"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowConfirmation(true);
                                      setArticleId(article?._id);
                                      setPRStatus(article?.prStatus);
                                    }}
                                  >
                                    Reject for Peer Review
                                  </button>
                                </div>
                              </>
                            ) : status === "accepted" ? (
                              <Link
                                to={{
                                  pathname: `/dashboard/article/${article?._id}/review-article`,
                                }}
                                state={{
                                  _id: article?.articleId,
                                  particularId: article?._id,
                                  prStatus: article?.prStatus,
                                }}
                                className="button button-primary"
                              >
                                View Article
                              </Link>
                            ) : status === "reviewed" && article?.prReview ? (
                              <Link
                                to={{
                                  pathname: `/dashboard/article/${article?._id}/view-report`,
                                }}
                                state={{
                                  _id: article?._id,
                                  rejectionArticleId: article?.articleId,
                                  status: status,
                                }}
                                className="button button-primary"
                              >
                                View Report
                              </Link>
                            ) : status === "notAccepted" ? (
                              <button
                                className="button button-primary"
                                onClick={() =>
                                  handleNotAcceptedReason(article?.articleId)
                                }
                              >
                                View Reason
                              </button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <div className="tabContentWrap">
                  <div className="notArticle">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    {status === "draft" ? (
                      <div className="pr_draft">
                        <h2>No New Requests for Peer Review Yet!</h2>
                        <p className="d-inline-block w-100">
                          All the research articles await your expert review
                          here<span>
                          We know you've got that keen eye for spotting
                          scientific brilliance. It is your own book club, but
                          with a dash of scientific excitement!
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "accepted" ? (
                      <div className="pr_accepted">
                        <h2>No accepted Peer Review requests yet!</h2>
                        <p className="d-inline-block w-100">
                          This is where the magic happens, and your expertise
                          shines!<span>
                          You hold special power to shape the future of
                          scholarly knowledge! Your constructive critiques play
                          a crucial role in guiding authors toward excellence.
                        </span>
                        </p>
                        
                      </div>
                    ) : status === "reviewed" ? (
                      <div className="pr_reviewed">
                        <h2>No peer reviewed articles here!</h2>
                        <p className="d-inline-block w-100">
                          Imagine this tab as a red carpet for your brilliant
                          contributions! <span>
                          We appreciate the time and effort you put into
                          evaluating each article. Your expertise truly makes a
                          world of difference. Thank you very much...
                        </span>
                        </p>
                       
                      </div>
                    ) : status === "notAccepted" ? (
                      <div className="pr_notAccepted">
                        <h2>We respect Your reasons!</h2>
                        <p className="d-inline-block w-100">
                          Articles you could not accept for Peer Review will
                          show here<span>
                          We are your biggest fans, celebrating every review you
                          conquer like it's a touchdown in the academic arena!
                          Three cheers to that...
                        </span>
                        </p>
                        
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>

        <div className="backFooterBox">
          <div className="backBtnWrap">
            <Link
              to="#"
              onClick={(e) => handleRedirect(e, "/dashboard/peer-reviewer")}
            >
              <span>Back</span>
              <img src={backArrow} alt="#" />
            </Link>
          </div>
          {totalPages > 1 && (
            <div className="paginationWrap">
              <Pagination
                boundaryRange={1}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                activePage={page}
                prevItem={{ content: <b class="icon-arrow-left" /> }}
                nextItem={{ content: <b class="icon-arrow-right" /> }}
                onPageChange={(event, data) => handlePageChange(data)}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
        <div
          className="pullUp"
          onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <b className="icon-arrow-right" />
        </div>
      </DashboardLayout>
      <RejectModal
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={articleId}
        fetchPRArticle={fetchPRArticle}
        prStatus={prStatus}
        userId={id}
        setUpdateRequestList={setUpdateRequestList}
      />
      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center"><p class="allGoodText">Are you sure you want to reject this article for Peer Review?</div></p>`}
        leftBtnTxt={"CONFIRM"}
        rightBtnTxt={"NO, GO BACK"}
      />

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText">
              Are you sure you want to accept this article for Peer Review?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-gray bold mr-3"
            onClick={() => setShowAcceptConfirmation(false)}
          >
            NO, GO BACK
          </span>
          <button
            class="button button-primary"
            onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
          >
            CONFIRM
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">ARTICLE ACCEPTED FOR PEER REVIEW</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAccept(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAssContent">
            <p>Thank You! We truly appreciate you</p>
            <p className="mb-4">taking the time out to review this article.</p>
            <p>As a thank you gesture, we will add 250</p>
            <p>Mudras to your Mudra Kosh (Wallet) once</p>
            <p className="mb-4">the article is published.</p>
            <p>
              Please find this article in the{" "}
              <Link to="/peer-reviewer/accepted" state={"accepted"}>
                Accepted
              </Link>
            </p>
            <p>section of your dashboard under Peer Reviewer.</p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            onClick={() => {
              setShowAccept(false);
              navigate("/dashboard/peer-reviewer");
            }}
          >
            MY DASHBOARD
          </span>
          <Link
            className="button button-primary"
            to={`/dashboard/article/submit-review/${articleId}`}
            state={{
              articleId: redirectionId,
            }}
          >
            WRITE REVIEW
          </Link>
        </Modal.Footer>
      </Modal>
      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
      />
      {status === "draft" && (
        <ArticleDetailsModal
          showArticleDetails={showArticleDetails}
          setShowArticleDetails={setShowArticleDetails}
          articleData={articleData}
          currentData={currentData}
          status={"Peer Review"}
        />
      )}
    </>
  );
};

export default PeerReview;
