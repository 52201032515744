import React, { useRef,useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import jwt_decode from "jwt-decode";
import { DatePicker, Space } from 'antd';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RadioButton from "../Radio_button";
import Checkbox from "../Checkbox";
import Select from "../Select";
import Input from "../Input";
import Textarea from "../Textarea";
import { validateEmail, checkUrl } from "../../../utils";
import successful from "../../../assets/img/icons/successful.svg";
import crossref from "../../../assets/img/icons/crossref.svg";
import launchOfferBand from "../../../assets/img/icons/Launch-Offer-Band.png"
import active_info from "../../../svg/active-info.svg";
import {
  FetchJournalDetails,
  FetchJournalByKeyWord,
  GetOrcidID,
  FetchSubjectSpecialization
} from "../../../containers/autherFlow/submitArticle/queries";
import { editorTitleOptionsNew } from "../../../constants";
import {
  getProfileDetails,
  updateProfileDetails,
  getOtherSpecialization,
} from "../../../store/apiCalls/profileDetails";
import { SAVE_SUBJECT_STATUS } from "../../../store/actions/profileActions";
import { GET_OTHER_SUBJECT } from "../../../store/actions/profileActions";
import alertSvg from "../../../assets/img/icons/alert_info.svg";
import OtherSubject from "../../OtherSubject/OtherSubject";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
const buddhistLocale = {
  ...en,
  lang: {
    ...en.lang,
    fieldDateFormat: 'YYYY-MM-DD',
    fieldDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    yearFormat: 'YYYY',
    cellYearFormat: 'YYYY',
  },
};


const mySubmission = (subjects, saveAndPay) => {
  const dispatch = useDispatch();
  
  const [isJournalExist, setisJournalExist] = useState(0);
  const isJournalExistRef = useRef(isJournalExist);
  const reviewProcessScroll = useRef(null);
  const executeScrollds = () => reviewProcessScroll.current.scrollIntoView();
  isJournalExistRef.current = isJournalExist;
  const otherSubject = useSelector(
    (state) => state?.ProfileReducer?.otherSubject
  );
  const sucessHandler = (journalDetails) => {
    if (journalDetails.status === 200) {
      const journalList = [];
      journalDetails.data.data.forEach((element) => {
        const data = { label: element.journalName, value: element._id };
        journalList.push(data);
      });

      setjournalMaster(journalList);
    }
  };
  const sucessGenralDetailsHandler = (journalDetails) => { 
    if (journalDetails.status === 200) {
      const preVal = preValues;
      const details = journalDetails.data.data;
      const storeJournal = [];
      const storeJournalF = [];
      if (
        details.length > 0 &&
        preValues.journalType === journalTypes.society
      ) {
        const subjectDetl = [];
        const specialization = [];

        details.forEach((element, k) => {
          storeJournal.push({ journalName: element.journalName });
          element.subjectData.forEach((subject, p) => {
            if (subject.subject) {
              const val = preValues;
              if (preVal?.journal === element.journalName) {
                val.subject = subject.subject.subjectName;
                val.subjectId = subject.subject._id;
              }
              const subj = {
                val: subject.subject.subjectName,
                key: subject.subject._id,
              };
              subjectDetl[element.journalName] = subj;
              subject.specialization.forEach((spec) => {
                const data = { key: spec._id, value: spec.specialization };
                specialization[element.journalName] = data;
                if (preVal?.journal === element.journalName) {
                  val.specialization = spec.specialization;
                  val.specializationId = spec._id;
                }
              });
              if (preVal?.journal === element.journalName) {
                setpreValues({ ...val });
              }
            }
          });
        });
        setsocitySubjectDesp({
          subject: subjectDetl,
          descpline: specialization,
        });
        setjournalOptions(storeJournal);
        setjournalOptionsFirst(storeJournal);
      } else if (
        details.length > 0 &&
        preValues.journalType === journalTypes.mega
      ) {
        const storeJournalS = [];
        const specilizations = [];
        const subjects = [];
        details.forEach((element, k) => {
          storeJournal.push({ journalName: element.journalName });
          if (preVal?.secondaryJournalName !== element.journalName)
            storeJournalF.push({ journalName: element.journalName });
          if (preVal?.journal !== element.journalName)
            storeJournalS.push({ journalName: element.journalName });
          const subjct = [];
          element.subjectData.forEach((subject, p) => {
            subjct.push({
              val: subject.subject.subjectName,
              key: subject.subject._id,
            });
            specilizations[subject.subject._id] = subject.specialization;

            if (preVal?.subjectId === subject.subject._id) {
              const subjectSpecialization = [];
              if (subject.specialization && subject.specialization.length > 0) {
                subject.specialization.forEach((element) => {
                  const data = {
                    key: element._id,
                    value: element.specialization,
                  };
                  subjectSpecialization.push(data); 
                });
              }
            }
            if (preVal?.secondarySubject?._id === subject?.subject?._id) {
              const subjectSpecializations = [];
              if (subject.specialization && subject.specialization.length > 0) {
                subject.specialization.forEach((element) => {
                  const data = {
                    key: element._id,
                    value: element.specialization,
                  };
                  subjectSpecializations.push(data);
                });
              }
            }
          });
          if (preVal?.journal === element.journalName) {
            //setdescplineSubject(subjct);
          }
          if (preVal?.secondaryJournalName === element.journalName) {
           // setdescplineSubjectSec(subjct);
          }
          subjects[element.journalName] = subjct;
        });
        setjournalOptions(storeJournal);
        setjournalOptionsFirst(storeJournalF);
        setjournalOptionsSecondry(storeJournalS);
        setjournalOptions(storeJournal);
      } else {
        setsocityJournalDetails([{ societyName: "", relatedData: [] }]);
      }
    }
  };
  const sucessSubjectSpecialization = (details) => { 
    if (details.status === 200) {
      const data=details.data.data;
      const pr=preValues; let selcteSpeId=''; let selcteSpe='';
      if(preValues.journal===data.data[0].journalName)
      {
        const subject=[];
        data.data.forEach((element) => {
          const SubjectData = {
            key: element._id,
            val: element.subjectName,
          };
          subject.push(SubjectData);
          
        });
        
       const subjectSpecialization = [];const specializationM = []; const singleSub=[]
       data.specilisation.forEach((ele,k) => {
         const spe=[];
          if(ele?.specialization)
          {
            
           
            const sp = { key: ele._id, value: ele.specialization };
            subjectSpecialization.push(sp);
            const subId=data.data[0]?._id;
            singleSub.push(ele);
            if(subId && k===(data.specilisation.length-1))
            specializationM[subId]=singleSub;
          }
          else{  
            
            ele.forEach((el,p) => { 
              
              
              if(k===0 && isJournalExistRef.current!==2)
              {
                const sp = { key: el._id, value: el.specialization };
                subjectSpecialization.push(sp);
              }
              else if(isJournalExistRef.current===2 && preValues.subjectId===el.subjectId)
              {
                const sp = { key: el._id, value: el.specialization };
                subjectSpecialization.push(sp);
              }
              spe.push(el)
              
            });
            const subId=data.data[k]?._id;
            
            specializationM[subId]=spe;
          }
          
       }); 
       
       setspecializationMaster(specializationM)
       setspecialization(subjectSpecialization)
       setdescplineSubject(subject)
        if(isJournalExistRef.current!==2)
        {
          pr.subject=data.data[0].subjectName;
          pr.subjectId=data.data[0]._id;
        }
        else if(isJournalExistRef.current===2)
        {
          if(preValues.subjectId===data.data[0]._id)
          pr.subject=data.data[0].subjectName;
          setpreValues({ ...pr});
        }
       
      }
      else if(preValues.secondaryJournalName===data.data[0].journalName)
      {
        const subject=[];
        data.data.forEach((element) => {
          const SubjectData = {
            key: element._id,
            val: element.subjectName,
          };
          subject.push(SubjectData);
        });
        const subjectSpecialization = [];const specializationM = []; const singleSub=[]
       data.specilisation.forEach((ele,k) => {
         const spe=[]
          if(ele?.specialization)
          {
            if(k===0)
            {
              selcteSpeId=ele._id
              selcteSpe=ele.specialization
            }
           
            const sp = { key: ele._id, value: ele.specialization };
            subjectSpecialization.push(sp);
            const subId=data.data[0]?._id;
            singleSub.push(ele);
            if(subId && k===(data.specilisation.length-1))
            specializationM[subId]=singleSub;
            
          }
          else{  
            
            ele.forEach((el,p) => { 
              
              
              if(k===0 && p===0)
              {
                selcteSpeId=el._id;
                selcteSpe=el.specialization
              }
              
              if(k===0 && isJournalExistRef.current!==2)
              { 
                const sp = { key: el._id, value: el.specialization };
                subjectSpecialization.push(sp);
              }
              else if(isJournalExistRef.current===2 && preValues.secondarySubject._id===el.subjectId)
              {
                const sp = { key: el._id, value: el.specialization };
                subjectSpecialization.push(sp);
              }
              
              spe.push(el)
              
            });
            const subId=data.data[k]?._id;
            specializationM[subId]=spe;
          }
          
       });
      
       setspecializationSecMaster(specializationM)
       setsecondrySpecialization(subjectSpecialization)
       setdescplineSubjectSec(subject)
       
        if(isJournalExistRef.current!==2)
        {
          pr.secondarySubjectName=data.data[0].subjectName;
          pr.secondarySubject._id=data.data[0]._id;
        }
        else if(isJournalExistRef.current===2)
        {
          if(preValues.secondarySubject?._id===data.data[0]._id)
          pr.secondarySubjectName=data.data[0].subjectName;
          setpreValues({ ...pr});
        }
       
     }
     
     if(isJournalExistRef.current!==2)
     setpreValues({ ...pr});
    }
  };

  function getSubjectSpecialization(journalName)
  {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { journalName: journalName },
      headers: headers,
    };
    if(journalName)
    getJournalSubjects(queryParams);
    else
    dispatch({ type: "SET_LOADER", payload: false });
  }

  const { mutate: getJournalSubjects} = FetchSubjectSpecialization(sucessSubjectSpecialization);
  const sucessOrcidID = (orcid) => {
    if (orcid.status === 200) {
      const OrcidId = orcid.data.orcid;
      updateOrcidIdInProfile(OrcidId);
    }
  };

  const { mutate: getJournal } = FetchJournalByKeyWord(sucessHandler);
  const { mutate: getJournalDetails } = FetchJournalDetails(
    sucessGenralDetailsHandler
  );
  const { mutate: getOrcidID } = GetOrcidID(sucessOrcidID);
  const [ProfileDetails, setProfileDetails] = useState({});
  const [specializationMaster, setspecializationMaster] = useState([]);
  const [specializationSecMaster, setspecializationSecMaster] = useState([]);
  const [specialization, setspecialization] = useState([]);
  const [articleTypeListwconf, setarticleTypeListwconf] = useState([]);
  const [articleTypeListwoutconf, setarticleTypeListwoutconf] = useState([]);
  const [articleTypeListf, setarticleTypeListf] = useState([]);
  const [descplineSubject, setdescplineSubject] = useState([]);

  const [descplineSubjectSec, setdescplineSubjectSec] = useState([]);
  const [countrylist, setcountrylist] = useState([ 
    {name: 'Afghanistan', code: 'AF'}, 
    {name: 'Åland Islands', code: 'AX'}, 
    {name: 'Albania', code: 'AL'}, 
    {name: 'Algeria', code: 'DZ'}, 
    {name: 'American Samoa', code: 'AS'}, 
    {name: 'AndorrA', code: 'AD'}, 
    {name: 'Angola', code: 'AO'}, 
    {name: 'Anguilla', code: 'AI'}, 
    {name: 'Antarctica', code: 'AQ'}, 
    {name: 'Antigua and Barbuda', code: 'AG'}, 
    {name: 'Argentina', code: 'AR'}, 
    {name: 'Armenia', code: 'AM'}, 
    {name: 'Aruba', code: 'AW'}, 
    {name: 'Australia', code: 'AU'}, 
    {name: 'Austria', code: 'AT'}, 
    {name: 'Azerbaijan', code: 'AZ'}, 
    {name: 'Bahamas', code: 'BS'}, 
    {name: 'Bahrain', code: 'BH'}, 
    {name: 'Bangladesh', code: 'BD'}, 
    {name: 'Barbados', code: 'BB'}, 
    {name: 'Belarus', code: 'BY'}, 
    {name: 'Belgium', code: 'BE'}, 
    {name: 'Belize', code: 'BZ'}, 
    {name: 'Benin', code: 'BJ'}, 
    {name: 'Bermuda', code: 'BM'}, 
    {name: 'Bhutan', code: 'BT'}, 
    {name: 'Bolivia', code: 'BO'}, 
    {name: 'Bosnia and Herzegovina', code: 'BA'}, 
    {name: 'Botswana', code: 'BW'}, 
    {name: 'Bouvet Island', code: 'BV'}, 
    {name: 'Brazil', code: 'BR'}, 
    {name: 'British Indian Ocean Territory', code: 'IO'}, 
    {name: 'Brunei Darussalam', code: 'BN'}, 
    {name: 'Bulgaria', code: 'BG'}, 
    {name: 'Burkina Faso', code: 'BF'}, 
    {name: 'Burundi', code: 'BI'}, 
    {name: 'Cambodia', code: 'KH'}, 
    {name: 'Cameroon', code: 'CM'}, 
    {name: 'Canada', code: 'CA'}, 
    {name: 'Cape Verde', code: 'CV'}, 
    {name: 'Cayman Islands', code: 'KY'}, 
    {name: 'Central African Republic', code: 'CF'}, 
    {name: 'Chad', code: 'TD'}, 
    {name: 'Chile', code: 'CL'}, 
    {name: 'China', code: 'CN'}, 
    {name: 'Christmas Island', code: 'CX'}, 
    {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {name: 'Colombia', code: 'CO'}, 
    {name: 'Comoros', code: 'KM'}, 
    {name: 'Congo', code: 'CG'}, 
    {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {name: 'Cook Islands', code: 'CK'}, 
    {name: 'Costa Rica', code: 'CR'}, 
    {name: 'Cote D\'Ivoire', code: 'CI'}, 
    {name: 'Croatia', code: 'HR'}, 
    {name: 'Cuba', code: 'CU'}, 
    {name: 'Cyprus', code: 'CY'}, 
    {name: 'Czech Republic', code: 'CZ'}, 
    {name: 'Denmark', code: 'DK'}, 
    {name: 'Djibouti', code: 'DJ'}, 
    {name: 'Dominica', code: 'DM'}, 
    {name: 'Dominican Republic', code: 'DO'}, 
    {name: 'Ecuador', code: 'EC'}, 
    {name: 'Egypt', code: 'EG'}, 
    {name: 'El Salvador', code: 'SV'}, 
    {name: 'Equatorial Guinea', code: 'GQ'}, 
    {name: 'Eritrea', code: 'ER'}, 
    {name: 'Estonia', code: 'EE'}, 
    {name: 'Ethiopia', code: 'ET'}, 
    {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {name: 'Faroe Islands', code: 'FO'}, 
    {name: 'Fiji', code: 'FJ'}, 
    {name: 'Finland', code: 'FI'}, 
    {name: 'France', code: 'FR'}, 
    {name: 'French Guiana', code: 'GF'}, 
    {name: 'French Polynesia', code: 'PF'}, 
    {name: 'French Southern Territories', code: 'TF'}, 
    {name: 'Gabon', code: 'GA'}, 
    {name: 'Gambia', code: 'GM'}, 
    {name: 'Georgia', code: 'GE'}, 
    {name: 'Germany', code: 'DE'}, 
    {name: 'Ghana', code: 'GH'}, 
    {name: 'Gibraltar', code: 'GI'}, 
    {name: 'Greece', code: 'GR'}, 
    {name: 'Greenland', code: 'GL'}, 
    {name: 'Grenada', code: 'GD'}, 
    {name: 'Guadeloupe', code: 'GP'}, 
    {name: 'Guam', code: 'GU'}, 
    {name: 'Guatemala', code: 'GT'}, 
    {name: 'Guernsey', code: 'GG'}, 
    {name: 'Guinea', code: 'GN'}, 
    {name: 'Guinea-Bissau', code: 'GW'}, 
    {name: 'Guyana', code: 'GY'}, 
    {name: 'Haiti', code: 'HT'}, 
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {name: 'Holy See (Vatican City State)', code: 'VA'}, 
    {name: 'Honduras', code: 'HN'}, 
    {name: 'Hong Kong', code: 'HK'}, 
    {name: 'Hungary', code: 'HU'}, 
    {name: 'Iceland', code: 'IS'}, 
    {name: 'India', code: 'IN'}, 
    {name: 'Indonesia', code: 'ID'}, 
    {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {name: 'Iraq', code: 'IQ'}, 
    {name: 'Ireland', code: 'IE'}, 
    {name: 'Isle of Man', code: 'IM'}, 
    {name: 'Israel', code: 'IL'}, 
    {name: 'Italy', code: 'IT'}, 
    {name: 'Jamaica', code: 'JM'}, 
    {name: 'Japan', code: 'JP'}, 
    {name: 'Jersey', code: 'JE'}, 
    {name: 'Jordan', code: 'JO'}, 
    {name: 'Kazakhstan', code: 'KZ'}, 
    {name: 'Kenya', code: 'KE'}, 
    {name: 'Kiribati', code: 'KI'}, 
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {name: 'Korea, Republic of', code: 'KR'}, 
    {name: 'Kuwait', code: 'KW'}, 
    {name: 'Kyrgyzstan', code: 'KG'}, 
    {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {name: 'Latvia', code: 'LV'}, 
    {name: 'Lebanon', code: 'LB'}, 
    {name: 'Lesotho', code: 'LS'}, 
    {name: 'Liberia', code: 'LR'}, 
    {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {name: 'Liechtenstein', code: 'LI'}, 
    {name: 'Lithuania', code: 'LT'}, 
    {name: 'Luxembourg', code: 'LU'}, 
    {name: 'Macao', code: 'MO'}, 
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {name: 'Madagascar', code: 'MG'}, 
    {name: 'Malawi', code: 'MW'}, 
    {name: 'Malaysia', code: 'MY'}, 
    {name: 'Maldives', code: 'MV'}, 
    {name: 'Mali', code: 'ML'}, 
    {name: 'Malta', code: 'MT'}, 
    {name: 'Marshall Islands', code: 'MH'}, 
    {name: 'Martinique', code: 'MQ'}, 
    {name: 'Mauritania', code: 'MR'}, 
    {name: 'Mauritius', code: 'MU'}, 
    {name: 'Mayotte', code: 'YT'}, 
    {name: 'Mexico', code: 'MX'}, 
    {name: 'Micronesia, Federated States of', code: 'FM'}, 
    {name: 'Moldova, Republic of', code: 'MD'}, 
    {name: 'Monaco', code: 'MC'}, 
    {name: 'Mongolia', code: 'MN'}, 
    {name: 'Montserrat', code: 'MS'}, 
    {name: 'Morocco', code: 'MA'}, 
    {name: 'Mozambique', code: 'MZ'}, 
    {name: 'Myanmar', code: 'MM'}, 
    {name: 'Namibia', code: 'NA'}, 
    {name: 'Nauru', code: 'NR'}, 
    {name: 'Nepal', code: 'NP'}, 
    {name: 'Netherlands', code: 'NL'}, 
    {name: 'Netherlands Antilles', code: 'AN'}, 
    {name: 'New Caledonia', code: 'NC'}, 
    {name: 'New Zealand', code: 'NZ'}, 
    {name: 'Nicaragua', code: 'NI'}, 
    {name: 'Niger', code: 'NE'}, 
    {name: 'Nigeria', code: 'NG'}, 
    {name: 'Niue', code: 'NU'}, 
    {name: 'Norfolk Island', code: 'NF'}, 
    {name: 'Northern Mariana Islands', code: 'MP'}, 
    {name: 'Norway', code: 'NO'}, 
    {name: 'Oman', code: 'OM'}, 
    {name: 'Pakistan', code: 'PK'}, 
    {name: 'Palau', code: 'PW'}, 
    {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {name: 'Panama', code: 'PA'}, 
    {name: 'Papua New Guinea', code: 'PG'}, 
    {name: 'Paraguay', code: 'PY'}, 
    {name: 'Peru', code: 'PE'}, 
    {name: 'Philippines', code: 'PH'}, 
    {name: 'Pitcairn', code: 'PN'}, 
    {name: 'Poland', code: 'PL'}, 
    {name: 'Portugal', code: 'PT'}, 
    {name: 'Puerto Rico', code: 'PR'}, 
    {name: 'Qatar', code: 'QA'}, 
    {name: 'Reunion', code: 'RE'}, 
    {name: 'Romania', code: 'RO'}, 
    {name: 'Russian Federation', code: 'RU'}, 
    {name: 'RWANDA', code: 'RW'}, 
    {name: 'Saint Helena', code: 'SH'}, 
    {name: 'Saint Kitts and Nevis', code: 'KN'}, 
    {name: 'Saint Lucia', code: 'LC'}, 
    {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {name: 'Samoa', code: 'WS'}, 
    {name: 'San Marino', code: 'SM'}, 
    {name: 'Sao Tome and Principe', code: 'ST'}, 
    {name: 'Saudi Arabia', code: 'SA'}, 
    {name: 'Senegal', code: 'SN'}, 
    {name: 'Serbia and Montenegro', code: 'CS'}, 
    {name: 'Seychelles', code: 'SC'}, 
    {name: 'Sierra Leone', code: 'SL'}, 
    {name: 'Singapore', code: 'SG'}, 
    {name: 'Slovakia', code: 'SK'}, 
    {name: 'Slovenia', code: 'SI'}, 
    {name: 'Solomon Islands', code: 'SB'}, 
    {name: 'Somalia', code: 'SO'}, 
    {name: 'South Africa', code: 'ZA'}, 
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {name: 'Spain', code: 'ES'}, 
    {name: 'Sri Lanka', code: 'LK'}, 
    {name: 'Sudan', code: 'SD'}, 
    {name: 'Suriname', code: 'SR'}, 
    {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {name: 'Swaziland', code: 'SZ'}, 
    {name: 'Sweden', code: 'SE'}, 
    {name: 'Switzerland', code: 'CH'}, 
    {name: 'Syrian Arab Republic', code: 'SY'}, 
    {name: 'Taiwan, Province of China', code: 'TW'}, 
    {name: 'Tajikistan', code: 'TJ'}, 
    {name: 'Tanzania, United Republic of', code: 'TZ'}, 
    {name: 'Thailand', code: 'TH'}, 
    {name: 'Timor-Leste', code: 'TL'}, 
    {name: 'Togo', code: 'TG'}, 
    {name: 'Tokelau', code: 'TK'}, 
    {name: 'Tonga', code: 'TO'}, 
    {name: 'Trinidad and Tobago', code: 'TT'}, 
    {name: 'Tunisia', code: 'TN'}, 
    {name: 'Turkey', code: 'TR'}, 
    {name: 'Turkmenistan', code: 'TM'}, 
    {name: 'Turks and Caicos Islands', code: 'TC'}, 
    {name: 'Tuvalu', code: 'TV'}, 
    {name: 'Uganda', code: 'UG'}, 
    {name: 'Ukraine', code: 'UA'}, 
    {name: 'United Arab Emirates', code: 'AE'}, 
    {name: 'United Kingdom', code: 'GB'}, 
    {name: 'United States', code: 'US'}, 
    {name: 'United States Minor Outlying Islands', code: 'UM'}, 
    {name: 'Uruguay', code: 'UY'}, 
    {name: 'Uzbekistan', code: 'UZ'}, 
    {name: 'Vanuatu', code: 'VU'}, 
    {name: 'Venezuela', code: 'VE'}, 
    {name: 'Viet Nam', code: 'VN'}, 
    {name: 'Virgin Islands, British', code: 'VG'}, 
    {name: 'Virgin Islands, U.S.', code: 'VI'}, 
    {name: 'Wallis and Futuna', code: 'WF'}, 
    {name: 'Western Sahara', code: 'EH'}, 
    {name: 'Yemen', code: 'YE'}, 
    {name: 'Zambia', code: 'ZM'}, 
    {name: 'Zimbabwe', code: 'ZW'} 
  ]);
  const otherSubjectTitle = 'Other Subject (choose this option to register your subject)';
  const [secondrySpecialization, setsecondrySpecialization] = useState([]);
  const journalTypes = {
    society: "6454a6390d88e62fa10503bf",
    mega: "6454a6390d88e62fa10503be",yogaJournal: "AyurYoga – The Mega Journal of Ayurveda and Yoga",
  };
  const [showMaterialPolicy, setShowMaterialPolicy] = useState(false);
  const [incompleteInfoPopup, setincompleteInfoPopup] = useState(false);
  const [createcitationPopup, setcreatecitationPopup] = useState(false);
  const [citationGeneratedPopup, setcitationGeneratedPopup] = useState(false);
  const [needHelpPopup, setneedHelpPopup] = useState(false);
  const [isSocityJournal, setisSocityJournal] = useState(false);
  const [articleTypeDesc, setarticleTypeDesc] = useState({
    name: "",
    desc: "",
    wordCount: "",
  });
  const [socityJournalDetails, setsocityJournalDetails] = useState([
    { societyName: "", relatedData: [] },
  ]);
  const [socitySubjectDesp, setsocitySubjectDesp] = useState({
    subject: [],
    descpline: [],
  });
  const [authorCount, setauthorCount] = useState([0]);
  const [APCData, setAPCData] = useState([]);
  const [popupError, setpopupError] = useState("");
  const [defaultActiveKey, setdefaultActiveKey] = useState("0");
  const [other, setOther] = useState({
    show: false,
    specialization: "",
    disabled: true,
  });
  const [subjectApproval, setsubjectApproval] = useState(false);
  const [showSubject, setShowSubject] = useState(false);
  const [authorContent, setauthorContent] = useState({
    authorTitle: [{ val: "" }],
    authorname: [{ val: "" }],
    authorEmail: [{ val: "" }],
    authorUniversity: [{ val: "" }],
    authorDepartment: [{ val: "" }],
    authorOrcidID: [{ val: "" }],
    contributionStatement: "",
    isMePrimaryAuthor: true,
  });
  const [authorError, setauthorError] = useState({
    authorTitleError: [],
    authornameError: [],
    authorEmailError: [],
    authorUniversityError: [],
    authorDepartmentError: [],
    contributionStatementError: "",
  });
  const [fundingCount, setfundingCount] = useState([0]);
  const [fundingDetails, setfundingDetails] = useState({
    name: [{ val: "" }],
    email: [{ val: "" }],
    phone: [{ val: "" }],
    fundingAgency: [{ val: "" }],
    fundingAgencyUrl: [{ val: "" }],
    grantAmount: [{ val: "" }],
    fundingInformation: "",
    noFundingRecevied: false,
  });
  const [fundingDetailsError, setfundingDetailsError] = useState({
    nameError: [],
    emailError: [],
    phoneError: [],
    fundingAgencyError: [],
    fundingAgencyUrlError: [],
    grantAmountError: [],
    fundingInformationError: "",
  }); 
  const [reviewerCount, setreviewerCount] = useState([0,1,2,3,4]);
  const [reviewerDetails, setreviewerDetails] = useState({
    title: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    email: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    name: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    university: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    orcidId: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    department: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    specialization: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    additionalComment: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }],
    recommendReviwer: true,
  });
  console.log('reviewerCount',reviewerCount);
  console.log('reviewerDetails',reviewerDetails);
  const [reviewerDetailsError, setreviewerDetailsError] = useState({
    nameError: [],
    emailError: [],
    titleError: [],
    universityError: [],
    specializationError: [],
  });

  const [journalOptions, setjournalOptions] = useState([]);
  const [journalOptionsFirst, setjournalOptionsFirst] = useState([]);
  const [journalOptionsSecondry, setjournalOptionsSecondry] = useState([]);
  const [preValues, setpreValues] = useState({
    id: "",
    journalType: "",
    societyName: "",
    disciplineType: "",
    journal: "",
    subject: "",
    subjectId: "",
    specialization: "",
    specializationId: "",
    articleType: "",
    // reviewType: ""
    reviewType: "only post-publication open review",
    secondaryJournalName: "",
    secondarySubjectName:"",
    secondarySubject: {'_id':''},
    secondarySpecialization: "",
    secondaryJournalNameError: "",
    secondarySubjectError: "",
    secondarySpecializationError: "",
  });
  const [statements, setstatements] = useState({
    commercialFinanceConflict: "",
    conflictDetail: "",
    manuscriptVersion: "",
    manuscriptVersionDetail: "",
    checkedCondition: false,
    conflictError: "",
    versionError: "",
    acceptAllError: "",
  });
  const [conference, setconference] = useState({
    conferenceName: "",
    city: "",
    country: "",
    url: "",
    date: "",
    conferenceChairName: "",
    conferenceChairiEmail: "",
    trackChairName: "",
    trackChairEmail: "",
    checkedCondition: false,
  });
  const [conferenceError, setconferenceError] = useState({
    conferenceName: "",
    city: "",
    country: "",
    url: "",
    date: "",
    conferenceChairName: "",
    conferenceChairiEmail: "",
    trackChairName: "",
    trackChairEmail: "",
  });
  const defaultValuedate = dayjs(conference.date!=''?conference.date:'01-01-2024');
  // console.log("conferenceconference",conference);

  useEffect(() => {
    handleGetSpecialization();
    
    setAPCData(subjects.APCData)
    let manageTab='0';
    const editedDetail = subjects.preStepData; 
    let isAuthorExist = false;let society = false;
    if (editedDetail.submissionDetail.articleType) {
      const data = preValues;
      manageTab='1';
      fetchJernalDetails(editedDetail.submissionDetail?.journalType?._id);
      if (
        editedDetail.submissionDetail?.journalType?._id === journalTypes.society
      ) {
        setisSocityJournal(true);
        society=true
        data.reviewType = "pre-publication peer review only";
      }
      setisJournalExist(2)// 2 journal value exist 
      data.societyName = editedDetail.submissionDetail?.societyName;
      if (data.societyName !== "") {
        data.subject = editedDetail.submissionDetail?.subject?.subjectName;
        data.specialization =
          editedDetail.submissionDetail?.specialization.specialization;
      }
      data.journalType = editedDetail.submissionDetail?.journalType?._id;
      data.disciplineType = editedDetail.submissionDetail?.disciplineType?._id;
      data.journal = editedDetail.submissionDetail?.journalName;
      if(society===false && data.journal!=='')
      { 
        getSubjectSpecialization(data.journal)
      }
      data.subjectId = editedDetail.submissionDetail?.subject?._id;
      
      if (
        editedDetail.submissionDetail?.subject?._id === "" &&
        editedDetail.submissionDetail?.subjectId?._id !== ""
      )
        data.subjectId = editedDetail.submissionDetail?.subject?._id;
        data.specializationId = editedDetail.submissionDetail?.specialization?._id;
      if (
        editedDetail.submissionDetail?.specialization?._id === "" &&
        editedDetail.submissionDetail?.specializationId?._id !== ""
      )
        data.specializationId =
          editedDetail.submissionDetail?.specializationId._id;
      data.articleType = editedDetail.submissionDetail?.articleType._id;
      data.secondarySubject= editedDetail.submissionDetail?.secondarySubject;
      data.secondaryJournalName =
        editedDetail.submissionDetail?.secondaryJournalName;
      if(society===false && data.secondaryJournalName!=='')
      {
        setTimeout(function() {
          
         getSubjectSpecialization(data.secondaryJournalName)
        }, 1000);
        
      }
      data.secondarySpecialization =
        editedDetail.submissionDetail?.secondarySpecialization;
      if (editedDetail.reviewType.typeOfReview)
        data.reviewType = editedDetail.reviewType?.typeOfReview; 
      setpreValues({ ...data });

      const specialization =
        subjects.subjectSpecializationList[
          editedDetail.submissionDetail?.subject?._id
        ];
      const subjectSpecialization = [];
      if (specialization && specialization.length > 0) {
        specialization.forEach((element) => {
          const data = { key: element._id, value: element.specialization };
          subjectSpecialization.push(data);
        });
      }

      setspecialization(subjectSpecialization);
    }

    if (editedDetail.conference?._id &&
      editedDetail.conference?._id !== "") {
        manageTab = '2';
        const conferenc = conference;

        conferenc.conferenceName = editedDetail?.conference?.conferenceName;
        conferenc.city = editedDetail?.conference?.city;
        conferenc.country = editedDetail?.conference?.country;
        conferenc.url = editedDetail?.conference?.url;
        conferenc.date = editedDetail?.conference?.date; 
        conferenc.conferenceChairName = editedDetail?.conference?.conferenceChairName;
        conferenc.conferenceChairiEmail = editedDetail?.conference?.conferenceChairiEmail;
        conferenc.trackChairName = editedDetail?.conference?.trackChairName;
        conferenc.trackChairEmail = editedDetail?.conference?.trackChairEmail;
        // console.log("ddd", editedDetail?.conference?.conferenceName); 
        setconference({ ...conferenc }); 
    }
    
    if (
      editedDetail.reviewType?.typeOfReview &&
      editedDetail.reviewType?.typeOfReview !== ""
    ) {
      
      if(editedDetail.reviewType.paymentDone===true)
      manageTab='3';
      const totalreviewer = [];
      const revwrs = reviewerDetails;
      editedDetail.reviewType.suggestedReviewers.forEach((reviewr, k) => {
        totalreviewer.push(k);
        if (revwrs.title[k]) {
          revwrs.title[k].val = reviewr.title;
          revwrs.name[k].val = reviewr.fullName;
          revwrs.email[k].val = reviewr?.email;
          revwrs.university[k].val = reviewr.affiliatedInstitude;
          revwrs.orcidId[k].val = reviewr.orcidId;
          revwrs.department[k].val = reviewr.affiliatedDepartment;
          revwrs.specialization[k].val = reviewr.specialization._id;
          revwrs.additionalComment[k].val = reviewr.additionalComment;
        } else {
          revwrs.title.push({ val: reviewr.title });
          revwrs.name.push({ val: reviewr.fullName });
          revwrs.email.push({ val: reviewr?.email });
          revwrs.university.push({ val: reviewr.affiliatedInstitude });
          revwrs.orcidId.push({ val: reviewr.orcidId });
          revwrs.department.push({ val: reviewr.affiliatedDepartment });
          revwrs.specialization.push({ val: reviewr.specialization._id });
          revwrs.additionalComment.push({ val: reviewr.additionalComment });
        }
      });

      if (editedDetail.reviewType.suggestedReviewers.length === 0) {
        totalreviewer.push(0);
        totalreviewer.push(1);
        totalreviewer.push(2);
        totalreviewer.push(3);
        totalreviewer.push(4);
        // revwrs.title.push({ val: "" });
        // revwrs.title.push({ val: "" });
        // revwrs.title.push({ val: "" });
        // revwrs.title.push({ val: "" });
        // revwrs.title.push({ val: "" });
        // revwrs.name.push({ val: "" });
        // revwrs.name.push({ val: "" });
        // revwrs.name.push({ val: "" });
        // revwrs.name.push({ val: "" });
        // revwrs.name.push({ val: "" });
        // revwrs.email.push({ val: "" });
        // revwrs.email.push({ val: "" });
        // revwrs.email.push({ val: "" });
        // revwrs.email.push({ val: "" });
        // revwrs.email.push({ val: "" });
        // revwrs.university.push({ val: "" });
        // revwrs.university.push({ val: "" });
        // revwrs.university.push({ val: "" });
        // revwrs.university.push({ val: "" });
        // revwrs.university.push({ val: "" });
        // revwrs.orcidId.push({ val: "" });
        // revwrs.orcidId.push({ val: "" });
        // revwrs.orcidId.push({ val: "" });
        // revwrs.orcidId.push({ val: "" });
        // revwrs.orcidId.push({ val: "" });
        // revwrs.department.push({ val: "" });
        // revwrs.department.push({ val: "" });
        // revwrs.department.push({ val: "" });
        // revwrs.department.push({ val: "" });
        // revwrs.department.push({ val: "" });
        // revwrs.specialization.push({ val: "" });
        // revwrs.specialization.push({ val: "" });
        // revwrs.specialization.push({ val: "" });
        // revwrs.specialization.push({ val: "" });
        // revwrs.specialization.push({ val: "" });
        // revwrs.additionalComment.push({ val: "" });
        // revwrs.additionalComment.push({ val: "" });
        // revwrs.additionalComment.push({ val: "" });
        // revwrs.additionalComment.push({ val: "" });
        // revwrs.additionalComment.push({ val: "" });
      }
      revwrs.recommendReviwer = !editedDetail.reviewType.isRecommendReviewers;
      setreviewerDetails({ ...revwrs });
      setreviewerCount([...totalreviewer]);
    }

    if (editedDetail.authorDtl?._id && editedDetail.authorDtl?._id !== "") {
      const author = authorContent;
      const totalAuthor = [];
      editedDetail.authorDtl.author.forEach((authr, k) => {
        totalAuthor.push(k);
        if (author.authorTitle[k]) {
          author.authorTitle[k].val = authr.title;
          author.authorname[k].val = authr?.fullName;
          author.authorEmail[k].val = authr?.email;
          author.authorUniversity[k].val = authr?.affiliatedInstitude;
          author.authorDepartment[k].val = authr?.affiliatedDepartment;
          author.authorOrcidID[k].val = authr?.orcidId;
        } else {
          author.authorTitle.push({ val: authr.title });
          author.authorname.push({ val: authr?.fullName });
          author.authorEmail.push({ val: authr?.email });
          author.authorUniversity.push({ val: authr?.affiliatedInstitude });
          author.authorDepartment.push({ val: authr?.affiliatedDepartment });
          author.authorOrcidID.push({ val: authr?.orcidId });
        }
      });

      if (totalAuthor.length > 0) setauthorCount([...totalAuthor]);
      author.isMePrimaryAuthor = editedDetail.authorDtl?.notThePrimaryAuthor;
      author.contributionStatement =
        editedDetail.authorDtl?.contributionStatement;
      setauthorContent({ ...author });
      isAuthorExist = true;
      manageTab='3';
    }

    if (
      editedDetail.fundingDetails?._id &&
      editedDetail.fundingDetails?._id !== ""
    ) {
      manageTab='4';
      const fund = fundingDetails;
      const totalFund = [];
      editedDetail.fundingDetails.funder.forEach((funds, k) => {
        totalFund.push(k);
        if (fund.name[k]) {
          fund.name[k].val = funds.nameOfContactPerson;
          fund.email[k].val = funds?.emailId;
          fund.phone[k].val = funds?.mobileNumber;
          fund.fundingAgency[k].val = funds?.NameOfFundingAgency;
          fund.fundingAgencyUrl[k].val = funds?.urlOfFundingAgency;
          fund.grantAmount[k].val = funds?.grantAmount;
        }
      });

      if (editedDetail.fundingDetails.funder.length === 0) {
        totalFund.push(0);
        fund.name.push({ val: "" });
        fund.email.push({ val: "" });
        fund.phone.push({ val: "" });
        fund.fundingAgency.push({ val: "" });
        fund.fundingAgencyUrl.push({ val: "" });
        fund.grantAmount.push({ val: "" });
      }
      fund.fundingInformation = editedDetail.fundingDetails.fundingInformation;

      fund.noFundingRecevied = editedDetail.fundingDetails.noFundingRecevied;
      setfundingDetails({ ...fund });
      if (totalFund.length > 0) setfundingCount([...totalFund]);
    }

    if (editedDetail.statements?.AakashGangaJournal?.versionOfManuscript) {
      const statement = statements;
     
      statement.commercialFinanceConflict =
        editedDetail.statements.commercial.commercialOrfinancialConflictOfInterest;
      statement.conflictDetail = editedDetail.statements.commercial.text;
      statement.manuscriptVersion =
        editedDetail.statements.AakashGangaJournal.versionOfManuscript;
      statement.manuscriptVersionDetail =
        editedDetail.statements.AakashGangaJournal.text;
      statement.checkedCondition = true;
      setstatements({ ...statement });
    }
    
    
    manageTabs(manageTab)
    fetchProfileData(isAuthorExist);
    subjects.submissionRef.current = manageTabs;
  }, []);
  function manageTabs(activeKey) {
    setdefaultActiveKey(activeKey);
  }

  useEffect(() => {
    // remove Conference Paper
    let updatedData = subjects.articleTypeList.filter(item => item.key !== "664aed76ada7b4f743b760a9");
    setarticleTypeListf(subjects.articleTypeList);
    setarticleTypeListwoutconf(updatedData);
    setarticleTypeListwconf(subjects.articleTypeList);

  }, []);


  function saveOrcidId(code) {
    const data = {
      client_id: "APP-RAKRR9CDZ5FK8R7Z",
      client_secret: "159fe597-d2e0-4ac9-8dd4-ad07aaed037a",
      grant_type: "authorization_code",
      code: code,
      redirect_uri: "https://unikove.com/projects/orcid/orcid.php",
    };
    const headers = {
      Accept: `application/json`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const queryParams = {
      payload: data,
      headers,
    };
    getOrcidID(queryParams);
  }

  const updateOrcidIdInProfile = async (orcidId) => {
    const { id } = jwt_decode(localStorage.usertoken);
    const headers = { Authorization: `Bearer ${userLoginDetails?.token}` };
    const queryParams = {
      params: { id },
      data: { orcidId: orcidId },
      headers,
    };
    const updateResp = await updateProfileDetails(queryParams);
    if (updateResp?.status === 200) {
    }
    setEditDetails(updateResp);
    fetchProfileData();
  };
  const fetchProfileData = (isAuthorExist) => {
    const fetchdata = async () => {
      const { id } = jwt_decode(localStorage.usertoken);
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const params = { id: id };
      return getProfileDetails(headers, params);
    };

    fetchdata()
      .then((userData) => {
        if (userData.status === 200 && userData?.data?.data) {
          setProfileDetails(userData.data.data);
          if (isAuthorExist === false) {
            const authr = authorContent;
            authr.authorTitle[0].val = userData.data.data.title;
            authr.authorname[0].val = userData.data.data.fullName;
            authr.authorEmail[0].val = userData.data.data.email;
            authr.authorUniversity[0].val =
              userData.data.data.affiliation?.affiliations[0]?.name;
            authr.authorDepartment[0].val =
              userData.data.data.affiliation?.affiliations[0]?.department;
            setauthorContent({ ...authr });
          }
        }
      })
      .catch((err) => console.log(err));
  };
  function fetchJernalDetails(Jid) {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { journalType: Jid },
      headers: headers,
    };
    getJournalDetails(queryParams);
  }
  function handlejournalOPtions(selectedId, type, data) {
    let journal = journalOptions;
    if (data !== "") journal = data;
    const sJournal = [];
    journal.forEach((element) => {
      if (element.journalName !== selectedId) {
        sJournal.push(element);
      }
    });
    if (type === "second") setjournalOptionsFirst(sJournal);
    else if (type === "first") setjournalOptionsSecondry(sJournal);
  }
  function handleSelectChanges(e, type) {
    const val = preValues;
    if (type === "subject") {
      val.subjectId = e;
       const specialization = specializationMaster[e]; 
       const subjectSpecialization = [];
       specialization.forEach((element) => {
         const data = { key: element._id, value: element.specialization };
         subjectSpecialization.push(data);
       });

      setspecialization(subjectSpecialization);
    } else if (type === "journalType") {
      val.journalType = e;

      if (e == '6454a6390d88e62fa10503be') {
        setarticleTypeListf(articleTypeListwconf);
      } else {
        setarticleTypeListf(articleTypeListwoutconf)
      }

      val.journal = "";
      val.disciplineType = "";
      if (e === journalTypes.society) {
        fetchJernalDetails(e);
        val.societyName = "";
        val.reviewType = "";
        val.subject = "";
        val.subjectId = "";
        val.articleType = "";
        val.specialization = "";
        val.specializationId = "";
        val.secondarySubject._id = "";
        val.secondarySubjectName = "";
        val.reviewType = "pre-publication peer review only";
        setisSocityJournal(true);
      } else {
        fetchJernalDetails(e);
        // val.reviewType = ""
        val.reviewType = "only post-publication open review";
        val.subject = "";
        val.subjectId = "";
        val.articleType = "";
        val.specialization = "";
        val.specializationId = "";
        val.secondaryJournalName = "";
        val.secondarySpecialization = "";
        val.secondarySubject._id = "";
        val.secondarySubjectName = "";
        setdescplineSubject([]);
        setdescplineSubjectSec([]);
        setsecondrySpecialization([]);
        setspecialization([]);
        setisSocityJournal(false);
      }
    } else if (type === "socityType") {
      socityJournalDetails.forEach((element) => {
        if (element.relatedData[e]) {
          handleSocietChange(element.relatedData[e]);
        }
      });
      val.societyName = e;
    } else if (type === "specialization") {
      val.specializationId = e;
      const subjectName = filterSubjectName(specialization, e); 
      if (subjectName === otherSubjectTitle) {
        setOther({ ...other, show: true, disabled: false });
        setShowSubject(true);
      }
    } else if (type === "secondaryJournalName") {
      val.secondaryJournalName = e;
      val.secondarySpecialization = "";
      val.secondarySubjectName = "";
      val.secondarySubject._id = "";
      val.secondaryJournalNameError = "";
      setsecondrySpecialization([]);
      setisJournalExist(1) // 1 old data not exist
      getSubjectSpecialization(e)
      
      handlejournalOPtions(e, "second", "");
    } else if (type === "secondarySubject") {
      val.secondarySubject._id = e;
      const specialization = specializationSecMaster[e]; 
      const subjectSpecialization = [];
      specialization.forEach((element) => {
        const data = { key: element._id, value: element.specialization };
        subjectSpecialization.push(data);
      });
      setsecondrySpecialization(subjectSpecialization);
      val.secondarySubjectError = "";
    } else if (type === "secondarySpecialization") {
      val.secondarySpecialization = e;
      val.secondarySpecializationError = "";
    } else if (type === "articleTypeList") {
      val.articleType = e;
      getArticleTypeDesc(e);
    } else if (type === "journal") { 
      val.journal = e;
      val.subject = "";
      val.subjectId = "";
     
      setspecialization([]);
     
      if (isSocityJournal === false)
      {
        setisJournalExist(1) // 1 old data not exist
        getSubjectSpecialization(e)
      }
      
      
      handlejournalOPtions(e, "first", "");
      if (isSocityJournal === true) {
        const getSub = socitySubjectDesp.subject[e]; 
        const getDes = socitySubjectDesp.descpline[e]; 
        val.subject = getSub.val;
        val.subjectId = getSub.key;
        val.specialization = getDes.value;
        val.specializationId = getDes.key;
      }
    } else if (type === "disciplineType") {
      val.disciplineType = e;
    } else if (type === "countrylist") {
      const conferenc = conference; 
      conferenc.country = e;
      setconference({ ...conferenc }); 
    }

    setpreValues({ ...val });
  }

  function getArticleTypeDesc(articleTypeId) {
    const details = subjects.articleTypeList.filter(
      (detl) => detl.key === articleTypeId
    );
    setarticleTypeDesc({
      isShow: true,
      name: details[0].value,
      desc: details[0].description,
      wordCount: details[0].wordCount,
    });
  }
  function closegetArticleTypeDesc() {
    setarticleTypeDesc({ isShow: false, name: "", desc: "", wordCount: "" });
  }

  function filterSubjectName(subjects, id) {
    if (subjects.length === 0 || isSocityJournal === true || id === "" || !id)
      return "NoOther";
    return subjects.find(function (o) {
      return o.key === id;
    }).value;
  }
  const handleGetSpecialization = async () => {
    const headers = { Authorization: `Bearer ${localStorage.usertoken || ""}` };
    const { id } = jwt_decode(localStorage.usertoken);
    const params = {
      userId: id,
    };
    dispatch({ type: "SET_LOADER", payload: true });
    const addResp = await getOtherSpecialization(headers, params);
    dispatch({ type: "SET_LOADER", payload: false });
    if (addResp.data?.success) {
      dispatch({
        type: GET_OTHER_SUBJECT,
        payload: addResp?.data?.data,
      });
      getSubjectStatus(addResp?.data?.data);
    } else return;
  };

  const getSubjectStatus = (anotherSubject) => {
    anotherSubject &&
      anotherSubject?.filter((sub) => {
        if (sub?.status === "Pending") {
          dispatch({
            type: SAVE_SUBJECT_STATUS,
            payload: true,
          });
        }
        return;
      });
  };

  function handleSocietChange(details) {
    const pre = preValues;
    pre.societyName = details.societyName;
    pre.journal = details.journalName;
    pre.subject = details[0].subject.subjectName;
    pre.subjectId = details[0].subject._id;
    pre.specialization = details[0].specialization[0].specialization;
    pre.specializationId = details[0].specialization[0]._id;
    setpreValues({ ...pre });
  }
  function removeReviewers(key) {
    const data = reviewerCount;
    const details = reviewerDetails;
    const dataError = reviewerDetailsError;
    dataError.nameError[key] = "";
    dataError.emailError[key] = "";
    dataError.titleError[key] = "";
    dataError.universityError[key] = "";
    dataError.specializationError[key] = "";
    setreviewerDetailsError({ ...dataError });
    if (details.title[key]) {
      details.title.splice(key, 1);
    }
    if (details.email[key]) {
      details.email.splice(key, 1);
    }
    if (details.name[key]) {
      details.name.splice(key, 1);
    }
    if (details.university[key]) {
      details.university.splice(key, 1);
    }
    if (details.orcidId[key]) {
      details.orcidId.splice(key, 1);
    }
    if (details.department[key]) {
      details.department.splice(key, 1);
    }
    if (details.specialization[key]) {
      details.specialization.splice(key, 1);
    }
    if (details.additionalComment[key]) {
      details.additionalComment.splice(key, 1);
    }

    setreviewerDetails({ ...details });
    data.splice(key, 1);
    setreviewerCount([...data]);
  }
  function handleReviewTypeChange(e) {
    if (subjects.completedTabs.review === false || isSocityJournal === false) {
      const { name, id } = e.target;
      const val = preValues;
      val.reviewType = id;
      setpreValues({ ...val });
    }
  }
  function handleReviewerCheckbox(e) {
    const data = reviewerDetails;
    let val = false;
    if (e.target.checked) val = true;
    data.recommendReviwer = val;
    if (val === false) {
      // setreviewerCount([0]);
      data.title = [{ val: "" }];
      data.email = [{ val: "" }];
      data.name = [{ val: "" }];
      data.university = [{ val: "" }];
      data.orcidId = [{ val: "" }];
      data.department = [{ val: "" }];
      data.specialization = [{ val: "" }];
      data.additionalComment = [{ val: "" }];
      const dataError = reviewerDetailsError;
      dataError.nameError = [];
      dataError.emailError = [];
      dataError.titleError = [];
      dataError.universityError = [];
      dataError.specializationError = [];
      setreviewerDetailsError({ ...dataError });
    }
    setreviewerDetails({ ...data });
  }
  function handlePrimaryAuthorCheckbox(e) {
    const author = authorContent;
    const authrEror = authorError;
    let val = true;
    if (e.target.checked) val = false;

    author.isMePrimaryAuthor = val;
    setauthorContent({ ...author });
    if (val === false) {
      if (authorCount.length === 1) {
        addMoreAuthor();
      }

      const authr = authorContent;

      authr.authorTitle[0].val = "";
      authr.authorname[0].val = "";
      authr.authorEmail[0].val = "";
      authr.authorUniversity[0].val = "";
      authr.authorDepartment[0].val = "";
      authr.authorOrcidID[0].val = "";
      authr.authorTitle[1].val = ProfileDetails.title;
      authr.authorname[1].val = ProfileDetails.fullName;
      authr.authorEmail[1].val = ProfileDetails.email;
      authr.authorUniversity[1].val =
        ProfileDetails.affiliation?.affiliations[0]?.name;
      authr.authorDepartment[1].val =
        ProfileDetails.affiliation?.affiliations[0]?.department;
      authr.authorDepartment[1].val =
        ProfileDetails.affiliation?.affiliations[0]?.department;
      setauthorContent({ ...authr });
      if (authrEror.authorTitleError[1]) {
        authrEror.authorTitleError[1] = "";
        authrEror.authornameError[1] = "";
        authrEror.authorEmailError[1] = "";
        authrEror.authorUniversityError[1] = "";
        authrEror.authorDepartmentError[1] = "";
        setauthorError({ ...authrEror });
      }
    } else {
      const authr = authorContent;
      authr.authorTitle[0].val = ProfileDetails.title;
      authr.authorname[0].val = ProfileDetails.fullName;
      authr.authorEmail[0].val = ProfileDetails.email;
      authr.authorUniversity[0].val =
        ProfileDetails.affiliation?.affiliations[0]?.name;
      authr.authorDepartment[0].val =
        ProfileDetails.affiliation?.affiliations[0]?.department;
      authr.authorOrcidID[0].val = "";
      if (authorCount.length > 1) {
        authr.authorTitle[1].val = "";
        authr.authorname[1].val = "";
        authr.authorEmail[1].val = "";
        authr.authorUniversity[1].val = "";
        authr.authorDepartment[1].val = "";
        authr.authorOrcidID[1].val = "";
        removeAuthorInputs(1);
      }
      setauthorContent({ ...authr });

      authrEror.authorTitleError[0] = "";
      authrEror.authornameError[0] = "";
      authrEror.authorEmailError[0] = "";
      authrEror.authorUniversityError[0] = "";
      authrEror.authorDepartmentError[0] = "";
      setauthorError({ ...authrEror });
    }
  }
  function handleStatementInput(e, type) {
    const data = statements;
    if (type === "conflictDetail") {
      data.conflictDetail = e;
    } else if (type === "manuscriptVersionDetail") {
      data.manuscriptVersionDetail = e;
    }
    setstatements({ ...data });
  }
  function handleStatementScriptChange(e) {
    const data = statements;
    if (e.target.id === "scriptversionyes") data.manuscriptVersion = "yes";
    else data.manuscriptVersion = "no";
    setstatements({ ...data });
  }
  function handleStatementConflictChange(e) {
    const data = statements;
    if (e.target.id === "conflictyes") data.commercialFinanceConflict = "2";
    else data.commercialFinanceConflict = "1";
    setstatements({ ...data });
  }
  function handleAcceptStatementCheckbox(e) {
    const data = statements;
    let val = false;
    if (e.target.checked) {
      val = true;
      data.acceptAllError = "";
    }

    data.checkedCondition = val;
    setstatements({ ...data });
  }
  function handleFundingCheckbox(e) {
    const data = fundingDetails;
    let val = false;
    if (e.target.checked) val = true;
    data.noFundingRecevied = val;

    if (val === true) {
      data.name[0].val = "";
      data.email[0].val = "";
      data.phone[0].val = "";
      data.fundingAgency[0].val = "";
      data.fundingAgencyUrl[0].val = "";
      data.grantAmount[0].val = "";
      data.fundingInformation = "";
      const error = fundingDetailsError;
      error.nameError = [];
      error.emailError = [];
      error.phoneError = [];
      error.fundingAgencyError = [];
      error.fundingAgencyUrlError = [];
      error.grantAmountError = [];
      error.fundingInformationError = "";
      setfundingDetailsError({ ...error });
    }
    setfundingDetails({ ...data });
  }
  function handleReviewerInput(e, index, type) {
    const data = reviewerDetails;

    const dataError = reviewerDetailsError;
    if (type === "reviewername") {
      data.name[index].val = e.target.value;
      dataError.nameError[index] = "";
    } else if (type === "email") {
      data.email[index].val = e.target.value;
      dataError.emailError[index] = "";
    } else if (type === "university") {
      data.university[index].val = e.target.value;
      dataError.universityError[index] = "";
    } else if (type === "department") {
      data.department[index].val = e.target.value;
    } else if (type === "orcidId") {
      data.orcidId[index].val = e.target.value;
    } else if (type === "additionalComment") {
      data.additionalComment[index].val = e;
    }
    setreviewerDetailsError({ ...dataError });
    setreviewerDetails({ ...data });
  }
  function handleSelectChangesReviewer(e, index, type) {
    const data = reviewerDetails;
    const dataError = reviewerDetailsError;
    if (type === "title") {
      data.title[index].val = e;
      dataError.titleError[index] = "";
    } else if (type === "specialization") {
      data.specialization[index].val = e;
      dataError.specializationError[index] = "";
    }
    setreviewerDetailsError({ ...dataError });
    setreviewerDetails({ ...data });
  }
  function handleConference(e, type) {
    const data = conference;
    if  (type === "nameConference") {
      data.conferenceName = e.target.value;
      setconferenceError(errors => ({ ...errors, conferenceName: "" }));
    } else if (type === "city") {
      data.city = e.target.value;
      setconferenceError(errors => ({ ...errors, city: "" }));
    } else if (type === "urlConference") {
      data.url = e.target.value;
      setconferenceError(errors => ({ ...errors, url: "" }));
    } else if (type === "dateConferencePresentation") {
      data.date = e.target.value;
      setconferenceError(errors => ({ ...errors, date: "" }));
    } else if (type === "nameConferenceChair") { 
      data.conferenceChairName = e.target.value;
      setconferenceError(errors => ({ ...errors, conferenceChairName: "" }));
    } else if (type === "emailConferenceChair") {
      data.conferenceChairiEmail = e.target.value;
      setconferenceError(errors => ({ ...errors, conferenceChairiEmail: "" }));
    } else if (type === "nameTrackChair") {
      data.trackChairName = e.target.value;
      setconferenceError(errors => ({ ...errors, trackChairName: "" }));
    } else if (type === "emailTrackChair") {
      data.trackChairEmail = e.target.value;
      setconferenceError(errors => ({ ...errors, trackChairEmail: "" }));
    }
   
    // console.log(type);
    // console.log(e.target.value);
    setconference({ ...data });
    // console.log("conference",conference);
  }
  const handledatepicker = (date, dateString) => {
    const data = conference;
    data.date = dateString;
    setconferenceError(errors => ({ ...errors, date: "" }));
    // console.log(date, dateString);
    setconference({ ...data });
  };
  function handleFundingInput(e, index, type) {
    const data = fundingDetails;
    const dataError = fundingDetailsError;
    if (type === "fundingInformation") {
      data.fundingInformation = e;
      dataError.fundingInformationError = "";
    } else if (type === "fundingAgency") {
      data.fundingAgency[index].val = e.target.value;
      dataError.fundingAgencyError[index] = "";
    } else if (type === "fundingAgencyUrl") {
      data.fundingAgencyUrl[index].val = e.target.value;
      dataError.fundingAgencyUrlError[index] = "";
    } else if (type === "grantAmount") {
      data.grantAmount[index].val = e.target.value;
      dataError.grantAmountError[index] = "";
    } else if (type === "name") {
      data.name[index].val = e.target.value;
      dataError.nameError[index] = "";
    } else if (type === "email") {
      data.email[index].val = e.target.value;
      dataError.emailError[index] = "";
    } else if (type === "phone") {
      data.phone[index].val = e.target.value;
      dataError.phoneError[index] = "";
    }
    setfundingDetailsError({ ...dataError });
    setfundingDetails({ ...data });
  }

  function addMoreAuthor() {
    const data = authorCount;
    const author = authorContent;
    author.authorTitle.push({ val: "" });
    author.authorname.push({ val: "" });
    author.authorEmail.push({ val: "" });
    author.authorUniversity.push({ val: "" });
    author.authorDepartment.push({ val: "" });
    author.authorOrcidID.push({ val: "" });
    setauthorContent({ ...author });
    data.push(authorCount.length);
    setauthorCount([...data]);
  }

  function addReviwer() {
    const data = reviewerCount;
    const details = reviewerDetails;
    data.push(reviewerCount.length);
    setreviewerCount([...data]);
    details.title.push({ val: "" });
    details.email.push({ val: "" });
    details.name.push({ val: "" });
    details.university.push({ val: "" });
    details.orcidId.push({ val: "" });
    details.department.push({ val: "" });
    details.specialization.push({ val: "" });
    details.additionalComment.push({ val: "" });
    setreviewerDetails({ ...details });
  }
  function addMoreFunder() {
    const data = fundingCount;
    const fnd = fundingDetails;
    fnd.name.push({ val: "" });
    fnd.email.push({ val: "" });
    fnd.phone.push({ val: "" });
    fnd.fundingAgency.push({ val: "" });
    fnd.grantAmount.push({ val: "" });
    fnd.fundingAgencyUrl.push({ val: "" });

    setfundingDetails({ ...fnd });
    data.push(fundingCount.length);
    setfundingCount([...data]);
  }
  function removeFundingInputs(key) {
    const data = fundingCount;
    const details = fundingDetails;
    const dataError = fundingDetailsError;
    data.splice(key, 1);
    setfundingCount([...data]);
    if (dataError.nameError[key]) {
      dataError.nameError[key] = "";
      dataError.emailError[key] = "";
      dataError.phoneError[key] = "";
      dataError.fundingAgencyError[key] = "";
      dataError.fundingAgencyUrlError[key] = "";
      dataError.grantAmountError[key] = "";
    }

    setfundingDetailsError({ ...dataError });
    if (details.name[key]) {
      details.name.splice(key, 1);
    }
    if (details.email[key]) {
      details.email.splice(key, 1);
    }
    if (details.phone[key]) {
      details.phone.splice(key, 1);
    }
    if (details.fundingAgency[key]) {
      details.fundingAgency.splice(key, 1);
    }
    if (details.fundingAgencyUrl[key]) {
      details.fundingAgencyUrl.splice(key, 1);
    }
    if (details.grantAmount[key]) {
      details.grantAmount.splice(key, 1);
    }
    setfundingDetails({ ...details });
  }
  function removeAuthorInputs(key) {
    const data = authorCount;
    const details = authorContent;
    const dataError = authorError;
    data.splice(key, 1);
    setauthorCount([...data]);
    if (dataError.authorTitleError[key]) {
      dataError.authorTitleError[key] = "";
      dataError.authornameError[key] = "";
      dataError.authorEmailError[key] = "";
      dataError.authorUniversityError[key] = "";
      dataError.authorDepartmentError[key] = "";
    }

    setauthorError({ ...dataError });
    if (details.authorTitle[key]) {
      details.authorTitle.splice(key, 1);
    }
    if (details.authorname[key]) {
      details.authorname.splice(key, 1);
    }

    if (details.authorEmail[key]) {
      details.authorEmail.splice(key, 1);
    }
    if (details.authorUniversity[key]) {
      details.authorUniversity.splice(key, 1);
    }
    if (details.authorDepartment[key]) {
      details.authorDepartment.splice(key, 1);
    }
    setauthorContent({ ...details });
  }
  function handleauthorInput(e, key, type) {
    const details = authorContent;
    const dataError = authorError;

    if (type === "title") {
      details.authorTitle[key].val = e;
      if (e !== "") dataError.authorTitleError[key] = "";
    } else if (type === "name") {
      details.authorname[key].val = e.target.value;
      if (e.target.value !== "") dataError.authornameError[key] = "";
    } else if (type === "email") {
      details.authorEmail[key].val = e.target.value;
      if (e.target.value !== "") dataError.authorEmailError[key] = "";
    } else if (type === "university") {
      details.authorUniversity[key].val = e.target.value;
      if (e.target.value !== "") dataError.authorUniversityError[key] = "";
    } else if (type === "department") {
      details.authorDepartment[key].val = e.target.value;
      if (e.target.value !== "") dataError.authorDepartmentError[key] = "";
    } else if (type === "orcid") {
      details.authorOrcidID[key].val = e.target.value;
    } else if (type === "contribution") {
      details.contributionStatement = e;
      if (e !== "") dataError.contributionStatementError = "";
    }
    setauthorContent({ ...details });
    setauthorError({ ...dataError });
  }
  function handleAccordian(key) {
    if (defaultActiveKey === key) setdefaultActiveKey("");
    else setdefaultActiveKey(key);
  }
  function saveAndPay(type) {
    if (defaultActiveKey === "0") {
      let IsError = false;
      if (
        preValues.journal === "" ||
        preValues.subjectId === "" ||
        (preValues.articleType === "" &&
          subjects.completedTabs.mySubmission === false)
      ) {
        IsError = true;
      }

      if (
        isSocityJournal === false &&
        preValues.disciplineType !== "646b3a74636258312d34abcf"
      ) {
        const pre = preValues;
        if (preValues.secondaryJournalName === "") {
          pre.secondaryJournalNameError = "Please select secondary journal";
          IsError = true;
        }

        if (preValues.secondarySubject._id === "") {
          IsError = true;
          pre.secondarySubjectError = "Please select secondary subject";
        }
        if (preValues.secondarySpecialization === "") {
          IsError = true;
          pre.secondarySpecializationError =
            "Please select secondary specialization";
        }
        setpreValues({ ...pre });
      }
      
      else if(isSocityJournal===true || preValues.disciplineType === "646b3a74636258312d34abcf")
      {
        preValues.secondarySubject='';
      }
      if (
        filterSubjectName(specialization, preValues.specializationId) ===
        otherSubjectTitle
      ) {
        IsError = true;
        setsubjectApproval(true);
      }

      if (IsError === false) { 
        subjects.saveAndPay(preValues, type, defaultActiveKey);
        if(preValues.articleType != "664aed76ada7b4f743b760a9"){
        setdefaultActiveKey("2");
        }else{
          setdefaultActiveKey("1");
        }
      }
    } else if (defaultActiveKey === "2") {
      if (reviewerDetails.recommendReviwer === false) {
        const data = reviewerDetails;
        data.reviewType = preValues.reviewType;

        data.additionalComment = [{ val: "" }];
        data.department = [{ val: "" }];
        data.email = [{ val: "" }];
        data.name = [{ val: "" }];
        data.specialization = [{ val: "" }];
        data.title = [{ val: "" }];
        data.university = [{ val: "" }];
        subjects.saveAndPay(data, type, defaultActiveKey);
        setdefaultActiveKey("2");
      } else {
        const isValidREview = validateReview();
        if (reviewerCount?.length <= 4) {
          setpopupError(
            "Please add a minimum five Peer Reviewers to proceed."
          );
          setincompleteInfoPopup(true);
        }
        else if (isValidREview === false) {
          const data = reviewerDetails;
          data.reviewType = preValues.reviewType;
          subjects.saveAndPay(data, type, defaultActiveKey);
          setdefaultActiveKey("3");
        } else {
          setpopupError(
            "Please provide complete information about recommended reviewer."
          );
          setincompleteInfoPopup(true);
        }
      }
    } else if (defaultActiveKey === "3") {
      const isValidAuthor = validateAuthor();
      if (isValidAuthor === false) {
        const data = authorContent;
        subjects.saveAndPay(data, type, defaultActiveKey);
        setdefaultActiveKey("4");
      } else {
        setpopupError(
          "Please provide complete information about the Primary Author."
        );
        setincompleteInfoPopup(true);
      }
    } else if (defaultActiveKey === "4") {
      if (type === 3) {
        const data = fundingDetails;
        data.noFundingRecevied = false;

        subjects.saveAndPay(data, type, defaultActiveKey);
        setdefaultActiveKey("4");
      } else {
        const isValidAuthor = validateFunding();
        if (isValidAuthor === false) {
          const data = fundingDetails;

          subjects.saveAndPay(data, type, defaultActiveKey);
          setdefaultActiveKey("5");
        } else {
          setpopupError(
            "Please provide complete information about funding details."
          );
          setincompleteInfoPopup(true);
        }
      }
    } else if (defaultActiveKey === "5") {
      if (statements.checkedCondition === false) {
        const error = statements;
        error.acceptAllError = "Please accept term and conditions";
        setstatements({ ...error });
        setpopupError("Please provide complete information about statement.");
        setincompleteInfoPopup(true);
      } else {
        subjects.saveAndPay(statements, type, defaultActiveKey);
        setdefaultActiveKey("6");
      }
    } else if (defaultActiveKey === "1") {
      let IsError = false;
        
        if (conference.conferenceName === "") {
          setconferenceError(errors => ({ ...errors, conferenceName: "Conference Name is required" }));
          IsError = true;
        } else if (conference.city === "") {
          setconferenceError(errors => ({ ...errors, city: "City is required" }));
          IsError = true;
        } else if (conference.country === "") {
          setconferenceError(errors => ({ ...errors, country: "Country is required" }));
          IsError = true;
        } else if (conference.url === "") {
          setconferenceError(errors => ({ ...errors, url: "Url is required" }));
          IsError = true;
        } else if (conference.date === "") {
          setconferenceError(errors => ({ ...errors, date: "Date is required" }));
          IsError = true;
        } else if (conference.conferenceChairName === "") {
          setconferenceError(errors => ({ ...errors, conferenceChairName: "Name is required" }));
          IsError = true;
        } else if (conference.conferenceChairiEmail === "") {
          setconferenceError(errors => ({ ...errors, conferenceChairiEmail: "Email is required" }));
          IsError = true;
        } else if (conference.trackChairName === "") {
          setconferenceError(errors => ({ ...errors, trackChairName: "Name is required" }));
          IsError = true;
        } else if (conference.trackChairEmail === "") {
          setconferenceError(errors => ({ ...errors, trackChairEmail: "Email is required" }));
          IsError = true;
        } 
        
        if (preValues.articleType != "664aed76ada7b4f743b760a9") {
          setdefaultActiveKey("2");
        }else{
      if (type === 3) {
        const data = conference;
        

        if (IsError === false) {
        subjects.saveAndPay(data, type, defaultActiveKey);
        setdefaultActiveKey("2");
        }
       
      } else {
        // const isValidAuthor = validateFunding();
        // if (isValidAuthor === false) {
          const data = conference;
          if (IsError === false) {
          subjects.saveAndPay(data, type, defaultActiveKey);
          setdefaultActiveKey("2");
          }
         else {
          setpopupError(
            "Please provide complete information about Conference Paper"
          );
          setincompleteInfoPopup(true);
        }
      }
    }
    }

  }
  function validateFunding() {
    const dataError = fundingDetailsError;

    let isError = false;
    if (fundingDetails.noFundingRecevied === false) {
      for (let i = 0; i < fundingCount.length; i++) {
        if (!fundingDetails.name[i] || fundingDetails.name[i].val === "") {
          isError = true;
          dataError.nameError[i] = `Please enter name`;
        } else {
          dataError.nameError[i] = "";
        }
        if (!fundingDetails.email[i] || fundingDetails.email[i].val === "") {
          isError = true;
          dataError.emailError[i] = `Please enter email`;
        } else if (!validateEmail(fundingDetails.email[i].val)) {
          isError = true;
          dataError.emailError[i] = `Please enter valid email`;
        } else {
          dataError.emailError[i] = "";
        }
        if (!fundingDetails.phone[i] || fundingDetails.phone[i].val === "") {
          isError = true;
          dataError.phoneError[i] = `Please enter phone number`;
        } else {
          dataError.phoneError[i] = "";
        }
        if (
          !fundingDetails.fundingAgency[i] ||
          fundingDetails.fundingAgency[i].val === ""
        ) {
          isError = true;
          dataError.fundingAgencyError[i] = `Please enter agency name`;
        } else {
          dataError.fundingAgencyError[i] = "";
        }
        if (
          !fundingDetails.fundingAgencyUrl[i] ||
          fundingDetails.fundingAgencyUrl[i].val === ""
        ) {
          isError = true;
          dataError.fundingAgencyUrlError[i] = `Please enter agency url`;
        } else if (!checkUrl(fundingDetails.fundingAgencyUrl[i].val)) {
          isError = true;
          dataError.fundingAgencyUrlError[i] = `Please enter valid url`;
        } else {
          dataError.fundingAgencyUrlError[i] = "";
        }
        if (
          !fundingDetails.grantAmount[i] ||
          fundingDetails.grantAmount[i].val === ""
        ) {
          isError = true;
          dataError.grantAmountError[i] = `Please enter amount`;
        }
        if (fundingDetails.fundingInformation === "") {
          isError = true;
          dataError.fundingInformationError = `Please enter funding information`;
        }
        setfundingDetailsError({ ...dataError });
      }
    }
    return isError;
  }
  function validateReview() {
    let isError = false;
    const data = reviewerDetailsError;
    for (var i = 0; i < reviewerCount.length; i++) {
      if(reviewerDetails.name[0].val != ""){
      if (!reviewerDetails.title[i] || reviewerDetails.title[i].val === "") {
        isError = true;
        data.titleError[i] = `Please enter title`;
      } else {
        data.titleError[i] = "";
      }
      if (!reviewerDetails.name[i] || reviewerDetails.name[i].val === "") {
        isError = true;
        data.nameError[i] = "Please enter name";
      } else {
        data.nameError[i] = "";
      }
      if (!reviewerDetails.email[i] || reviewerDetails.email[i].val === "") {
        isError = true;
        data.emailError[i] = "Please enter email";
      } else if (!validateEmail(reviewerDetails.email[i].val)) {
        isError = true;
        data.emailError[i] = `Please enter valid email`;
      } else {
        data.emailError[i] = "";
      }
      if (
        !reviewerDetails.university[i] ||
        reviewerDetails.university[i].val === ""
      ) {
        isError = true;
        data.universityError[i] =
          "Please enter affiliated institution/university";
      } else {
        data.universityError[i] = "";
      }
      if (
        !reviewerDetails.specialization[i] ||
        reviewerDetails.specialization[i].val === ""
      ) {
        isError = true;
        data.specializationError[i] = "Please select specialization";
      } else {
        data.specializationError[i] = "";
      }
      setreviewerDetailsError({ ...data });
  }
    }
    return isError;
  }
  function validateAuthor() {
    const dataError = authorError;

    let isError = false;
    for (let i = 0; i < authorCount.length; i++) {
      if (
        !authorContent.authorTitle[i] ||
        authorContent.authorTitle[i].val === ""
      ) {
        isError = true;
        dataError.authorTitleError[i] = `Please enter title`;
      } else {
        dataError.authorTitleError[i] = "";
      }
      if (
        !authorContent.authorname[i] ||
        authorContent.authorname[i].val === ""
      ) {
        isError = true;
        dataError.authornameError[i] = `Please enter first name`;
      } else {
        dataError.authornameError[i] = "";
      }

      if (
        !authorContent.authorEmail[i] ||
        authorContent.authorEmail[i].val === ""
      ) {
        isError = true;
        dataError.authorEmailError[i] = `Please enter email`;
      } else if (!validateEmail(authorContent.authorEmail[i].val)) {
        isError = true;
        dataError.authorEmailError[i] = `Please enter valid email`;
      } else {
        dataError.authorEmailError[i] = "";
      }
      if (
        !authorContent.authorUniversity[i] ||
        authorContent.authorUniversity[i].val === ""
      ) {
        isError = true;
        dataError.authorUniversityError[i] = `Please enter university name`;
      } else {
        dataError.authorUniversityError[i] = "";
      }
      if (
        !authorContent.contributionStatement ||
        authorContent.contributionStatement === ""
      ) {
        isError = true;
        dataError.contributionStatementError = `Please enter contribution statement`;
      } else if (authorContent.contributionStatement.split(" ").length > 300) {
        isError = true;
        data.abstractError = "300 words are allowed";
      } else {
        dataError.contributionStatementError = "";
      }
      setauthorError({ ...dataError });
    }
    return isError;
  }

  const successfullyContact = () => {
    setneedHelpPopup(false);
  };
  function handleOtherSubject() {
    handleGetSpecialization();
  }
// console.log("defaultActiveKey",subjects.completedTabs); 
  return (
    <div className={subjects.isUpdateNewVersion===true?'peerArticlePage readonlyDetailPage':'peerArticlePage'}>
      <div className="customAccordion">
        <Accordion activeKey={defaultActiveKey} flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => handleAccordian("0")}>
              <p>My submission details <br/><small className="sub_headline_submission">Please complete all the sections on this page and pay the registration fee to start writing the article.</small> </p>
              {subjects.completedTabs.mySubmission === false && (
                <b>To be completed</b>
              )}
              {subjects.completedTabs.mySubmission === true && (
                <b className="completed">Completed</b>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div className="tabCenter">
                <div className="row">
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm">
                        Journal Type<sup className="supStar">*</sup>
                      </label>
                      <div className="selectBox">
                        <Select
                          selectedValue={preValues.journalType}
                          options={subjects.journalType?.map(
                            (val) => val?.value
                          )}
                          optionValues={subjects.journalType?.map(
                            (val) => val?.key
                          )}
                          name="journalType"
                          placeholder=""
                          handleChange={(e) =>
                            handleSelectChanges(e, "journalType")
                          }
                          required={true}
                          mandatory={false}
                          disabled={subjects.completedTabs.review}
                        />
                      </div>
                    </div>
                  </div>
                 
                  {isSocityJournal === false &&
                    preValues.journalType !== "" && (
                      <div className="col-md-6">
                        <div className="fieldWrap">
                          <label class="labelForm">
                            Discipline Type<sup className="supStar">*</sup>
                          </label>
                          <div className="selectBox">
                            <Select
                              selectedValue={preValues.disciplineType} 
                              options={subjects.desciplineMaster?.map(
                                (val) => val?.disciplineType
                              )}
                              optionValues={subjects.desciplineMaster?.map(
                                (val) => val?._id
                              )}
                              name="disciplineType"
                              placeholder=""
                              handleChange={(e) =>
                                handleSelectChanges(e, "disciplineType")
                              }
                              required={true}
                              mandatory={false}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {preValues.journalType !== "" && (
                <div className="journalWrapTab">
                  <div className="row">
                    <div className="col-md-6">
                      <span className="commanTitle02">
                        Primary Journal<sup className="supStar">*</sup>
                      </span>
                    </div>
                    <div className="col-md-6 aboutJour">
                      <a href="">About the Journal</a>
                    </div>
                  </div>
                  <div className="tabCenter">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="fieldWrap">
                          <label className="labelForm">
                            Journal Name<sup className="supStar">*</sup>
                          </label>
                          {preValues.journalType !== "" && (
                            <div className="selectBox">
                              <Select
                                selectedValue={preValues.journal}
                                options={journalOptionsFirst?.map(
                                  (val) => val.journalName
                                )}
                                optionValues={journalOptionsFirst?.map(
                                  (val) => val.journalName
                                )}
                                name="journalName"
                                placeholder=""
                                handleChange={(e) =>
                                  handleSelectChanges(e, "journal")
                                }
                                required={true}
                                mandatory={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fieldWrap">
                          <label className="labelForm">
                            Discipline<sup className="supStar">*</sup>
                          </label>
                          {isSocityJournal === false &&  preValues.journal==journalTypes.yogaJournal &&
                            preValues.journalType !== ""  && (
                              <div className="selectBox">
                                <Select
                                  selectedValue={preValues.subjectId}
                                  options={descplineSubject?.map(
                                    (val) => val?.val
                                  )}
                                  optionValues={descplineSubject?.map(
                                    (val) => val?.key
                                  )}
                                  name="subject"
                                  placeholder=""
                                  handleChange={(e) =>
                                    handleSelectChanges(e, "subject")
                                  }
                                  required={true}
                                  mandatory={false}
                                />
                              </div>
                            )}
                          {(isSocityJournal === true || preValues.journal!=journalTypes.yogaJournal) &&
                            preValues.journalType !== "" &&  (
                              <div className="inputTextBox">
                                <Input
                                  errors=""
                                  name="subject"
                                  readOnly={true}
                                  className="form-control grey-input"
                                  type="text"
                                  required={"required"}
                                  value={preValues.subject}
                                  placeholder="Discipline"
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fieldWrap">
                          <label className="labelForm ">Subject<sup className="supStar">*</sup> </label>
                          {isSocityJournal === false &&
                            preValues.journalType !== "" && (
                              <>
                                <div className="selectBox subjectDropDown">
                                  <Select
                                    selectedValue={preValues.specializationId}
                                    options={specialization?.map(
                                      (val) => val?.value
                                    )}
                                    optionValues={specialization?.map(
                                      (val) => val?.key
                                    )}
                                    name="specialization"
                                    placeholder="Search and select"
                                    handleChange={(e) =>
                                      handleSelectChanges(e, "specialization")
                                    }
                                    required={true}
                                    mandatory={false}
                                    isSearchable={true}
                                  />
                                </div>
                                
                                {otherSubject?.map((sub) => {
                                  if (sub?.status === "Pending") {
                                    return (
                                      <div className="custom_input approvalInput">
                                        <Input
                                          readOnly
                                          className={
                                            "form-control active-input specialization-input"
                                          }
                                          label={""}
                                          value={sub?.specialization}
                                          postTextClass={"Pending approval"}
                                          disabled
                                        />
                                      </div>
                                    );
                                  } else return null;
                                })}
                              </>
                            )} 
                          
                          {isSocityJournal === true &&
                            preValues.journalType !== "" && (
                              <div className="inputTextBox">
                                <Input
                                  errors=""
                                  name="specialization"
                                  readOnly={true}
                                  className="form-control grey-input"
                                  type="text"
                                  required={"required"}
                                  value={preValues.specialization}
                                  placeholder="Subject"
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fieldWrap">
                          <label className="labelForm">
                            Article Type<sup className="supStar">*</sup>
                          </label>
                          <div className="selectBox">
                            <Select
                              selectedValue={preValues.articleType}
                              options={articleTypeListf?.map(
                                (val) => val?.value
                              )}
                              optionValues={articleTypeListf?.map(
                                (val) => val?.key
                              )}
                              name="articleTypeList"
                              placeholder=""
                              handleChange={(e) =>
                                handleSelectChanges(e, "articleTypeList")
                              }
                              required={true}
                              mandatory={false}
                            />
                            <div className="somethingMind">
                              Something else on your mind?{" "}
                              <a href="mailto:contactus@vikramshilaedu.in">
                                contactus@vikramshilaedu.in
                              </a>
                            </div>
                            {preValues.articleType !== "" &&
                              articleTypeDesc.isShow === true && (
                                <div className="perspectiveToolWrap ">
                                  <h2>
                                    {articleTypeDesc.name}{" "}
                                  </h2>
                                  <div className="perspectiveTool">
                                    <b className="cross-icon" onClick={closegetArticleTypeDesc}></b>
                                    <b className="article-type">{articleTypeDesc.name}</b>
                                    <p>{articleTypeDesc.desc} </p>
                                    <div className="perspectiveFoot">
                                  <i>GUIDELINES (MAX. LIMITS)</i>   
                                  <span>Abstract length: Up to 500 words</span>
                                </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {(isSocityJournal === false  && (preValues.disciplineType==='6454a75cab3a64f719e681c1' || preValues.disciplineType==='6454a75cab3a64f719e681c2')) && <div className="otherSubjectBorder">
                      <div className="questionBox">
                        <span>“Other” subject option can only be selected as a part of primary journal.</span>
                        </div>
                    </div>}
                  </div>
                  

                  {/*********Secondry Journal*********/}
                  {isSocityJournal === false &&
                    preValues.journalType !== "" &&
                    preValues.disciplineType !== "646b3a74636258312d34abcf" && (
                      <>
                        <div className="row">
                          <div className="col-md-12 ">
                            <span className="commanTitle02 pt-3">
                              Secondary Journal<sup className="supStar">*</sup>
                            </span>
                          </div>
                        </div>
                        <div className="tabCenter">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="fieldWrap">
                                <label className="labelForm">
                                  Journal Name<sup className="supStar">*</sup>
                                </label>
                                <div className="selectBox">
                                  <Select
                                    selectedValue={
                                      preValues.secondaryJournalName
                                    } //preValues.journal
                                    options={journalOptionsSecondry.map(
                                      (val) => val.journalName
                                    )}
                                    optionValues={journalOptionsSecondry?.map(
                                      (val) =>
                                        val.journalName
                                    )}
                                    name="journalName"
                                    placeholder=""
                                    handleChange={(e) =>
                                      handleSelectChanges(
                                        e,
                                        "secondaryJournalName"
                                      )
                                    }
                                    required={true}
                                    mandatory={false}
                                  />
                                  {preValues.secondaryJournalNameError !==
                                    "" && (
                                    <div className="label-error">
                                      {preValues.secondaryJournalNameError}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="fieldWrap">
                                <label className="labelForm">
                                  Discipline<sup className="supStar">*</sup>
                                </label>
                                {preValues.secondaryJournalName===journalTypes.yogaJournal&&
                                preValues.journalType !== "" && (
                              <div className="selectBox">
                                  <Select
                                    selectedValue={preValues.secondarySubject?._id}
                                    options={descplineSubjectSec?.map(
                                      (val) => val?.val
                                    )}
                                    optionValues={descplineSubjectSec?.map(
                                      (val) => val?.key
                                    )}
                                    name="subject"
                                    placeholder=""
                                    handleChange={(e) =>
                                      handleSelectChanges(e, "secondarySubject")
                                    }
                                    required={true}
                                    mandatory={false}
                                  />
                                  {preValues.secondarySubjectError !== "" && (
                                    <div className="label-error">
                                      {preValues.secondarySubjectError}
                                    </div>
                                  )}
                                </div>)}
                                {preValues.secondaryJournalName!==journalTypes.yogaJournal &&
                              preValues.journalType !== "" && (
                              <div className="inputTextBox">
                                <Input
                                  errors=""
                                  name="specialization"
                                  readOnly={true}
                                  className="form-control grey-input"
                                  type="text"
                                  required={"required"}
                                  value={preValues.secondarySubjectName}
                                  placeholder="Discipline"
                                />
                              </div>
                            )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="fieldWrap">
                                <label className="labelForm">Subject</label>
                                <div className="selectBox">
                                  <Select
                                    selectedValue={ preValues.secondarySpecialization?._id??preValues.secondarySpecialization}
                                    options={secondrySpecialization
                                      ?.filter(
                                        (element) => element?.value !== otherSubjectTitle
                                      )
                                      ?.map((val) => val?.value)}
                                    optionValues={secondrySpecialization?.map(
                                      (val) => val?.key
                                    )}
                                    name="specialization"
                                    placeholder="Search and select"
                                    handleChange={(e) =>
                                      handleSelectChanges(
                                        e,
                                        "secondarySpecialization"
                                      )
                                    }
                                    required={true}
                                    mandatory={false}
                                    isSearchable={true}
                                  />
                                  {preValues.secondarySpecializationError !==
                                    "" && (
                                    <div className="label-error">
                                      {preValues.secondarySpecializationError}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {/***********End Secondry Journla**************/}
                </div>
              )}
              <div className="guidelinesWrap mt-5">
                <div className="toolWrap imgControl">
                  <div className="crossrefWrap">
                    <span>
                      <img src={crossref} />
                    </span>
                    <div className="guidelText">
                      <Link
                        to={{ pathname: "/authorGuidelines" }}
                        target="_blank"
                      >
                        Author Guidelines
                      </Link>
                    </div>
                  </div>
                </div>
                <span>
                  <sup className="supStar">*</sup>Mandatory
                </span>
              </div>
              <div className="guidelines-save-work">
                <sup className="supStar">*</sup>Please save and continue your work using the button at the bottom of the page to go to the next section
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {preValues.articleType === '664aed76ada7b4f743b760a9' ? (
          <Accordion.Item
            eventKey="1"
            className={
              subjects.completedTabs.mySubmission === false &&
              subjects.completedTabs.review === false
                ? "disabled"
                : ""
            }
            

          >
            <Accordion.Header onClick={() => handleAccordian("1")}>
              <p>Conference Details
                <sup className="supStar">*</sup>
              </p>
              <b
                className={
                  subjects.completedTabs.conference === false 
                    ? ""
                    : "completed"
                }
              >
                {subjects.completedTabs.conference === false
                  ? "To be completed"
                  : "Completed"}
              </b>
            </Accordion.Header>
            <Accordion.Body className="pt-0 pb-0">
              <div className="tabCenter">
                <div className="row">
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm mt-2">
                      Name of Conference <sup className="supStar">*</sup>
                      </label>
                            <Input
                             errors={conferenceError?.conferenceName}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "nameConference")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.conferenceName}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm mt-2">
                      City  <sup className="supStar">*</sup>
                      </label>
                            <Input
                               errors={conferenceError?.city}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "city")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.city}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm mt-2">
                      Country <sup className="supStar">*</sup>
                      </label>
                      <div className="selectBox py-2 w-100">
                            <Select
                              selectedValue={conference.country}
                              options={countrylist?.map(
                                (val) => val?.name
                              )}
                              optionValues={countrylist?.map(
                                (val) => val?.name
                              )}
                              name="country"
                              placeholder=""
                              handleChange={(e) =>
                                handleSelectChanges(e, "countrylist")
                              }
                              required={true}
                              mandatory={false}
                            />
                          </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm mt-2">
                      URL of Conference Page   <sup className="supStar">*</sup>
                      </label>
                            <Input
                             errors={conferenceError?.url}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "urlConference")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.url}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm mt-2">
                      Date of Conference Paper Presentation <sup className="supStar">*</sup>
                      </label>
                            <div className="customdatepick py-2 w-100">
                            <DatePicker onChange={handledatepicker} getPopupContainer={(triggerNode) => {return triggerNode.parentNode; }} defaultValue={defaultValuedate} locale={buddhistLocale}   />
                            </div>
                    </div>
                  </div>
                </div>
                <span className="commanTitle02 d-block pt-5 pb-2">
                Conference Chair<sup className="supStar">*</sup>
                </span>
                <div className="row">
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm">
                      Name of Conference Chair   <sup className="supStar">*</sup>
                      </label>
                            <Input
                              errors={conferenceError?.conferenceChairName}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "nameConferenceChair")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.conferenceChairName}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm">
                      Email address of Conference Chair  <sup className="supStar">*</sup>
                      </label>
                            <Input
                              errors={conferenceError?.conferenceChairiEmail}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "emailConferenceChair")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.conferenceChairiEmail}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                </div>
                <span className="commanTitle02 d-block pt-5 pb-2">
                Track Chair<sup className="supStar">*</sup>
                      </span>
                <div className="row">
                  <div className="col-md-6">
                    
                    <div className="fieldWrap">
                      <label className="labelForm">
                      Name of Track Chair  <sup className="supStar">*</sup>
                      </label>
                            <Input
                               errors={conferenceError?.trackChairName}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "nameTrackChair")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.trackChairName}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fieldWrap">
                      <label className="labelForm">
                      Email address of Track Chair  <sup className="supStar">*</sup>
                      </label>
                            <Input
                               errors={conferenceError?.trackChairEmail}
                              name={`fname`}
                              handleChange={(e) =>
                                handleConference(e, "emailTrackChair")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={conference.trackChairEmail}
                              placeholder="Enter"
                              // readOnly={
                              //   (authorContent?.isMePrimaryAuthor === true &&
                              //     index === 0) ||
                              //   (authorContent?.isMePrimaryAuthor === false &&
                              //     index === 1)
                              // }
                            />
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          ) : null }
          <Accordion.Item
            eventKey="2"
            className={`${
              preValues.articleType === "664aed76ada7b4f743b760a9" 
                ? (subjects.completedTabs.conference === false ? "disabled" : "")
                : (subjects.completedTabs.mySubmission === false ? "disabled" : "")
            }`}
            
          >
            <Accordion.Header onClick={() => handleAccordian("2")}>
              <p>{preValues.articleType != '664aed76ada7b4f743b760a9' ? "Choice of Review Process":"Conference Paper Submission"}</p>
              {subjects.completedTabs.review === false && (
                <b>To be completed</b>
              )}
              {subjects.completedTabs.review === true && (
                <b className="completed">Completed</b>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div className="">
                {isSocityJournal === true && (
                  <p className="contentReview">
                    This journal only follows Pre-Publishing Double Anonymous
                    Peer Review.
                  </p>
                )}
                {isSocityJournal !== true && preValues.articleType != "664aed76ada7b4f743b760a9" &&  (
                  <p className="contentReview">
                    Select the review you would like your article to undergo. Please note that you cannot change your choice of review process after this stage.
                  </p>
                )}
                {preValues.articleType != '664aed76ada7b4f743b760a9' ? (
                <div className="row">
                  <div className="col-md-6 pr-5 monthRemove">
                    <div
                      className={
                        preValues.reviewType ===
                        "pre-publication peer review only"
                          ? "regardingChkBox active"
                          : "regardingChkBox"
                      }
                    >
                      <div className="regardingRadio">
                        <div className="radioButtonCustom">
                          <div className="infoTextIcon">
                            <RadioButton
                              label="Pre-Publishing Double Anonymous Peer Review"
                              name="reviewType"
                              labelClass={"pre-publication peer review only"}
                              id="pre-publication peer review only"
                              checked={
                                preValues.reviewType ===
                                "pre-publication peer review only"
                              }
                              handleChange={handleReviewTypeChange}
                              required
                              disabled={subjects.completedTabs.review === true}
                            />

                            <div className="customTool">
                              {["bottom"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Popover>
                                      <Popover.Body>
                                        <div className="popoverToolWrap">
                                          <p>
                                            <b>
                                              Pre-Publishing Double Anonymous
                                              Peer Review
                                            </b>
                                            refers to the evaluation process where independent experts in the relevant field critically assess research manuscripts without knowledge of the authors' identities. This double-blind approach ensures an unbiased and fair assessment, focusing solely on the scientific merit and validity of the research.
                                          </p>
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <img
                                    className="pe-cursor ps-1"
                                    src={active_info}
                                    alt="no img"
                                  />
                                </OverlayTrigger>
                              ))}
                            </div>
                          </div>
                          <span className="postReviewI">
                            (Includes Post Publication Open Review)
                          </span>
                        </div>
                      </div>
                      <div className="priceReview">
                        <ul>
                          <li className="priceReviewList">
                            <div className="priceReviewImg">
                              <i className="priceIcon"></i>
                              Article Processing Charge:
                            </div>
                            <div className="priceReviewRate">
                              <del className="active">
                                {ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[0]?.lastPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[0]?.lastPrice : APCData?.[0]?.lastPrice}
                              </del>
                            </div>
                          </li>
                          <li className="priceReviewList saveprice">
                            <div className="priceReviewImg">
                              <i className="saveIcon"></i>
                              Introductory Discount:
                            </div>
                            <div className="priceReviewRate">
                            {ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[0]?.saving
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[0]?.saving : APCData?.[0]?.saving}
                            </div>
                          </li>
                          <li className="priceReviewList">
                            <div className="priceReviewImg">
                              <i className="roducIcon"></i>
                              Introductory Price:
                            </div>
                            <div className="priceReviewRate">
                              <big>{ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[0]?.introductoryPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[0]?.introductoryPrice : APCData?.[0]?.introductoryPrice}</big>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="imp-info pt-0 pb-0">
                        <p>Important Information
                          <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap ">
                                      <p><sup className="supStar">*</sup><i>The article turn-around time is within 45 days.</i></p>
                                      <p><sup className="supStar">*</sup><i>The platform exercises the right to withdraw the offer anytime.</i></p>
                                      <p><sup className="supStar">*</sup><i>All discount applied to the original amount will be applied at the final invoice stage.</i></p>
                                      <p><b><sup className="supStar">*</sup>There is a one time registration charge of {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000"} that will be refunded to your wallet as soon as your article is published. This is to prevent bots from accessing our system.</b></p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pl-5 monthRemove">
                    {/* <div className="launchWrap ">
                      <div className="launch-offer blinkWrap">
                          <img src={launchOfferBand} alt="launch-offer" />
                      </div>
                    
                    <div className="customTool">
                      
                      {["bottom"].map((placement) => (
                        
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          
                          overlay={
                            <Popover>
                              
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  {
                                    ProfileDetails?.nationality === "withInIndia" ? (
                                      <>
                                      <p className="tagp mt-0">
                                        <strong>Our normal publishing process:</strong>
                                      </p>
                                      <p className="tagp mt-1">
                                        <span>1.</span> A one-time refundable fee of Rs 1000 is payable to
                                        access our publishing system. This is simply to retain the sanctity
                                        of our process and to ensure no bots or unknown content gets into
                                        our system. This amount is fully refunded to your{" "}
                                        <strong>Mudra Kosh</strong> (online wallet) if your article is
                                        rejected.
                                      </p>
                                      <p className="tagp">
                                        <span>2.</span> On acceptance of your article, you will receive a
                                        note asking you to pay the balance of the APC. This varies depending
                                        on whether you are eligible to a publishing grant or not.
                                      </p>
                                      <p className="tagp ">
                                        <strong>Launch offer:</strong>
                                      </p>
                                      <p className="tagp mt-1">
                                        <span>1.</span> You will still need to pay the initial fee of Rs.
                                        1000, however we will refund this when the article if published (or
                                        rejected).
                                      </p>
                                      <p className="tagp">
                                        <span>2.</span> We are currently waiving the balance amount payable
                                        – please use the code <span className="blue-text"><u>Welcome2023</u></span>{" "}
                                        This is an offer that is currently available for a short time only.
                                        Hurry! Publish with us now.
                                      </p>
                                      </>
                                    ) : (
                                      <>
                                      <p className="tagp mt-0">
                                        <strong>Our normal publishing process:</strong>
                                      </p>
                                      <p className="tagp mt-1">
                                        <span className="onetimerefund">1.</span> A one-time refundable fee
                                        of US$50 is payable to access our publishing system. This amount is
                                        to register you on the platform and confirm that you are a human
                                        accessing the system. This ensures that no bots or unknown content
                                        gets into our system.
                                      </p>
                                      <p className="tagp">
                                        This amount will be fully refunded to your account’s Mudra Kosh
                                        (online wallet) if your article is rejected. You can use this amount
                                        to write a new article or re-submit your article.
                                      </p>
                                      <p className="tagp">
                                        <span>2.</span> On acceptance of your article, you will receive an
                                        email asking you to pay the balance of the APC.
                                      </p>
                                      <p className="tagp ">
                                        <strong>Launch offer:</strong>
                                      </p>
                                      <p className="tagp mt-1">
                                        <span>1.</span> We are currently offering an opportunity to publish
                                        free of charge on our platform. However, you will still need to pay
                                        the initial fee of US$50. We will refund this to your account’s
                                        Mudra Kosh when the article is published <div className="d-inline-block">(or rejected)</div>.
                                      </p>
                                      <p className="tagp">
                                        <span>2.</span> We are currently waiving the balance amount payable
                                        – Please use the code{" "}
                                        <span className="blue-text"><u>Happiness2024</u></span> This is an offer
                                        that is currently available for a short time only. Hurry! Publish
                                        with us now.
                                      </p>
                                    </>
                                    )
                                  }
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                    </div> */}
                    <div
                      className={
                        preValues.reviewType ===
                        "only post-publication open review"
                          ? "regardingChkBox active"
                          : "regardingChkBox"
                      }
                    >
                      <div className="regardingRadio">
                        <div className="radioButtonCustom">
                          <div
                            className={
                              isSocityJournal === true
                                ? "infoTextIcon"
                                : "infoTextIcon"
                            }
                          >
                            <RadioButton
                              label="POST PUBLICATION OPEN PEER REVIEW"
                              name="reviewType"
                              labelClass={"post publication open Peer review"}
                              id="only post-publication open review"
                              checked={
                                preValues.reviewType ===
                                "only post-publication open review"
                              }
                              disabled={
                                // isSocityJournal ||
                                subjects.completedTabs.review === true
                              }
                              handleChange={handleReviewTypeChange}
                              required
                            />
                            <div className="customTool">
                              {["bottom"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Popover>
                                      <Popover.Body>
                                        <div className="popoverToolWrap ">
                                          <p>
                                            Post Publication Open Peer Review allows
                                            researchers to share their work
                                            openly with the scientific community
                                            and receive feedback{" "}
                                            <b>after publication</b>. It
                                            involves open and ongoing evaluation
                                            by experts and the wider academic
                                            community. 
                                          </p>
                                          <p className="mt-3 mb-0">This process aims to
                                            improve the quality and impact of
                                            research by promoting constructive
                                            criticism, diverse perspectives, and
                                            the identification of potential
                                            errors or shortcomings.</p>
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <img
                                    className="pe-cursor ps-1"
                                    src={active_info}
                                    alt="no img"
                                  />
                                </OverlayTrigger>
                              ))}
                            </div>
                          </div>
                          <span className="refundable-price blinkWrap">(Refundable {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000" } payable)</span>
                          <span>&nbsp;</span>
                        </div>
                      </div>
                      <div className="priceReview">
                        <ul>
                          <li className="priceReviewList">
                            <div className="priceReviewImg">
                              <i className="priceIcon"></i>
                              Article Processing Charge:
                            </div>
                            <div className="priceReviewRate">
                              <del>{ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[1]?.lastPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[1]?.lastPrice : APCData?.[1]?.lastPrice}</del>
                            </div>
                          </li>
                          <li className="priceReviewList saveprice">
                            <div className="priceReviewImg">
                              <i className="saveIcon"></i>
                              Introductory Discount:
                            </div>
                            <div className="priceReviewRate">
                            {ProfileDetails?.nationality === "withInIndia"
                                  ?"INR " +  APCData?.[1]?.saving
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[1]?.saving : APCData?.[1]?.saving}
                            </div>
                          </li>
                          <li className="priceReviewList">
                            <div className="priceReviewImg">
                              <i className="roducIcon"></i>
                              Introductory Price:
                            </div>
                            <div className="priceReviewRate">
                              <big>{ProfileDetails?.nationality === "withInIndia"
                                  ?"INR " +  APCData?.[1]?.introductoryPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[1]?.introductoryPrice : APCData?.[1]?.introductoryPrice}</big>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="imp-info pt-0 pb-0">
                        <p>Important Information
                          <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap ">
                                      <p><sup className="supStar">*</sup><i>The article turn-around time is within 30 days.</i></p>
                                      <p><sup className="supStar">*</sup><i>The platform exercises the right to withdraw the offer anytime.</i></p>
                                      <p><sup className="supStar">*</sup><i>All discount applied to the original amount will be applied at the final invoice stage.</i></p>
                                      <p><b><sup className="supStar">*</sup>There is a one time registration charge of {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000"} that will be refunded to your wallet as soon as your article is published. This is to prevent bots from accessing our system.</b></p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div></p>
                      </div>
                    </div>
                  </div>
                  </div>
                ) : (
                  <div className="row justify-content-center">
                    <div className="col-md-6 pl-5">
                    
                      <div
                        className={
                          preValues.reviewType ===
                            "only post-publication open review"
                            ? "regardingChkBox active"
                            : "regardingChkBox"
                        }
                      >
                        <div className="regardingRadio">
                          <div className="radioButtonCustom">
                            <div
                              className={
                                isSocityJournal === true
                                  ? "infoTextIcon"
                                  : "infoTextIcon"
                              }
                            >
                              <RadioButton
                                label="Conference Paper Submission"
                                name="reviewType"
                                labelClass={"Conference Paper Submission"}
                                id="Conference Paper Submission"
                                checked={true}
                                // disabled={
                                //   isSocityJournal ||
                                //   subjects.completedTabs.review === true
                                // }
                                handleChange={handleReviewTypeChange}
                                required
                              />
                              <div className="customTool">
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover>
                                        <Popover.Body>
                                          <div className="popoverToolWrap ">
                                          <p>
                                          Please note that conference papers will only undergo post publishing review after acceptance. </p>
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <img
                                      className="pe-cursor ps-1"
                                      src={active_info}
                                      alt="no img"
                                    />
                                  </OverlayTrigger>
                                ))}
                              </div>
                            </div>
                            {preValues.articleType != "664aed76ada7b4f743b760a9" &&  (
                              <>
                              <span className="refundable-price blinkWrap">(Refundable {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000"} payable)</span>
                              <span>&nbsp;</span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="priceReview">
                          <ul>
                            <li className="priceReviewList">
                              <div className="priceReviewImg">
                                <i className="priceIcon"></i>
                                Article Processing Charge:
                              </div>
                              <div className="priceReviewRate">
                                <del>{ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[3]?.lastPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[3]?.lastPrice : APCData?.[3]?.lastPrice}</del>
                              </div>
                            </li>
                            <li className="priceReviewList saveprice">
                              <div className="priceReviewImg">
                                <i className="saveIcon"></i>
                                Introductory Discount:
                              </div>
                              <div className="priceReviewRate">
                                {ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[3]?.saving
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[3]?.saving : APCData?.[3]?.saving}
                              </div>
                            </li>
                            <li className="priceReviewList">
                              <div className="priceReviewImg">
                                <i className="roducIcon"></i>
                                Introductory Price:
                              </div>
                              <div className="priceReviewRate">
                                <big>{ProfileDetails?.nationality === "withInIndia"
                                  ? "INR " + APCData?.[3]?.introductoryPrice
                                  : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[3]?.introductoryPrice : APCData?.[3]?.introductoryPrice}</big>
                              </div>
                            </li>
                          </ul>
                        </div>
                        {preValues.articleType != "664aed76ada7b4f743b760a9" &&  (
                              <>
                        <div className="imp-info pt-0 pb-0">
                          <p>Important Information
                            <div className="customTool">
                              {["bottom"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Popover>
                                      <Popover.Body>
                                        <div className="popoverToolWrap ">
                                          <p><sup className="supStar">*</sup><i>The article turn-around time is within 30 days.</i></p>
                                          <p><sup className="supStar">*</sup><i>The platform exercises the right to withdraw the offer anytime.</i></p>
                                          <p><sup className="supStar">*</sup><i>All discount applied to the original amount will be applied at the final invoice stage.</i></p>
                                          <p><b><sup className="supStar">*</sup>There is a one time registration charge of {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000"} that will be refunded to your wallet as soon as your article is published. This is to prevent bots from accessing our system.</b></p>
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <img
                                    className="pe-cursor ps-1"
                                    src={active_info}
                                    alt="no img"
                                  />
                                </OverlayTrigger>
                              ))}
                            </div></p>
                        </div>
                        </>
                            )}
                      </div>
                    </div>
                  </div>
                )}
                {subjects.prePaymentStatus === true && (
                  <div className="paymentSuccessWrap">
                    <div className="paymentSuccessText">
                      <img src={successful} /> Article Registration Payment
                      Successful
                    </div>
                    <div className="paymentSuccessTool">
                      Download Invoice
                      <div className="customTool">
                        {["bottom"].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <div className="popoverToolWrap">
                                    <p>PEER REVIEW</p>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <img
                              className="pe-cursor ps-1"
                              src={active_info}
                              alt="no img"
                            />
                          </OverlayTrigger>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div className="clearfix mt-4">
                  <div className="questionBox reddot">
                  {preValues.articleType != "664aed76ada7b4f743b760a9" &&  (  
                    <>
                    <span> 
                      Pay the initial registration amount of {ProfileDetails?.nationality === "outSideIndia" ? "USD 50" : "INR 1000"} to start
                      writing your article immediately.
                      {/* <sup className="supStar">*</sup>. */}
                     </span>
                     </>
                  )}
                    {
                      ProfileDetails?.nationality === "outSideIndia" ? null : (
                        <span>
                          After your article is approved for publishing, you can
                          check eligibility to apply for a grant in the author
                          dashboard.
                        </span>
                      )
                    }
                    {preValues.articleType != "664aed76ada7b4f743b760a9" &&  (
                      <>
                    <span>
                      Your article may also be eligible for extra discounts.
                      These will be available at the time of final payment.
                    </span>
                    <span>
                      {/* <sup className="supStar">*</sup> */}
                      In case your article is
                      not accepted for publishing, the registration amount of
                      {ProfileDetails?.nationality === "outSideIndia" ? " USD 50" : " INR 1000"} will be refunded in your Mudra Kosh (Wallet), in
                      your dashboard.
                    </span>
                    </>
                    )}
                  </div>
                </div>

                <div className="reviewerWrap mt-0">
                  <span className="commanTitle02">
                    Add Five Peer Reviewers To Proceed
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p>
                                    <b className="red-text">We recommend that you suggest five potential Peer Reviewers</b>
                                    <p className="mt-1">Suggesting five potential reviewers will help build the peer reviewer database. This will further help you and us to maintain the authenticity of research coming out of the platform. </p> 
                                  </p>
                               
                                  <p className="mt-3">
                                    Reviewers will remain anonymous. Please do not add yourself or your co-authors to the reviewer list. Since this is a community driven platform, please suggest as many as you can. We truly appreciate your contribution to the cause. 
                                  </p>

                                  <p className="mt-3">
                                  <b>Please note</b> that this information is not displayed to any of the reviewers.
                                  </p>

                                  <p className="mt-3">
                                    The Aakashganga platform reserves the right to accept or not use your suggestions. Thank you for your valuable assistance, and we greatly appreciate your contribution in identifying potential reviewers for this  manuscript. It will help us speed up the selection of appropriate reviewers.
                                  </p>

                                  <p className="mt-3">
                                  <b>Process for your article: Aakashganga will invite at least five reviewers from its internal Peer Review Panel</b><br />
                                  This panel will consist of relevant specialists who have either volunteered for the panel or previously published as an author in Aakashganga. The names you suggest will generally not be contacted to review your article.
                                  </p>
                                  
                                  <p className="mb-0">
                                  <b>How many reviews do I need?</b><br />
                                    You need one completed review from Aakashganga invited reviewers to satisfy Double Anonymous Peer Review. 
                                  </p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                    <span className="mandatorySup pl-2">
                      Optional
                    </span>
                  </span>
                  <div className="reviewerForm">
                    {reviewerCount.map((num, index) => (
                      <div className="reviewerFormArea recommendArea">
                        {index >= 0 && (
                          <div className="reviewrHeading">
                            <h2 className="commanTitle02">Peer Reviewer {index+1}</h2>
                          </div>
                        )}
                        <div className="recommendAuthor">
                          {index > 4 &&
                            subjects.completedTabs.review === false && (
                              <div className="removeAuthors">
                                <i onClick={() => removeReviewers(index)}>
                                  {" "}
                                  Remove Details
                                </i>
                              </div>
                            )}
                          <div className="reviewerFormWrap">
                            <div className="reviewerFormLeft reviewerTitle">
                              <div className="reviewerFormLeftBox">
                                <div className="fieldWrap">
                                  <label class="labelForm">
                                    Title<sup className="supStar">*</sup>
                                  </label>
                                  <div className="selectBox">
                                    <Select
                                      selectedValue={
                                        reviewerDetails?.title[index].val
                                      }
                                      options={editorTitleOptionsNew?.map(
                                        (val) => val?.key
                                      )}
                                      optionValues={editorTitleOptionsNew?.map(
                                        (val) => val?.value
                                      )}
                                      name={`reviewerTitle${index}`}
                                      placeholder="Select"
                                      handleChange={(e) =>
                                        handleSelectChangesReviewer(
                                          e,
                                          index,
                                          "title"
                                        )
                                      }
                                      required={true}
                                      mandatory={false}
                                    />
                                    {reviewerDetailsError.titleError[index] !==
                                      "" && (
                                      <div class="label-error">
                                        {reviewerDetailsError.titleError[index]}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="reviewerFormRightBox">
                                <div className="fieldWrap">
                                  <label class="labelForm">
                                    Full Name<sup className="supStar">*</sup>
                                  </label>
                                  <div className="inputTextBox">
                                    <Input
                                      errors={
                                        reviewerDetailsError?.nameError[index]
                                      }
                                      name={`reviewername${index}`}
                                      handleChange={(e) =>
                                        handleReviewerInput(
                                          e,
                                          index,
                                          "reviewername"
                                        )
                                      }
                                      className="form-control grey-input"
                                      type="text"
                                      required={"required"}
                                      value={reviewerDetails?.name[index].val}
                                      placeholder="Enter"
                                     
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reviewerFormRight">
                              <div className="fieldWrap">
                                <label class="labelForm">
                                  Email ID<sup className="supStar">*</sup>
                                </label>
                                <div className="inputTextBox">
                                  <Input
                                    errors={
                                      reviewerDetailsError?.emailError[index]
                                    }
                                    name={`email${index}`}
                                    handleChange={(e) =>
                                      handleReviewerInput(e, index, "email")
                                    }
                                    className="form-control grey-input"
                                    type="text"
                                    required={"required"}
                                    value={reviewerDetails?.email[index].val}
                                    placeholder="Enter"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="reviewerFormWrap">
                            <div className="reviewerFormLeft">
                              <div className="reviewerFormRightBox">
                                <div className="fieldWrap">
                                  <label class="labelForm">
                                    Affiliated Institution/University
                                    <sup className="supStar">*</sup>
                                  </label>
                                  <div className="inputTextBox">
                                    <Input
                                      errors={
                                        reviewerDetailsError?.universityError[
                                          index
                                        ]
                                      }
                                      name={`university${index}`}
                                      handleChange={(e) =>
                                        handleReviewerInput(
                                          e,
                                          index,
                                          "university"
                                        )
                                      }
                                      className="form-control grey-input"
                                      type="text"
                                      required={"required"}
                                      value={
                                        reviewerDetails?.university[index].val
                                      }
                                      placeholder="Enter"
                                      
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="reviewerFormRight">
                              <div className="fieldWrap">
                                <label class="labelForm">
                                  Subject<sup className="supStar">*</sup>
                                </label>
                                <div className="selectBox">
                                  <Select
                                    selectedValue={
                                      reviewerDetails?.specialization[index].val
                                    }
                                    options={subjects.specializationList?.map(
                                      (val) => val?.specialization
                                    )}
                                    optionValues={subjects.specializationList?.map(
                                      (val) => val?._id
                                    )}
                                    name={`specialization${index}`}
                                    placeholder="Search and Select"
                                    handleChange={(e) =>
                                      handleSelectChangesReviewer(
                                        e,
                                        index,
                                        "specialization"
                                      )
                                    }
                                    required={true}
                                    mandatory={false}
                                    isSearchable={true}
                                    
                                  />
                                  {reviewerDetailsError.specializationError[
                                    index
                                  ] !== "" && (
                                    <div class="label-error">
                                      {
                                        reviewerDetailsError
                                          .specializationError[index]
                                      }
                                    </div>
                                  )}
                                </div>
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {
                    reviewerCount?.length >= 5 && (
                      <span className="mandatoryAdd">
                        <sup className="supStar">*</sup>
                        <b>We invite you to add details of more reviewers if you would like. Please click on the 'Add More Peer Reviewers' button below to continue.</b>
                      </span>
                    )
                  }
                  
                  {
                    reviewerCount?.length > 4 && (
                    <div className="suggestWrap">
                      <a
                        onClick={() => addReviwer()}
                       
                      >
                        {" "}
                        + add Peer Reviewer
                        
                      </a>
                    </div>
                   )
                  }

                  <div className="guidelinesWrap mt-4">
                    <div className="crossrefWrap">
                      <span>
                        <img src={crossref} />
                      </span>
                      <div className="guidelText">
                        <div className="toolWrap imgControl">
                          <div className="customTool d-none">
                            {["bottom"].map((placement) => (
                              <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                  <Popover>
                                    <Popover.Body>
                                      <div className="popoverToolWrap">
                                        <p>Author Guidelines</p>
                                      </div>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <img
                                  className="pe-cursor ps-1"
                                  src={active_info}
                                  alt="no img"
                                />
                              </OverlayTrigger>
                            ))}
                          </div>
                          <Link
                            to={{ pathname: "/authorGuidelines" }}
                            target="_blank"
                          >
                            Author Guidelines
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span className="mandatorySup pl-3">
                      <sup className="supStar">*</sup>Mandatory
                    </span>
                  </div>
                  <div className="guidelines-save-work">
                    <sup className="supStar">*</sup>Please save and continue your work using the button at the bottom of the page to go to the next section
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            className={
              subjects.completedTabs.review === false &&
              subjects.completedTabs.funding === false
                ? "disabled"
                : ""
            }
            
          >
            <Accordion.Header onClick={() => handleAccordian("3")}>
              <p>Authors and contributors</p>
              <b
                className={
                  subjects.completedTabs.author === true ? "completed" : ""
                }
              >
                {subjects.completedTabs.author === false
                  ? "To be completed"
                  : "Completed"}
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <div className="contributorsBody">
                <div className="contributorsTitle">
                  <h3>
                    PRIMARY AUTHOR DETAILS<sup className="supStar">*</sup>
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p>
                                    <b>Order of Authors</b>
                                  </p>
                                  <br />
                                  <p>
                                    The order of authors follows a top-to-bottom
                                    arrangement, with the Primary Author
                                    positioned at the top.
                                  </p>
                                  <p>
                                    If you have started the submission process
                                    from your dashboard, your details will be
                                    automatically populated as the ‘Primary
                                    Author’.
                                  </p>
                                  <p>
                                    If you are not the Primary Author, please
                                    check the box, ‘I am not the Primary
                                    Author’. Your details will be automatically
                                    populated under the ‘Secondary Author’
                                    section. At this stage, you are required to
                                    fill in the details of the Primary Author,
                                    in order to move ahead with your submission.
                                  </p>
                                  <p>
                                    <b>Complete List of Authors</b>
                                  </p>
                                  <p>
                                    Please list all the authors under the tabs:
                                    Primary Author Details, Secondary Author
                                    Details and so on. You are required to
                                    provide the comprehensive list of all the
                                    authors who have contributed to the research
                                    paper, along with their affiliation details
                                    and ORCiD id.{" "}
                                  </p>
                                  <p>
                                    As a policy, we do not allow any
                                    modifications made to the author details,
                                    after your article is accepted for editorial
                                    assessment. Please ensure that all authors
                                    are included to facilitate prompt handling
                                    of your manuscript.
                                  </p>
                                  <p>
                                    If you think you must add another author
                                    after the manuscript has been sent for
                                    editorial assessment, please send us an
                                    email informing us about the change and{" "}
                                    <b>
                                      restart the fresh process of manuscript
                                      submission on the Aakashganga Platform. We
                                      will mark your current version of
                                      submission null and void.
                                    </b>{" "}
                                  </p>
                                  <p>
                                    <b>Co-authors</b>
                                  </p>
                                  <p>
                                    All the authors submitting the paper should
                                    be registered users of the platform.{" "}
                                  </p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </h3>
                  <div className="authorWrapTitle">
                    {preValues.articleType === '664aed76ada7b4f743b760a9' ? null : (
                      <>
                    <label className="customCheck">
                      <Checkbox
                        onRadioChange={handlePrimaryAuthorCheckbox}
                        name="isReviwer"
                        checked={!authorContent?.isMePrimaryAuthor}
                        className="form-check-input"
                      />

                      <i></i>
                      <b>I am not the primary author </b>
                    </label>
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p>
                                    If you are not the Primary Author, please
                                    check the box, ‘I am not the Primary
                                    Author’. Your details will be automatically
                                    populated under the ‘Secondary Author’
                                    section. At this stage, you are required to
                                    fill in the details of the Primary Author,
                                    to move ahead with your submission.{" "}
                                  </p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                      </>
                    )}
                    <div
                      className="helpText"
                      onClick={() => setneedHelpPopup(true)}
                    >
                      need help?
                    </div>
                  </div>
                </div>
                {authorCount.map((num, index) => (
                  <div
                    className="contributorsDetails position-relative"
                    key={`authr${num}`}
                  >
                    {index == 1 && (
                      <div className="contributorsTitle secondryAuthor">
                        <h3>
                          SECONDARY AUTHOR DETAILS
                          <sup className="supStar">*</sup>
                        </h3>
                      </div>
                    )}
                    {index > 1 && (
                      <div className="contributorsTitle secondryAuthor">
                        <h3>
                          Other AUTHOR DETAILS<sup className="supStar">*</sup>
                        </h3>
                      </div>
                    )}
                    <div className="otherAuthor">
                      {num >
                        (authorContent?.isMePrimaryAuthor === true ? 0 : 1) && (
                        <div className="removeAuthors">
                          <i onClick={() => removeAuthorInputs(index)}>
                            Remove Details
                          </i>
                        </div>
                      )}
                      <div className="contributorsDetailsLeft">
                        <div className="contributorsFirst">
                          <div className="defaultInput title">
                            <label>
                              Title<sup className="supStar">*</sup>
                            </label>
                            <div className="selectBox">
                              <Select
                                selectedValue={
                                  authorContent?.authorTitle[index].val
                                }
                                options={editorTitleOptionsNew?.map(
                                  (val) => val?.key
                                )}
                                optionValues={editorTitleOptionsNew?.map(
                                  (val) => val?.value
                                )}
                                name={`title${index}`}
                                placeholder=""
                                handleChange={(e) =>
                                  handleauthorInput(e, index, "title")
                                }
                                required={true}
                                mandatory={false}
                                disabled={
                                  (authorContent?.isMePrimaryAuthor === true &&
                                    index === 0) ||
                                  (authorContent?.isMePrimaryAuthor === false &&
                                    index === 1)
                                }
                              />
                              {authorError?.authorTitleError[index] !== "" && (
                                <div class="label-error">
                                  {authorError?.authorTitleError[index]}
                                </div>
                              )}
                            </div>
                            
                          </div>
                          <div className="defaultInput w-100">
                            <label>
                              Full Name<sup className="supStar">*</sup>
                            </label>

                            <Input
                              errors={authorError?.authornameError[index]}
                              name={`fname${index}`}
                              handleChange={(e) =>
                                handleauthorInput(e, index, "name")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={authorContent?.authorname[index].val}
                              placeholder="Enter"
                              readOnly={
                                (authorContent?.isMePrimaryAuthor === true &&
                                  index === 0) ||
                                (authorContent?.isMePrimaryAuthor === false &&
                                  index === 1)
                              }
                            />
                          </div>
                        </div>
                        <div className="contributorsFirst">
                          <div className="defaultInput w-100">
                            <label>
                              Affiliated Institution/University
                              <sup className="supStar">*</sup>
                            </label>
                            <Input
                              errors={authorError?.authorUniversityError[index]}
                              name={`university${index}`}
                              handleChange={(e) =>
                                handleauthorInput(e, index, "university")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={authorContent?.authorUniversity[index].val}
                              placeholder="Enter"
                              readOnly={
                                (authorContent?.isMePrimaryAuthor === true &&
                                  index === 0) ||
                                (authorContent?.isMePrimaryAuthor === false &&
                                  index === 1)
                              }
                            />
                          </div>
                        </div>
                        <div className="contributorsFirst">
                          <div className="defaultInput w-100">
                            <label>ORCiD ID
                              <div className="customTool">
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover>
                                        <Popover.Body>
                                          <div className="popoverToolWrap">
                                            <p>We encourage you to provide your ORCID iDs as it helps identify researchers ensuring proper attribution, safeguarding against mistaken identity and plagiarism. If you don’t have an ORCiD id, create one now: <u>https://orcid.org/</u></p>
                                            <br />
                                            <p>As the scholarly landscape becomes increasingly digital and interconnected, embracing ORCID iDs aligns with the modernization of research practices, solidifying researchers' profiles and enabling them to actively participate in a dynamic and globally collaborative academic ecosystem.</p>
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <img
                                      className="pe-cursor ps-1"
                                      src={active_info}
                                      alt="no img"
                                    />
                                  </OverlayTrigger>
                                ))}
                              </div>
                            </label>
                            <Input
                              errors={""}
                              name={`orcid${index}`}
                              handleChange={(e) =>
                                handleauthorInput(e, index, "orcid")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={authorContent?.authorOrcidID[index].val}
                              placeholder="Enter"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contributorsDetailsRight">
                        <div className="contributorsFirst">
                          <div className="defaultInput w-100">
                            <label>
                              Email ID<sup className="supStar">*</sup>
                            </label>
                            <Input
                              errors={authorError?.authorEmailError[index]}
                              name={`email${index}`}
                              handleChange={(e) =>
                                handleauthorInput(e, index, "email")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={authorContent?.authorEmail[index].val}
                              placeholder="Enter"
                              readOnly={
                                (authorContent?.isMePrimaryAuthor === true &&
                                  index === 0) ||
                                (authorContent?.isMePrimaryAuthor === false &&
                                  index === 1)
                              }
                            />
                          </div>
                        </div>
                        <div className="contributorsFirst">
                          <div className="defaultInput w-100">
                            <label>Affiliated Department</label>
                            <Input
                              errors={authorError?.authorDepartmentError[index]}
                              name={`department${index}`}
                              handleChange={(e) =>
                                handleauthorInput(e, index, "department")
                              }
                              className="fieldForm"
                              type="text"
                              required={"required"}
                              value={authorContent?.authorDepartment[index].val}
                              placeholder="Enter"
                              readOnly={
                                (authorContent?.isMePrimaryAuthor === true &&
                                  index === 0) ||
                                (authorContent?.isMePrimaryAuthor === false &&
                                  index === 1)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {preValues.articleType === '664aed76ada7b4f743b760a9' ? null : (

                <div class="fieldMore pt-0 pb-0 ml-3 mb-25 mt-25">
                  <a
                    className={
                      subjects.completedTabs.author === true
                        ? "opacity5X addMargin"
                        : "addMargin"
                    }
                    onClick={() => addMoreAuthor()}
                  >
                    + ADD CO-AUTHOR
                  </a>
                </div>
                )}
                <div class="statementWrap authorStatement">
                  <div className="defaultInput">
                    <label>
                      <span>
                        <b>
                          Author/s contribution statement
                          <sup className="supStar">*</sup>
                        </b>
                        <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap">
                                      <h2>Author Contribution Guidelines</h2>
                                      <p>
                                        Please add information about the
                                        contribution of each author. The
                                        statement can be up to several sentences
                                        long, describing the tasks of individual
                                        authors. For more information on author
                                        contribution statement, please refer to
                                        our{" "}
                                        <a href="" className="button-link">
                                          author guidelines
                                        </a>
                                        .
                                      </p>
                                      <p>
                                        The author contributor statement
                                        typically includes information about the
                                        following:
                                      </p>
                                      <ol>
                                        <li>
                                          Conceptualization and design of the
                                          study
                                        </li>
                                        <li>Acquisition of data</li>
                                        <li>
                                          Analysis and interpretation of data
                                        </li>
                                        <li>Drafting of the manuscript</li>
                                        <li>
                                          Critical revision of the manuscript
                                        </li>
                                        <li>
                                          Approval of the final version of the
                                          manuscript for submission
                                        </li>
                                      </ol>
                                      <p>
                                        Overall, the author contributor
                                        statement is an important component of a
                                        research article, and it helps to ensure
                                        that the research process is transparent
                                        and accountable.
                                      </p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div>
                      </span>
                      <span>
                        <b>Word Limit</b>: 300
                      </span>
                    </label>
                    <Textarea
                      name="contribution"
                      rows="4"
                      className="form-control grey-input title"
                      placeholder="Give your statement"
                      value={authorContent.contributionStatement}
                      onChange={(e) => {
                        handleauthorInput(e.target.value, 0, "contribution");
                      }}
                    />
                    {authorError.contributionStatementError !== "" && (
                      <div className="label-error">
                        {authorError.contributionStatementError}
                      </div>
                    )}
                  </div>

                  <div className="guidelinesWrap p-0">
                    <div className="crossrefWrap">
                      <span>
                        <img src={crossref} />
                      </span>
                      <div className="guidelText">
                        <div className="toolWrap imgControl">
                          <Link
                            to={{ pathname: "/authorGuidelines" }}
                            target="_blank"
                          >
                            Author Guidelines
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span className="posiTopManage">
                      <sup className="supStar">*</sup>Mandatory
                    </span>
                  </div>
                  <div className="guidelines-save-work pr-0">
                    <sup className="supStar">*</sup>Please save and continue your work using the button at the bottom of the page to go to the next section
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
             className={
              subjects.completedTabs.author === false &&
              subjects.completedTabs.statement === false
                ? "disabled"
                : ""
            }
           
          >
            <Accordion.Header onClick={() => handleAccordian("4")}>
              <p>Funding details</p>
              <b
                className={
                  subjects.completedTabs.funding === true ? "completed" : ""
                }
              >
                {subjects.completedTabs.funding === true
                  ? "Completed"
                  : "Optional"}{" "}
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fundingWrap">
                <h3 className="commanTitle02">
                  <div className="">
                    Funding Information<sup className="supStar">*</sup>{" "}
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p>
                                    We request you to list all your funding
                                    information here including funds received
                                    for open access publication fees, from your
                                    institution, library, or other grants. It
                                    will be included in your article upon final
                                    publication. Disclosure of funding
                                    information demonstrates your commitment to
                                    transparency. If the study or research was
                                    commercially funded, please declare this
                                    information in the conflict-of-interest
                                    statement in the section below, clearly
                                    stating the role of each funder in the study
                                    including design and collection, analysis,
                                    and interpretation of the results, if any.
                                    Leave empty if no funding received.
                                  </p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                    <div className="recivedCheck">
                      <label className="customCheck">
                        <Checkbox
                          onRadioChange={handleFundingCheckbox}
                          name="noFundingRecevied"
                          checked={fundingDetails?.noFundingRecevied}
                          className="form-check-input"
                        />
                        <i></i>
                        <b>No funding received</b>
                      </label>
                    </div>
                  </div>
                </h3>
                <div className="fundingInfo">
                  <p className="funderContent">
                    Please declare all sources of funding received for the
                    research being submitted. This information includes the name
                    of funding agencies, grant amount, and a short description
                    of each funder’s role.
                  </p>
                  <div className="funderTextBoxArea">
                    <Textarea
                      name="fundingInformation"
                      rows="3"
                      className="defaultFeild"
                      placeholder="Type here..."
                      value={fundingDetails.fundingInformation}
                      readOnly={fundingDetails?.noFundingRecevied}
                      onChange={(e) => {
                        handleFundingInput(
                          e.target.value,
                          0,
                          "fundingInformation"
                        );
                      }}
                    />
                    {fundingDetailsError.fundingInformationError !== "" && (
                      <div className="label-error">
                        {fundingDetailsError.fundingInformationError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="funderWrap">
                  {fundingCount.map((num, index) => (
                    <div className={index > 0 ? " primaryRepeatWrap" : ""}>
                      {index == 0 && (
                        <h3 className="commanTitle02 mt-0">Primary Funder</h3>
                      )}
                      {index == 1 && (
                        <h3 className="commanTitle02 secondryFunder mt-0">
                          SECONDARY Funder
                        </h3>
                      )}
                      {index > 1 && (
                        <h3 className="commanTitle02 secondryFunder mt-0">
                          Other Funder
                        </h3>
                      )}

                      <div className="primaryFunder">
                        {num > 0 && (
                          <div className="removeAuthors">
                            <i onClick={() => removeFundingInputs(index)}>
                              Remove Details
                            </i>
                          </div>
                        )}
                        <div className="primaryFunderLeft">
                          <div className="defaultInput">
                            <label>Name of Funding Agency</label>
                            <Input
                              errors={
                                fundingDetailsError?.fundingAgencyError[index]
                              }
                              name={`fundingAgency${index}`}
                              handleChange={(e) =>
                                handleFundingInput(e, index, "fundingAgency")
                              }
                              className="defaultFeild"
                              type="text"
                              required={"required"}
                              value={fundingDetails?.fundingAgency[index]?.val}
                              placeholder="Enter"
                              readOnly={fundingDetails?.noFundingRecevied}
                            />
                          </div>
                          <div className="inrBox">
                            <i>{ProfileDetails?.nationality === 'withInIndia' ? 'INR' : 'USD'}</i>
                            <div className="defaultInput">
                              <label>Grant Amount</label>
                              <Input
                                errors={
                                  fundingDetailsError?.grantAmountError[index]
                                }
                                name={`grantAmount${index}`}
                                handleChange={(e) =>
                                  handleFundingInput(e, index, "grantAmount")
                                }
                                className="defaultFeild"
                                type="number"
                                required={"required"}
                                value={fundingDetails?.grantAmount[index]?.val}
                                placeholder="Enter"
                                readOnly={fundingDetails?.noFundingRecevied}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="primaryFunderRight">
                          <div className="defaultInput">
                            <label>URL of Funding Agency</label>
                            <Input
                              errors={
                                fundingDetailsError?.fundingAgencyUrlError[
                                  index
                                ]
                              }
                              name={`fundingAgencyUrl${index}`}
                              handleChange={(e) =>
                                handleFundingInput(e, index, "fundingAgencyUrl")
                              }
                              className="defaultFeild"
                              type="text"
                              required={"required"}
                              value={
                                fundingDetails?.fundingAgencyUrl[index]?.val
                              }
                              placeholder="Enter"
                              readOnly={fundingDetails?.noFundingRecevied}
                            />
                          </div>
                        </div>
                        <div className="contactFounder">
                          <h3 className="commanTitle02">Contact Details</h3>
                          <div className="primaryFunderLeft">
                            <div className="defaultInput">
                              <label>Name of contact person</label>
                              <Input
                                errors={fundingDetailsError?.nameError[index]}
                                name={`name${index}`}
                                handleChange={(e) =>
                                  handleFundingInput(e, index, "name")
                                }
                                className="defaultFeild"
                                type="text"
                                required={"required"}
                                value={fundingDetails?.name[index]?.val}
                                placeholder="Enter"
                                readOnly={fundingDetails?.noFundingRecevied}
                              />
                            </div>
                            <div className="defaultInput">
                              <label>Mobile Number</label>
                              <Input
                                errors={fundingDetailsError?.phoneError[index]}
                                name={`phone${index}`}
                                handleChange={(e) =>
                                  handleFundingInput(e, index, "phone")
                                }
                                className="defaultFeild"
                                type="text"
                                required={"required"}
                                value={fundingDetails?.phone[index]?.val}
                                placeholder="Enter"
                                readOnly={fundingDetails?.noFundingRecevied}
                              />
                            </div>
                          </div>
                          <div className="primaryFunderRight">
                            <div className="defaultInput">
                              <label>Email ID</label>
                              <Input
                                errors={fundingDetailsError?.emailError[index]}
                                name={`email${index}`}
                                handleChange={(e) =>
                                  handleFundingInput(e, index, "email")
                                }
                                className="defaultFeild"
                                type="text"
                                required={"required"}
                                value={fundingDetails?.email[index]?.val}
                                placeholder="Enter"
                                readOnly={fundingDetails?.noFundingRecevied}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 fieldMore mb-4">
                    <div className="fieldMoreArange">
                      <a
                        className={
                          fundingDetails?.noFundingRecevied ? "opacity5" : ""
                        }
                        onClick={() => addMoreFunder()}
                      >
                        + ADD FUNDER
                      </a>
                    </div>
                  </div>
                  <div className="guidelinesWrap">
                    <div className="crossrefWrap">
                      <span>
                        <img src={crossref} />
                      </span>
                      <div className="guidelText">
                        <div className="toolWrap imgControl">
                          <Link
                            to={{ pathname: "/authorGuidelines" }}
                            target="_blank"
                          >
                            Author Guidelines
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span>
                      <sup className="supStar">*</sup>Mandatory
                    </span>
                  </div>
                  <div className="guidelines-save-work">
                    <sup className="supStar">*</sup>Please save and continue your work using the button at the bottom of the page to go to the next section
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="5"
            className={
              subjects.completedTabs.funding === false
                ? "disabled"
                : ""
            }
           
          >
            <Accordion.Header onClick={() => handleAccordian("5")}>
              <p>Statements
                <sup className="supStar">*</sup>
              </p>
              <b
                className={
                  subjects.completedTabs.statement === false ||
                  statements?.checkedCondition === false
                    ? ""
                    : "completed"
                }
              >
                {subjects.completedTabs.statement === false ||
                statements?.checkedCondition === false
                  ? "To be completed"
                  : "Completed"}
              </b>
            </Accordion.Header>

            <Accordion.Body className="pt-0 pb-0">
              <div className="statementsWrap">
                <div className="statementsLeft">
                  <div className="statements">
                    <strong>
                      Commercial or financial conflict of interest
                    </strong>
                    <div className="regardingChklist">
                      <div className="regardingRadio">
                        <RadioButton
                          label="The authors declare that the research was conducted in the absence of any commercial or financial relationships that could be construed as a potential conflict of interest."
                          name="conflict"
                          id="conflictno"
                          labelClass={""}
                          checked={statements.commercialFinanceConflict === "1"}
                          handleChange={handleStatementConflictChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="regardingChklist mt-2 mb-3">
                      <div className="regardingRadio">
                        <RadioButton
                          label="The authors declare a conflict of interest."
                          name="conflict"
                          id="conflictyes"
                          labelClass={""}
                          checked={statements.commercialFinanceConflict === "2"}
                          handleChange={handleStatementConflictChange}
                          required
                        />
                      </div>
                    </div>
                    {statements.commercialFinanceConflict === "2" && (
                      <div className="custom_input">
                        <Textarea
                          name="conflictDetail"
                          rows="4"
                          className="form-control"
                          placeholder="Provide more details..."
                          value={statements.conflictDetail}
                          onChange={(e) => {
                            handleStatementInput(
                              e.target.value,
                              "conflictDetail"
                            );
                          }}
                        />
                        {statements.conflictError !== "" && (
                          <div className="label-error">
                            {statements.conflictError}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="statements">
                    <strong>
                      Has a version of this manuscript been submitted previously
                    </strong>
                    <div className="regardingChklist">
                      <div className="regardingRadio">
                        <RadioButton
                          label="No"
                          name="scriptversion"
                          id="scriptversionno"
                          labelClass={""}
                          checked={statements.manuscriptVersion === "no"}
                          handleChange={handleStatementScriptChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="regardingChklist">
                      <div className="regardingRadio">
                        <RadioButton
                          label="Yes"
                          name="scriptversion"
                          id="scriptversionyes"
                          labelClass={""}
                          checked={statements.manuscriptVersion === "yes"}
                          handleChange={handleStatementScriptChange}
                          required
                        />
                      </div>
                    </div>
                    {statements.manuscriptVersion === "yes" && (
                      <Textarea
                        name="manuscriptVersionDetail"
                        rows="4"
                        className="form-control mt-4"
                        placeholder="Provide more details..."
                        value={statements.manuscriptVersionDetail}
                        onChange={(e) => {
                          handleStatementInput(
                            e.target.value,
                            "manuscriptVersionDetail"
                          );
                        }}
                      />
                    )}
                    {statements.versionError !== "" && (
                      <div className="label-error">
                        {statements.versionError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="statementsRight">
                  <label className="customCheck">
                    <Checkbox
                      onRadioChange={handleAcceptStatementCheckbox}
                      name="acceptStatement"
                      checked={statements?.checkedCondition}
                      className="form-check-input"
                    />

                    <i></i>
                    <b>
                      I warrant that I am expressly authorized to submit this
                      article and to agree to the following conditions on behalf
                      of myself and every author of this manuscript:
                    </b>
                  </label>
                  {statements.acceptAllError !== "" && (
                    <div className="label-error">
                      {statements.acceptAllError}
                    </div>
                  )}
                  <ul>
                    <li>
                      This manuscript is not currently under consideration for
                      publication elsewhere and has not been previously
                      published by any other journal or publication forum.
                    </li>
                    <li>
                      This manuscript adheres to Aakashganga{" "}
                      <a href="" onClick={(e) => {
                        e.preventDefault();
                        setShowMaterialPolicy(true)
                      }}>
                        materials and data policies
                      </a>{" "}
                      in its reporting requirements and data availability
                      standards.
                    </li>
                    <li>
                      I have obtained all necessary consents to permit
                      Aakashganga to publish this article, including any
                      third-party materials, on CC-BY terms.
                    </li>
                    <li>
                      I have read, understood and hereby accept the{" "}
                      <a href="/terms-conditions" target="_blank">
                        Aakashganga Terms & Conditions
                      </a>
                    </li>
                  </ul>
                  <p className="copyright">
                    © Vikramshila Research Pvt Ltd 2023
                  </p>
                </div>
              </div>
              <div className="guidelinesWrap statementsGuide">
                <div className="crossrefWrap">
                  <span>
                    <img src={crossref} />
                  </span>
                  <div className="guidelText">
                    <div className="toolWrap imgControl">
                      <Link
                        to={{ pathname: "/authorGuidelines" }}
                        target="_blank"
                      >
                        Author Guidelines
                      </Link>
                    </div>
                  </div>
                </div>
                <span>
                  <sup className="supStar">*</sup>Mandatory
                </span>
                  </div>
            </Accordion.Body>
          </Accordion.Item>

       

        </Accordion>

        {subjects.preStepData.plagirismCheck === "notDone" && (
          <>
            {defaultActiveKey === "0" && (
              <div className="confirmFooter">
                <div className="confirmContent"></div>
                <div className="confirmBtn">
                  <span
                    className={
                      preValues.journal === "" ||
                      preValues.subjectId === "" ||
                      preValues.articleType === ""
                        ? "btn-link-active opacity5"
                        : "btn-link-active"
                    }
                    onClick={() => saveAndPay(1)}
                  >
                    Save
                  </span>
                  <button
                    disabled={
                      preValues.journal === "" ||
                      preValues.subjectId === "" ||
                      preValues.articleType === ""
                        ? true
                        : false
                    }
                    className="button button-primary"
                    type="button"
                    onClick={() => {
                      saveAndPay(2);

                    }}
                  >
                    SAVE AND CONTINUE
                  </button>
                </div>
              </div>
            )}
             {subjects.completedTabs.mySubmission === true &&
              defaultActiveKey === "1" && (
                <div className="confirmFooter">
                 <div className="confirmContent">
                 {preValues.articleType === '664aed76ada7b4f743b760a9'?null:(
                  <>
                   <h3>
                     Remaining ARTICLE PROCESSING CHARGE: <b>{ProfileDetails?.nationality === "outSideIndia" ? "USD":'INR'} {preValues.reviewType ===
                               "pre-publication peer review only"? (APCData[0].introductoryPrice - APCData[2].introductoryPrice) : (APCData[1].introductoryPrice - APCData[2].introductoryPrice)}</b>
                   </h3>

                   <div className="questionBox reddot">
                     <span>
                       You can also be eligible for additional grants and
                       discounts.
                     </span>
                     <span>
                       All offers will be applied after the article is accepted
                       for publication.
                     </span>
                   </div>
                   </>)}
                 </div>
                 <div className="confirmBtn">
                   <span
                     className="btn-link-active"
                     onClick={() => saveAndPay(1)}
                   >
                     Save
                   </span>
                   <button
                     className="button button-primary"
                     type="button"
                     onClick={() => saveAndPay(2)}
                   >
                     SAVE AND CONTINUE
                   </button>
                 </div>
               </div>
              )}
            {
              defaultActiveKey === "2" && subjects.completedTabs.statement == false  && (
                <div className="confirmFooter">
                <div className="confirmContent">
                <h3>
                    Article Registration Payment: 
                    {preValues.articleType === '664aed76ada7b4f743b760a9'?(
                    <>
                    <b> {ProfileDetails?.nationality === "withInIndia"
                                ? "INR " + APCData?.[3]?.introductoryPrice
                                : ProfileDetails?.nationality === "outSideIndia" ? "$" + APCData?.[3]?.introductoryPrice : APCData?.[3]?.introductoryPrice}</b>
                    </>):(<>
                      <b> {ProfileDetails?.nationality==='withInIndia'?`INR ${APCData[2]?.introductoryPrice}`:`USD ${APCData[2]?.introductoryPrice}`}</b>
                    </>)}
                   

                   
                  </h3>
                  <div className="questionBox reddot">
                  {preValues.articleType != "664aed76ada7b4f743b760a9" &&  (
                    <>
                  <span>
                      The remaining balance will charge after your article is
                      accepted for publishing
                    </span> 
                    </>
                  )}
                  </div>
                </div>
                <div className="confirmBtn">
                  <button
                    disabled={preValues.reviewType === "" ? true : false}
                    className="button button-primary"
                    type="button"
                    onClick={() => saveAndPay(2)}
                  >CONTINUE TO PAYMENT
                  </button>
                </div>
              </div>
                
              )}
            {subjects.completedTabs.review === true &&
              defaultActiveKey === "3" && (
               
                <div className="confirmFooter">
                <div className="confirmContent">
                {preValues.articleType === '664aed76ada7b4f743b760a9'?null:(
                  <>
                  <h3>
                    Remaining ARTICLE PROCESSING CHARGE: <b>{ProfileDetails?.nationality === "outSideIndia" ? "USD":'INR'} {preValues.reviewType ===
                              "pre-publication peer review only"? (APCData[0].introductoryPrice - APCData[2].introductoryPrice) : (APCData[1].introductoryPrice - APCData[2].introductoryPrice)}</b>
                  </h3>
                  <div className="">
                    <span>
                      You may also be eligible for additional grants and
                      discounts
                    </span>
                    <span>
                      All offers will be applied after the article is accepted
                      for publication
                    </span>
                  </div>
                  </>
                )}
                </div>
                <div className="confirmBtn">
                  <span
                    className="btn-link-active"
                    onClick={() => saveAndPay(3)}
                  >
                    Skip
                  </span>
                  <span
                    className="btn-link-active"
                    onClick={() => saveAndPay(1)}
                  >
                    Save
                  </span>
                  <button
                    className="button button-primary"
                    type="button"
                    onClick={() => saveAndPay(2)}
                  >
                    SAVE AND CONTINUE
                  </button>
                </div>
              </div>
              )}
            {subjects.completedTabs.author === true &&
              defaultActiveKey === "4" && (
                
                  <div className="confirmFooter">
                  <div className="confirmContent">
                  {preValues.articleType === '664aed76ada7b4f743b760a9'?null:(
                  <>
                    <h3>
                      Remaining ARTICLE PROCESSING CHARGE: <b>{ProfileDetails?.nationality === "outSideIndia" ? "USD" : 'INR'} {preValues.reviewType ===
                        "pre-publication peer review only" ? (APCData[0].introductoryPrice - APCData[2].introductoryPrice) : (APCData[1].introductoryPrice - APCData[2].introductoryPrice)}</b>
                    </h3>
                    <div className="">
                      <span>
                        You may also be eligible for additional grants and
                        discounts
                      </span>
                      <span>
                        All offers will be applied after the article is accepted
                        for publication
                      </span>
                    </div>
                    </>
                  )}
                  </div>
                  <div className="confirmBtn">
                    <span
                      className="btn-link-active"
                      onClick={() => saveAndPay(3)}
                    >
                      Skip
                    </span>
                    <span
                      className="btn-link-active"
                      onClick={() => saveAndPay(1)}
                    >
                      Save
                    </span>
                    <button
                      className="button button-primary"
                      type="button"
                      onClick={() => saveAndPay(2)}
                    >
                      SAVE AND CONTINUE
                    </button>
                  </div>
                </div>
              )}
            {subjects.completedTabs.funding === true &&
              defaultActiveKey === "5" && (
                
              
                 <div className="confirmFooter">
                 <div className="confirmContent">
                 {preValues.articleType === '664aed76ada7b4f743b760a9'?null:(
                  <>
                   <h3>
                     Remaining ARTICLE PROCESSING CHARGE: <b>{ProfileDetails?.nationality === "outSideIndia" ? "USD" : 'INR'} {preValues.reviewType ===
                       "pre-publication peer review only" ? (APCData[0].introductoryPrice - APCData[2].introductoryPrice) : (APCData[1].introductoryPrice - APCData[2].introductoryPrice)}</b>
                   </h3>
                   <div className="">
                     <span>
                       You may be eligible for discounts. All offers will be
                       applied after the article is accepted for publication
                     </span>
                   </div>
                   </>
                 )}
                 </div>
                 <div className="confirmBtn">
                   <span
                     className={
                       (statements?.checkedCondition === true && statements?.commercialFinanceConflict !==''&& statements?.manuscriptVersion !=='')
                         ? "btn-link-active"
                         : "btn-link-active opacity5"
                     }
                     onClick={() => saveAndPay(1)}
                   >
                     Save
                   </span>
                   <button
                     className="button button-primary"
                     disabled={!statements?.checkedCondition || statements?.commercialFinanceConflict ==='' ||  statements?.manuscriptVersion ===''}
                     type="button"
                     onClick={() => saveAndPay(2)}
                   >
                     Proceed
                   </button>
                 </div>
               </div>
              )}
           
          </>
        )}
      </div>
      <Modal
        dialogclassName="modal-dialog-centered modal-md md-x"
        show={incompleteInfoPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal authorModalRed md-x"
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title">Details Incomplete</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setincompleteInfoPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-4 pt-0">
          <div className="grantDetails alertPopup">
            <div className="incompleteText w-75 m-auto mb-3 pl-3 pr-3">
              <p
                className="pl-4 pr-4"
                dangerouslySetInnerHTML={{ __html: popupError }}
              />
            </div>
          </div>
          <div className="clearfix">
            <button
              className="button button-primary redBtn"
              type="button"
              onClick={() => setincompleteInfoPopup(false)}
            >
              Okay
            </button>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer pb-3">
          <div className="helpPopupText">
            Need help? Write to us at<a href="">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-dialog-centered modal-md"
        show={createcitationPopup}
        size="md"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Create Citation</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setcreatecitationPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="createCitationAlertPopup">
            <h2>
              Author Name<sup className="supStar">*</sup>
            </h2>

            <div className="row">
              <div className="col-md-6">
                <div className="defaultInput mt-0">
                  <label className="defaultLable">
                    Full Name<sup className="supStar">*</sup>
                  </label>
                  <Input
                    name={`name`}
                    className="defaultFeild"
                    type="text"
                    placeholder="Full Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="defaultInput mt-0">
                  <label className="defaultLable">
                    Last Name<sup className="supStar">*</sup>
                  </label>
                  <Input
                    name={`Lastname`}
                    className="defaultFeild"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="urlPopup">
              <div className="row">
                <div className="col-md-12">
                  <div className="defaultInput">
                    <label className="defaultLable">
                      URL<sup className="supStar">*</sup>
                    </label>
                    <Input
                      name={`name`}
                      className="defaultFeild"
                      type="text"
                      placeholder="Name of contact person"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="defaultInput">
                  <label className="defaultLable">
                    Year of Publication<sup className="supStar">*</sup>
                  </label>
                  <Input
                    name={`name`}
                    className="defaultFeild"
                    type="number"
                    placeholder="Year of Publication"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 citationButton">
                <button
                  className="button button-primary"
                  type="button"
                  onClick={() => setcreatecitationPopup(false)}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer pb-3">
          <div className="mandatoryCount">
            <sup className="supStar">*</sup>Mandatory
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-dialog-centered modal-md"
        show={citationGeneratedPopup}
        size="md"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Citation Generated</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setcitationGeneratedPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="createCitationAlertPopup">
            <div className="createCitationText">
              <div className="col-md-12 pt-3">
                <div className="defaultInput mt-0">
                  <label className="defaultLable">In-text Citation</label>
                  <div className="copyInput">
                    <Input
                      name={`name`}
                      className="defaultFeild"
                      type="text"
                      placeholder="In-text Citation"
                    />
                    <i>Copy</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer pb-4">
          <button
            className="button button-primary"
            type="button"
            onClick={() => setincompleteInfoPopup(false)}
          >
            DONE
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-dialog-centered modal-md"
        show={needHelpPopup}
        size="sm"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Need Help?</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setneedHelpPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="needNewPopup">
            <p>For any questions, write to us at</p>
            <a href="">contactus@vikramshilaedu.in</a>
            <p className="pt-2">We will strike to respond at the earliest.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary"
            type="button"
            onClick={() => setneedHelpPopup(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogclassName="modal-dialog-centered modal-md"
        show={subjectApproval}
        size="sm"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">ALL GOOD THINGS TAKE TIME</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setsubjectApproval(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="needNewPopup text-center">
            <p>You can continue filling out the submission</p>
            <p>form after we have approved the subject.</p>
            <p className="pt-2">Thank you for your patience</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary"
            type="button"
            onClick={() => setsubjectApproval(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-dialog-centered modal-lg"
        size="lg"
        className="authorModal"
        show={showMaterialPolicy}
        backdrop="static"
        id="congratsMobile"
        tabIndex={-1}
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Material and Data Policies</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowMaterialPolicy(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="needNewPopup">
            <p className="mb-3">
              We are committed to open data and being transparent. We require that you make available all data relevant to the conclusions of the manuscript. We work towards having the correct data available, ensuring increased levels of transparency and reproducibility in our published articles.
            </p>
            <p className="mb-3">Relevant materials such as analytic methods, study material, tables etc should preferably be made available through a link; you are encouraged to make this material available upon request to interested researchers, and be stated in the manuscript.</p>
            <p>You must comply with the General Data Protection Regulation (‘GDPR’) [in case it is applicable to you] and all relevant data protection and privacy legislation in other jurisdictions, including India.</p>
          </div>
        </Modal.Body>
      </Modal>

      <OtherSubject
        showSubject={showSubject}
        setShowSubject={setShowSubject}
        props={handleOtherSubject}
        other={other}
        subjectId={preValues.subjectId}
        subjectType={'article'}
        setOther={setOther}
      />
    </div>
  );
};

export default mySubmission;
