import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share"; 
import { SERVER_URL } from "../../config";
import { Pagination } from "semantic-ui-react";
import copy from "copy-to-clipboard";
import SocietyJournalImg from "../../assets/assets_html/images/society homepage.png";
import YoutubeIcon from "../../assets/assets_html/images/Youtube-Icon.svg";
import adsport from "../../assets/img/icons/adsport.svg";
import coin from "../../assets/img/icons/coinT.svg";
import NewsLetter from "../../components/global/NewsLetter";
import InterestedSection from "../../components/journals/InterestedSection";
import NewInterestedSection from "../../components/journals/NewInterestedSection";
import JournalMainBanner from "../../components/journals/JournalMainBanner";
import SubmitArticleModal from "../../components/Modal/SubmitArticleModal";
import { getJournalDetailById, getLatestArticles } from "../../store/apiCalls/dashboard";
import { getJournalSubjects } from "../../store/apiCalls/articles";
import EditorialBoard from "../EditorialBoard";
import MobileModalAlert from "../../components/Modal/MobileModalAlert";
import Advertisement from "../../../src/components/viewArticle/advertisement.js";
import AakashgangaHeader from "../../components/global/HeaderComponent";
import AakashgangaFooter from "../../components/global/FooterComponent";
import NewRelatedArticles from "../../components/viewArticle/newRelatedArticles.js";
import notyet from "../../assets/img/icons/notarticle.svg";

function SamajShastraJournalComponent() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = params;
  
  // const { id } = "params";
  const [mobileAlert, setMobileAlert] = useState(false);
  const societyJournal = "Society Journal";
  // const societyJournal = location?.state?.societyJournal;
  const pageName = location?.state?.pageName;
  const journalName = location?.state?.journalName;
  const SHARE_URL = `${SERVER_URL}/society-journal/${params.discipline}/${id}`;
  const [modalPageName, setModalPageName] = useState(pageName);
  const [modalJournalName, setJournalModalName] = useState(journalName);
  const [show, setShow] = useState(false);
  const [showRec, setShowRec] = useState(false);
  const dispatch = useDispatch();
  const [showAdvertising, setShowAdvertising] = useState(false);
  const handleClose = () => setShow(false);
  const [activeType, setActiveType] = useState("description");
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadArticle, setUploadArticle] = useState(false);
  const [articleData, setArticleData] = useState([]);
  const token = localStorage.getItem("usertoken");
  const title = `I recommend that you checkout ${modalJournalName}. The process of submission is fully automated and it offers affordable APCs, and a very short turnaround time.`;
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handleScrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const [journalData, setJournalData] = useState([]);
  const [filteredData, setFilteredData] = useState(specializationOptions);

  const limit = 3;
  const userDetails = useSelector((state) => state?.LoginReducer?.user);
  const [latestArticlesCount, setLatestArticlesCount] = useState("");
  let latestArticlesTotalPages = Math.ceil(latestArticlesCount / limit);
  const [latestPage, setLatestPage] = useState(1);

  const fetchLatestArticlesData = () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      dispatch({ type: "SET_LOADER", payload: true });
      const fetchdata = async () => {
          const params = {
              page: latestPage,
              limit: limit,
              userId: userDetails?._id,
              societyId: journalData?._id,
          };
          return getLatestArticles(headers, params);
      };
      fetchdata()
          .then((ArticleRes) => {
              if (ArticleRes.status === 200 && ArticleRes?.data) {
                  dispatch({ type: "SET_LOADER", payload: false });
                  setArticleData(ArticleRes?.data);
                  setLatestArticlesCount(ArticleRes?.data?.totalCount);
              }
          })
          .catch((err) => console.log(err));
      dispatch({ type: "SET_LOADER", payload: false });
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = specializationOptions.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    const specSearch = [...filtered];
    setFilteredData(specSearch);
  };

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 2000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timer);
  }, []);


  const toggleSearchDropdown = () => {
    setShowSearchDropdown(!showSearchDropdown);
  };
  const getJournalDetail = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalId: id,
      slug: id
    };
    const response = await getJournalDetailById(headers, params);
    if (response?.status === 200) {
      setJournalData(response?.data?.data);
      getAllSubject(response?.data?.data?.journalName);
    } else return;
  };

  const getAllSubject = async (decId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { journalName: decId },
      headers: headers,
    };
    const response = await getJournalSubjects(queryParams);
    if (response?.status === 200) {
      let newRecord = response?.data?.data?.specilisation;
      if (decId !== "AyurYoga – The Mega Journal of Ayurveda and Yoga") {
        let specOpt = newRecord
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        setSpecializationOptions(specOpt);
        setFilteredData(specOpt);
      } else {
        let specOpt0 = newRecord?.[0]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);

        let specOpt1 = newRecord?.[1]
          ?.filter(
            (spec) =>
              spec?.specialization !==
              "Other Subject (choose this option to register your subject)"
          )
          .map((spec) => spec?.specialization);
        const children = specOpt0.concat(specOpt1);
        setSpecializationOptions(children);
        setFilteredData(children);
      }
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleCodeCopy = (e) => {
    copy(SHARE_URL);
    e.target.textContent = "Copied";
    e.target.style.setProperty("color", "#ffffff");
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Add the "th" suffix to the day
    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
    } else if (day === 2 || day === 22) {
        daySuffix = "nd";
    } else if (day === 3 || day === 23) {
        daySuffix = "rd";
    }

    return ` ${month} ${day},  ${year}`;
}
const aimsandscope = useRef(null);

const scrollToaimsandscope = () => {
  if (aimsandscope.current) {
    aimsandscope.current.scrollIntoView({ behavior: 'smooth' });
  }
};
    

  useEffect(() => { 
    {
      societyJournal === "Society Journal" ? getJournalDetail() : getJournalDetail();
    }
    
  }, [id]);

  useEffect(() => {
    fetchLatestArticlesData();
}, [journalData]);

const JDescription = journalData?.journalDescription?journalData?.journalDescription:"";

const words = JDescription.split(/\s+/);

// Get the first 150 words
const first150Words = words.slice(0, 158).join(' ');

// Get the rest of the words
const restOfWords = words.slice(158).join(' ');

// console.log("journalDataarticleData==>",journalData);
  return (
    <>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          {id === 'socialsciences-megajournal-SSJSS'?'Samaj Shastra – The Mega Journal of Social Sciences'
      :id === 'business-management-megajournal-CSJBM'?'Chanakya Shastra – The Mega Journal of Business and Management'
      :id === 'medicine-megajournal-CSJM'?'Charakh Shastra – The Mega Journal of Medicine'
      :id === 'engineering-megajournal-AYSJE'?'Abhiyantran Shastra – The Mega Journal of Engineering'
      :id === 'information-technology-megajournal-SSJIT'?'Soochna Shastra – The Mega Journal of Information Technology'
      :id === 'sciences-megajournal-VSJS'?'Vigyan Shastra – The Mega Journal of Sciences'
      :id === "Ayurveda-Yoga-megajournal-AJAY" ? 'AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga' 
      :id === "society-institute-university" ? 'Society Journals – The Society Journal of Sciences' : null}
        </h1>
        <JournalMainBanner
          imgUrl={
            societyJournal === "Society Journal"
              ? journalData?.masthead
              : journalData?.masthead
          }
        />
        <div className="menu-center-part dsmar-25">
          <div className="journalShadow">
            <div className="ag_menu justify">
              <ul className="ag_menu ag_menuSub justify tagul">
                {societyJournal === "Society Journal" ? null : (
                  <li className="tagli">
                    <a
                      href="#"
                      class="custom-journal taga"
                      onClick={toggleSearchDropdown}
                    >
                      Disciplines <img src="/assets/images/downarrow.png" alt="#" />
                    </a>

                    {showSearchDropdown && (
                      <div className="tagliSubNave">
                        <div className="disciplinesbg">
                          <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input
                              type="text"
                              class="form-control input30"
                              placeholder="Type to search"
                              value={searchQuery}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                          <ul className="disciplinesscroll">
                            {filteredData.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                )}
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                  {/* DISCIPLINE{" "} */}
                    <span className=" text-black text-uppercase" style={{}}>
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.subject?.subjectName
                        : journalData?.subjectData?.[0]?.subject?.subjectName}
                    </span>
                  </a>
                </li>
                
                {/* <li>
                  <a href="javascript:;" class="custom-journal taga">
                  Subject{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.specialization?.[0]?.specialization
                        : journalData?.subjectData?.[0]?.specialization?.[0]?.specialization}
                    </span>
                  </a>
                </li> */}
                <li className="active tagli custom-journal">
                  <Link
                    to="/open-access"
                    onClick={(e) => handleRedirect(e, "/open-access")}
                    className="custom-journal taga"
                  >
                    OPEN SCIENCE
                  </Link>
                </li>
                <li>
                  <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" class="custom-journal taga">
                 <img style={{width: '100px', maxWidth: '100%', transform:'none'}} src={coin} alt="#" />
                    {/* <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.subjectData?.[0]?.subject?.subjectName
                        : journalData?.subjectData?.[0]?.subject?.subjectName}
                    </span> */}
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    E-ISSN{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.issnNumber
                        : journalData?.issnNumber}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                    ABBR{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      {societyJournal === "Society Journal"
                        ? journalData?.abbreviation
                        : journalData?.abbreviation}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="custom-journal taga">
                  FIRST PUBLISHED{" "}
                    <span className="colr">
                      {" "}
                      {""}
                      <div dangerouslySetInnerHTML={{__html:formatDate(journalData?.createdAt)}}></div>
                      {/* {societyJournal === "Society Journal"
                        ? formatDate(journalData?.createdAt)
                        : formatDate(journalData?.createdAt)} */}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="maindiv bg journals-main-div">
            <div className="div30  ">
              <div className="journalslist custom-journalist-class">
                <div className=" align-items-center">
                  <div className="ag_heading_center_wrap our-partner mt-0 info-custom">
                    <h2 className="tagh2">Information</h2>
                  </div>
                </div>
                <ul className="journalInfoList">
                  <li class="custom-li-journal tagli">
                    <Link
                      to="javascript:;"
                      className={activeType === "description" ? "active" : null}
                      onClick={() => setActiveType("description")}
                    >
                      Description
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      to="javascript:;"
                      className={activeType === "aimsandscope" ? "active" : null}
                      onClick={() => {setActiveType("aimsandscope");scrollToaimsandscope()}}
                    >
                      Aims and Scope
                    </Link>
                  </li>
                  {societyJournal === "Society Journal" ? (
                    <li class="custom-li-journal tagli">
                      <Link
                        to={`editorial-board`}
                        onClick={() => {
                          setActiveType("editorial-board");
                        }}
                        state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                        className={
                          activeType === "editorial-board" ? "active" : null
                        }
                      >
                        Editorial Board
                      </Link>
                    </li>
                  ) : (
                    <li class="custom-li-journal tagli">
                      <Link
                        to={`/explore-journals/${id}/publishing-editor`}
                        state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                        onClick={handleScrollTop}
                      >
                        Publishing Editors
                      </Link>
                    </li>
                  )}
                  <li class="custom-li-journal tagli">
                    <Link
                      to={"all-articles"}
                      state={{ journalName: journalData?.journalName, journalId: journalData?._id }}
                      className={
                        activeType === "access-articles" ? "active" : null
                      }
                      onClick={() => setActiveType("access-articles")}
                    >
                      Access Articles
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      to="/author-submission-guidelines"
                      target="_blank"
                      className={
                        activeType === "author-guidelines" ? "active" : null
                      }
                    >
                      Author Guidelines
                    </Link>
                  </li>
                 
                  
                 
                  

                  {societyJournal === "Society Journal" ? null : (
                    <li class="custom-li-journal tagli">
                      <Link
                        to="/how-we-publish"
                        target="_blank"
                        className={
                          activeType === "publishing-model" ? "active" : null
                        }
                      >
                        Publishing Model
                      </Link>
                    </li>
                  )}

                  <li class="custom-li-journal tagli">
                    <Link
                      className={
                        activeType === "content-type" ? "active" : null
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("content-type");
                        setShow(true);
                      }}
                    >
                      Content Type
                    </Link>
                  </li>
                  
                  <li class="custom-li-journal tagli">
                    <Link
                      className={activeType === "recommend" ? "active" : null}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("recommend");
                        setShowRec(true);
                        setModalPageName(pageName);
                        setJournalModalName(journalName);
                      }}
                    >
                      Recommend
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      className={activeType === "advertising" ? "active" : null}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveType("advertising");
                        setShowAdvertising(true);
                      }}
                    >
                      Advertise With Us
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                       to="/registration"
                    >
                     Become a Reviewer 
                    </Link>
                  </li>
                  <li class="custom-li-journal tagli">
                    <Link
                      to="/journal/get-in-touch"
                      target="_blank"
                      className={
                        activeType === "get-in-touch" ? "active" : null
                      }
                    >
                      Get in Touch
                    </Link>
                  </li>
                </ul>
              </div>
              {societyJournal === "Society Journal" ? null : (
                <div className="journalslist martop-10 main-class-publish">
                  <div className=" align-items-center publish-article-custom">
                    <div className="publishArtWrap">
                      <p>
                        Publish Multidisciplinary and Interdisciplinary articles
                        with just one click.
                      </p>
                      {!token && (
                        <Link
                          to="javascript:;"
                          onClick={() =>
                            isMobile ? setMobileAlert(true) : navigate("/login")
                          }
                          className="ag_btn_red taga"
                        >
                          Publish Article
                        </Link>
                      )}
                      {token && (
                        <Link
                          onClick={() => {
                            isMobile
                              ? setMobileAlert(true)
                              : setUploadArticle(true);
                          }}
                          className="ag_btn_red taga"
                        >
                          Publish Article
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="journalslist martop-10 main-class-publish">
               
                <h2 className="font22 custom-24 tagh2 text-center">Email Alerts</h2>
                {/* <p>Sign up to receive Email alerts</p> */}
                <Link
                  to="/registration"
                  onClick={(e) => handleRedirect(e, "/registration")}
                  className="ag_btn_red signup-btn-journal taga"
                >
                  SIGN UP
                </Link> 
              </div>
              <div className="journalslist martop-10 main-class-publish px-3">
               
                <h2 className="font22 custom-24 tagh2 text-center"> Journal-related Queries</h2>
                <p className="text-center fs-21px"><u>journal.mwp@gmail.com</u></p>
                {/* <Link
                  to="/registration"
                  onClick={(e) => handleRedirect(e, "/registration")}
                  className="ag_btn_red signup-btn-journal taga"
                >
                
                </Link>  */}
              </div>
              <div className=" martop-10 ">
                {journalData?.advertisement?.addSpotFirst?(
                  <>
                  <Advertisement 
                        pagename="societypage"
                        details={{ img: journalData?.advertisement?.addSpotFirst ? journalData?.advertisement?.addSpotFirst : adsport }}
                      />
                  </>
                  ):null}
              </div>
            </div>
            <div className="div70">
              <div className="journalBackWrap">
                {/* <span>
                  {societyJournal === "Society Journal"
                    ? "Society Homepage"
                    : "Journal Homepage"}
                </span> */}
                <span className="">
                   <a href={`https://doi.org/${journalData?.doiurl}`}>https://doi.org/{journalData?.doiurl}</a>
                </span>
                {!token && (
                  <Link
                    to="javascript:;"
                    onClick={() =>
                      isMobile ? setMobileAlert(true) : navigate("/login")
                    }
                    className="ag_btn_red ag_btn_redclr "
                  >
                    Submit Article
                  </Link>
                )}
                {token && (
                  <Link
                    onClick={() => {
                      isMobile ? setMobileAlert(true) : setUploadArticle(true);
                    }}
                    className="ag_btn_red ag_btn_redclr  "
                  >
                    Submit Article
                  </Link>
                )}
                
              </div>
              <h1 class="marlef50 content-journal">{journalData?.journalName}</h1>
              <div className="marlef50 content-journal" dangerouslySetInnerHTML={{__html:first150Words}}></div>
              
              <div className="marlef50 content-journal mt-2" dangerouslySetInnerHTML={{__html:restOfWords}}></div>
              <p className="underline_or_head marlef50 scroll-mt-95"  ref={aimsandscope}>Aims and Scope</p>
              <div className="marlef50 content-journal" dangerouslySetInnerHTML={{__html:journalData?.aimAndScope}}></div>

              {/* {journalData?.journalName ===
                "Samaj Shastra – The Mega Journal of Social Sciences" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal">
                    The term <b>“Samaj Shastra,”</b> which translates to “Social
                    Science” in Hindi, is an Open Access Mega Journal, making
                    high-quality Social Science research accessible to a global
                    audience from India. The Mega Journal, welcomes
                    contributions from all subfields within social sciences,
                    such as sociology, psychology, anthropology, political
                    science, and more.
                  </p>
                  
                  <p className="marlef50 content-journal">
                    Samaj Shastra provides a comprehensive framework for
                    understanding and analyzing the complexities of human
                    society, its structures, institutions, and interactions.
                    Researchers and scholars specializing in Samaj Shastra
                    engage in empirical studies, theoretical analyses, and
                    critical examinations of social phenomena, aiming to uncover
                    patterns, explore social dynamics, and contribute to the
                    existing body of knowledge. Its inclusive approach fosters
                    interdisciplinary collaboration, encouraging researchers to
                    explore the intersections of different disciplines and
                    promote holistic understanding of societal dynamics.
                  </p>
                  
                  <p className="marlef50 content-journal">
                    We pride ourselves on offering both pre-publishing and
                    post-publishing peer review options, ensuring the utmost
                    rigor and credibility in the research we publish. Moreover,
                    Ayur Yoga boasts one of the world’s most affordable Article
                    Processing Charges (APCs), ensuring accessibility for
                    researchers globally.
                  </p>
                   
                  <p className="marlef50 content-journal">
                    Join us on this exciting journey as we redefine the
                    boundaries of social science research and strive for a more
                    inclusive and informed world.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "Chanakya Shastra – The Mega Journal of Business and Management" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal tagp">
                    <b>Chanakya Shastra,</b> The Mega Journal of Business and
                    Management, aims to provide a platform for researchers,
                    academicians, and practitioners to share their cutting-edge
                    insights, transformative ideas, and rigorous scholarly
                    research in diverse areas of business and management.
                  </p>
                   
                  <p className="marlef50 content-journal tagp">
                    Chanakya Shastra derives its name from the ancient Indian
                    text attributed to Chanakya, an eminent scholar and advisor
                    to the Mauryan Empire.
                  </p>
                  
                  <p className="marlef50 content-journal tagp">
                    With a broad and inclusive scope, Chanakya Shastra welcomes
                    contributions from various disciplines, promoting
                    interdisciplinary dialogue and collaboration. We encourage
                    submissions related to general management, marketing,
                    finance and accounting, operations and supply chain
                    management, human resources management, international
                    business, organizational theory and behaviour, business
                    ethics and corporate social responsibility, information
                    systems and technology management, and business law and
                    regulations amongst others.
                  </p>
                   
                  <p className="marlef50 content-journal tagp">
                    We also welcome studies that explore emerging trends,
                    disruptive technologies, and transformative business models
                    that shape the future of organizations.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "Charakh Shastra – The Mega Journal of Medicine" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal">
                    <b>Charakh Shastra,</b> The Mega Journal of Medicine aims to
                    be a comprehensive platform for the publication of
                    high-quality research in the field of medicine, encompassing
                    various disciplines and specialties.
                  </p>
                 
                  <p className="marlef50 content-journal">
                    The name Charakh Shastra is inspired by Charakh Samhita,
                    written by the sage Charakh, which provides valuable
                    insights into the principles of disease management,
                    prevention, and overall well-being.
                  </p>
                 
                  <p className="marlef50 content-journal">
                    We are dedicated to advancing the field of medicine through
                    the dissemination of high-quality, peer-reviewed research.
                    With a commitment to open access, we aim to make valuable
                    medical knowledge freely available to a global audience,
                    ensuring that healthcare professionals, researchers, and the
                    general public can benefit from the latest advancements in
                    medical science. By promoting evidence-based medicine and
                    highlighting innovative approaches to healthcare delivery,
                    our journal aims to improve patient outcomes, inform medical
                    practice, and shape the future of medicine.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga" && (
                <div className="megaAlign">
                  <p className="marlef50 content-journal tagp">
                    Welcome to <b>AyurYoga Shastra</b>, The Mega Journal of Ayurveda and Yoga.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    'Ayur' embodies the holistic system of Ayurveda, and 'Yoga' signifies the union of mind, body, and spirit. AyurYoga Shastra is the Mega Journal dedicated to the convergence of Ayurveda and Yoga.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    AyurYoga Shastra is committed to propagating ancient wisdom while embracing modern scientific insights. Our journal operates under a Gold Open Access policy, ensuring that transformative research in Ayurveda and Yoga reaches enthusiasts worldwide.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    With an automated submission and processing system, authors experience a seamless journey, complemented by an affordable Article Processing Charge, fostering inclusivity and accessibility.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    At AyurYoga Shastra speed meets quality; our meticulous Peer Review process ensures publication within 30 to 45 days, maintaining the highest academic standards.
                  </p>
                  
                  <p className="marlef50 content-journal tagp">
                    We deeply value the transformative power of shared wisdom, fuelling progress and innovation in Ayurveda and Yoga. Our author dashboards prioritize transparency and ease, offering comprehensive information at authors' fingertips, ensuring a hassle-free experience.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    AyurYoga Shastra promotes interdisciplinary and multidisciplinary collaboration, acknowledging that the holistic realms of Ayurveda and Yoga often require diverse perspectives.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    Embracing this ethos, we cultivate an environment where ancient traditions blend with modern discoveries, fostering a dynamic exchange of ideas and holistic wellness solutions.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "Soochna Shastra – The Mega Journal of Information Technology" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal">
                    Introducing <b>Soochna Shastra</b>, The Mega Journal of Information Technology, where ‘Soochna’ signifies Information in Hindi.
                  </p>
                
                  <p className="marlef50 content-journal">
                    At Soochna Shastra, we've embarked on a journey to redefine accessibility and excellence in scholarly publishing. As advocates for openness, our journal operates on a Gold Open Access policy, ensuring that ground-breaking research in Information Technology reaches every corner of the globe. With our streamlined, fully automated submission and processing system, authors experience a seamless journey from submission to publication, making it hassle-free.
                  </p>
                
                  <p className="marlef50 content-journal">
                    Our core mission at Soochna Shastra is to be the catalyst propelling the advancement of IT knowledge and innovation. Through our state-of-the-art platform, we foster global visibility, inviting researchers, scholars, and IT experts from diverse backgrounds to share their insights and discoveries.
                  </p>
                   
                  <p className="marlef50 content-journal">
                    We pride ourselves on offering one of the most affordable Article Processing Charges, making scholarly contributions accessible without financial barriers. Our commitment to efficiency doesn’t compromise quality; typical articles are meticulously reviewed and published between 30 to 45 days. Our author dashboards offer transparency and comprehensive information, ensuring authors have all they need at their fingertips, just a click away.
                  </p>
                 
                  <p className="marlef50 content-journal">
                    Navigate through our discipline link to explore the diverse subjects we cover, each contributing to the rich tapestry of Information Technology.
                  </p>
                 
                  <p className="marlef50 content-journal">
                    Soochna Shastra thrives on promoting interdisciplinary collaboration, acknowledging that IT challenges often demand multidisciplinary solutions. Embracing this approach, we cultivate an environment where diverse perspectives converge, fostering a dynamic exchange of ideas and enabling holistic problem-solving.
                  </p>
                   
                  <p className="marlef50 content-journal">
                    Join us on this pioneering journey at Soochna Shastra, where innovation meets accessibility, and knowledge knows no bounds.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "Vigyan Shastra – The Mega Journal of Sciences" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal">
                    Vigyan, the embodiment of 'Science' in Hindi, encapsulates curiosity, exploration, and the relentless pursuit of knowledge.
                  </p>
                 
                  <p className="marlef50 content-journal">
                    Welcome to <b>Vigyan Shastra</b>, the Mega Journal dedicated to the expansive world of Science.
                  </p>
                  
                  <p className="marlef50 content-journal">
                    Vigyan Shastra pursues an unwavering dedication to openness. Our Gold Open Access policy ensures that scientific research transcends boundaries. With an automated submission and processing system, authors experience a seamless journey, complemented by one of the most affordable Article Processing Charges worldwide, ensuring accessibility for all.
                  </p>
                   
                  <p className="marlef50 content-journal">
                    At Vigyan Shastra, speed meets quality; our meticulous Peer Review process ensures publication within 30 to 45 days, maintaining the highest academic standards.
                  </p>
                  
                  <p className="marlef50 content-journal">
                    Explore our discipline link to embark on a journey through the diverse subjects that converge under the vast umbrella of Science.
                  </p>
                  
                  <p className="marlef50 content-journal">
                    We firmly believe in the transformative potential of shared knowledge, fuelling progress and innovation in Science. Our author dashboards prioritize transparency and convenience, offering all necessary information at the authors' fingertips, just a click away. Acknowledging the urgency of timely publication, we take pride in our ability to expedite the process without compromising academic integrity.
                  </p>
                   
                  <p className="marlef50 content-journal">
                    Vigyan Shastra thrives on fostering interdisciplinary and multidisciplinary collaboration, recognizing that complex scientific challenges demand diverse perspectives.
                  </p>
                </div>
              )}

              {journalData?.journalName ===
                "Abhiyantran Shastra – The Mega Journal of Engineering" && (
                  <div className="megaAlign">
                  <p className="marlef50 content-journal tagp">
                    <b>Abhiyantran Shastra</b>, The Mega Journal of Engineering is committed to the expansive realm of Engineering.
                  </p>
                   
                  <p className="marlef50 content-journal tagp">
                    Abhiyantran Shastra champions excellence and is committed to openness as reflected in our Gold Open Access policy. With an automated submission and processing system, we ensure a seamless journey for authors, backed by our pledge to provide one of the most affordable Article Processing Charges globally, making scholarly contributions attainable for all.
                  </p>
                  
                  <p className="marlef50 content-journal tagp">
                    Speed and quality are non-negotiable at Abhiyantran Shastra; our rigorous Peer Review process ensures that articles see the light of publication within 30 to 45 days.
                  </p>
                  
                  <p className="marlef50 content-journal tagp">
                    Navigate through our discipline link to delve into the diverse array of subjects encapsulated within the vast field of Engineering.
                  </p>
                   
                  <p className="marlef50 content-journal tagp">
                    We firmly believe in the transformative power of shared knowledge, fostering progress and innovation in Engineering. Our author dashboards are designed for transparency and convenience, putting all necessary information at the authors' fingertips, just a click away.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    Abhiyantran Shastra thrives on promoting interdisciplinary collaboration as we understand that engineering challenges often necessitate diverse solutions. Embracing this ethos, we cultivate an environment where varied perspectives converge, facilitating a vibrant exchange of ideas and holistic problem-solving.
                  </p>
                 
                  <p className="marlef50 content-journal tagp">
                    Join us on this journey at Abhiyantran Shastra, where the realms of innovation and engineering converge, breaking barriers and shaping the future of the discipline.
                  </p>
                </div>
              )}
              {journalData?.journalName ===
                "Society Journals – The Society Journal of Sciences" && (
                  <div className="megaAlign">
                    <p className="marlef50 content-journal tagp">
                      Aims to provide a platform for researchers, academicians,
                      and practitioners to share their cutting-edge insights,
                      transformative ideas, and rigorous scholarly research in
                      diverse areas of business and management.
                    </p>
                     
                    <p className="marlef50 content-journal tagp">
                      Chanakya Shastra derives its name from the ancient Indian
                      text attributed to Chanakya, an eminent scholar and
                      advisor to the Mauryan Empire.
                    </p>
                    
                    <p className="marlef50 content-journal tagp">
                      With a broad and inclusive scope, Chanakya Shastra
                      welcomes contributions from various disciplines, promoting
                      interdisciplinary dialogue and collaboration. We encourage
                      submissions related to general management, marketing,
                      finance and accounting, operations and supply chain
                      management, human resources management, international
                      business, organizational theory and behaviour, business
                      ethics and corporate social responsibility, information
                      systems and technology management, and business law and
                      regulations amongst others.
                    </p>
                     
                    <p className="marlef50 content-journal tagp">
                      We also welcome studies that explore emerging trends,
                      disruptive technologies, and transformative business
                      models that shape the future of organizations.
                    </p>
                     
                  </div>
              )}
               */}

              {/* {societyJournal === "Society Journal" &&
                activeType !== "editorial-board" && (
                  <div className="megaAlign">
                    <p className="marlef50 content-journal tagp">
                      Aims to provide a platform for researchers, academicians,
                      and practitioners to share their cutting-edge insights,
                      transformative ideas, and rigorous scholarly research in
                      diverse areas of business and management.
                    </p>
                     
                    <p className="marlef50 content-journal tagp">
                      Chanakya Shastra derives its name from the ancient Indian
                      text attributed to Chanakya, an eminent scholar and
                      advisor to the Mauryan Empire.
                    </p>
                    
                    <p className="marlef50 content-journal tagp">
                      With a broad and inclusive scope, Chanakya Shastra
                      welcomes contributions from various disciplines, promoting
                      interdisciplinary dialogue and collaboration. We encourage
                      submissions related to general management, marketing,
                      finance and accounting, operations and supply chain
                      management, human resources management, international
                      business, organizational theory and behaviour, business
                      ethics and corporate social responsibility, information
                      systems and technology management, and business law and
                      regulations amongst others.
                    </p>
                     
                    <p className="marlef50 content-journal tagp">
                      We also welcome studies that explore emerging trends,
                      disruptive technologies, and transformative business
                      models that shape the future of organizations.
                    </p>
                     
                  </div>
                )} */}

              {activeType === "editorial-board" && <EditorialBoard />}

              {/* {activeType === "editorial-board" ? null : (
                <>
                  <div class="horizontal-line">
                    <hr />
                  </div>
                  <div className="ag_heading_center_wrap our-partner mt-0 advantages">
                    <h2 className="font31 font-48 tagh2">
                      Advantages Of Publishing With Aakashganga
                    </h2>
                  </div>

                  <div className="bgimggroup main-group-btns">
                    <div className="maingroup ">
                      <div>
                        <p className="groupcss tagp">
                          Single-View Dashboards; Transparent And Detailed On
                          Your Fingertips
                        </p>
                      </div>

                      <div>
                        {" "}
                        <p className="groupcss tagp">
                          Option Of Pre-Publishing & Post-Publishing Peer
                          Reviews
                        </p>
                      </div>

                      <div>
                        <p className="groupcss tagp">
                          A Fully Automated Publishing Platform
                        </p>
                      </div>

                      <div>
                        {" "}
                        <p className="groupcss tagp">Multiple Article Types</p>
                      </div>

                      <div>
                        <p className="groupcss tagp">Multiple Article Types</p>
                      </div>

                      <div>
                        <p className="groupcss tagp">Resources To Learn</p>
                      </div>

                      <div>
                        <p className="groupcss tagp">Quick Support</p>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
              <p className="underline_or_head marlef50">Published Articles</p>
              <div className="row recommendedMain marlef50" >
                                        {articleData && articleData?.data?.length > 0 ? (
                                            articleData?.data?.map((articleRes, index) => {
                                                const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                                                const contentWithoutSpecialChars = () => {
                                                    return articleRes?.abstract?.replace(
                                                        specialCharPattern,
                                                        ""
                                                    );
                                                };
                                                return (
                                                    <div className="col-md-12 p-0">
                                                    
                                                        <NewRelatedArticles
                                                            index={index}
                                                            published={articleRes?.updatedAt}
                                                            id={articleRes?.readinglistId}

                                                            title={articleRes?.title}
                                                            userTitle={
                                                                articleRes?.user?.[0]?.title
                                                            }
                                                            pageName={"society-homepage"}
                                                            slug={articleRes?.slug}
                                                            userName={
                                                              (articleRes?.authorDetails?.author!="" && articleRes?.authorDetails != null)
                                                                ? articleRes?.authorDetails?.author
                                                                : articleRes?.user
                                                            }
                                                            {...articleRes?.authorDetails?.author?.map((autName) => {
                                                                return (
                                                                    <>
                                                                        {autName.title}
                                                                        {autName.fullName}
                                                                    </>
                                                                );
                                                            })}
                                                            contentWithoutSpecialChars={contentWithoutSpecialChars}
                                                            typeOfArticle={articleRes?.typeofarticles?.[0]?.articleName}
                                                            journalName={
                                                                articleRes?.submissionDetail?.journalName
                                                            }
                                                            journalType={
                                                              articleRes?.journalType?.[0]?.journalType
                                                          }
                                                            subjectName={articleRes?.subjectDetail?.[0]?.subjectName}
                                                            specialization={articleRes?.specializations?.[0]?.specialization}
                                                            fetchReadingList={fetchLatestArticlesData}
                                                            readingList={articleData}
                                                            articleId={articleRes?._id}
                                                            readingflag={articleRes?.readingflag}
                                                        />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="tabContentWrap" style={{borderRadius:"20px",minHeight:"200px",border:"1px solid #d6d6d675"}}> 
                                                <div className="notArticle">
                                                    <figure>
                                                        <img src={notyet} alt="#" />
                                                    </figure>
                                                    <h2>Coming Soon!</h2>

                                                </div>
                                            </div>
                                        )}
                                        {latestArticlesTotalPages > 1 && (
                                            <div className="paginationWrap mt-4">
                                                <Pagination
                                                    boundaryRange={1}
                                                    firstItem={null}
                                                    lastItem={null}
                                                    siblingRange={1}
                                                    activePage={latestPage}
                                                    prevItem={{ content: <b class="icon-arrow-left" /> }}
                                                    nextItem={{ content: <b class="icon-arrow-right" /> }}
                                                    onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                    totalPages={latestArticlesTotalPages}
                                                />
                                            </div>
                                        )}
                                    </div>
            </div>
          </div>
        </div>

        {activeType === "editorial-board" ? null : (
          <div className="ag_main_wrap ag_opportunities_wrap custom-journal-last">
            <div className="container">
              <div className="row justify-content-center align-items-center mt-5 fulldiv-blog-type">
                <div className="col-12 align-items-center mb-3">
                  <div className="ag_heading_center_wrap interested-head">
                    <h2 className="cus-title font-48 tagh2">
                      You Might Also Be Interested In
                    </h2>
                  </div>
                </div>
                {showComponent && (
                <NewInterestedSection staticPageLinks={journalData?.staticPageLinks} />
                )}
                {/* <InterestedSection  /> */}
              </div>
            </div>
          </div>
        )}

        {/* <!-- NewsLetter Start --> */}
        <NewsLetter />
        <SubmitArticleModal
          uploadArticle={uploadArticle}
          setUploadArticle={setUploadArticle}
        />
        <AakashgangaFooter />

        <Modal
          show={showAdvertising}
          size="lg"
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter markitPopupRecomendBox"
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-2">Advertise With Us</h2>
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowAdvertising(false);
                setActiveType("description");
              }}
              aria-label="Close"
            />
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="advertisingModal">
              <img src="/assets/images/advertising.png" alt="advertising" />
              <div className="advertisingContent">
                <span className="colorBlack">
                  For advertising inquiries, write to us at
                </span>{" "}
                <a href="mailto:contactus@vikramshilaedu.in">
                  contactus@vikramshilaedu.in
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
          className="markitPopupBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setActiveType("description")}
          >
            <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizemod ml-2">
                Aakashganga currently accepts the following types of research
                articles for publishing
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2">
            <div className="px-2 text-center">
              <p className="f-14">
                <ul className="contentModal">
                  <li className="section">
                    <strong>Original Research:</strong> These are original
                    research studies presenting new findings and insights in any
                    field of science or medicine.
                  </li>
                  <li className="efforts">
                    <strong>Review Articles:</strong> These provide
                    comprehensive and critical reviews of existing research,
                    summarizing and analysing the current state of knowledge on
                    a specific topic.
                  </li>
                  <li className="efforts">
                    <strong>Systematic Review:</strong> A comprehensive and
                    structured method of synthesizing and analyzing existing
                    research studies and evidence on a specific research
                    question or topic.
                  </li>
                  <li className="efforts">
                    <strong>Methodology Articles:</strong> These describe new
                    methods or techniques developed for research or data
                    analysis. Methodology articles focus on the technical
                    aspects of research and often include detailed protocols or
                    algorithms.
                  </li>
                  <li className="efforts">
                    <strong>Data Articles:</strong> These focus primarily on
                    data sets, describing and providing access to large data
                    collections or databases. Data articles may include data
                    descriptions, data analyses, and data visualization.
                  </li>
                  <li className="efforts">
                    <strong>Case Reports:</strong> These describe unique or
                    interesting medical or clinical cases that contribute to the
                    understanding of a particular disease, treatment, or
                    condition.
                  </li>
                  <li className="efforts">
                    <strong>Short Reports:</strong> These are concise research
                    papers presenting new and significant findings but with a
                    shorter format compared to full research articles.
                  </li>
                  <li className="efforts">
                    <strong>Perspectives or Opinions:</strong> These articles
                    express the authors’ perspectives, opinions, or commentaries
                    on a specific scientific, social, or ethical issue.
                  </li>
                  <li className="efforts">
                    <strong>Conceptual Articles:</strong> These articles propose
                    new concepts, frameworks, or theoretical models, often based
                    on existing research or literature.
                  </li>
                  <li className="efforts">
                    <strong>Commentaries:</strong> Commentaries offer critical
                    analysis or perspective on previously published research or
                    current issues within a field.
                  </li>
                  <li className="efforts">
                    <strong>Book Reviews:</strong> These articles evaluate and
                    critique recently published books relevant to the scope of
                    the journal.
                  </li>
                </ul>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>

        <Modal
          show={showRec}
          onHide={() => setShowRec(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="markitPopupRecomendBox"
        >
          <Modal.Header
            className="justify-content-center closeButtonStyle"
            closeButton
            onClick={() => setActiveType("description")}
          >
            <Modal.Title className="modal-title">
              <h2 className="fontsizeshastra">Recommend {modalPageName}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-2 text-left recommendModalBox">
            <strong>Share it on:</strong>{" "}
            <ul className="ag_ftr_socials contentModal">
              <li>
                <FacebookShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtags={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-facebook.svg"
                      alt="Facebook Icon"
                    />
                  </b>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={SHARE_URL}
                  title={title}
                  hashtag={"aakashganga"}
                >
                  <b>
                    <img
                      src="/assets/images/icon-twitter.svg"
                      alt="Twitter Icon"
                    />
                  </b>
                </TwitterShareButton>
              </li>
              <li>
                <LinkedinShareButton url={SHARE_URL} title={title}>
                  <b>
                    <img
                      src="/assets/images/icon-linkedIn.svg"
                      alt="LinkedIn Icon"
                    />
                  </b>
                </LinkedinShareButton>
              </li>
            </ul>
            <hr className="journalHr" />
            <strong>Copy Link:</strong>{" "}
            <div className="dis">
              <div class="ag_newsletter_input widrec">
                <input
                  type=""
                  value={SHARE_URL}
                  placeholder="Copy Link"
                ></input>
              </div>
              <a
                href="javascript:;"
                onClick={handleCodeCopy}
                className=" ag_btn_red ag_btn_redclr copy"
              >
                Copy
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-3 pb-3 contactModalFooter"></Modal.Footer>
        </Modal>
        <MobileModalAlert
          mobileAlert={mobileAlert}
          setMobileAlert={setMobileAlert}
        />
      </div>
    </>
  );
}

export default SamajShastraJournalComponent;
