import React, { useEffect, useState, useRef } from "react";
//import jwt_decode from "jwt-decode";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { searchJournalAndArticle } from "../../store/apiCalls/dashboard";
import { getAllMasterData } from "../../store/apiCalls/fetchmaster";
import Notifications from "../notifications";
import SubmitArticleModal from "../Modal/SubmitArticleModal";
import MobileModalAlert from "../Modal/MobileModalAlert";
import MobileModalReg from "../Modal/MobileMobleReg";
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

function HeaderComponent() {
  const listElement = useRef(null);
  const { user } = useSelector((state) => state?.LoginReducer);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlertReg, setMobileAlertReg] = useState(false);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const [journalNameforurl, setjournalNameforurl] = useState("");
  const [subjectNameforurl, setsubjectNameforurl] = useState("");
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const activePath = window.location.pathname;
  const [activeType, setActiveType] = useState("Home");
  const [artcileList, setArtcileList] = useState([]);
  const [journalList, setJournalList] = useState([]);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("usertoken");
  const [searchStatus, setSearchStatus] = useState(false);
  const [gernalType, setgernalType] = useState([]);
  const [societylist, setsocietylist] = useState([]);
  const [subjectlist, setsubjectlist] = useState([]);

  async function getMasterList() {
    const msterlst = await getAllMasterData();
    // setsubjectlist(msterlst?.data?.subjectdata);
    const subjectMapping = {};
    msterlst?.data?.subjectdata.forEach(subject => {
      subjectMapping[subject._id] = subject.subjectName;
    }); 
    if (msterlst.status === 200) {
      const journalType = [];
      const societyjournalType = [];
      
      msterlst?.data?.allJournal.forEach((element) => {
        const JournalTitle = element?.journalName.split(" – ");
        const data = {
          key: element._id,
          slug: element?.slug,
          subjectName: subjectMapping[element?.subjectData?.[0]?.subject] || 'Unknown Subject',
          value: JournalTitle?.[0],
          Dec: JournalTitle?.[1],
          SocietyName: element?.societyName,
          journalName: element?.journalName,
        };
        if (element?.societyName == "society" && element?.launch==true) {
          societyjournalType.push(data);
        }
        journalType.push(data);
      });
      setgernalType(journalType); 
      setsocietylist(societyjournalType);
      
    }
    // console.log("societylist",societylist);
  }
  const formatUrl = (inputString) => {
    if (!inputString) {
      return ''; // Return an empty string or handle the case appropriately
  }
    let formattedString = inputString.toLowerCase(); 
    formattedString = formattedString.replace(/\s/g, '-');
    return formattedString;
  }
 
  const handlejournalNameforurl = (journalType,typeOfArticle) => {
    var fname = "";
    if (journalType=="Mega Journal" && typeOfArticle=="Conference Papers") {
      fname = formatUrl("Conference Paper");
    }else{
      fname = formatUrl(journalType);
    }
    return fname;
  }
  const handlesubjectName = (subjectName) => {
    const subjectMapping = {
      "Medicine and Health Sciences": "medicine",
      "Business, Management and Communication": "business-management",
      "Social Science and Humanities": "social-science",
      "Science": "science",
      "Engineering": "engineering",
      "Information Technology": "information-technology",
      "Ayurveda": "ayurveda"
    };
  
    const fname = subjectMapping[subjectName] || "";
    // console.log("subjectName", subjectName);
    // setsubjectNameforurl(fname);
    return fname;
  };
  const getActiveUser = (e) => {
    const dropdownToggleVal = localStorage.getItem("dropdownToggleVal");
    if (dropdownToggleVal === "Author") {
      handleRedirect(e, "/dashboard");
    } else if (dropdownToggleVal === "Peer Reviewer") {
      handleRedirect(e, "/dashboard/peer-reviewer");
    } else if (dropdownToggleVal === "PublishingEditor") {
      handleRedirect(e, "/dashboard/publishing-editor");
    } else {
      if (user?.role === "Publish Editor") {
        handleRedirect(e, "/dashboard/publishing-editor");
      } else if (user?.role === "Peer Reviewer" || user?.role === "Author") {
        handleRedirect(e, "/dashboard");
      }
    }
  };

  const searchJournalArticle = async (search) => {
    const headers = { Authorization: `Bearer ${""}` };
    const params = {
      search,
      page: 1,
      limit: 10,
      sorting: -1, 
    };
    const response = await searchJournalAndArticle(headers, params);
    setArtcileList(response?.data?.data);
    setJournalList(response?.data?.journal);
  };
  // console.log("journalList", journalList[0]);

  const handleClick = event => {
    if (hamburgerMenu) {
      setHamburgerMenu(false);
    } else {
      setHamburgerMenu(true);
    }

    event.currentTarget.classList.toggle('opened');
    event.currentTarget.setAttribute('aria-expanded', event.currentTarget.classList.contains('opened'));
  };

  useEffect(() => {
    getMasterList();
    if (search) {
      searchJournalArticle(search);
    }
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Attach the event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [search]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmSource = searchParams.get('utm_source');
  // Accessing UTM parameters
  if (!utmSource) {
    var allutm = "";
  } else {
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmContent = searchParams.get('utm_content');

    var allutm = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent}`;
  }

  return (
    <div className="ag_header_wrap d-flex flex-column">
      <div className={token ? "ag_header_bottom header_active" : "ag_header_bottom"}>
      {/* container markitingHeader */}
        <div className="px-4 ">
          <div className="header-main-row justify-content-between align-items-center">
            <div className="main-logo-part">
              <div className="ag_logo">
                <Link to="/" onClick={() => navigate("/")}>
                  <img
                    className="tagimg"
                    src="/assets/images/logo.png"
                    alt="Aakashganga Logo"
                  />
                </Link>
              </div>
            </div>
            <div className={hamburgerMenu ? 'menu-center-part active' : 'menu-center-part'}>
              <div className="ag_menu">
                <ul className="tagul menulist">
                  <li className={activePath === "/" ? "active tagli" : "tagli"}>
                    <Link
                      to="/"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                    </Link>
                  </li>

                  <li className={activePath === "/about-us" ? "active tagli" : "tagli"}>
                    <Link
                      to="/about-us"
                      onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
                    >
                      About
                    </Link>
                  </li>

                  <li
                    className={
                      activeType === "MegaJournals" ? "active tagli" : "tagli"
                    }
                  >
                    <Link
                      to="javascript:;"
                      onClick={() => setActiveType("MegaJournals")}
                    >
                      Mega Journals <img src="/assets/images/downarrow.png" alt="Down Arrow" />
                    </Link>
                    <ul className="tagul subNavBox">
                      {gernalType &&
                        gernalType?.length > 0 &&
                        gernalType?.map((data) => {
                          return (
                            <>
                              {data?.SocietyName === "mega" &&
                                <li>
                                  <div className="manageNavi">
                                    <Link
                                      to={
                                        data?.journalName === 'Samaj Shastra – The Mega Journal of Social Sciences' ? '/mega-journals/social-sciences'
                                          : data?.journalName === 'Chanakya Shastra – The Mega Journal of Business and Management' ? '/mega-journals/business-management'
                                            : data?.journalName === 'Charakh Shastra – The Mega Journal of Medicine' ? '/mega-journals/medicine'
                                              : data?.journalName === 'Abhiyantran Shastra – The Mega Journal of Engineering' ? '/mega-journals/engineering'
                                                : data?.journalName === 'Soochna Shastra – The Mega Journal of Information Technology' ? '/mega-journals/information-technology'
                                                  : data?.journalName === 'Vigyan Shastra – The Mega Journal of Sciences' ? '/mega-journals/sciences'
                                                    : data?.journalName === "AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga" ? '/mega-journals/ayurveda-and-yoga' : null}
                                      onClick={() =>
                                        window.scroll({
                                          top: 0,
                                          behavior: "smooth",
                                        })
                                      }
                                      state={{
                                        pageName: data?.value,
                                        journalName: data?.journalName,
                                        societyJournal: "Mega Journal"
                                      }}
                                    >
                                      {data?.value}
                                    </Link>
                                    <div className="sub-lines"> {data?.Dec}</div>
                                    <small className="liveJounral">Live</small>
                                  </div>
                                </li>
                              }
                            </>
                          );
                        })}
                    </ul>
                  </li>

                  <li
                    className={
                      activeType === "SocietyJournals"
                        ? "active tagli"
                        : "tagli"
                    }
                  >

                    <Link
                      className="pointerevents"
                      // to="/explore-journals/society-institute-university"
                      to="javascript:;"
                      onClick={() => setActiveType("SocietyJournals")}
                      state={{ societyJournal: "Society Journal" }}
                    >
                      Society Journals <img src="/assets/images/downarrow.png" alt="Down Arrow" />
                    </Link>
                    {/* <ul className="tagul societyUl subNavBox">
                      <li>Coming Soon</li>
                      </ul> */}
                    <ul className={societylist?.length === 0 ? 'tagul societyUl justify-content-center ps-0 flex-wrap subNavBox' : 'tagul flex-wrap justify-content-center ps-0 societyUl subNavBox'}>

                      {societylist &&
                        societylist?.length > 0 &&
                        societylist?.map((data, index) => {
                          return (
                            <>
                              {data?.SocietyName === "society" &&
                                <li >
                                  <div className="manageNavi">
                                    <Link
                                      // to={
                                      //   data?.journalName === 'Society Journals – The Society Journal of Sciences'?'/explore-journals/society-institute-university': null}
                                      to={`/society-journals/${formatUrl(data?.subjectName)}/${data?.slug}`}
                                      onClick={() =>
                                        window.scroll({
                                          top: 0,
                                          behavior: "smooth",
                                        })
                                      }
                                      state={{
                                        pageName: data?.value,
                                        journalName: data?.journalName,
                                        societyJournal: "Society Journal"
                                      }}
                                    >
                                      {data?.value}
                                    </Link>
                                    <div className="sub-lines"> {data?.Dec}</div>
                                    <small className="liveJounral">Live</small>
                                  </div>
                                </li>
                              }
                            </>
                          );
                        })}
                      {societylist?.length == 0 &&
                        <li>Coming Soon</li>
                      }
                    </ul>
                  </li>

                  <li
                    className={
                      activePath === "/conference-papers"
                        ? "active tagli"
                        : "tagli"
                    }
                  >
                    <Link
                      to={{ pathname: `/conference-papers` }}
                      onClick={() => {
                        navigate("/conference-papers"),
                        window.scroll({ top: 0, behavior: "smooth" })
                      }}
                    >
                      Conference Papers
                    </Link>
                  </li>
                  <li
                    className={
                      activePath === "/all-research-articles"
                        ? "active tagli"
                        : "tagli"
                    }
                  >
                    <Link
                      to={{ pathname: `/all-research-articles` }}
                      onClick={() => {
                        navigate("/all-research-articles");
                      }}
                    >
                      Articles
                    </Link>
                  </li>
                  <li
                    className={
                      uploadArticle ||
                        activePath === "/dashboard/submit-article"
                        ? "active bold-menu"
                        : "bold-menu"
                    }
                  >
                    {!token && (
                      <Link to="javascript:;" onClick={() => isMobile ? navigate("/login" + allutm) : navigate("/login" + allutm)}>
                        Publish Article
                      </Link>
                    )}
                    {token && (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          isMobile ? setUploadArticle(true) : setUploadArticle(true);
                        }}
                      >
                        Publish Article
                      </Link>
                    )}
                  </li>
                  {!token && (
                    <Link
                      to="javascript:;"
                      onClick={() => isMobile ? navigate("/registration" + allutm) : navigate("/registration" + allutm)}
                      // onClick={() =>isMobile ? setMobileAlertReg(true): navigate("/login")}
                      className="ag_btn mobileonly"
                    >
                      Register{" "}
                      <img
                        src="/assets/images/icon-login.png"
                        alt="Authentication Button Icon"
                      />
                    </Link>
                  )}
                  {token && (
                    <Link
                      to="/dashboard"
                      onClick={getActiveUser}
                      className="ag_btn mobileonly daskBtn"
                    >
                      My Dashboard{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M5.6 7.7H0.7C0.514348 7.7 0.336301 7.77375 0.205025 7.90503C0.0737498 8.0363 0 8.21435 0 8.4V13.3C0 13.4857 0.0737498 13.6637 0.205025 13.795C0.336301 13.9263 0.514348 14 0.7 14H5.6C5.78565 14 5.9637 13.9263 6.09497 13.795C6.22625 13.6637 6.3 13.4857 6.3 13.3V8.4C6.3 8.21435 6.22625 8.0363 6.09497 7.90503C5.9637 7.77375 5.78565 7.7 5.6 7.7ZM13.3 0H8.4C8.21435 0 8.0363 0.0737498 7.90503 0.205025C7.77375 0.336301 7.7 0.514348 7.7 0.7V5.6C7.7 5.78565 7.77375 5.9637 7.90503 6.09497C8.0363 6.22625 8.21435 6.3 8.4 6.3H13.3C13.4857 6.3 13.6637 6.22625 13.795 6.09497C13.9263 5.9637 14 5.78565 14 5.6V0.7C14 0.514348 13.9263 0.336301 13.795 0.205025C13.6637 0.0737498 13.4857 0 13.3 0ZM13.3 7.7H8.4C8.21435 7.7 8.0363 7.77375 7.90503 7.90503C7.77375 8.0363 7.7 8.21435 7.7 8.4V13.3C7.7 13.4857 7.77375 13.6637 7.90503 13.795C8.0363 13.9263 8.21435 14 8.4 14H13.3C13.4857 14 13.6637 13.9263 13.795 13.795C13.9263 13.6637 14 13.4857 14 13.3V8.4C14 8.21435 13.9263 8.0363 13.795 7.90503C13.6637 7.77375 13.4857 7.7 13.3 7.7ZM5.6 0H0.7C0.514348 0 0.336301 0.0737498 0.205025 0.205025C0.0737498 0.336301 0 0.514348 0 0.7V5.6C0 5.78565 0.0737498 5.9637 0.205025 6.09497C0.336301 6.22625 0.514348 6.3 0.7 6.3H5.6C5.78565 6.3 5.9637 6.22625 6.09497 6.09497C6.22625 5.9637 6.3 5.78565 6.3 5.6V0.7C6.3 0.514348 6.22625 0.336301 6.09497 0.205025C5.9637 0.0737498 5.78565 0 5.6 0Z"
                          fill="#167092"
                        />
                      </svg>
                    </Link>
                  )}
                </ul>
              </div>
            </div>

            <div className="formSide">
              <div className="ag_search_box ">
                <div
                  ref={dropdownRef}
                  className={
                    searchStatus === true && isOpen
                      ? "active maniSearch"
                      : "maniSearch"
                  }
                >
                  <input
                    type="search"
                    placeholder="Search for journals and articles"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      searchJournalArticle(e.target.value);
                    }}
                    onKeyDown={(e) => { (e.key === "Enter" && search != '') ? (navigate(`/search-result/${search}`), setSearch("")) : "" }}
                  />

                  <b
                    className={
                      search !== ""
                        ? "searchArrowIcon"
                        : "searchArrowIcon opacity-5"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={() => {
                        navigate(`/search-result/${search}`), setSearch("")
                      }}
                    >
                      <circle cx="12" cy="12" r="12" fill="#167092" />
                      <path
                        d="M8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75V11.25ZM17.5303 12.5303C17.8232 12.2374 17.8232 11.7626 17.5303 11.4697L12.7574 6.6967C12.4645 6.40381 11.9896 6.40381 11.6967 6.6967C11.4038 6.98959 11.4038 7.46447 11.6967 7.75736L15.9393 12L11.6967 16.2426C11.4038 16.5355 11.4038 17.0104 11.6967 17.3033C11.9896 17.5962 12.4645 17.5962 12.7574 17.3033L17.5303 12.5303ZM8 12.75H17V11.25H8V12.75Z"
                        fill="white"
                      />
                    </svg>
                  </b>

                  {search !== "" && (
                    <div className="mainSearchResult">
                      <span className="relatly">Recently Searched</span>
                      <div className="searchRepeat">
                        {artcileList.length > 0 && (
                          <span className="title">Articles</span>
                        )}
                        <ul>
                          {artcileList &&
                            artcileList.length > 0 &&
                            artcileList?.slice(0, 3).map((article, index) => {
                              return (
                                <li>
                                  <Link
                                    // onClick={(e) => {
                                    //   handleRedirect(
                                    //     e,
                                    //     `/viewPublishedArticle/${article?._id}`
                                    //   );
                                    // }}
                                    onClick={(e) => {
                                      handleRedirect(
                                        e,
                                        `/${handlejournalNameforurl(article?.journalType?.[0]?.journalType,article?.typeofarticles?.[0]?.articleName)}/${handlesubjectName(article?.subjectDetail?.[0]?.subjectName)}/article/${article?.slug}`
                                      );
                                    }}
                                  >
                                    {" "}
                                    {article?.title}
                                    
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="searchRepeat">
                        {journalList.length > 0 && (
                          <span className="title">Journals</span>
                        )}
                        <ul>
                          {journalList &&
                            journalList.length > 0 &&
                            journalList?.slice(0, 3).map((journal, index) => {
                              return (
                                <li>
                                  {/* {journal?.societyName} */}
                                  {journal?.societyName == "mega" ? (
                                    <>
                                      <Link
                                        // to={`/explore-journals/${journal?._id}`}
                                        to={
                                          journal?.journalName === 'Samaj Shastra – The Mega Journal of Social Sciences' ? '/mega-journals/social-sciences'
                                            : journal?.journalName === 'Chanakya Shastra – The Mega Journal of Business and Management' ? '/mega-journals/business-management'
                                              : journal?.journalName === 'Charakh Shastra – The Mega Journal of Medicine' ? '/mega-journals/medicine'
                                                : journal?.journalName === 'Abhiyantran Shastra – The Mega Journal of Engineering' ? '/mega-journals/engineering'
                                                  : journal?.journalName === 'Soochna Shastra – The Mega Journal of Information Technology' ? '/mega-journals/information-technology'
                                                    : journal?.journalName === 'Vigyan Shastra – The Mega Journal of Sciences' ? '/mega-journals/sciences'
                                                      : journal?.journalName === "AyurYoga Shastra – The Mega Journal of Ayurveda and Yoga" ? '/mega-journals/ayurveda-and-yoga' : null}
                                      >
                                        {" "}
                                        {journal?.journalName}
                                      </Link>
                                    </>) : (<>
                                      <Link
                                         to={`/society-journals/${formatUrl(journal?.subjectData?.subject[0]?.subjectName)}/${journal?.slug}`} >
                                        {" "}
                                        {journal?.journalName}
                                      </Link>
                                    </>)}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="ag_search_icon"
                  onClick={() => {
                    searchStatus
                      ? (setSearchStatus(false), setIsOpen(false))
                      : (setSearchStatus(true), setIsOpen(true));
                  }}
                ></div>
              </div>

              <button
                className="ag_menu_icon"
                onClick={(e) => handleClick(e)}
                aria-label="Main Menu"
              >
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>


              {!token && (
                <Link
                  to="javascript:;"
                  onClick={() => isMobile ? navigate("/registration" + allutm) : navigate("/registration" + allutm)}
                  // onClick={() =>isMobile ? setMobileAlertReg(true): navigate("/login")}
                  className="ag_btn"
                >
                  {isMobile ? 'Register' : 'Register'}
                  {/* {" "} */}
                  {isMobile ? null : <img
                    src="/assets/images/icon-login.png"
                    alt="Authentication Button Icon"
                  />}
                </Link>
              )}
              {token && (
                <Link
                  to="/dashboard"
                  className="ag_btn deskonly daskBtn"
                  onClick={getActiveUser}
                >
                  My dashboard{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.6 7.7H0.7C0.514348 7.7 0.336301 7.77375 0.205025 7.90503C0.0737498 8.0363 0 8.21435 0 8.4V13.3C0 13.4857 0.0737498 13.6637 0.205025 13.795C0.336301 13.9263 0.514348 14 0.7 14H5.6C5.78565 14 5.9637 13.9263 6.09497 13.795C6.22625 13.6637 6.3 13.4857 6.3 13.3V8.4C6.3 8.21435 6.22625 8.0363 6.09497 7.90503C5.9637 7.77375 5.78565 7.7 5.6 7.7ZM13.3 0H8.4C8.21435 0 8.0363 0.0737498 7.90503 0.205025C7.77375 0.336301 7.7 0.514348 7.7 0.7V5.6C7.7 5.78565 7.77375 5.9637 7.90503 6.09497C8.0363 6.22625 8.21435 6.3 8.4 6.3H13.3C13.4857 6.3 13.6637 6.22625 13.795 6.09497C13.9263 5.9637 14 5.78565 14 5.6V0.7C14 0.514348 13.9263 0.336301 13.795 0.205025C13.6637 0.0737498 13.4857 0 13.3 0ZM13.3 7.7H8.4C8.21435 7.7 8.0363 7.77375 7.90503 7.90503C7.77375 8.0363 7.7 8.21435 7.7 8.4V13.3C7.7 13.4857 7.77375 13.6637 7.90503 13.795C8.0363 13.9263 8.21435 14 8.4 14H13.3C13.4857 14 13.6637 13.9263 13.795 13.795C13.9263 13.6637 14 13.4857 14 13.3V8.4C14 8.21435 13.9263 8.0363 13.795 7.90503C13.6637 7.77375 13.4857 7.7 13.3 7.7ZM5.6 0H0.7C0.514348 0 0.336301 0.0737498 0.205025 0.205025C0.0737498 0.336301 0 0.514348 0 0.7V5.6C0 5.78565 0.0737498 5.9637 0.205025 6.09497C0.336301 6.22625 0.514348 6.3 0.7 6.3H5.6C5.78565 6.3 5.9637 6.22625 6.09497 6.09497C6.22625 5.9637 6.3 5.78565 6.3 5.6V0.7C6.3 0.514348 6.22625 0.336301 6.09497 0.205025C5.9637 0.0737498 5.78565 0 5.6 0Z"
                      fill="#167092"
                    />
                  </svg>
                </Link>
              )}
              {token && <Notifications />}
            </div>
          </div>
        </div>
      </div>
      <SubmitArticleModal
        uploadArticle={uploadArticle}
        setUploadArticle={setUploadArticle}
      />
      <MobileModalAlert mobileAlert={mobileAlert}
        setMobileAlert={setMobileAlert} />
      <MobileModalReg mobileAlertReg={mobileAlertReg}
        setMobileAlertReg={setMobileAlertReg} />
    </div>
  );
}

export default HeaderComponent;
