import { useState } from "react";
import NewsLetter from "../components/global/NewsLetter";
import { SUB_HEADINGS } from "../constants";
import SubHeader from "../components/global/SubHeader";
import { Link, useNavigate } from "react-router-dom";

import MeghaJournalObjective from "../components/welcometomeghajournals/MeghaJournalObjective";
import MeghaJournalOpenScienceObjectives from "../components/welcometomeghajournals/MeghaJournalOpenScienceObjectives";
import MeghaJournalTable from "../components/welcometomeghajournals/MeghaJournalTable";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import SubmitArticleModal from "../components/Modal/SubmitArticleModal";
import { isMobile } from 'react-device-detect';
import MobileModalAlert from "../components/Modal/MobileModalAlert";

function WelcomeToMegaJournals() {
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const token = localStorage.getItem("usertoken");
  return (
    <>
    <div className="tagbody">
    <AakashgangaHeader />
      <h1 className="seo-related-tag">
        Welcome To Our Mega Journals: Amplify Your Research Impact
      </h1>
      <main className="main">
        <SubHeader title={SUB_HEADINGS.WELCOME_TO_MEGA_JOURNALS} />

        <div className="content-area welcome-mega-journal">
          <div className="container">
            <MeghaJournalObjective />

            <h2 className="large-title">
              {SUB_HEADINGS.WE_REALLY_SUPPORT_OPEN_SCIENCE}
            </h2>

            <MeghaJournalOpenScienceObjectives />

            <div className="register-button">
              {!token && (
                <button className="tagbutton" onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")}>Publish article</button>
                )}
                {token && (
                  <button className="tagbutton" onClick={() => {
                    isMobile ? setMobileAlert(true):setUploadArticle(true);
                  }}>Publish article</button>
                )}
            </div>

            <MeghaJournalTable />

            <p className="pera-text efforts tagp">
              Our Mega Journals offer an unparalleled opportunity for
              researchers like you to make a substantial impact on the global
              research landscape. By embracing inclusivity, efficiency, and
              expansive reach, Aakashganga Mega Journals empower you to share
              your insights with a diverse and engaged audience, ultimately
              contributing to the advancement of knowledge across disciplines.
            </p>

            <p className="pera-text tagp">
              Ready to amplify your research impact? Join us in our Mega
              Journals journey!
            </p>

            <div className="register-button">
            {!token && (
              <button className="tagbutton" onClick={() =>isMobile ? setMobileAlert(true): navigate("/login")} >Publish article</button>
            )}
            {token && (
              <button className="tagbutton" onClick={() => {
                isMobile ? setMobileAlert(true):setUploadArticle(true);
              }} >Publish article</button>
            )}
            </div>

            <p className="pera-text text-center tagp">
              For more questions on our processes, policies and other aspects,
              please refer to our {" "}
              <Link  to="/faq" target="_blank"><strong className='underline'>FAQ section.</strong></Link>
            </p>
          </div>
        </div>
      </main>
      {/* NewsLetter Start */}
      <NewsLetter />
      <SubmitArticleModal uploadArticle={uploadArticle} setUploadArticle={setUploadArticle} />
      <MobileModalAlert mobileAlert={mobileAlert} setMobileAlert={setMobileAlert} />
      <AakashgangaFooter />
      {/* Footer Redline Start */}
      </div>
    </>
  );
}

export default WelcomeToMegaJournals;
