import React, { useRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
import alertSearch from "../../../assets/img/icons/alertSearch.svg";
import Confetti from "react-confetti";
import {
  reviewArticle,
  updatePRReport,
} from "../../../store/apiCalls/dashboard";
import { FetchProfanityList } from "../submitArticle/queries";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ContentDetails from "../../../components/viewArticle/contentDetails";
import ReviewTrinkaScript from "../../../assets/js/Trinka-new-test";
import { checkEmptyArticle, checkGrammar } from "../../../utils";
import GrammarSpellModal from "../../../components/Modal/GrammarSpellModal";

const SubmitReview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [spellCheckModal, setSpellCheckModal] = useState(false);
  const location = useLocation();
  const articleId = location?.state?.articleId;
  const navigate = useNavigate();
  const [showWorkSaved, setShowWorkSaved] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [updatedResponseData, setUpdatedResponseData] = useState();
  const [saveStatus, setSaveStatus] = useState(false);
  const [profanityWords, setProfanityWords] = useState([]);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });
  const [formErrors, setFormErrors] = useState({
    existingLiterature: "",
    abstract: "",
    adequateReference: "",
    languageAcceptable: "",
    arguedAndStructured: "",
    majorChanges: "",
    strenghtsAndWeeknesses: "",
    conflictOfIntrest: "",
    recommendation: "",
    confidentialCommentsToEditor: "",
  });
  const [prReview, setPrReview] = useState({
    existingLiterature: "",
    abstract: "",
    adequateReference: "",
    languageAcceptable: "",
    arguedAndStructured: "",
    majorChanges: "",
    strenghtsAndWeeknesses: "",
    conflictOfIntrest: "",
    recommendation: "",
    confidentialCommentsToEditor: "",
  });

  const arguedStructuredRef = useRef();
  const strenghtsWeaknessRef = useRef();
  const majorChangesRef = useRef();
  const conflictsOfInterestRef = useRef();
  const confidentialCommentsRef = useRef();

  const continueReview = async () => {
    let error = false;
  
    let arguedStructured = arguedStructuredRef.current?.value;
    let strenghtsWeakness = strenghtsWeaknessRef.current?.value;
    let majorChanges = majorChangesRef.current?.value;
    let conflictsOfInterest = conflictsOfInterestRef.current?.value;
    let editorComments = confidentialCommentsRef.current?.value;
    
    const commonDetl = arguedStructured+' '+strenghtsWeakness+' '+majorChanges+' '+conflictsOfInterest+' '+editorComments;

    dispatch({ type: "SET_LOADER", payload: true });
    const response = await checkGrammar(commonDetl);
    dispatch({ type: "SET_LOADER", payload: false });
    const result = response?.data?.url?.sentences;
    result?.map((sentence) => {
      sentence.result?.forEach((item) => {
        if (
          (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
          (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
        ) {
          error = true;
          setSpellCheckModal(true);
        } else {
          setSpellCheckModal(false);
        }
      });
    });
    if (error === true) {
      setSpellCheckModal(true);
      return;
    } else {
      setSpellCheckModal(false);
    if (validateForm()) {
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const allParams = () => {
        const data = {
          id: id,
          report: true,
          reportStatus: "send",
          prReview: {},
        };
        if (prReview.existingLiterature) {
          data.prReview.existingLiterature = prReview.existingLiterature;
        }
        if (prReview.abstract) {
          data.prReview.abstract = prReview.abstract;
        }
        if (prReview.adequateReference) {
          data.prReview.adequateReference = prReview.adequateReference;
        }
        if (prReview.languageAcceptable) {
          data.prReview.languageAcceptable = prReview.languageAcceptable;
        }
        if (prReview.arguedAndStructured) {
          data.prReview.arguedAndStructured = arguedStructured;
        }
        if (prReview.majorChanges) {
          data.prReview.majorChanges = majorChanges;
        }
        if (prReview.strenghtsAndWeeknesses) {
          data.prReview.strenghtsAndWeeknesses = strenghtsWeakness;
        }
        if (prReview.recommendChanges) {
          data.prReview.recommendChanges = recommendChanges;
        }
        if (prReview.conflictOfIntrest) {
          data.prReview.conflictOfIntrest = conflictsOfInterest;
        }
        if (prReview.recommendation) {
          data.prReview.recommendation = prReview.recommendation;
        }
        if (prReview.confidentialCommentsToEditor) {
          data.prReview.confidentialCommentsToEditor = editorComments;
        }
        return data;
      };
      const response = await updatePRReport(headers, allParams());
      if (response?.status === 200) {
        setShowSubmit(true);
      } else {
        return toast.error("Please try again!!");
      }
    }
  }
  };

  const saveReview = async () => {
    if (validateForm()) {
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const allParams = () => {
        const data = {
          id: id,
          report: true,
          reportStatus: "save",
          prReview: {},
        };
        if (prReview.existingLiterature) {
          data.prReview.existingLiterature = prReview.existingLiterature;
        }
        if (prReview.abstract) {
          data.prReview.abstract = prReview.abstract;
        }
        if (prReview.adequateReference) {
          data.prReview.adequateReference = prReview.adequateReference;
        }
        if (prReview.languageAcceptable) {
          data.prReview.languageAcceptable = prReview.languageAcceptable;
        }
        if (prReview.arguedAndStructured) {
          data.prReview.arguedAndStructured = prReview.arguedAndStructured;
        }
        if (prReview.majorChanges) {
          data.prReview.majorChanges = prReview.majorChanges;
        }
        if (prReview.strenghtsAndWeeknesses) {
          data.prReview.strenghtsAndWeeknesses =
            prReview.strenghtsAndWeeknesses;
        }
        if (prReview.recommendChanges) {
          data.prReview.recommendChanges = prReview.recommendChanges;
        }
        if (prReview.conflictOfIntrest) {
          data.prReview.conflictOfIntrest = prReview.conflictOfIntrest;
        }
        if (prReview.recommendation) {
          data.prReview.recommendation = prReview.recommendation;
        }
        if (prReview.confidentialCommentsToEditor) {
          data.prReview.confidentialCommentsToEditor =
            prReview.confidentialCommentsToEditor;
        }
        return data;
      };
      const response = await updatePRReport(headers, allParams());
      if (response?.status === 200) {
        setSaveStatus(true);
        setShowWorkSaved(true);
      } else {
        return toast.error("Please try again!!");
      }
    }
  };

  const getReviewArticle = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: articleId,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setSaveStatus(true);
      setUpdatedResponseData(response?.data?.currentStatus?.[0]?.prReview);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!prReview?.existingLiterature) {
      errors.existingLiterature = "Kindly choose one of them";
      valid = false;
    }
    if (!prReview?.abstract) {
      errors.abstract = "Kindly choose one of them";
      valid = false;
    }
    if (!prReview?.adequateReference) {
      errors.adequateReference = "Kindly choose one of them";
      valid = false;
    }
    if (!prReview?.languageAcceptable) {
      errors.languageAcceptable = "Kindly choose one of them";
      valid = false;
    }
    if (!prReview?.arguedAndStructured) {
      errors.arguedAndStructured = "This field is required";
      valid = false;
    }
    if (!prReview?.majorChanges) {
      errors.majorChanges = "This field is required";
      valid = false;
    }
    if (!prReview?.strenghtsAndWeeknesses) {
      errors.strenghtsAndWeeknesses = "This field is required";
      valid = false;
    }
    if (!prReview?.recommendation) {
      errors.recommendation = "Kindly choose one of them";
      valid = false;
    }
    if (!prReview?.confidentialCommentsToEditor) {
      errors.confidentialCommentsToEditor = "This field is required";
      valid = false;
    }
    if (!prReview?.conflictOfIntrest) {
      errors.conflictOfIntrest = "This field is required";
      valid = false;
    }

    const commonDetl = prReview.confidentialCommentsToEditor+' '+prReview?.conflictOfIntrest+' '+prReview?.majorChanges+' '+prReview?.strenghtsAndWeeknesses+' '+prReview?.arguedAndStructured;
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(commonDetl?.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList.push(profanityWords[i]);
      }
    }
    setProfanityWordsFound(profanityWordList);
    if (isProfinityWord === true) {
      valid = false;
      setprofanityAlert({ isOPen: true, type: 0 });
    }

    setFormErrors(errors);
    return valid;
  };
  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };
  useEffect(() => {
    const script = document.createElement("script");
      script.src = ReviewTrinkaScript;
      script.id = 'reviewTrinkaId'
      script.async = true;
      if (!document.getElementById('reviewTrinkaId')) {
        document.body.appendChild(script);
      }
    getReviewArticle();
    getProfanity();
  }, []);

  useEffect(() => {
    setPrReview({
      ...prReview,
      existingLiterature: updatedResponseData?.existingLiterature,
      abstract: updatedResponseData?.abstract,
      adequateReference: updatedResponseData?.adequateReference,
      languageAcceptable: updatedResponseData?.languageAcceptable,
      arguedAndStructured: updatedResponseData?.arguedAndStructured,
      majorChanges: updatedResponseData?.majorChanges,
      strenghtsAndWeeknesses: updatedResponseData?.strenghtsAndWeeknesses,
      conflictOfIntrest: updatedResponseData?.conflictOfIntrest,
      recommendation: updatedResponseData?.recommendation,
      confidentialCommentsToEditor:
        updatedResponseData?.confidentialCommentsToEditor,
    });
  }, [saveStatus]);

  const sucessHandlerProfanity = (profanity) => {
    if (profanity.status === 200) {
      setProfanityWords(profanity.data.data);
    }
  };
  async function getProfanity() {
    //
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      headers: headers,
    };

    getProfanityList(queryParams);
  }
  const { mutate: getProfanityList } = FetchProfanityList(
    sucessHandlerProfanity
  );

  return (
    <>
      <div className="submitReviewPage">
        <div className="reviewHead">
        <div className="innerMergin">
          <div className="title">ARTICLE</div>
          <div className="reviewHeadAction">
            <span onClick={() => navigate(-1)}>CLOSE</span>
            <a className="button-link reportsBtn" href="mailto:contactus@vikramshilaedu.in?subject=Aakashganga - Peer Review Query">SUBMIT QUERY</a>
            <span>WRITE REVIEW</span>
          </div>
          </div>
        </div>
        <div className="reportLayout">
          <div className="row">
            <div className="col-md-6 reportLayoutLeft reviewArticleReport">
              <div className="commanBox">
                <ContentDetails
                  details={{ title: "Title", desc: articleData?.title }}
                />
              </div>

              <div className="commanBox">
                <ContentDetails
                  details={{
                    title: "Running Title",
                    desc: articleData?.runningTitle,
                  }}
                />
              </div>

              <div className="commanBox">
                <ContentDetails
                  pagename="review-article"
                  details={{
                    title: "Abstract",
                    desc: articleData?.abstract,
                  }}
                />
              </div>
              {articleData?.articleData?.articleSection?.map(
                (article) =>
                  checkEmptyArticle(article?.content) !== "" && (
                    <div className="commanBox">
                      <ContentDetails
                        pagename="review-article"
                        details={{
                          title: article?.title,
                          desc: article?.content,
                        }}
                      />
                    </div>
                  )
              )}

              {checkEmptyArticle(articleData?.articleData?.references) !==
                "" && (
                <div className="commanBox">
                  <ContentDetails
                    pagename="review-article"
                    details={{
                      title: "References",
                      desc: articleData?.articleData?.references,
                    }}
                  />
                </div>
              )}

              {checkEmptyArticle(articleData?.articleData?.references) !==
                "" && (
                <div className="commanBox">
                  <ContentDetails
                    pagename="review-article"
                    details={{
                      title: "Acknowledgements",
                      desc: articleData?.articleData?.acknowledgement,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="col-md-6">
              {" "}
              <div className="reportLayoutRight">
                <h3 className="titlePree peerReportWrap">
                  peer Review report
                  <div className="group-button">
                    <button className="button-link mr-2" onClick={saveReview}>
                      SAVE
                    </button>
                    <button
                      class="button button-primary ml-2"
                      type="submit"
                      onClick={continueReview}
                    >
                      SUBMIT REPORT
                    </button>
                    <span
                      className="closeBtn"
                      onClick={() => navigate(-1)}
                    ></span>
                  </div>
                </h3>
                <div className="addQuestionWrap">
                  <div className="addQuestionTable">
                    <table>
                      <thead>
                        <tr>
                          <th className="pl-0">QUESTIONNAIRE</th>
                          <th className="text-center">YES</th>
                          <th className="text-center">NO</th>
                          <th className="text-center">NOT APPLICABLE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="pl-0">
                            <div className="questionTd">
                              Does this article contribute to the existing
                              literature on the subject?{" "}
                              <sup className="supStar">*</sup>
                            </div>
                            <small className="articleFormError">
                              {formErrors?.existingLiterature &&
                                `${formErrors?.existingLiterature}`}
                            </small>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="existingLiterature"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    existingLiterature: e.target.value,
                                  })
                                }
                                value={"Yes"}
                                checked={prReview.existingLiterature === "Yes"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="existingLiterature"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    existingLiterature: e.target.value,
                                  })
                                }
                                value={"No"}
                                checked={prReview.existingLiterature === "No"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="existingLiterature"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    existingLiterature: e.target.value,
                                  })
                                }
                                value={"Not Applicable"}
                                checked={
                                  prReview.existingLiterature ===
                                  "Not Applicable"
                                }
                              />
                              <b></b>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-0">
                            <div className="questionTd">
                              Does the Abstract (Summary) clearly and accurately
                              describe the content of the article?{" "}
                              <sup className="supStar">*</sup>
                            </div>
                            <small className="articleFormError">
                              {formErrors?.abstract &&
                                `${formErrors?.abstract}`}
                            </small>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="abstract"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    abstract: e.target.value,
                                  })
                                }
                                value={"Yes"}
                                checked={prReview.abstract === "Yes"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="abstract"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    abstract: e.target.value,
                                  })
                                }
                                value={"No"}
                                checked={prReview.abstract === "No"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="abstract"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    abstract: e.target.value,
                                  })
                                }
                                value={"Not Applicable"}
                                checked={prReview.abstract === "Not Applicable"}
                              />
                              <b></b>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-0">
                            <div className="questionTd">
                              Is adequate reference made to other work in the
                              field? <sup className="supStar">*</sup>
                            </div>
                            <small className="articleFormError">
                              {formErrors?.adequateReference &&
                                `${formErrors?.adequateReference}`}
                            </small>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="adequateReference"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    adequateReference: e.target.value,
                                  })
                                }
                                value={"Yes"}
                                checked={prReview.adequateReference === "Yes"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="adequateReference"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    adequateReference: e.target.value,
                                  })
                                }
                                value={"No"}
                                checked={prReview.adequateReference === "No"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="adequateReference"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    adequateReference: e.target.value,
                                  })
                                }
                                value={"Not Applicable"}
                                checked={
                                  prReview.adequateReference ===
                                  "Not Applicable"
                                }
                              />
                              <b></b>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-0">
                            <div className="questionTd">
                              Is the language acceptable?{" "}
                              <sup className="supStar">*</sup>
                            </div>
                            <small className="articleFormError">
                              {formErrors?.languageAcceptable &&
                                `${formErrors?.languageAcceptable}`}
                            </small>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="languageAcceptable"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    languageAcceptable: e.target.value,
                                  })
                                }
                                value={"Yes"}
                                checked={prReview.languageAcceptable === "Yes"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="languageAcceptable"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    languageAcceptable: e.target.value,
                                  })
                                }
                                value={"No"}
                                checked={prReview.languageAcceptable === "No"}
                              />
                              <b></b>
                            </label>
                          </td>
                          <td className="text-center">
                            <label className="customRadio">
                              <input
                                type="radio"
                                name="languageAcceptable"
                                onChange={(e) =>
                                  setPrReview({
                                    ...prReview,
                                    languageAcceptable: e.target.value,
                                  })
                                }
                                value={"Not Applicable"}
                                checked={
                                  prReview.languageAcceptable ===
                                  "Not Applicable"
                                }
                              />
                              <b></b>
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="commanTitle02">Give your Feedback here:</div>
                    <div className="questionFeedBack">
                      <p>
                        Is the article well-argued and structured? Are the
                        conclusions supported by the argument and the
                        information presented here?{" "}
                        <sup className="supStar">*</sup>
                      </p>

                      <textarea
                        className="form-control"
                        onChange={(e) =>
                          setPrReview({
                            ...prReview,
                            arguedAndStructured: e.target.value,
                          })
                        }
                        value={prReview.arguedAndStructured}
                        ref={arguedStructuredRef}
                      />

                      <small className="articleFormError">
                        {formErrors?.arguedAndStructured &&
                          `${formErrors?.arguedAndStructured}`}
                      </small>
                    </div>
                    <div className="questionFeedBack">
                      <p>
                        What are the main strengths and weaknesses of the
                        article? <sup className="supStar">*</sup>
                      </p>

                      <textarea
                        className="form-control"
                        onChange={(e) =>
                          setPrReview({
                            ...prReview,
                            strenghtsAndWeeknesses: e.target.value,
                          })
                        }
                        value={prReview.strenghtsAndWeeknesses}
                        ref={strenghtsWeaknessRef}
                      />

                      <small className="articleFormError">
                        {formErrors?.strenghtsAndWeeknesses &&
                          `${formErrors?.strenghtsAndWeeknesses}`}
                      </small>
                    </div>
                    <div className="questionFeedBack">
                      <p>
                        Does this article need major changes? If yes, please
                        list them below. <sup className="supStar">*</sup>
                      </p>

                      <textarea
                        className="form-control"
                        onChange={(e) =>
                          setPrReview({
                            ...prReview,
                            majorChanges: e.target.value,
                          })
                        }
                        value={prReview.majorChanges}
                        ref={majorChangesRef}
                      />

                      <small className="articleFormError">
                        {formErrors?.majorChanges &&
                          `${formErrors?.majorChanges}`}
                      </small>
                    </div>

                    <div
                      className="questionFeedBack mb-4"
                    >
                      <p>
                        Please state any conflict(s) of interest that you have
                        in relation to the review of this paper (state “none” if
                        this is not applicable).<sup className="supStar">*</sup>
                      </p>

                      <textarea
                        className="form-control mb-0"
                        onChange={(e) =>
                          setPrReview({
                            ...prReview,
                            conflictOfIntrest: e.target.value,
                          })
                        }
                        value={prReview.conflictOfIntrest}
                        ref={conflictsOfInterestRef}
                      />

                      <small className="articleFormError">
                        {formErrors?.conflictOfIntrest &&
                          `${formErrors?.conflictOfIntrest}`}
                      </small>
                    </div>

                    <div className="commanTitle02">
                      Recommendation <sup className="supStar">*</sup>
                    </div>
                    <div className="recomArt">
                      <label className="customRadio">
                        <input
                          type="radio"
                          name="recommendation"
                          onChange={(e) =>
                            setPrReview({
                              ...prReview,
                              recommendation: e.target.value,
                            })
                          }
                          value={"ACCEPT AND PUBLISH"}
                          checked={
                            prReview.recommendation === "ACCEPT AND PUBLISH"
                          }
                        />
                        <b></b>
                        <span>ACCEPT AND PUBLISH</span>
                      </label>
                      <label className="customRadio">
                        <input
                          type="radio"
                          name="recommendation"
                          onChange={(e) =>
                            setPrReview({
                              ...prReview,
                              recommendation: e.target.value,
                            })
                          }
                          value={"NEEDS MAJOR REVISION"}
                          checked={
                            prReview.recommendation === "NEEDS MAJOR REVISION"
                          }
                        />
                        <b></b>
                        <span>NEEDS MAJOR REVISION</span>
                      </label>
                      <label className="customRadio">
                        <input
                          type="radio"
                          name="recommendation"
                          onChange={(e) =>
                            setPrReview({
                              ...prReview,
                              recommendation: e.target.value,
                            })
                          }
                          value={"REJECT FOR PUBLISHING"}
                          checked={
                            prReview.recommendation === "REJECT FOR PUBLISHING"
                          }
                        />
                        <b></b>
                        <span>REJECT FOR PUBLISHING </span>
                      </label>
                      <small className="articleFormError">
                      {formErrors?.recommendation &&
                        `${formErrors?.recommendation}`}
                    </small>
                    </div>

                    <div className="editorCommaintWrap">
                      <div className="editorCommaint">
                        <div className="commanTitle02">
                          Confidential Comments to the Editor{" "}
                          <sup className="supStar">*</sup>
                        </div>
                        {/* <GrammarlyEditorPlugin> */}
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setPrReview({
                                ...prReview,
                                confidentialCommentsToEditor: e.target.value,
                              })
                            }
                            value={prReview.confidentialCommentsToEditor}
                            ref={confidentialCommentsRef}
                          />
                        {/* </GrammarlyEditorPlugin> */}
                        <small className="articleFormError">
                          {formErrors?.confidentialCommentsToEditor &&
                            `${formErrors?.confidentialCommentsToEditor}`}
                        </small>
                      </div>
                    </div>
                  {/* </Grammarly> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          dialogClassName="modal-dialog-centered modal-sm authorModal md-x"
          show={showSubmit}
          id="congratsMobile"
          tabIndex={-1}
        >
          <Modal.Header className="modal-header">
            <h2 class="modal-title">GOOD TO KNOW</h2>
            <button
              type="button"
              class="btn-close"
              ariaLabel="Close"
              onClick={() => setShowSubmit(false)}
            ></button>
          </Modal.Header>

          <Modal.Body className="pt-0">
            <div className="aaforAss text-center">
              <p>
                Are you sure you want to
                <br />
                submit this report?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <span
              className="btn-link-gray bold mr-3"
              onClick={(e) => setShowSubmit(false)}
            >
              NO, GO BACK
            </span>
            <button
              class="button button-primary"
              onClick={() => {
                setShowSubmit(false);
                setShowAccept(true);
              }}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName="modal-dialog-centered modal-sm authorModal"
          show={showAccept}
          id="congratsMobile"
          tabIndex={-1}
        >
          <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
          <Modal.Header className="modal-header">
            <div class="modal-icon">
              {" "}
              <img src={doneSvg} alt="#" />
            </div>
            <h2 class="modal-title">Thank You</h2>
            <button
              type="button"
              class="btn-close"
              ariaLabel="Close"
              onClick={() => navigate("/dashboard/peer-reviewer")}
            ></button>
          </Modal.Header>

          <Modal.Body className="pt-0">
            <div className="aaforAss pl-2 pr-2">
              <p className="mb-3">
                We appreciate your contribution
                <br />
                to scholarly research.
              </p>
              <p className="mb-3">
                Your review has been successfully sent to the Publishing Editor.
              </p>
              <p>
                As a thank you gesture, we will add 250 Mudras to your Mudra
                Kosh (Wallet) once the article is published.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              class="button button-primary"
              onClick={() => {
                setShowAccept(false);
                navigate("/dashboard/peer-reviewer");
              }}
            >
              DONE
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName="modal-dialog-centered modal-sm authorModal"
          show={showWorkSaved}
          id="congratsMobile"
          tabIndex={-1}
        >
          <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
          <Modal.Header className="modal-header">
            <div class="modal-icon">
              {" "}
              <img src={doneSvg} alt="#" />
            </div>
            <h2 class="modal-title pl-0 pr-0">YOUR WORK HAS BEEN SAVED</h2>
            <button
              type="button"
              class="btn-close"
              ariaLabel="Close"
              onClick={() => setShowWorkSaved(false)}
            ></button>
          </Modal.Header>

          <Modal.Body className="pt-0">
            <div className="aaforAssContent">
              <p>
                Find this report in the{" "}
                <Link to="/peer-reviewer/accepted" state={"accepted"}>
                  Accepted
                </Link>{" "}
                <br />
                section of your dashboard.
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <span
              className="btn-link-active mr-3"
              onClick={(e) => handleRedirect(e, "/dashboard/peer-reviewer")}
            >
              MY DASHBOARD
            </span>
            <button
              class="button button-primary"
              onClick={() => setShowWorkSaved(false)}
            >
              Continue
            </button>
          </Modal.Footer>
        </Modal>

        {/* Profanity Alert */}
        <Modal
          dialogclassName=""
          show={profanityAlert.isOPen}
          id="congratsMobile"
          tabIndex={-1}
          className={
            profanityAlert.type === 0
              ? "authorModal md-x"
              : "authorModal rightSide profanity-content-right"
          }
          centered
        >
          <Modal.Header className="modal-header">
            <div className="modal-icon">
              <img src={alertSearch} alt="#" />
            </div>
            <h5 className="modal-title">PROFANITY check Results</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
            ></button>
          </Modal.Header>
          <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
            <div className="grantDetails alertPopup">
              <div className="checkResultText">
                <strong>
                  Thank you for sharing your Peer Review report for this
                  article.{" "}
                </strong>
                <p>
                  Before your report is processed, please make sure that the
                  following words are removed :
                </p>
              </div>

              <div className="offensiveBox">
                <ol>
                  {profanityWordsFound.map((wrd, index) => (
                    <li>{wrd}</li>
                  ))}
                </ol>
              </div>
              <div className="mt-4 mb-3">
                {profanityAlert.type === 0 && (
                  <button
                    className="button button-primary"
                    type="button"
                    onClick={(e) =>
                      setprofanityAlert({ isOPen: false, type: 0 })
                    }
                  >
                    make Amendments
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="pb-0">

            <div className="helpPopupText pb-3">
              Need help? Write to us at
              <a href="mailto:contactus@vikramshilaedu.in">
                contactus@vikramshilaedu.in
              </a>
            </div>
          </Modal.Footer>
        </Modal>

        <GrammarSpellModal
          spellCheckModal={spellCheckModal}
          setSpellCheckModal={setSpellCheckModal}
          mainContent={"Thank you for sharing your Peer Review report for submission."}
          upperContent={"We have noticed that there are still some grammar and language issues that need your attention."}
          middleContent={"You will find them highlighted in the Peer Review report for correction."}
          lowerContent={"Before your Peer Review report is processed, please make sure that the highlighted issues are fixed."}
        />
      </div>
    </>
  );
};

export default SubmitReview;
