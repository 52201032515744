import React, { useEffect, useState } from "react";
import { Pagination } from "semantic-ui-react";
import jwt_decode from "jwt-decode";
import DashboardLayout from "../../../layout/dashboard";
import { getDateFormat } from "../../../utils";
import Select from "../../../components/common/Select";
import Modal from "react-bootstrap/Modal";
import notyet from "../../../assets/img/icons/notarticle.svg";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { getMudrasHistory } from "../../../store/apiCalls/dashboard";
import { getAllMasterData } from "../../../store/apiCalls/fetchmaster";
import { useNavigate } from "react-router-dom";

function history() {
  const { id } = jwt_decode(localStorage.usertoken);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [user, setUser] = useState(-1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userId, setUserId] = useState(id);
  const [mudrasData, setMudrasData] = useState();
  const [historyResult, setHistoryResult] = useState(false);
  const [page, setPage] = useState(1);
  const [masterReason, setMasterReason] = useState([]);
  const [reason, setReason] = useState('');
  const [role, setRole] = useState('');
  const limit = 10;
  const navigate = useNavigate();
  const options = [
    { value: "Peer Reviewer", label: "Peer Reviewer" },
    { value: "Publish Editor", label: "Publish Editor" },
    { value: "Author", label: "Author" },
  ];
 
  const applyFilter = () => {
    getFilterMudraKosh(role, reason);
  };
  const getFilterMudraKosh = (role, reason) => {
    if (reason != undefined && role != undefined) {
      const fetchdata = async () => {
        const params = { limit, page, userId, reason, role };
        return getMudrasHistory(headers, params);
      };
      fetchdata()
        .then((mudrasData) => {
          setMudrasData(mudrasData.data);
        })
        .catch((err) => console.log(err));
    } else if (role != undefined) {
      const fetchdata = async () => {
        const params = { limit, page, userId, role };
        return getMudrasHistory(headers, params);
      };
      fetchdata()
        .then((mudrasData) => {
          setMudrasData(mudrasData.data);
        })
        .catch((err) => console.log(err));
    } else if (reason != undefined) {
      const fetchdata = async () => {
        const params = { limit, page, userId, reason };
        return getMudrasHistory(headers, params);
      };
      fetchdata()
        .then((mudrasData) => {
          setMudrasData(mudrasData.data);
        })
        .catch((err) => console.log(err));
    } else {
    }
  };
  const handleReset = () => {
     setReason('');
    setRole('');
    fetchMudrasData();
  };
 
  const fetchMudrasData = () => {
    const fetchdata = async () => {
      const params = { limit, page, userId };
      return getMudrasHistory(headers, params);
    };
    fetchdata()
      .then((mudrasData) => {
        setMudrasData(mudrasData.data);
      })
      .catch((err) => console.log(err));
  };
  
  let totalPages = Math.ceil(mudrasData?.totalDataCount / limit);
  async function getMasterList() {
    const msterlst = await getAllMasterData();
    if (msterlst.status === 200) {
      const reasonOptionData = msterlst?.data?.reasonData.map((item) => {
        return {
          value: item.reasonForSendMudras,
          label: item.reasonForSendMudras,
        };
      });
      setMasterReason(reasonOptionData);
    }
  }
  const shortingHistory = (val) => {
    let value = "";
    let short = "";
    if (user == -1) {
      setUser(1);
      short = 1;
    } else {
      setUser(-1);
      short = -1;
    }
    if (val === "title") {
      value = { limit, page, userId, title: short };
    } else if (val === "added") {
      value = { limit, page, userId, added: short };
    } else if (val === "mudras") {
      value = { limit, page, userId, mudras: short };
    }
    const fetchdata = async () => {
      const params = value;
      return getMudrasHistory(headers, params);
    };
    fetchdata()
      .then((mudrasData) => {
        setMudrasData(mudrasData.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMasterList();
    fetchMudrasData();
  }, [page]);
  
  const handleChangeReason = (e) => {
    setReason(e);
};
const handleChangeRole = (e) => {
  setRole(e);
};
const handleRedirect = (e, path) => {
  if (e !== "") e.preventDefault();
  window.scroll({ top: 0, behavior: "smooth" });
  navigate(path);
};
const handleClick = (articleId) => {
  handleRedirect("", `/viewPublishedArticle/${articleId}`);
};

  return (
    <>
         <DashboardLayout>
              <div className="midraMainBox">
                <div className="mudraSearch">
                  <h3>Mudra Transfer History</h3>
                </div>
                <div className="redingFilterWrap ">
                  <div className="commanBox commanBoxBorder0 mb-5">
                    <div className="mudraFilter">
                      <div className="mudraFilterListLeft">
                      <div className="mudraFilterList">
                        <label className="labelForm">Reason</label>
                        <div className="fieldWrap selectBox">
                        <Select
                        handleChange={(e) => handleChangeReason(e)}
                            selectedValue={reason}
                            options={masterReason?.map((val) => val?.value)}
                            optionValues={masterReason?.map(
                              (val) => val?.label
                            )}
                            name="journalType"
                            placeholder=""
                             
                          />
                        </div>
                      </div>
                      <div className="mudraFilterList">
                      <label className="labelForm">Role</label>
                        <div className="fieldWrap selectBox">
                        <Select
                        handleChange={(e) => handleChangeRole(e)}
                            selectedValue={role}
                            options={options?.map((val) => val?.value)}
                            optionValues={options?.map(
                              (val) => val?.label
                            )}
                            name="journalType"
                            placeholder=""
                            
                          />
                          
                        </div>
                      </div>

                      </div>
                      
                       
                      <div className="actionWrap">
                        <span className="filterText">Filter</span>
                        <b className="button-link-gray" onClick={() => handleReset()}>CLEAR ALL</b>
                        <a
                          class="button button-primary"
                          onClick={applyFilter}
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="historyTable">
                  <table>
                    <thead>
                      <tr>
                        <th>REASON</th>
                        <th>ROLE</th>
                        <th className="sortingWrap">
                          <span>
                            TITLE
                            <b
                              className="sort"
                              onClick={() => shortingHistory("title")}
                            />
                          </span>
                        </th>
                        <th className="sortingWrap">
                          <span>
                            Added
                            <b
                              className="sort"
                              onClick={() => shortingHistory("added")}
                            />
                          </span>
                        </th>
                        <th className="text-right sortingWrap mudraTh">
                          <span className="float-right">
                            MUDRAS
                            <b
                              className="sort"
                              onClick={() => shortingHistory("mudras")}
                            />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mudrasData?.data &&
                        mudrasData?.data?.length > 0 &&
                        mudrasData?.data?.map((data) => {
                          return (
                            <>
                            <tr className="blankTr">
                            <td colSpan="5"></td>
                          </tr>
                              <tr>
                                <td>
                                  <div className="hisTableBox">
                                    {data?.reason ? data?.reason : "--"}
                                  </div>
                                </td>
                                <td>
                                  <div className="hisTableBox">
                                    {data?.user?.[0]?.role
                                      ? data?.user?.[0]?.role
                                      : "--"}
                                  </div>
                                </td>
                                <td>
                                  <div className="hisTableBox">
                                    <a className="transText mxWiText" title={data?.article[0]?.title? data?.article[0]?.title: "--"} onClick={(e) => handleClick(data?.articleId)}>{data?.article[0]?.title? data?.article[0]?.title: "--"}</a>
                                  </div>
                                </td>
                                <td>
                                  <div className="hisTableBox">
                                    {" "}
                                    {getDateFormat(data?.createdAt)}
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div className="hisTableBox">
                                    <div className="mudraContent">                                    
                                      <big className="inrText">{data?.tranjectionType == 'debit' ? <span >-</span> : <span >+</span>}{data?.mudras}</big>
                                      <span className="debitColor"> {data?.tranjectionType}</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {mudrasData?.data && mudrasData?.data?.length === 0 && (
                <div className="tabContentWrap">
                  <div className="notArticle">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    <h2>No articles yet!</h2>
                    <p>
                      You have not written any articles.<br/> Articles that you save as drafts will show here
                    </p>
                    
                  </div>
                </div>
              )}

              <div className="backFooterBox">
                <div className="backBtnWrap">
                  <a onClick={() => window.history.back()}>
                    <span>Back</span>
                    <img src={backArrow} alt="#" />
                  </a>
                </div>
                {mudrasData?.data?.length > 0 && (
                  <div className="paginationWrap">
                    <Pagination
                      boundaryRange={1}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      activePage={page}
                      prevItem={{ content: <b class="icon-arrow-left" /> }}
                      nextItem={{ content: <b class="icon-arrow-right" /> }}
                      onPageChange={(event, data) => setPage(data.activePage)}
                      totalPages={totalPages}
                    />
                  </div>
                )}
              </div>

              <div
                className="pullUp"
                onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <b className="icon-arrow-right" />
              </div>
            </DashboardLayout>
          

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-md authorModal"
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Reject Review</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="rejectModal">
            <div className="row">
              <div className="col-md-12">
                <p>Please specify your reason:</p>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>It is my subject but it is not my specialization</span>
                </label>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>Sorry, I cannot meet the deadline </span>
                </label>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>Not my subject area </span>
                </label>
              </div>

              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>It is not my subject </span>
                </label>
              </div>

              <div className="col-md-12">
                <textarea
                  placeholder="Other reason..."
                  className="inputField"
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <button
            class="button button-primary"
            type="submit"
            onClick={handleClose}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={historyResult}
        size="md"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
        className="authorModal md-x"
      >
        <Modal.Header>
          <h5 className="modal-title pr-color">History</h5>
          <button
            type="button"
            className="btn-close"
            onClick={(e) => setHistoryResult(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="historyModalWrap">
            <div className="historyList">
              <p>
                <b>Referral Code</b>
                <i>12 Oct 2022</i>
              </p>
              <span>250 Mudras</span>
            </div>
            <div className="historyList">
              <p>
                <b>Referral Code</b>
                <i>12 Oct 2022</i>
              </p>
              <span>250 Mudras</span>
            </div>
          </div>
          <div className="modalFoot">
            <button className="button button-primary">CLOSE</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default history;
